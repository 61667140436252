export enum ReadingVersion {

  KANDL_BASIC = 'ReadingVersion-BasicKL',
  KANDL_ADJUDICATION = 'ReadingVersion-AdjudicationKL',
  KANDL_KOALA = 'ReadingVersion-KOALAKandL',

  WBMRI_BASIC = 'ReadingVersion-BasicWBMRI',
  WBMRI_ADJUDICATION = 'ReadingVersion-AdjudicationWBMRI',

  MOAKS_BASIC = 'ReadingVersion-BasicMOAKS',
  MOAKS_3Visits = 'MOAKS_3Visits',
  MOAKS_MOCART = 'MOAKS+MOCART',

  CANDEN_SPARCC_BASIC = 'ReadingVersion-BasicCandenSparcc',
  CANDEN_SPARCC_ADJUDICATION = 'ReadingVersion-AdjudicationCandenSparcc',
  CANDEN_SPARCC_MODERATION = 'ReadingVersion-ModerationCandenSparcc',

  PSAMRIS_BASIC = 'ReadingVersion-BasicPsAMRIS',
  PSAMRIS_ADJUDICATION = 'ReadingVersion-AdjudicationPsAMRIS',

  IF_BASIC = 'ReadingVersion-BasicIF',

  DEMRIQ_BASIC = 'ReadingVersion-BasicDEMRIQ',

  NAVADIP5_BASIC = 'ReadingVersion-BasicNOVADIP5',

  LUGANO_BASIC = 'ReadingVersion-BasicLUGANO',

  MRANO_BASIC = 'ReadingVersion-BasicMRANO',

  RANO_ASSESSMENT = 'ReadingVersion-AssessmentMRANO',
  RANO_ADJUDICATION = 'RANO_ADJUDICATION',

  MRANO_EFFICACY = 'ReadingVersion-EfficacyMRANO',

  RECIST_ELIGIBILITY = 'ReadingVersion-RecistEligibility',

  RECIST_BASIC = 'ReadingVersion-BasicRECISTv1.1',

  HEEL_BASIC = 'ReadingVersion-BasicHEEL',

  WEBINAR_BASIC = 'ReadingVersion-BasicWebinar',

  PSMA_PET_ELIGIBILITY_BASIC = 'ReadingVersion-BasicPSMAPET',

  JSW_BASIC = 'ReadingVersion-BasicJSW',

  JSW_MODERATION = 'ReadingVersion-ModerationJSW',

  JSW_3Visits = 'JSW_3Visits',

  OARSI_BASIC = 'ReadingVersion-BasicOARSI',

  ATYR_ELIGIBILITY = 'aTyrEligibility',

  GENANT_ELIGIBILITY_ADJUDICATION = 'AdjudicationGenantEligibility',

  GUERBET_ADJUDICATION = 'GUERBET_ADJUDICATION',

  IF_GENANT_ELIGIBILITY = 'GenantEligibilityIF',

  DXA_ELIGIBILITY = 'GenantEligibilityDXA',

  DXA_SIMPLE_READ = 'SimpleReadDXA',

  IF_Multi_Tab = 'IFMultiTab',

  IF_Historical = 'IFHistorical',

  Advanced_Analysis = 'ADV_ANALYSIS_BASIC',

  L3SMI_BASIC = 'L3SMI_BASIC',

  MEPO_BASIC = 'MEPO_BASIC',

  RAPNO_BASIC = 'RAPNO_BASIC',
}
