import { mJSWScoring } from './../../../_models/IF/mJSW-Scoring-model';
import { Component, OnInit } from '@angular/core';
import {NewTabScoringFormKind, ReadingFormScoring, ReadingFormScoringComponent} from '../reading-form-scoring.component';
import {forkJoin, Observable} from 'rxjs';
import {BasicResponse} from '../../../core/interfaces/basic-response';
import {Store} from '@ngxs/store';
import {
  ImagingProjectService,
  ReadingConfigFlexibleService,
  ReadingIncidentalFindingsService,
  StudySequenceLabelService
} from '../../../_services';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {IFQuestionFormControls, IFQuestionFormGroup} from './question-form-model';
import {MatSelectChange} from '@angular/material/select';
import {IFScreenshotDialog, ScreenshotDialogComponent} from './screenshot-dialog/screenshot-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {b64toBlob} from '../../../util/data-util/blob-utils';
import {MimeType} from '../../../core/constants/mime-type';
import {map, mergeMap, switchMap} from 'rxjs/operators';
import {ToastService} from '../../../_services/internal/toast.service';
import {ResponseCode} from '../../../core/constants/response-code';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';
import { VisitType } from 'src/app/_models/ImagingProject/patient-model';
import { VisitStatus } from 'src/app/_models/ImagingProject/IF/incidental-findings-config-model';
import { ReadingLevel } from 'src/app/core/constants/reading-level';
import { QuestionFormat } from '../../ImagingProject/ImagingProjectReading/IF/imaging-project-reading-if/model.questionformat';
import { ReadingVersion } from 'src/app/core/constants/reading-version';
import { Report } from 'src/app/_models/IF/get-ifmultitab-reports-model';
import { BasicReadingVisit } from 'src/app/_models/basic-reading';

enum QuestionType {
  RADIO_BUTTON_WITH_COMMENT = 'radioButtonWithComment',
  RADIO_BUTTON_WITHOUT_COMMENT = 'radioButtonWithoutComment',
  RADIO_BUTTON_MULTISELECT_WITH_COMMENT = 'radioButtonMultiselectWithComment',
  TEXT_AREA = 'textArea',
  CHECK_BOX = 'checkBox',
  DROP_DOWN = 'dropDown',
  RADIO_BUTTON = 'radioButton',
}

interface Question {
  uuid: string;
  answerType: QuestionType;
  answeringOption1: string | null;
  answeringOption2: string | null;
  questionText: string;
  questionTab: string | null;
  multiSelectAnswers: {
    title: string,
    commentRequired: boolean,
    answer?: boolean,
    answerComment?: string
  }[];
  formControl: FormControl;
  answer?: string;
  comment?: string;
  qIndex?: number;
}

interface IncidentalFinding {
  id: number;
  comment: string;
  fileUrl: string;
  fileUrlPreview: string;
  modalityName: string;
  sequenceLabelName: string;
  sliceNumber: number;
}

@Component({
  selector: 'app-if',
  templateUrl: './incidental-findings.component.html',
  styleUrls: ['./incidental-findings.component.css']
})
export class IncidentalFindingsComponent extends ReadingFormScoringComponent implements OnInit, ReadingFormScoring {
  readonly questionType = QuestionType;
  readonly OTHER = 'other';
  currentReading;
  readingForm: FormGroup = this.fb.group({});
  visitsArrayForm: FormArray = this.fb.array([]);
  visitForm: FormGroup = this.fb.group({});
  incidentalFindingsForm: FormGroup = this.fb.group({});
  screenshotFeatureEnabled = false;
  enableCommentOptionsForScreenshot: boolean;
  predefinedComments: string[];
  visitConfigs: any[];
  flexibleConfig;
  isOpenImage = false;
  selectedVisit = 0;
  subAnswerByVisitAndQuestion = [];
  hiddenForm = false;

  question_tabs = [];
  otherTabCount = 1;
  reports: Report[] = [];
  mJSWScorings: {
    visit: BasicReadingVisit,
    mJSWScorings: mJSWScoring
  }[] = [];
  mJSWServices = [];
  absoluteDifferences = {
    rightKneeLateralDiff: '0',
    rightKneeMedialDiff: '0',
    leftKneeLateralDiff: '0',
    leftKneeMedialDiff: '0',
    rightHipDiff: '0',
    leftHipDiff: '0'
  };
  saveMeasurementsDisabled = true;
  createQuestionFormGroupProcessed = [];

  constructor(private store: Store,
              private studySequenceLabelService: StudySequenceLabelService,
              private readingConfigService: ReadingConfigFlexibleService,
              private fb: FormBuilder,
              private dialog: MatDialog,
              private readingIncidentalFindingsService: ReadingIncidentalFindingsService,
              private imagingProjectService: ImagingProjectService,
              private toastService: ToastService) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(new SetPageHeaderTitle('Incidental Findings'));
    this.initOnCurrentReading();
    this.readingSeriesInitiated.subscribe(resp => {
      if (resp) {

        setTimeout(() => {
          this.chooseActiveVisitByStatus();
        }, 0);

        // to filter the series based on composition id
        if (this.currentReading.readingLevel === ReadingLevel.PATIENT && this.currentReading.readingVersion === ReadingVersion.IF_Multi_Tab) {
          if (this.currentReading['compositionId'] === null) {
            this.filterSeriesNavigatorContentSubject.next({ modality: 'MRI', condition: false });
          } else {
            this.filterSeriesNavigatorContentSubject.next({ modality: 'MRI', condition: true });
          }
        } else {
          this.filterSeriesNavigatorContentSubject.next(null);
        }
      }
    });
    this.hideScoringFormSubject.subscribe(hidden => {
      this.hiddenForm = hidden;
    });
  }

  initOnCurrentReading(): void {

    this.currentReadingSubject.subscribe(currentReading => {
      this.otherTabCount = 1;
      this.visitConfigs = [];
      if (currentReading.readingLevel === ReadingLevel.LONGITUDINAL) {
        this.switchSubmitBtnDisabledSubject.next(false);
      }

      this.newTabScoringFormEnabledSubject.next(currentReading.readingVersion === ReadingVersion.IF_Multi_Tab ? NewTabScoringFormKind.ATTACHED_DETACHED : NewTabScoringFormKind.DISABLED);

      this.readingForm = new FormGroup({});
      this.visitsArrayForm = new FormArray([]);
      this.visitForm = new FormGroup({});
      this.currentReading = currentReading;
      this.initReadingForm();

    });
  }

  initReadingForm(): void {
    this.readingConfigService.getById(this.currentReading.configId).subscribe(resp => {

      this.flexibleConfig = resp.data.config;

      // this.currentReading.visits = this.currentReading.visits.sort((a, b) => a.patientVisitOrder - b.patientVisitOrder);

      this.currentReading.visits.forEach((v, i) => {
      });

      // get visit configs
      const serviceList = [];
      (this.currentReading.visits as any[]).forEach((visit) => {
        serviceList.push(this.imagingProjectService.getVisitConfig(visit.visitConfigId));
      });

      forkJoin(serviceList).subscribe((responseList: any[]) => {

        (this.currentReading.visits as any[]).forEach((visit) => {
          const visitConfig = responseList.find(resp => resp.data.id === visit.visitConfigId).data;

          visit.name = visitConfig.visitName;
          visit.durationTimeUnit = visitConfig.visit.durationTimeUnit;
          visit.durationTimeValue = visitConfig.visit.durationTimeValue;
          visit.type = visitConfig.visitType;
          visit.durationTimeUnit = visitConfig.visit.durationTimeUnit;
          visit.noUploads = visitConfig.noUploads;
          if (this.flexibleConfig.hideVisitChronology === 'hide_visits_chronology') {
            visit.name = visitConfig.visitBlindName; // for blind name
          }
          this.visitConfigs.push(visitConfig);

          this.mJSWScorings.push({visit: visit, mJSWScorings: {
            rightKneeLateralMm: 'NE',
            rightKneeMedialMm: 'NE',
            leftKneeLateralMm: 'NE',
            leftKneeMedialMm: 'NE',
            rightKneeLateralValid: false,
            rightKneeMedialValid: false,
            leftKneeLateralValid: false,
            leftKneeMedialValid: false,
            leftKneeComment: 'NE',
            rightKneeComment: 'NE',
            rightHip: 'NE',
            leftHip: 'NE',
            hipComment: 'NE'
          }});
        });

        this.enableCommentOptionsForScreenshot = resp.data.config.enableCommentOptionsForScreenshot;
        this.predefinedComments = resp.data.config.screenshotComments.map(c => c.optionText);
        this.screenshotFeatureEnabled = resp.data.config.screenshotFeature || resp.data.config.screenshotFeature.amiScreenshotFeature;

        if (this.flexibleConfig.readingVersion !== ReadingVersion.IF_Multi_Tab) {
          this.initIncidentalFindingsBasicVisitsForm();
        } else {
          this.readingIncidentalFindingsService
          .getPreviousIFmultitabReading(this.currentReading.patientId, this.currentReading.studyId)
          .subscribe((response: BasicResponse<Report[]>) => {
            this.reports = response.data;
          });
          this.initIncidentalFindingsMultiTabVisitsForm();
        }
        this.initIncidentalFindingsForm();


      });
    });
  }

  initIncidentalFindingsBasicVisitsForm(): void {
    this.getCurrentVisits().forEach((v, visitIndex) => {

      const visitFormControl = new FormGroup({
        id: new FormControl(v.id),
        visitConfigId: new FormControl(v.visitConfigId),
        name: new FormControl(v.name),
        created: new FormControl(v.created),
        durationTimeUnit: new FormControl(v.durationTimeUnit),
        durationTimeValue: new FormControl(v.durationTimeValue),
        baseline: new FormControl(v.baseline),
        repeatAllowed: new FormControl(v.repeatAllowed),
        status: new FormControl(v.status),
        type: new FormControl(v.type),
        questions: new FormArray([]),
        incidentalFindings: new FormArray([]),
      });

      (<FormArray>this.visitsArrayForm).push(visitFormControl);
      visitFormControl.valueChanges.subscribe(() => {
        this.checkValidity(visitFormControl);
      });
      const finalVisitQuestions = this.getVisitQuestionsFromReadingOrFlexibleConfig(v);
      //create questions for each visit
        (finalVisitQuestions as any[]).forEach((q: Question) => {
          const questionControl = this.fb.group({
            uuid: new FormControl(q.uuid),
            qIndex: new FormControl(q.qIndex),
            answerType: new FormControl(q.answerType, Validators.required),
            answeringOption1: new FormControl(q.answeringOption1),
            answeringOption2: new FormControl(q.answeringOption2),
            questionText: new FormControl(q.questionText, Validators.required),
            multiSelectAnswers: new FormArray([]),
            answer: new FormControl(q.answer,
              // tslint:disable-next-line:max-line-length
              q.answerType === QuestionType.RADIO_BUTTON_WITHOUT_COMMENT || q.answerType === QuestionType.RADIO_BUTTON_WITH_COMMENT || q.answerType === QuestionType.RADIO_BUTTON_MULTISELECT_WITH_COMMENT ?
                Validators.required : []),
            comment: new FormControl(q.comment,
              ((q.answerType === QuestionType.TEXT_AREA || q.answerType === QuestionType.RADIO_BUTTON_WITH_COMMENT) && q.answer !== q.answeringOption1) ? Validators.required : [])
          } as IFQuestionFormControls) as IFQuestionFormGroup;

          if (q.answerType === QuestionType.RADIO_BUTTON_MULTISELECT_WITH_COMMENT) {
            q.multiSelectAnswers.forEach((m) => {
              const control = new FormGroup({
                title: new FormControl(m.title),
                answer: new FormControl(m.answer),
                commentRequired: new FormControl(m.commentRequired),
                answerComment: new FormControl(m.answerComment, m.answer && q.answerType === QuestionType.RADIO_BUTTON_MULTISELECT_WITH_COMMENT && m.commentRequired ? Validators.required : [])
              });
              (<FormArray>questionControl.get('multiSelectAnswers')).push(control);
            });
          }

          (<FormArray>this.visitsArrayForm.controls[visitIndex].get('questions')).push(questionControl);
        });
    });
  }

  initIncidentalFindingsMultiTabVisitsForm(): void {
    this.getCurrentVisits().filter(visit => visit.status !== VisitStatus.DONE).forEach((v, visitIndex) => {
      const bilateralShouldersModel = v.model?.bilateralShoulders;
      const bilateralShouldersFormGroup: FormGroup = this.fb.group({
        qualityAssessment: this.qualityAssessmentFormGroupMaker(v.type, this.currentReading.readingLevel, bilateralShouldersModel?.QualityAssessment),
        exclusionaryFindings: this.exclusionaryFindingsFormGroupMaker(bilateralShouldersModel?.ExclusionaryFindings),
        nonExclusionaryFindings: new FormControl(bilateralShouldersModel?.NonExclusionaryFindings),
        jointStatus: this.jointStatusFormGroupMaker(bilateralShouldersModel?.JointStatus)
      });

      const bilateralHipsModel = v.model?.bilateralHips;
      const bilateralHipsFormGroup: FormGroup = this.fb.group({
        qualityAssessment: this.qualityAssessmentFormGroupMaker(v.type, this.currentReading.readingLevel, bilateralHipsModel?.QualityAssessment),
        presenceOfPriorArthroplasty: this.presenceOfPriorArthroplastyFormGroupMaker(bilateralHipsModel?.PresenceOfPriorArthroplasty),
        kAndL: this.kAndLFormGroupMaker(bilateralHipsModel?.KellgrenAndLawrance),
        exclusionaryFindings: this.exclusionaryFindingsFormGroupMaker(bilateralHipsModel?.ExclusionaryFindings),
        nonExclusionaryFindings: new FormControl(bilateralHipsModel?.NonExclusionaryFindings),
        jointStatus: this.jointStatusFormGroupMaker(bilateralHipsModel?.JointStatus)
      });

      const bilateralKneesModel = v.model?.bilateralKnees;
      const bilateralKneesFormGroup: FormGroup = this.fb.group({
        qualityAssessment: this.qualityAssessmentFormGroupMaker(v.type, this.currentReading.readingLevel, bilateralKneesModel?.QualityAssessment),
        presenceOfPriorArthroplasty: this.presenceOfPriorArthroplastyFormGroupMaker(bilateralKneesModel?.PresenceOfPriorArthroplasty),
        kAndL: this.kAndLFormGroupMaker(bilateralKneesModel?.KellgrenAndLawrance),
        exclusionaryFindings: this.exclusionaryFindingsFormGroupMaker(bilateralKneesModel?.ExclusionaryFindings),
        nonExclusionaryFindings: new FormControl(bilateralKneesModel?.NonExclusionaryFindings),
        jointStatus: this.jointStatusFormGroupMaker(bilateralKneesModel?.JointStatus)
      });

      const kneesMRIModel = v.model?.kneesMRI;
      const kneesMRIFormGroup: FormGroup = this.fb.group({
        qualityAssessment: this.qualityAssessmentFormGroupMaker(v.type, this.currentReading.readingLevel, kneesMRIModel?.QualityAssessment),
        presenceOfPriorArthroplasty: this.presenceOfPriorArthroplastyFormGroupMaker(kneesMRIModel?.PresenceOfPriorArthroplasty),
        exclusionaryFindings: this.exclusionaryFindingsFormGroupMaker(kneesMRIModel?.ExclusionaryFindings),
        nonExclusionaryFindings: new FormControl(kneesMRIModel?.NonExclusionaryFindings),
        jointStatus: this.jointStatusFormGroupMaker(kneesMRIModel?.JointStatus)
      });

      this.question_tabs = [];

      if (v.type !== VisitType.UNSCHEDULED_REGULAR && this.currentReading.readingLevel !== ReadingLevel.PATIENT) {
        if (this.currentReading.readingLevel === 'MODALITY' && this.currentReading.series.find(series => series.modalityName.toLowerCase().includes('mri'))) {
          (<FormGroup>kneesMRIFormGroup.get('jointStatus')).removeControl('result');
          (<FormGroup>kneesMRIFormGroup.get('jointStatus')).addControl('right', new FormControl(kneesMRIModel?.JointStatus?.right ?? null, [Validators.required]));
          (<FormGroup>kneesMRIFormGroup.get('jointStatus')).addControl('left', new FormControl(kneesMRIModel?.JointStatus?.left ?? null, [Validators.required]));
          const visitFormGroup = new FormGroup({
            id: new FormControl(v.id),
            visitConfigId: new FormControl(v.visitConfigId),
            name: new FormControl(v.name),
            created: new FormControl(v.created),
            durationTimeUnit: new FormControl(v.durationTimeUnit),
            durationTimeValue: new FormControl(v.durationTimeValue),
            baseline: new FormControl(v.baseline),
            repeatAllowed: new FormControl(v.repeatAllowed),
            status: new FormControl(v.status),
            type: new FormControl(v.type),
            questions: new FormGroup({
              kneesMRI: kneesMRIFormGroup
            }),
            incidentalFindings: new FormArray([]),
            comment: new FormControl(v.comment),
          });

          (<FormArray>this.visitsArrayForm).push(visitFormGroup);
          visitFormGroup.valueChanges.subscribe(() => {
            this.checkValidity(visitFormGroup);
          });

          this.question_tabs.push([
            {title: 'Knees MRI', value: 'kneesMRI'},
          ]);
        } else {
          (<FormGroup>bilateralKneesFormGroup.get('jointStatus')).removeControl('result');
          (<FormGroup>bilateralKneesFormGroup.get('jointStatus')).addControl('right', new FormControl(bilateralKneesModel?.JointStatus?.right ?? null, [Validators.required]));
          (<FormGroup>bilateralKneesFormGroup.get('jointStatus')).addControl('left', new FormControl(bilateralKneesModel?.JointStatus?.left ?? null, [Validators.required]));
          const visitFormGroup = new FormGroup({
            id: new FormControl(v.id),
            visitConfigId: new FormControl(v.visitConfigId),
            name: new FormControl(v.name),
            created: new FormControl(v.created),
            durationTimeUnit: new FormControl(v.durationTimeUnit),
            durationTimeValue: new FormControl(v.durationTimeValue),
            baseline: new FormControl(v.baseline),
            repeatAllowed: new FormControl(v.repeatAllowed),
            status: new FormControl(v.status),
            type: new FormControl(v.type),
            questions: new FormGroup({
              bilateralShoulders: bilateralShouldersFormGroup,
              bilateralHips: bilateralHipsFormGroup,
              bilateralKnees: bilateralKneesFormGroup
            }),
            incidentalFindings: new FormArray([]),
            comment: new FormControl(v.comment),
          });

          (<FormArray>this.visitsArrayForm).push(visitFormGroup);
          visitFormGroup.valueChanges.subscribe(() => {
            this.checkValidity(visitFormGroup);
          });

          this.question_tabs.push([
            {title: 'Bilateral Shoulders', value: 'bilateralShoulders'},
            {title: 'Bilateral Hips', value: 'bilateralHips'},
            {title: 'Bilateral Knees', value: 'bilateralKnees'},
          ]);
        }
      } else {

        this.contoursDataSubject.subscribe(scores => {
          if (scores !== null && scores !== undefined && scores.seriesId && this.mJSWScorings.length) {
            const currentContours = this.mJSWScorings.find(d => d.visit.series.find( s => s.seriesId === scores.seriesId));
            const currentContoursIndex = this.mJSWScorings.findIndex(d => d.visit.series.find( s => s.seriesId === scores.seriesId));
            // tslint:disable-next-line:max-line-length
            const currentKneesMJSWScoringForm = ((((this.getVisitFormByVisitIndex(visitIndex).get('questions') as FormGroup).get('bilateralKnees') as FormGroup).get('kneesmJSWScorings') as FormArray).controls[currentContoursIndex] as FormGroup);

            if (scores.lateral_leftKneeShortestDistance && scores.medial_leftKneeShortestDistance && scores.lateral_rightKneeShortestDistance && scores.medial_rightKneeShortestDistance) {
              currentContours.mJSWScorings.rightKneeLateralMm = scores.lateral_rightKneeShortestDistance ? scores.lateral_rightKneeShortestDistance : 'NE';
              currentKneesMJSWScoringForm.get('rightLateral').setValue(currentContours.mJSWScorings.rightKneeLateralMm);
              currentContours.mJSWScorings.rightKneeMedialMm = scores.medial_rightKneeShortestDistance ? scores.medial_rightKneeShortestDistance : 'NE';
              currentKneesMJSWScoringForm.get('rightMedial').setValue(currentContours.mJSWScorings.rightKneeMedialMm);
              currentContours.mJSWScorings.leftKneeLateralMm = scores.lateral_leftKneeShortestDistance ? scores.lateral_leftKneeShortestDistance : 'NE';
              currentKneesMJSWScoringForm.get('leftLateral').setValue(currentContours.mJSWScorings.leftKneeLateralMm);
              currentContours.mJSWScorings.leftKneeMedialMm = scores.medial_leftKneeShortestDistance ? scores.medial_leftKneeShortestDistance : 'NE';
              currentKneesMJSWScoringForm.get('leftMedial').setValue(currentContours.mJSWScorings.leftKneeMedialMm);
              this.calculateKneesmJSWAbsoluteDifference();
            } else {
              if (scores.lateral_rightKneeShortestDistance) {
                currentContours.mJSWScorings.rightKneeLateralMm = scores.lateral_rightKneeShortestDistance ? scores.lateral_rightKneeShortestDistance : 'NE';
                currentContours.mJSWScorings.rightKneeLateralValid = true;
                currentKneesMJSWScoringForm.get('rightLateral').setValue(currentContours.mJSWScorings.rightKneeLateralMm);
                this.absoluteDifferences.rightKneeLateralDiff = this.calculateAbsoluteDifference(this.extractMMValues('rightKneeLateralMm'));
              }

              if (scores.medial_rightKneeShortestDistance) {
                currentContours.mJSWScorings.rightKneeMedialMm = scores.medial_rightKneeShortestDistance ? scores.medial_rightKneeShortestDistance : 'NE';
                currentContours.mJSWScorings.rightKneeMedialValid = true;
                currentKneesMJSWScoringForm.get('rightMedial').setValue(currentContours.mJSWScorings.rightKneeMedialMm);
                this.absoluteDifferences.rightKneeMedialDiff = this.calculateAbsoluteDifference(this.extractMMValues('rightKneeMedialMm'));
              }

              if (scores.medial_leftKneeShortestDistance) {
                currentContours.mJSWScorings.leftKneeMedialMm = scores.medial_leftKneeShortestDistance ? scores.medial_leftKneeShortestDistance : 'NE';
                currentContours.mJSWScorings.leftKneeMedialValid = true;
                currentKneesMJSWScoringForm.get('leftMedial').setValue(currentContours.mJSWScorings.leftKneeMedialMm);
                this.absoluteDifferences.leftKneeMedialDiff = this.calculateAbsoluteDifference(this.extractMMValues('leftKneeMedialMm'));
              }

              if (scores.lateral_leftKneeShortestDistance) {
                currentContours.mJSWScorings.leftKneeLateralMm = scores.lateral_leftKneeShortestDistance ? scores.lateral_leftKneeShortestDistance : 'NE';
                currentContours.mJSWScorings.leftKneeLateralValid = true;
                currentKneesMJSWScoringForm.get('leftLateral').setValue(currentContours.mJSWScorings.leftKneeLateralMm);
                this.absoluteDifferences.leftKneeLateralDiff = this.calculateAbsoluteDifference(this.extractMMValues('leftKneeLateralMm'));
              }
            }

            if (scores.Right_Hip_ShortestDistance) {
              currentContours.mJSWScorings.rightHip = scores.Right_Hip_ShortestDistance ? scores.Right_Hip_ShortestDistance : 'NE';
              this.absoluteDifferences.rightHipDiff = this.calculateAbsoluteDifference(this.extractMMValues('rightHip'));
            }

            if (scores.Left_Hip_ShortestDistance) {
              currentContours.mJSWScorings.leftHip = scores.Left_Hip_ShortestDistance ? scores.Left_Hip_ShortestDistance : 'NE';
              this.absoluteDifferences.leftHipDiff = this.calculateAbsoluteDifference(this.extractMMValues('leftHip'));
            }

            this.saveMeasurementsDisabled = false;

            this.updateCommentValidation(visitIndex, currentContoursIndex);
          }
        });

        this.initIncidentalFindingsMultiTabmJSWScoring();

        bilateralShouldersFormGroup.removeControl('jointStatus');
        bilateralShouldersFormGroup.addControl('reportsComment', new FormControl(v.model?.bilateralShoulders?.reportsComment));
        bilateralHipsFormGroup.removeControl('presenceOfPriorArthroplasty');
        bilateralHipsFormGroup.removeControl('kAndL');
        bilateralHipsFormGroup.removeControl('jointStatus');
        bilateralKneesFormGroup.removeControl('presenceOfPriorArthroplasty');
        bilateralKneesFormGroup.removeControl('kAndL');
        bilateralKneesFormGroup.removeControl('jointStatus');
        bilateralHipsFormGroup.addControl('hipsmJSWScorings', this.hipsmJSWScorings());
        bilateralKneesFormGroup.addControl('kneesmJSWScorings', this.kneesmJSWScorings());

        const visitFormGroup = new FormGroup({
          id: new FormControl(v.id),
          visitConfigId: new FormControl(v.visitConfigId),
          name: new FormControl(v.name),
          created: new FormControl(v.created),
          durationTimeUnit: new FormControl(v.durationTimeUnit),
          durationTimeValue: new FormControl(v.durationTimeValue),
          baseline: new FormControl(v.baseline),
          repeatAllowed: new FormControl(v.repeatAllowed),
          status: new FormControl(v.status),
          type: new FormControl(v.type),
          questions: new FormGroup({
            bilateralShoulders: bilateralShouldersFormGroup,
            bilateralHips: bilateralHipsFormGroup,
            bilateralKnees: bilateralKneesFormGroup,
          }),
          incidentalFindings: new FormArray([]),
          comment: new FormControl(v.comment),
        });


        this.question_tabs.push([
          {title: 'Bilateral Shoulders', value: 'bilateralShoulders'},
          {title: 'Bilateral Hips', value: 'bilateralHips'},
          {title: 'Bilateral Knees', value: 'bilateralKnees'},
        ]);

        if (this.currentReading['compositionId'] !== null) {
          this.question_tabs = [];
          (visitFormGroup.get('questions') as FormGroup).removeControl('bilateralHips');
          (visitFormGroup.get('questions') as FormGroup).removeControl('bilateralShoulders');
          this.question_tabs.push([{title: 'Bilateral Knees', value: 'bilateralKnees'}]);
        }

        (<FormArray>this.visitsArrayForm).push(visitFormGroup);
        visitFormGroup.valueChanges.subscribe(() => {
          this.checkValidity(visitFormGroup);
        });
      }

    });
  }

  calculateKneesmJSWAbsoluteDifference() {
    this.absoluteDifferences.leftKneeLateralDiff = this.calculateAbsoluteDifference(this.extractMMValues('leftKneeLateralMm'));
    this.absoluteDifferences.leftKneeMedialDiff = this.calculateAbsoluteDifference(this.extractMMValues('leftKneeMedialMm'));
    this.absoluteDifferences.rightKneeLateralDiff = this.calculateAbsoluteDifference(this.extractMMValues('rightKneeLateralMm'));
    this.absoluteDifferences.rightKneeMedialDiff = this.calculateAbsoluteDifference(this.extractMMValues('rightKneeMedialMm'));
  }
  extractMMValues(key: 'leftKneeLateralMm' | 'leftKneeMedialMm' | 'rightKneeLateralMm' | 'rightKneeMedialMm' | 'rightHip' | 'leftHip') {
    return this.mJSWScorings.map(i => +i.mJSWScorings[key].replace(' mm', ''));
  }
  calculateAbsoluteDifference(values: number[]) {
    const val = values.filter(v => !isNaN(v));
    if (val.length < 2) {
      return 'NaN';
    }
    return Math.abs(Math.max(...val) - Math.min(...val)) + '';
  }

  updateCommentValidation(visitIndex, mJSWScoringIndex) {
    const currentContours = this.mJSWScorings[mJSWScoringIndex];
    const visitForm = this.getVisitFormByVisitIndex(visitIndex);

    const kneesmJSWScorings = visitForm.get('questions.bilateralKnees.kneesmJSWScorings') as FormGroup;
    const hipsmJSWScorings = visitForm.get('questions.bilateralHips.hipsmJSWScorings') as FormArray;
    if (!kneesmJSWScorings || !hipsmJSWScorings) {
      return;
    }

    const currentKneesMJSWScoringForm = kneesmJSWScorings?.controls[mJSWScoringIndex];

    if (['0'].includes(currentContours.mJSWScorings.rightKneeLateralMm) || ['0'].includes(currentContours.mJSWScorings.rightKneeMedialMm)) {
      currentKneesMJSWScoringForm?.get('rightComment').setValidators(Validators.required);
    } else {
      currentKneesMJSWScoringForm?.get('rightComment').setValidators(Validators.nullValidator);
    }
    currentKneesMJSWScoringForm?.get('rightComment').updateValueAndValidity();

    if (['0'].includes(currentContours.mJSWScorings.leftKneeLateralMm) || ['0'].includes(currentContours.mJSWScorings.leftKneeMedialMm)) {
      currentKneesMJSWScoringForm?.get('leftComment').setValidators(Validators.required);
    } else {
      currentKneesMJSWScoringForm?.get('leftComment').setValidators(Validators.nullValidator);
    }
    currentKneesMJSWScoringForm?.get('leftComment').updateValueAndValidity();

    const currentHipsMJSWScoringForm = hipsmJSWScorings?.controls[mJSWScoringIndex];

    if (['0'].includes(currentContours.mJSWScorings.rightHip) || ['0'].includes(currentContours.mJSWScorings.leftHip)) {
      currentHipsMJSWScoringForm?.get('comment').setValidators(Validators.required);
    } else {
      currentHipsMJSWScoringForm?.get('comment').setValidators(Validators.nullValidator);
    }
    currentHipsMJSWScoringForm?.get('comment').updateValueAndValidity();
    this.checkValidity(visitForm);
  }

  kneesmJSWScoringInputChange(visitIndex, mJSWScoringIndex) {
    const currentContours = this.mJSWScorings[mJSWScoringIndex];
    // tslint:disable-next-line:max-line-length
    const currentKneesMJSWScoringForm = ((((this.getVisitFormByVisitIndex(visitIndex).get('questions') as FormGroup).get('bilateralKnees') as FormGroup).get('kneesmJSWScorings') as FormArray).controls[mJSWScoringIndex] as FormGroup);

    currentContours.mJSWScorings.rightKneeLateralMm = currentKneesMJSWScoringForm.get('rightLateral').value ? currentKneesMJSWScoringForm.get('rightLateral').value : 'NE';
    currentContours.mJSWScorings.rightKneeMedialMm = currentKneesMJSWScoringForm.get('rightMedial').value ? currentKneesMJSWScoringForm.get('rightMedial').value : 'NE';
    currentContours.mJSWScorings.leftKneeLateralMm = currentKneesMJSWScoringForm.get('leftLateral').value ? currentKneesMJSWScoringForm.get('leftLateral').value : 'NE';
    currentContours.mJSWScorings.leftKneeMedialMm = currentKneesMJSWScoringForm.get('leftMedial').value ? currentKneesMJSWScoringForm.get('leftMedial').value : 'NE';
    this.calculateKneesmJSWAbsoluteDifference();

    this.saveMeasurementsDisabled = false;

    this.updateCommentValidation(visitIndex, mJSWScoringIndex);
  }

  NESelect(visitIndex, mJSWScoringIndex, region) {
    const currentContours = this.mJSWScorings[mJSWScoringIndex];
    const visitForm = this.getVisitFormByVisitIndex(visitIndex);
    const currentKneesMJSWScoringForm = (visitForm.get('questions.bilateralKnees.kneesmJSWScorings') as FormGroup).controls[mJSWScoringIndex];

    switch (region) {
      case 'rightLateral':
        currentKneesMJSWScoringForm.get('rightLateral').setValue('NE');
        currentContours.mJSWScorings.rightKneeLateralMm = 'NE';
        break;
      case 'rightMedial':
        currentKneesMJSWScoringForm.get('rightMedial').setValue('NE');
        currentContours.mJSWScorings.rightKneeMedialMm = 'NE';
        break;
      case 'leftMedial':
        currentKneesMJSWScoringForm.get('leftMedial').setValue('NE');
        currentContours.mJSWScorings.leftKneeMedialMm = 'NE';
        break;
      case 'leftLateral':
        currentKneesMJSWScoringForm.get('leftLateral').setValue('NE');
        currentContours.mJSWScorings.leftKneeLateralMm = 'NE';
        break;
      case 'rightHip':
        currentContours.mJSWScorings.rightHip = 'NE';
        break;
      case 'leftHip':
        currentContours.mJSWScorings.leftHip = 'NE';
        break;
    }

    this.calculateKneesmJSWAbsoluteDifference();

    this.saveMeasurementsDisabled = false;

    this.updateCommentValidation(visitIndex, mJSWScoringIndex);
  }

  ZeroSelect(visitIndex, mJSWScoringIndex, region) {
    const currentContours = this.mJSWScorings[mJSWScoringIndex];
    const visitForm = this.getVisitFormByVisitIndex(visitIndex);
    const currentKneesMJSWScoringForm = (visitForm.get('questions.bilateralKnees.kneesmJSWScorings') as FormGroup).controls[mJSWScoringIndex];

    switch (region) {
      case 'rightLateral':
        currentKneesMJSWScoringForm.get('rightLateral').setValue('0mm');
        currentContours.mJSWScorings.rightKneeLateralMm = '0';
        break;
      case 'rightMedial':
        currentKneesMJSWScoringForm.get('rightMedial').setValue('0mm');
        currentContours.mJSWScorings.rightKneeMedialMm = '0';
        break;
      case 'leftMedial':
        currentKneesMJSWScoringForm.get('leftMedial').setValue('0mm');
        currentContours.mJSWScorings.leftKneeMedialMm = '0';
        break;
      case 'leftLateral':
        currentKneesMJSWScoringForm.get('leftLateral').setValue('0mm');
        currentContours.mJSWScorings.leftKneeLateralMm = '0';
        break;
      case 'rightHip':
        currentContours.mJSWScorings.rightHip = '0';
        break;
      case 'leftHip':
        currentContours.mJSWScorings.leftHip = '0';
        break;
    }

    this.calculateKneesmJSWAbsoluteDifference();

    this.saveMeasurementsDisabled = false;

    this.updateCommentValidation(visitIndex, mJSWScoringIndex);
  }

  initIncidentalFindingsMultiTabmJSWScoring(): void {
    this.currentReading.visits.forEach((v, visitIndex) => {
      this.mJSWServices.push(this.readingIncidentalFindingsService.getmJSWScorings(this.currentReading.id, v.id));
    });

    forkJoin(this.mJSWServices).subscribe((response: {
      visit: any,
      data: mJSWScoring,
      responseCode: number,
      responseMessage: string
    }[]) => {
      this.mJSWScorings = [];
      this.absoluteDifferences = {
        rightKneeLateralDiff: '0',
        rightKneeMedialDiff: '0',
        leftKneeLateralDiff: '0',
        leftKneeMedialDiff: '0',
        rightHipDiff: '0',
        leftHipDiff: '0'
      };
      response.forEach((r, index) => {
        // tslint:disable-next-line:max-line-length
        this.mJSWScorings.push({
          visit: this.currentReading.visits[index],
          mJSWScorings: r.data !== null ? {
                                            rightKneeLateralMm: r.data.rightKneeLateralMm ? r.data.rightKneeLateralMm : 'NE',
                                            rightKneeMedialMm: r.data.rightKneeMedialMm ? r.data.rightKneeMedialMm : 'NE',
                                            leftKneeLateralMm: r.data.leftKneeLateralMm ? r.data.leftKneeLateralMm : 'NE',
                                            leftKneeMedialMm: r.data.leftKneeMedialMm ? r.data.leftKneeMedialMm : 'NE',
                                            rightKneeLateralValid: true,
                                            rightKneeMedialValid: true,
                                            leftKneeLateralValid: true,
                                            leftKneeMedialValid: true,
                                            leftKneeComment: r.data.leftKneeComment ? r.data.leftKneeComment : 'NE',
                                            rightKneeComment: r.data.rightKneeComment ? r.data.rightKneeComment : 'NE',
                                            rightHip: r.data.rightHip ? r.data.rightHip : 'NE',
                                            leftHip: r.data.leftHip ? r.data.leftHip : 'NE',
                                            hipComment: r.data.hipComment ? r.data.hipComment : 'NE'
                                          } : {
                                            rightKneeLateralMm: 'NE',
                                            rightKneeMedialMm: 'NE',
                                            leftKneeLateralMm: 'NE',
                                            leftKneeMedialMm: 'NE',
                                            rightKneeLateralValid: false,
                                            rightKneeMedialValid: false,
                                            leftKneeLateralValid: false,
                                            leftKneeMedialValid: false,
                                            leftKneeComment: 'NE',
                                            rightKneeComment: 'NE',
                                            rightHip: 'NE',
                                            leftHip: 'NE',
                                            hipComment: 'NE'
                                          }
        });

        const currentKneesMJSWScoringForm = (this.getVisitFormByVisitIndex(0).get('questions.bilateralKnees.kneesmJSWScorings') as FormArray)?.controls[index];
        currentKneesMJSWScoringForm?.setValue({
          rightComment: r.data?.rightKneeComment ?? 'NE',
          rightLateral: r.data?.rightKneeLateralMm ?? 'NE',
          rightMedial: r.data?.rightKneeMedialMm ?? 'NE',
          leftComment: r.data?.leftKneeComment ?? 'NE',
          leftLateral: r.data?.leftKneeLateralMm ?? 'NE',
          leftMedial: r.data?.leftKneeMedialMm ?? 'NE',
        });

        const currentHipsMJSWScoringForm = (this.getVisitFormByVisitIndex(0).get('questions.bilateralHips.hipsmJSWScorings') as FormArray)?.controls[index];

        currentHipsMJSWScoringForm?.setValue({
          comment: r.data?.hipComment ?? '' ,
          left: r.data?.leftHip ?? 'NE' ,
          right: r.data?.rightHip ?? 'NE' ,
        });


        this.updateCommentValidation(0, index);
      });
      this.calculateKneesmJSWAbsoluteDifference();
      this.absoluteDifferences.rightHipDiff = this.calculateAbsoluteDifference(this.extractMMValues('rightHip'));
      this.absoluteDifferences.leftHipDiff = this.calculateAbsoluteDifference(this.extractMMValues('leftHip'));
    });
    this.mJSWServices = [];
  }

  chooseActiveVisitByStatus() {
    const activeVisitIndex = this.getCurrentVisits().findIndex(visit => visit.status === VisitStatus.IN_PROGRESS || visit.status === VisitStatus.NEW_EDITABLE);
    const newNotEditable = this.getCurrentVisits().findIndex(visit => visit.status === VisitStatus.NEW_NOT_EDITABLE);
    if (activeVisitIndex !== -1)
      this.onChangeActivatedVisit(activeVisitIndex);
    else if (newNotEditable !== -1){
      this.onChangeActivatedVisit(newNotEditable);
    }
  }
  onChangeActivatedVisit(index) {
    const visit = this.getCurrentVisits()[index];

    if (this.isLongitudinalReading()) {
      this.selectedVisit = index;
      this.activedVisitSubject.next(this.getCurrentVisits()[index]);
    }

    this.updateTimepointStatus(VisitStatus.IN_PROGRESS, visit);
  }
  updateTimepointStatus(status: VisitStatus, visit) {
    if (visit.status === VisitStatus.IN_PROGRESS ||
      visit.status === VisitStatus.DONE ||
      visit.status === VisitStatus.NOT_AVAILABLE) { return; }

    this.readingIncidentalFindingsService.updateTimepointStatus(this.currentReading.studyId, this.currentReading.id, visit.visitConfigId, status).subscribe(response => {
      if (response.responseCode === ResponseCode.OK) {
        visit.status = status;
      }
    });
  }

  // find the visit which has questions
  getVisitQuestionsFromReadingOrFlexibleConfig(visit: any): QuestionFormat[] {
    let questions: QuestionFormat[];
    if (!visit.model) {
      if (visit.type === VisitType.UNSCHEDULED_EARLY_TERMINATION || visit.type === VisitType.UNSCHEDULED_REGULAR) {
        questions = this.flexibleConfig.visit.find(vi => vi.type === visit.type).questions;
      } else {
        questions = this.flexibleConfig.visit
          .find(vi => vi.durationTimeUnit === visit.durationTimeUnit && vi.durationTimeValue === visit.durationTimeValue).questions;
      }
    } else {
      questions = visit.model;
    }

    const orderedQuestions = (questions || []).sort((a, b) => a.qIndex - b.qIndex);

    return orderedQuestions;
  }

  getVisitFormByVisitIndex(visitIndex: number) {
    return <FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex];
  }

  getQuestionsControlsByVisit(visitIndex: number): any {
    return (<FormArray>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).controls;
  }

  getQuestionsValueByVisit(visitIndex: number): any {
    return (<FormArray>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).value;
  }

  getQuestionControlByVisit(visitIndex , questionIndex) {
    return (<FormArray>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).controls[questionIndex];
  }

  getSubAnswersByVisitAndQuestion(visitIndex: number, questionIndex: number) {
    const questions = this.getVisitQuestionsFromReadingOrFlexibleConfig(this.getCurrentVisits()[visitIndex])
    return questions[questionIndex].multiSelectAnswers;
  }

  getCheckBoxes(visitIndex: number, questionIndex: number) {
    return <FormArray>(<FormGroup>(<FormArray>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).controls[questionIndex]).get('multiSelectCheckBoxes');
  }

  getMultiselectSubAnswers(visitIndex, questionIndex, subIndex) {
    return (<FormArray>(<FormArray>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).controls[questionIndex].get('multiSelectAnswers')).controls[subIndex];
  }

  initIncidentalFindingsForm(): void {
    const currentVisits = this.getCurrentVisits()
    currentVisits.forEach((v, visitIndex) => {

      v.incidentalFindings.forEach(f => {
        this.addIncidentalFindings(f, visitIndex);
      });
    });
    this.readingForm.addControl('visits', this.visitsArrayForm);
    this.checkValidity(this.readingForm);
  }

  addIncidentalFindings(incidentalFinding: IncidentalFinding, visitIndex: number) {
    const form = this.fb.group({
      id: new FormControl(incidentalFinding.id, Validators.required),
      imageUrl: new FormControl(incidentalFinding.fileUrl),
      previewUrl: new FormControl(incidentalFinding.fileUrlPreview, Validators.required),
      comment: new FormControl(this.isCommentOptionsForScreenshotEnabled() ? incidentalFinding.comment : this.OTHER),
      manualComment: new FormControl(incidentalFinding.comment,
        [Validators.maxLength(200), Validators.required]),
      modality: new FormControl(incidentalFinding.modalityName),
      sequenceLabel: new FormControl(incidentalFinding.sequenceLabelName),
      sliceNumber: new FormControl(incidentalFinding.sliceNumber)
    });
    (<FormArray>this.visitsArrayForm.controls[visitIndex].get('incidentalFindings')).push(form)
    form.valueChanges.subscribe(() => {
      this.checkValidity(form);
    });
    form.updateValueAndValidity();
  }

  getInitIncidentalFindingsControlsByVisit(visitIndex: number) {
    return (<FormArray>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('incidentalFindings')).controls;
  }

  getInitIncidentalFindingsControlByVisit(visitIndex: number, ifIndex: number) {
    return (<FormArray>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('incidentalFindings')).controls[ifIndex];
  }

  onChangePredefinedComment(event: MatSelectChange, form: FormGroup & any): void {
    if (event.value === this.OTHER) {
      form.controls.manualComment.setValidators([Validators.required, Validators.maxLength(200)]);
      form.controls.manualComment.setValue(null);
    } else {
      form.controls.manualComment.clearValidators();
      form.controls.manualComment.setValue(event.value);
    }
    form.updateValueAndValidity();
    form.controls.manualComment.updateValueAndValidity();
  }

  openScreenshot(form: FormGroup & any): void {
    this.dialog.open(ScreenshotDialogComponent, {
      height: '95vh',
      width: '900px',
      data: <IFScreenshotDialog>{
        url: form.controls.imageUrl.value,
        modality: form.controls.modality.value,
        label: form.controls.sequenceLabel.value,
        slice: form.controls.sliceNumber.value
      }
    });
  }

  clearForm(): void {
  }

  getEndpointName(): string {
    return 'Incidental Findings';
  }

  loadReadings(studyId: number, readerId: number): Observable<BasicResponse<any>> {
    return this.readingIncidentalFindingsService.getAvailableReadings(studyId, readerId);
  }

  startReading(studyId: number, readingId: number): Observable<BasicResponse<any>> {
    return this.readingIncidentalFindingsService.startReading(studyId, readingId);
  }

  refreshReading() {
    const userId = +JSON.parse(localStorage.getItem('userId'));
    const studyId = +JSON.parse(localStorage.getItem('project')).id;
    if (userId && studyId) {
      this.loadReadings(studyId, userId).toPromise().then((response: BasicResponse<any[]>) => {
          const readings = response.data;
          const updatedReading = readings.find(r => r.id === this.currentReading.id);

          if (updatedReading) {
            this.currentReadingSubject.next(updatedReading);
          }
      });
    }
  }

  onSaveTimepoint(visitIndex: number, lock: boolean) {
    const model = this.buildAnswersObject(visitIndex);
    const visit = this.getCurrentVisits()[visitIndex];
    if (lock) {
      this.readingIncidentalFindingsService.lockTimepoint(this.currentReading.studyId, this.currentReading.id, visit.visitConfigId, model).subscribe(result => {
        if (result.responseCode === ResponseCode.OK) {
          this.toastService.success('SUCCESS: Timepoint is successfully saved and locked' )
          visit.status = VisitStatus.DONE;
          this.chooseActiveVisitByStatus();
          if (this.currentReading.readingVersion === ReadingVersion.IF_Historical) {
            this.refreshReading();
          }
        }
      })
    }
    else {
      this.readingIncidentalFindingsService.updateTimepointQuestion(this.currentReading.studyId, this.currentReading.id, visit.visitConfigId, model).subscribe(response => {
        if (response.responseCode === ResponseCode.OK) {
          this.toastService.success('SUCCESS: Timepoint is successfully saved')
        }
      })
    }
  }

  filterReading(reading: any): boolean {
    return (reading.visits || []).some(v => [VisitStatus.NEW_EDITABLE, VisitStatus.IN_PROGRESS].includes(v.status));
  }

  saveMeasurements() {
    const updatemJSWSServices = [];
    this.currentReading.visits.forEach((v, i) => {
      updatemJSWSServices.push(
        this.readingIncidentalFindingsService.updatemJSWScoringis(this.currentReading.id, v.id, this.mJSWScorings[i].mJSWScorings)
      );
    });

    forkJoin(updatemJSWSServices).subscribe( (response: BasicResponse<mJSWScoring>[]) => {
      this.saveMeasurementsDisabled = true;
    });
  }

  submitReading(studyId: number, data: { spentSeconds: number }): Observable<BasicResponse<any>> {
    const reqData = {
      id: this.currentReading.id,
      spentSeconds: data.spentSeconds,
    };
    const services = [];

    for (const [index, form] of this.toEntries((<FormArray>this.readingForm.get('visits')).controls)) {
      const visitConfigId = form.get('visitConfigId').value;
      const model = this.buildAnswersObject(index);
      // tslint:disable-next-line:max-line-length
      if (this.currentReading.readingVersion === ReadingVersion.IF_Multi_Tab && this.getCurrentVisits()[index].type === VisitType.UNSCHEDULED_REGULAR || (this.currentReading.readingLevel === ReadingLevel.PATIENT && this.currentReading.compositionId === null)) {
        this.saveMeasurements();
      }
      if (form.valid && !this.checkTimePointValidity(index)) {
        services.push(this.readingIncidentalFindingsService.lockTimepoint(this.currentReading.studyId, this.currentReading.id, visitConfigId, model))
      }
    }
    if (services.length > 0) {
      return forkJoin(services)
        .pipe(
          switchMap((result: any) => {
            if (result[0].responseCode === 200) {
              return this.readingIncidentalFindingsService.completeReading(this.currentReading.studyId, reqData);
            }
          }));
    } else {
      return this.readingIncidentalFindingsService.completeReading(this.currentReading.studyId, reqData);
    }
  }

  toEntries<T>(a: T[]) {
    return a.map((value, index) => [index, value] as const);
  }

  buildAnswersObject(visitIndex: number): Object[] {
    let answers;
    if (this.flexibleConfig.readingVersion !== 'IFMultiTab') {
      answers = [];
      for (const fromGroup of this.getQuestionsControlsByVisit(visitIndex)) {
        const controls = fromGroup.value;
        const data = Object();
          data.uuid = controls.uuid;
          data.answerType = controls.answerType;
          data.answeringOption1 = controls.answeringOption1;
          data.answeringOption2 = controls.answeringOption2;
          data.questionText = controls.questionText;
          data.comment = controls.comment;
          data.answer = controls.answer;
          data.multiSelectAnswers = controls.multiSelectAnswers;
          data.qIndex = controls.qIndex;
        answers.push(data);
      }
    } else {
      const data = Object();
      const answer = this.getQuestionsValueByVisit(visitIndex);
      const tabs = this.question_tabs[visitIndex];
      const visitType = this.getCurrentVisits()[this.getCurrentVisits().length - 1].type;
      tabs.forEach((tab: {title: string, value: string}) => {
        if (visitType === VisitType.UNSCHEDULED_REGULAR || this.currentReading.readingLevel === ReadingLevel.PATIENT) {
          if (tab.value.substring(0, 5) === 'other') {
            data[tab.value] = {
              AnatomicalRegion: (answer[tab.value].anatomicalRegion === null || answer[tab.value].anatomicalRegion === undefined) ? 'N/A' : answer[tab.value].anatomicalRegion,
              Modality: (answer[tab.value].modality === null || answer[tab.value].modality === undefined) ? 'N/A' : answer[tab.value].modality,
              QualityAssessment: this.buildQualityAssessmentObject(tab, answer, visitType),
              ExclusionaryFindings: this.buildExclusionaryFindingsObject(tab, answer, visitIndex),
              // tslint:disable-next-line:max-line-length
              NonExclusionaryFindings: (answer[tab.value].nonExclusionaryFindings === null || answer[tab.value].nonExclusionaryFindings === undefined) ? 'N/A' : answer[tab.value].nonExclusionaryFindings,
            };
          } else if (tab.value === 'bilateralShoulders') {
            data[tab.value] = {
              QualityAssessment: this.buildQualityAssessmentObject(tab, answer, visitType),
              reportsComment: (answer[tab.value].reportsComment === null || answer[tab.value].reportsComment === undefined) ? 'N/A' : answer[tab.value].reportsComment,
              ExclusionaryFindings: this.buildExclusionaryFindingsObject(tab, answer, visitIndex),
              // tslint:disable-next-line:max-line-length
              NonExclusionaryFindings: (answer[tab.value].nonExclusionaryFindings === null || answer[tab.value].nonExclusionaryFindings === undefined) ? 'N/A' : answer[tab.value].nonExclusionaryFindings,
            };
          } else if (tab.value === 'bilateralKnees') {

            data[tab.value] = {
              QualityAssessment: this.buildQualityAssessmentObject(tab, answer, visitType),
              reportsComment: (answer[tab.value].reportsComment === null || answer[tab.value].reportsComment === undefined) ? 'N/A' : answer[tab.value].reportsComment,
              ExclusionaryFindings: this.buildExclusionaryFindingsObject(tab, answer, visitIndex),
              // tslint:disable-next-line:max-line-length
              NonExclusionaryFindings: (answer[tab.value].nonExclusionaryFindings === null || answer[tab.value].nonExclusionaryFindings === undefined) ? 'N/A' : answer[tab.value].nonExclusionaryFindings,
              AbsoluteDifference: {
                rightKneeLateral: this.absoluteDifferences.rightKneeLateralDiff === 'NaN' ? 'NE' : this.absoluteDifferences.rightKneeLateralDiff,
                rightKneeMedial: this.absoluteDifferences.rightKneeMedialDiff === 'NaN' ? 'NE' : this.absoluteDifferences.rightKneeMedialDiff,
                leftKneeLateral: this.absoluteDifferences.leftKneeLateralDiff === 'NaN' ? 'NE' : this.absoluteDifferences.leftKneeLateralDiff,
                leftKneeMedial: this.absoluteDifferences.leftKneeMedialDiff === 'NaN' ? 'NE' : this.absoluteDifferences.leftKneeMedialDiff,
              }
            };

            this.currentReading.visits.forEach((v, i) => {
              this.mJSWScorings[i].mJSWScorings.leftKneeComment =
              (answer[tab.value].kneesmJSWScorings[i].leftComment === null ||
                answer[tab.value].kneesmJSWScorings[i].leftComment === undefined) ?
                'NE' : answer[tab.value].kneesmJSWScorings[i].leftComment;
              this.mJSWScorings[i].mJSWScorings.rightKneeComment =
              (answer[tab.value].kneesmJSWScorings[i].rightComment === null ||
                answer[tab.value].kneesmJSWScorings[i].rightComment === undefined) ?
                'NE' : answer[tab.value].kneesmJSWScorings[i].rightComment;
            });

          } else if (tab.value === 'bilateralHips') {
            data[tab.value] = {
              QualityAssessment: this.buildQualityAssessmentObject(tab, answer, visitType),
              ExclusionaryFindings: this.buildExclusionaryFindingsObject(tab, answer, visitIndex),
              // tslint:disable-next-line:max-line-length
              NonExclusionaryFindings: (answer[tab.value].nonExclusionaryFindings === null || answer[tab.value].nonExclusionaryFindings === undefined) ? 'N/A' : answer[tab.value].nonExclusionaryFindings,
              AbsoluteDifference: {
                rightHip: this.absoluteDifferences.rightHipDiff === 'NaN' ? 'NE' : this.absoluteDifferences.rightHipDiff,
                leftHip: this.absoluteDifferences.leftHipDiff === 'NaN' ? 'NE' : this.absoluteDifferences.leftHipDiff,
              }
            };

            this.currentReading.visits.forEach((v, i) => {
                this.mJSWScorings[i].mJSWScorings.hipComment =
                (answer[tab.value].hipsmJSWScorings[i].comment === null ||
                  answer[tab.value].hipsmJSWScorings[i].comment === undefined) ?
                  'NE' : answer[tab.value].hipsmJSWScorings[i].comment;
            });

          }
        } else {
          if (tab.value === 'bilateralHips' || tab.value === 'bilateralKnees') {
            data[tab.value] = {
              QualityAssessment: this.buildQualityAssessmentObject(tab, answer, visitType),
              PresenceOfPriorArthroplasty: this.buildPresenceOfPriorArthroplastyObject(tab, answer),
              KellgrenAndLawrance: this.buildKellgrenAndLawranceObject(tab, answer),
              ExclusionaryFindings: this.buildExclusionaryFindingsObject(tab, answer, visitIndex),
              // tslint:disable-next-line:max-line-length
              NonExclusionaryFindings: (answer[tab.value].nonExclusionaryFindings === null || answer[tab.value].nonExclusionaryFindings === undefined) ? 'N/A' : answer[tab.value].nonExclusionaryFindings,
              JointStatus: this.buildJointStatusObject(tab, answer)
            };
          } else if (tab.value === 'bilateralShoulders') {
            data[tab.value] = {
              QualityAssessment: this.buildQualityAssessmentObject(tab, answer, visitType),
              ExclusionaryFindings: this.buildExclusionaryFindingsObject(tab, answer, visitIndex),
              // tslint:disable-next-line:max-line-length
              NonExclusionaryFindings: (answer[tab.value].nonExclusionaryFindings === null || answer[tab.value].nonExclusionaryFindings === undefined) ? 'N/A' : answer[tab.value].nonExclusionaryFindings,
              JointStatus: this.buildJointStatusObject(tab, answer)
            };
          } else if (tab.value === 'kneesMRI') {
            data[tab.value] = {
              QualityAssessment: this.buildQualityAssessmentObject(tab, answer, visitType),
              PresenceOfPriorArthroplasty: this.buildPresenceOfPriorArthroplastyObject(tab, answer),
              ExclusionaryFindings: this.buildExclusionaryFindingsObject(tab, answer, visitIndex),
              // tslint:disable-next-line:max-line-length
              NonExclusionaryFindings: (answer[tab.value].nonExclusionaryFindings === null || answer[tab.value].nonExclusionaryFindings === undefined) ? 'N/A' : answer[tab.value].nonExclusionaryFindings,
              JointStatus: this.buildJointStatusObject(tab, answer)
            };
          } else {
            data[tab.value] = {
              AnatomicalRegion: (answer[tab.value].anatomicalRegion === null || answer[tab.value].anatomicalRegion === undefined) ? 'N/A' : answer[tab.value].anatomicalRegion,
              QualityAssessment: this.buildQualityAssessmentObject(tab, answer, visitType),
              ExclusionaryFindings: this.buildExclusionaryFindingsObject(tab, answer, visitIndex),
              // tslint:disable-next-line:max-line-length
              NonExclusionaryFindings: (answer[tab.value].nonExclusionaryFindings === null || answer[tab.value].nonExclusionaryFindings === undefined) ? 'N/A' : answer[tab.value].nonExclusionaryFindings,
            };
          }
        }
      });
      answers = data;
    }

    return answers;
  }

  buildQualityAssessmentObject(tab: {title: string, value: string}, answer, visitType: string) {
    if (visitType === VisitType.UNSCHEDULED_REGULAR || this.currentReading.readingLevel === ReadingLevel.PATIENT) {
      return  [
        {
          question: 'Is all protocol required imaging present and of interpretable quality?',
          // tslint:disable-next-line:max-line-length
          answer: (answer[tab.value].qualityAssessment.qualityAssessment === null || answer[tab.value].qualityAssessment.qualityAssessment === undefined) ? 'N/A' : answer[tab.value].qualityAssessment.qualityAssessment,
          comment: (answer[tab.value].qualityAssessment.comment === null || answer[tab.value].qualityAssessment.comment === undefined) ? 'N/A' : answer[tab.value].qualityAssessment.comment,
        }
      ];
    } else {
      return  [
        {
          question: 'Right '.concat(tab.title.substring(0,5) === 'Other' ? 'Side' : (tab.title==='Bilateral Hips'? 'Hip' : tab.title=== 'Bilateral Knees' || tab.title==='Knees MRI' ? 'Knee' :'Shoulder')),
          answer: (answer[tab.value].qualityAssessment.right === null || answer[tab.value].qualityAssessment.right === undefined) ? 'N/A' : answer[tab.value].qualityAssessment.right,
          comment: (answer[tab.value].qualityAssessment.rightComment === null || answer[tab.value].qualityAssessment.rightComment === undefined) ? 'N/A' : answer[tab.value].qualityAssessment.rightComment,
        },
        {
          question: "Left ".concat(tab.title.substring(0,5)==='Other' ? 'Side' : (tab.title==='Bilateral Hips'? 'Hip' : tab.title==='Bilateral Knees' || tab.title==='Knees MRI' ? 'Knee' :'Shoulder')),
          answer: (answer[tab.value].qualityAssessment.left === null || answer[tab.value].qualityAssessment.left === undefined) ? 'N/A' : answer[tab.value].qualityAssessment.left,
          comment: (answer[tab.value].qualityAssessment.leftComment === null || answer[tab.value].qualityAssessment.leftComment === undefined) ? 'N/A' : answer[tab.value].qualityAssessment.leftComment
        }
      ];
    }
  }

  buildPresenceOfPriorArthroplastyObject(tab: {title:string, value:string}, answer) {
    return  [
      {
        question: 'Total Arthroplasty',
        // tslint:disable-next-line:max-line-length
        right: (answer[tab.value].presenceOfPriorArthroplasty.totalArthroplasty.isRight === null || answer[tab.value].presenceOfPriorArthroplasty.totalArthroplasty.isRight === undefined) ? 'N/A' : answer[tab.value].presenceOfPriorArthroplasty.totalArthroplasty.isRight,
        // tslint:disable-next-line:max-line-length
        left: (answer[tab.value].presenceOfPriorArthroplasty.totalArthroplasty.isLeft === null || answer[tab.value].presenceOfPriorArthroplasty.totalArthroplasty.isLeft === undefined) ? 'N/A' : answer[tab.value].presenceOfPriorArthroplasty.totalArthroplasty.isLeft
      },
      {
        question: 'Hemi Arthroplasty',
        // tslint:disable-next-line:max-line-length
        right: (answer[tab.value].presenceOfPriorArthroplasty.hemiArthroplasty.isRight === null || answer[tab.value].presenceOfPriorArthroplasty.hemiArthroplasty.isRight === undefined) ? 'N/A' : answer[tab.value].presenceOfPriorArthroplasty.hemiArthroplasty.isRight,
        // tslint:disable-next-line:max-line-length
        left: (answer[tab.value].presenceOfPriorArthroplasty.hemiArthroplasty.isLeft === null || answer[tab.value].presenceOfPriorArthroplasty.hemiArthroplasty.isLeft === undefined) ? 'N/A' : answer[tab.value].presenceOfPriorArthroplasty.hemiArthroplasty.isLeft,
        // tslint:disable-next-line:max-line-length
        leftAnswer: (answer[tab.value].presenceOfPriorArthroplasty.hemiArthroplasty.left === null || answer[tab.value].presenceOfPriorArthroplasty.hemiArthroplasty.left === undefined) ? 'N/A' : answer[tab.value].presenceOfPriorArthroplasty.hemiArthroplasty.left,
        // tslint:disable-next-line:max-line-length
        rightAnswer: (answer[tab.value].presenceOfPriorArthroplasty.hemiArthroplasty.right === null || answer[tab.value].presenceOfPriorArthroplasty.hemiArthroplasty.right === undefined) ? 'N/A' : answer[tab.value].presenceOfPriorArthroplasty.hemiArthroplasty.right,
        lComment: (answer[tab.value].presenceOfPriorArthroplasty.hemiArthroplasty.leftComment === null || answer[tab.value].presenceOfPriorArthroplasty.hemiArthroplasty.leftComment === undefined) ? 'N/A' : answer[tab.value].presenceOfPriorArthroplasty.hemiArthroplasty.leftComment,
        rComment: (answer[tab.value].presenceOfPriorArthroplasty.hemiArthroplasty.rightComment === null || answer[tab.value].presenceOfPriorArthroplasty.hemiArthroplasty.rightComment === undefined) ? 'N/A' : answer[tab.value].presenceOfPriorArthroplasty.hemiArthroplasty.rightComment
      }
    ];
  }

  buildKellgrenAndLawranceObject(tab: {title:string, value:string}, answer) {
    return  [
      {
        question: 'Right '.concat(tab.title === 'Bilateral Hips' ? 'Hip' : 'Knee'),
        answer: (answer[tab.value].kAndL.right === null || answer[tab.value].kAndL.right === undefined) ? 'N/A' : answer[tab.value].kAndL.right
      },
      {
        question: 'Left '.concat(tab.title === 'Bilateral Hips' ? 'Hip' : 'Knee'),
        answer: (answer[tab.value].kAndL.left === null || answer[tab.value].kAndL.left === undefined) ? 'N/A' : answer[tab.value].kAndL.left
      },
    ];
  }

  buildJointStatusObject(tab: {title: string, value: string}, answer) {
    if (tab.value === 'kneesMRI') {
      return {
        right: (answer[tab.value].jointStatus.right === null || answer[tab.value].jointStatus.right === undefined) ? 'N/A' : answer[tab.value].jointStatus.right,
        left: (answer[tab.value].jointStatus.left === null || answer[tab.value].jointStatus.left === undefined) ? 'N/A' : answer[tab.value].jointStatus.left,
        comment: (answer[tab.value].jointStatus.comment === null || answer[tab.value].jointStatus.comment === undefined) ? 'N/A' : answer[tab.value].jointStatus.comment
      };
    }

    if (tab.value === 'bilateralKnees') {
      let result = null;
      if (answer[tab.value].jointStatus.right === 'Eligible' || answer[tab.value].jointStatus.left === 'Eligible') {
        result = 'Eligible';
      } else if (answer[tab.value].jointStatus.right === 'Requires Clinical Correlation' || answer[tab.value].jointStatus.left === 'Requires Clinical Correlation') {
        result = 'Requires Clinical Correlation';
      } else {
        result = 'Not Eligible';
      }

      return {
        result,
        right: (answer[tab.value].jointStatus.right === null || answer[tab.value].jointStatus.right === undefined) ? 'N/A' : answer[tab.value].jointStatus.right,
        left: (answer[tab.value].jointStatus.left === null || answer[tab.value].jointStatus.left === undefined) ? 'N/A' : answer[tab.value].jointStatus.left,
        comment: (answer[tab.value].jointStatus.comment === null || answer[tab.value].jointStatus.comment === undefined) ? 'N/A' : answer[tab.value].jointStatus.comment
      };
    }

    return {
      result: (answer[tab.value].jointStatus.result === null || answer[tab.value].jointStatus.result === undefined) ? 'N/A' : answer[tab.value].jointStatus.result,
      comment: (answer[tab.value].jointStatus.comment === null || answer[tab.value].jointStatus.comment === undefined) ? 'N/A' : answer[tab.value].jointStatus.comment
    };
  }

  buildExclusionaryFindingsObject(tab: {title: string, value: string}, answer, visitIndex?: number): object[] {
    const efq = this.exclusionaryFindingQuestions(visitIndex);
    const data: object[] = [];
    if (this.getCurrentVisits()[visitIndex].type !== VisitType.UNSCHEDULED_REGULAR && this.currentReading.readingLevel !== ReadingLevel.PATIENT) {
      if (tab.title === 'Bilateral Knees' || tab.title === 'Knees MRI' || tab.title === 'Bilateral Shoulders' || tab.value.slice(0, 5) === 'other') {
        efq.splice(efq.findIndex(e => e.title === 'CHD With Degenerative Joint Disease'), 1);
      }
      if (tab.title === 'Bilateral Hips' || tab.title === 'Knees MRI' || tab.title === 'Bilateral Shoulders' || tab.value.slice(0, 5) === 'other') {
        efq.splice(efq.findIndex(e => e.title === 'Excessive Malalignment Of The Knee'), 1);
      }
    }
    if (tab.title === 'Bilateral Hips' || tab.title === 'Bilateral Shoulders' || tab.title === 'Bilateral Knees' || tab.value.slice(0, 5) === 'other') {
      efq.splice(efq.findIndex(e => e.title === 'Posterior Meniscal Root Tear'), 1);
    }
    if (answer[tab.value].exclusionaryFindings.other.isOther === null || answer[tab.value].exclusionaryFindings.other.isOther === false) {
      efq.splice(efq.findIndex(e => e.title === 'Other'), 1);
    }
    // tslint:disable-next-line:max-line-length
    if (tab.title === 'Knees MRI' || tab.value.slice(0, 5) === 'other' || this.getCurrentVisits()[visitIndex].type === VisitType.UNSCHEDULED_REGULAR || this.currentReading.readingLevel === ReadingLevel.PATIENT) {
      efq[efq.findIndex(e => e.title === 'Stress Fracture')].title = 'Stress Fracture or Stress Reaction';
    }

    const targetKneeRight = (answer[tab.value].exclusionaryFindings.targetKneeRight);
    const targetKneeLeft = (answer[tab.value].exclusionaryFindings.targetKneeLeft);

    for (let i = 0 ; i < efq.length ; i++) {
      data.push(
        {
          question: efq[i].title === 'Other' ? answer[tab.value].exclusionaryFindings[efq[i].value].text : efq[i].title,
          // tslint:disable-next-line:max-line-length
          presence: (answer[tab.value].exclusionaryFindings[efq[i].value][efq[i].isChoosen] === null || answer[tab.value].exclusionaryFindings[efq[i].value][efq[i].isChoosen] === undefined) ? false : answer[tab.value].exclusionaryFindings[efq[i].value][efq[i].isChoosen],
          // tslint:disable-next-line:max-line-length
          right: (answer[tab.value].exclusionaryFindings[efq[i].value].isRight === null || answer[tab.value].exclusionaryFindings[efq[i].value].isRight === undefined && targetKneeRight === true) ? false : answer[tab.value].exclusionaryFindings[efq[i].value].isRight,
          // tslint:disable-next-line:max-line-length
          rightLocation: (answer[tab.value].exclusionaryFindings[efq[i].value].right === null || answer[tab.value].exclusionaryFindings[efq[i].value].right === undefined) ? 'N/A' : answer[tab.value].exclusionaryFindings[efq[i].value].right.toString(),
          // tslint:disable-next-line:max-line-length
          left: (answer[tab.value].exclusionaryFindings[efq[i].value].isLeft === null || answer[tab.value].exclusionaryFindings[efq[i].value].isLeft === undefined  && targetKneeLeft === true) ? false : answer[tab.value].exclusionaryFindings[efq[i].value].isLeft,
          // tslint:disable-next-line:max-line-length
          leftLocation: (answer[tab.value].exclusionaryFindings[efq[i].value].left === null || answer[tab.value].exclusionaryFindings[efq[i].value].left === undefined) ? 'N/A' : answer[tab.value].exclusionaryFindings[efq[i].value].left.toString(),
          // tslint:disable-next-line:max-line-length
          comment: (answer[tab.value].exclusionaryFindings[efq[i].value].comment === null || answer[tab.value].exclusionaryFindings[efq[i].value].comment === undefined) ? 'N/A' : answer[tab.value].exclusionaryFindings[efq[i].value].comment
        }
      );
    }

    return data;
  }

  updateReading(studyId: number, readingId: number, data: { spentSeconds: number }): Observable<BasicResponse<any>> {
    const readingData = { id: this.currentReading.id, spentSeconds: data.spentSeconds};
    return this.readingIncidentalFindingsService.updateReadingOld(studyId, this.currentReading.id, readingData);
  }

  updateIncidentalFinding(form: FormGroup & any, visitIndex: number): void {
    if (this.checkTimePointValidity(visitIndex))
      return;
    const data = {
      id: form.controls.id.value,
      comment: form.controls.comment.value === this.OTHER ? form.controls.manualComment.value : form.controls.comment.value
    };
    this.getCurrentVisits()[visitIndex].incidentalFindings.find(IF => IF.id === data.id).comment = data.comment;
    this.readingIncidentalFindingsService.updateIncidentialFindings(this.currentReading.studyId, data).subscribe(updateResp => {
      if (updateResp.responseCode !== ResponseCode.OK) {
        this.toastService.error('Update the Incidental Finding failed', 'Something went wrong. Try again or contact support team.');
      }
    }, () => this.toastService.error('Unexpected error', 'Something went wrong. Try again or contact support team.'));
  }

  onTakeScreenshot(data: { image: string; preview: string; seriesId: string; sliceNum: number }) {
    if (this.checkTimePointValidity(this.selectedVisit)) {
      this.toastService.info('INFO: Cannot add screenshot', 'The current timepoint is not editable');
      return;
    }
    data.sliceNum++;
    const blob = b64toBlob(data.image.split(',')[1], MimeType.JPEG);
    const blobPreview = b64toBlob(data.preview.split(',')[1], MimeType.JPEG);
    const series = this.visitConfigs[this.selectedVisit].series.find(s => s.seriesUID === data.seriesId);

    let linkData: IncidentalFindingsData;
    const request = this.readingIncidentalFindingsService
      .getIncidentalFindingsUploadLink(this.currentReading.studyId, this.currentReading.id, this.getCurrentVisits()[this.selectedVisit].visitConfigId, series.id, data.sliceNum);

      request.pipe(
        map(linkResp => {
          linkData = linkResp.data;
          forkJoin([
            this.readingIncidentalFindingsService.uplodFileToCloud(linkData.fileUrl, blob),
            this.readingIncidentalFindingsService.uplodFileToCloud(linkData.fileUrlPreview, blobPreview)
          ]).subscribe(([imgUploadRes, imgPreviewUploadRes]) => {
          }, () => this.toastService.error('Unexpected error', 'Something went wrong. Try again or contact support team.'));
          return;
        }),
        switchMap(() => {
          return forkJoin([
            this.studySequenceLabelService.getStudyModalitiesByStudyId(this.currentReading.studyId),
            this.studySequenceLabelService.getSequenceLabelsByStudyId(this.currentReading.studyId)
          ]);
        })
      )
      .pipe(
        mergeMap(([modalitiesResp, labelsResp]) => {
          const modality = modalitiesResp.data.find(mod => mod.name === series.projectModality);
          const sequenceLabel = labelsResp.data.find(la => la.name === series.label);

          if (series.projectModality == null) {
            linkData.modalityName = series.label;
          } else {
            linkData.modalityId = modality.id;
            linkData.modalityName = modality.name;
            if (sequenceLabel == null) {
              linkData.sequenceLabelName = series.label;
            } else {
              linkData.sequenceLabelId = sequenceLabel.id;
              linkData.sequenceLabelName = sequenceLabel.name;
            }
          }
          linkData.seriesId = series.id;
          linkData.sliceNumber = data.sliceNum;
          return this.readingIncidentalFindingsService
            .createIncidentalFindingsEntry(this.currentReading.studyId, +JSON.parse(localStorage.getItem('userId')),
              this.currentReading.id, this.getCurrentVisits()[this.selectedVisit].visitConfigId, linkData);
        }))
      .subscribe(response => {
        this.getCurrentVisits()[this.selectedVisit].incidentalFindings.push(response.data);
        this.addIncidentalFindings(response.data, this.selectedVisit);
      });

      return request;
  }

  isCommentOptionsForScreenshotEnabled(): boolean {
    return this.enableCommentOptionsForScreenshot;
  }

  deleteScreenshot(screenshot: IncidentalFinding, visitIndex: number): void {
    const userId: number = +JSON.parse(localStorage.getItem('userId'));
    const projectId: number = JSON.parse(localStorage.getItem('project')).id;

    this.readingIncidentalFindingsService.deleteIncidentialFindingsEntry(projectId, userId, screenshot.id).subscribe(deleteResp => {
      if (deleteResp.responseCode !== ResponseCode.OK) {
        this.toastService.error('Delete the Screenshot failed', 'Something went wrong. Try again or contact support team.');
      }
      this.removeIncidentalFindingFromLocalCurrentReading(screenshot, visitIndex);
    }, () => this.toastService.error('Unexpected error', 'Something went wrong. Try again or contact support team.'));
  }

  onRadioButtonWithCommentChange(visitIndex: number, questionIndex: number) {
    const visit = <FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex];
    const question = <FormGroup>(<FormArray>visit.get('questions')).controls[questionIndex];
    const commentField: FormControl = <FormControl>question.get('comment');
    if (this.isRadioButtonSecondOptionChosen(visitIndex, questionIndex)) {
      commentField.setValidators(Validators.required);
      commentField.setValue('');
      commentField.markAsUntouched();
    } else {
      commentField.setValidators(Validators.nullValidator);
    }
    commentField.updateValueAndValidity();
  }

  onRadioButtonForDisablingChackbox(visitIndex: number, questionIndex: number) {
    this.subAnswerByVisitAndQuestion = this.getSubAnswersByVisitAndQuestion(visitIndex, questionIndex)
    if(this.isRadioButtonSecondOptionChosen(visitIndex, questionIndex)) {
    } else {
      this.checkMultiselectAnswersSelected (visitIndex, -1);

      const visit = <FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex];
      const question = <FormGroup>(<FormArray>visit.get('questions')).controls[questionIndex];
      for(let i=0; i<this.subAnswerByVisitAndQuestion.length; i++){
        const multianswer = <FormGroup>(<FormArray>question.get('multiSelectAnswers')).controls[i];
        <unknown>multianswer.get('answer').setValue(null);
        const commentField: FormControl = <FormControl>multianswer.get('answerComment');
        commentField.setValidators(Validators.nullValidator);
        commentField.updateValueAndValidity();
      }
    }
  }

  onCheckboxSubAnswerChange(visitIndex: number, questionIndex: number, subIndex: number) {
    const visit = <FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex];
    const question = <FormGroup>(<FormArray>visit.get('questions')).controls[questionIndex];
    const multianswer = <FormGroup>(<FormArray>question.get('multiSelectAnswers')).controls[subIndex];

    const commentField: FormControl = <FormControl>multianswer.get('answerComment');
    if (<FormControl>multianswer.get('commentRequired').value && <FormControl>multianswer.get('answer').value) {
      commentField.setValidators(Validators.required);
      commentField.setValue('');
      commentField.markAsUntouched();
    } else {
      commentField.setValidators(Validators.nullValidator);
    }
    commentField.updateValueAndValidity();
  }

  isRadioButtonSecondOptionChosen(visitIndex: number, questionIndex: number): boolean {
    const visit = (<FormArray>this.readingForm.controls.visits).controls[visitIndex];
    const quesion = (<FormArray>visit.get('questions')).controls[questionIndex];
    return quesion.get('answer').value === quesion.get('answeringOption2').value;
  }

  onOpenImage(value: boolean) {
    this.isOpenImage = value;
    //this.checkValidity();
  }

  isLongitudinalReading() {
   return this.currentReading?.readingLevel === ReadingLevel.LONGITUDINAL
  }

  checkMultiselectAnswersSelected(visitIndex: number, checkInd?: number) {
    let checkIndex = checkInd | 0;
    const visit = (<FormArray>this.readingForm.controls.visits).controls[visitIndex];
    const quesions = (<FormArray>visit.get('questions')).controls;
    for (const [index, form] of this.toEntries(quesions)) {

      if (form.get('answer').value === form.get('answeringOption2').value &&
        form.get('answerType').value === this.questionType.RADIO_BUTTON_MULTISELECT_WITH_COMMENT) {
        const multiSelectAnswers = form.get('multiSelectAnswers').value as any[];
        if (multiSelectAnswers.filter(ans => ans.answer).length === 0) {
          checkIndex++;
        }
      }

    }
    return checkIndex > 0;
  }

  disableBySequntialLockAndSmartRead(changeStatus: boolean = false, visitIndex: number) {
    const reading = this.currentReading;
    const vis = reading.visits[visitIndex];

    if (vis.noUploads) return true;

    if (!reading.sequentialLock) return false;

    const previousVisitsNotDone = reading.visits.findIndex(v => !v.noUploads
      && !(v.status === VisitStatus.DONE
        // For IFHistorical we need to allow currect visit edit if previous visits does not have real uploads
          || (v.status === VisitStatus.NOT_AVAILABLE && reading.readingVersion === ReadingVersion.IF_Historical))) < visitIndex;

    if (changeStatus) {
      return visitIndex === 0 ? false : previousVisitsNotDone;
    }

    return (!reading.smartRead && vis.status !== VisitStatus.IN_PROGRESS && vis.status !== VisitStatus.NEW_EDITABLE)
     || vis.status === VisitStatus.NOT_AVAILABLE
     || (vis.status !== VisitStatus.DONE && visitIndex > 1 && previousVisitsNotDone);
  }

  checkTimePointValidity(visitIndex: number) {
    return this.getCurrentVisits()[visitIndex].status === VisitStatus.DONE ||
      this.getCurrentVisits()[visitIndex].status === VisitStatus.NEW_NOT_EDITABLE ||
      this.getCurrentVisits()[visitIndex].status === VisitStatus.NOT_AVAILABLE ||
      this.disableBySequntialLockAndSmartRead(true, visitIndex);
  }

  private checkValidity(formControl) {
    if (this.currentReading.readingLevel !== ReadingLevel.LONGITUDINAL) {
      const isDisabled = this.incidentalFindingsForm.invalid
        || formControl.invalid
        || ((this.flexibleConfig.readingVersion !== 'IFMultiTab') ? this.checkMultiselectAnswersSelected(0) : false);

      this.switchSubmitBtnDisabledSubject.next(isDisabled);
    }
  }

  private removeIncidentalFindingFromLocalCurrentReading(incidentalFinding: IncidentalFinding, visitIndex: number): void {
    const index = this.getCurrentVisits()[visitIndex].incidentalFindings.findIndex(x => x.id === incidentalFinding.id);
    if (index > -1) {
      this.getCurrentVisits()[visitIndex].incidentalFindings.splice(index, 1);
      (<FormArray>(<FormArray>this.readingForm.controls.visits).controls[visitIndex].get('incidentalFindings')).removeAt(index)
    }
  }

  qualityAssessmentFormGroupMaker(visitType: string, readingLevel: string, qualityAssessments?: any) {
    if (visitType === VisitType.UNSCHEDULED_REGULAR || readingLevel === ReadingLevel.PATIENT) {
      return this.fb.group({
        qualityAssessment: new FormControl(qualityAssessments?.[0]?.answer ?? null, [Validators.required]),
        comment: new FormControl({value: qualityAssessments?.[0]?.comment ?? null, disabled: !qualityAssessments?.[0]?.comment }),
      });
    } else {
      return this.fb.group({
        right: new FormControl(qualityAssessments?.[0]?.answer ?? null, [Validators.required]),
        rightComment: new FormControl({value: qualityAssessments?.[0]?.comment ?? null, disabled: !qualityAssessments?.[0]?.comment}),
        left: new FormControl(qualityAssessments?.[1]?.answer ?? null, [Validators.required]),
        leftComment: new FormControl({value: qualityAssessments?.[0]?.comment ?? null, disabled: !qualityAssessments?.[1]?.comment}),
      });
    }
  }

  hipsmJSWScorings() {
    const hipsmJSWScorings = new FormArray([]);
    this.currentReading.visits.forEach((v, index) => {
      hipsmJSWScorings.push(this.fb.group({
        right: new FormControl(this.mJSWScorings[index].mJSWScorings.rightHip),
        left: new FormControl(this.mJSWScorings[index].mJSWScorings.leftHip),
        comment: new FormControl(this.mJSWScorings[index].mJSWScorings.hipComment ?? null),
      }));
    });

    return hipsmJSWScorings;
  }

  kneesmJSWScorings() {
    const kneesmJSWScorings = new FormArray([]);
    this.currentReading.visits.forEach((v, index) => {
      kneesmJSWScorings.push(this.fb.group({
        rightLateral: new FormControl(this.mJSWScorings[index].mJSWScorings.rightKneeLateralMm),
        rightMedial: new FormControl(this.mJSWScorings[index].mJSWScorings.rightKneeMedialMm),
        rightComment: new FormControl(null),
        leftLateral: new FormControl(this.mJSWScorings[index].mJSWScorings.leftKneeLateralMm),
        leftMedial: new FormControl(this.mJSWScorings[index].mJSWScorings.leftKneeMedialMm),
        leftComment: new FormControl(null)
      }));
    });
    return kneesmJSWScorings;
  }

  private getByQuestion(exclusionaryFindingsModel, question) {
    return exclusionaryFindingsModel?.find(x => x.question === question);
  }

  private createQuestionWithLocationFormGroup(exclusionaryFindingsModel: any, question: string, presenceField: string) {
    const questionData = this.getByQuestion(exclusionaryFindingsModel, question);

    const formGroup = new FormGroup({
      [presenceField]: new FormControl(questionData?.presence),
      isRight: new FormControl({value: !!questionData?.right, disabled: !questionData?.right}),
      right: new FormControl({value: questionData?.rightLocation, disabled: !questionData?.rightLocation}),
      isLeft: new FormControl({value: questionData?.left, disabled: !questionData?.left}),
      left: new FormControl({value: questionData?.leftLocation, disabled: !questionData?.leftLocation}),
      comment: new FormControl({value: questionData?.comment, disabled: !questionData?.comment})
    });

    this.createQuestionFormGroupProcessed.push(question);
    return formGroup;
  }

  private createQuestionFormGroup(exclusionaryFindingsModel: any, question: string, presenceField: string) {
    const questionData = this.getByQuestion(exclusionaryFindingsModel, question);

    const formGroup = new FormGroup({
      [presenceField]: new FormControl(questionData?.presence),
      isRight: new FormControl({value: !!questionData?.right, disabled: !questionData?.right}),
      isLeft: new FormControl({value: questionData?.left, disabled: !questionData?.left}),
      comment: new FormControl({value: questionData?.comment, disabled: !questionData?.comment})
    });

    this.createQuestionFormGroupProcessed.push(question);
    return formGroup;
  }

  private createOther(exclusionaryFindingsModel: any) {
    const questionData = exclusionaryFindingsModel?.find(x => !this.createQuestionFormGroupProcessed.includes(x.question));

    const formGroup = new FormGroup({
      isOther: new FormControl(questionData?.presence),
      text: new FormControl({value: questionData?.question, disabled: !questionData?.question}),
      isRight: new FormControl({value: !!questionData?.right, disabled: !questionData?.right}),
      right: new FormControl({value: questionData?.rightLocation, disabled: !questionData?.rightLocation}),
      isLeft: new FormControl({value: questionData?.left, disabled: !questionData?.left}),
      left: new FormControl({value: questionData?.leftLocation, disabled: !questionData?.leftLocation}),
      comment: new FormControl({value: questionData?.comment, disabled: !questionData?.comment})
    });

    return formGroup;
  }

  exclusionaryFindingsFormGroupMaker(exclusionaryFindingsModel?: any) {
    this.createQuestionFormGroupProcessed = [];

    return this.fb.group({
      subchondralInsufficiencyFracture: this.createQuestionWithLocationFormGroup(exclusionaryFindingsModel, 'Subchondral Insufficiency Fracture', 'isSubchondralInsufficiencyFracture'),
      significantArticularBoneLoss: this.createQuestionWithLocationFormGroup(exclusionaryFindingsModel, 'Significant Articular Bone Loss', 'isSignificantArticularBoneLoss'), // CHECK THIS
      articularBoneFragmentationOrCollapse: this.createQuestionWithLocationFormGroup(exclusionaryFindingsModel, 'Articular Bone Fragmentation Or Collapse', 'isArticularBoneFragmentationOrCollapse'),
      osteonecrosis: this.createQuestionWithLocationFormGroup(exclusionaryFindingsModel, 'Osteonecrosis', 'isOsteonecrosis'),
      pathologicFracture: this.createQuestionWithLocationFormGroup(exclusionaryFindingsModel, 'Pathologic Fracture', 'isPathologicFracture'),
      primaryOrMetastaticTumor: this.createQuestionWithLocationFormGroup(exclusionaryFindingsModel, 'Primary Or Metastatic Tumor', 'isPrimaryOrMetastaticTumor'),
      extensiveSubchondralCysts: this.createQuestionWithLocationFormGroup(exclusionaryFindingsModel, 'Extensive Subchondral Cysts', 'isExtensiveSubchondralCysts'),
      recentFracture: this.createQuestionWithLocationFormGroup(exclusionaryFindingsModel, 'Recent Fracture', 'isRecentFracture'),
      excessiveMalalignmentOfTheKnee: this.createQuestionWithLocationFormGroup(exclusionaryFindingsModel, 'Excessive Malalignment Of The Knee', 'isExcessiveMalalignmentOfTheKnee'),
      pseudogout: this.createQuestionFormGroup(exclusionaryFindingsModel, 'Pseudogout', 'isPseudogout'),
      inflammatoryJointDisease: this.createQuestionFormGroup(exclusionaryFindingsModel, 'Inflammatory Joint Disease (RA, PsA, lupus, gout)', 'isInflammatoryJointDisease'),
      jointInfection: this.createQuestionFormGroup(exclusionaryFindingsModel, 'Joint Infection', 'isJointInfection'),
      pagetsDisease: this.createQuestionFormGroup(exclusionaryFindingsModel, 'Paget\'s Disease', 'isPagetsDisease'),
      atrophicOA: this.createQuestionFormGroup(exclusionaryFindingsModel, 'Atrophic OA', 'isAtrophicOA'),
      stressFracture: this.createQuestionFormGroup(exclusionaryFindingsModel, 'Stress Fracture or Stress Reaction', 'isStressFracture'),
      osteochondritisDissecans: this.createQuestionFormGroup(exclusionaryFindingsModel, 'Osteochondritis Dissecans', 'isOsteochondritisDissecans'),
      CHDWithDegenerativeJointDisease: this.createQuestionFormGroup(exclusionaryFindingsModel, 'CHD With Degenerative Joint Disease', 'isCHDWithDegenerativeJointDisease'),
      posteriorMeniscalRootTear: this.createQuestionFormGroup(exclusionaryFindingsModel, 'Posterior Meniscal Root Tear', 'isPosteriorMeniscalRootTear'),
      rapidProgressiveOAType1: this.createQuestionFormGroup(exclusionaryFindingsModel, 'Rapid Progressive OA (RPOA) Type 1', 'isRapidProgressiveOAType1'),
      rapidProgressiveOAType2: this.createQuestionFormGroup(exclusionaryFindingsModel, 'Rapid Progressive OA (RPAO) Type 2', 'isRapidProgressiveOAType2'),
      other: this.createOther(exclusionaryFindingsModel),
      targetKneeRight: new FormControl(exclusionaryFindingsModel?.some(e => e.right) ?? false),
      targetKneeLeft: new FormControl(exclusionaryFindingsModel?.some(e => e.left) ?? false),
    });
  }

  presenceOfPriorArthroplastyFormGroupMaker(presenceOfPriorArthroplasty?: any) {
    return this.fb.group({
      totalArthroplasty: new FormGroup({
        isRight: new FormControl(presenceOfPriorArthroplasty?.[0]?.right ?? 'No'),
        isLeft: new FormControl(presenceOfPriorArthroplasty?.[0]?.left ?? 'No'),
      }),
      hemiArthroplasty: new FormGroup({
        isRight: new FormControl(presenceOfPriorArthroplasty?.[1]?.right ?? 'No'),
        right: new FormControl(presenceOfPriorArthroplasty?.[1]?.rightAnswer ?? null),
        isLeft: new FormControl(presenceOfPriorArthroplasty?.[1]?.left ?? 'No'),
        left: new FormControl(presenceOfPriorArthroplasty?.[1]?.leftAnswer ?? null),
        leftComment: new FormControl(presenceOfPriorArthroplasty?.[1]?.lComment ?? null),
        rightComment: new FormControl(presenceOfPriorArthroplasty?.[1]?.rComment ?? null)
      }),
    });
  }

  kAndLFormGroupMaker(kAndL?: any) {
    return this.fb.group({
      right: new FormControl(kAndL?.[0]?.answer ?? null, [Validators.required]),
      left: new FormControl(kAndL?.[1]?.answer ?? null, [Validators.required])
    });
  }

  jointStatusFormGroupMaker(jointStatus?: any) {
    return this.fb.group({
      result: new FormControl(jointStatus?.result ?? null, [Validators.required]),
      comment: new FormControl(jointStatus?.comment ?? null)
    });
  }

  changeLeftQualityAssessmentCommentValidator(visitIndex: number, tab: string) {
    (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('leftComment')).enable();
    (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('leftComment')).setValue(null);
    if ((<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('left')).value === 'Suboptimal Quality'
    || (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('left')).value === 'Not Readable'
    ) {
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('leftComment')).setValidators([Validators.required]);
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('leftComment')).setErrors({required: true});
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('leftComment')).markAsTouched();
    } else {
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('leftComment')).setValidators(null);
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('leftComment')).setErrors(null);
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('leftComment')).markAsUntouched();
    }
    this.eligibilityDetermining(visitIndex, tab);
  }

  changeRightQualityAssessmentCommentValidator(visitIndex: number, tab: string) {
    (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('rightComment')).enable();
    (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('rightComment')).setValue(null);
    if ((<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('right')).value === 'Suboptimal Quality'
    || (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('right')).value === 'Not Readable'
    ) {
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('rightComment')).setValidators([Validators.required]);
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('rightComment')).setErrors({required: true});
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('rightComment')).markAsTouched();
    } else {
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('rightComment')).setValidators(null);
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('rightComment')).setErrors(null);
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('rightComment')).markAsUntouched();
    }
    this.eligibilityDetermining(visitIndex, tab);
  }

  changeUnschedueldFollowUpQualityAssessmentCommentValidator(visitIndex: number, tab: string) {
    (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('comment')).enable();
    (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('comment')).setValue(null);
    if ((<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('qualityAssessment')).value === 'No, other'
    ) {
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('comment')).setValidators([Validators.required]);
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('comment')).setErrors({required: true});
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('comment')).markAsTouched();
    } else {
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('comment')).setValidators(null);
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('comment')).setErrors(null);
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('comment')).markAsUntouched();
    }
    this.eligibilityDetermining(visitIndex, tab);
  }

  changeLeftExclusionaryFindingsDropDownValidator(visitIndex: number, tab: string, subQuestionName: string) {
    if((<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('isLeft')).value) {
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('left')).enable();
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('left')).setValidators([Validators.required]);
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('left')).setErrors({required: true});
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('left')).markAsTouched();
    } else {
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('left')).disable();
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('left')).setValue(null);
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('left')).setValidators(null);
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('left')).setErrors(null);
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('left')).markAsUntouched();
    }
    this.eligibilityDetermining(visitIndex, tab, subQuestionName);
    this.checkValidity(this.readingForm);
  }

  changeRightExclusionaryFindingsDropDownValidator(visitIndex: number, tab: string, subQuestionName: string) {
    if ((<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('isRight')).value) {
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('right')).enable();
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('right')).setValidators([Validators.required]);
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('right')).setErrors({required: true});
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('right')).markAsTouched();
    } else {
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('right')).disable();
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('right')).setValue(null);
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('right')).setValidators(null);
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('right')).setErrors(null);
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('right')).markAsUntouched();
    }
    this.eligibilityDetermining(visitIndex, tab, subQuestionName);
    this.checkValidity(this.readingForm);
  }

  isExclusionaryFindingsSubQuestion(visitIndex: number, tab: string, subQuestionName: string) {
    if ((<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('is' + subQuestionName.charAt(0).toUpperCase() + subQuestionName.slice(1))).value) {
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('isRight')).enable();
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('isLeft')).enable();
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('comment')).enable();
      if ((<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('text')) != null) {
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('text')).enable();
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('text')).setValidators([Validators.required]);
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('text')).setErrors({required: true});
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('text')).markAsTouched();
      }
    } else {
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('isRight')).setValue(null);
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('isLeft')).setValue(null);
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('isRight')).disable();
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('isLeft')).disable();
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('comment')).setValue(null);
      (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('comment')).disable();
      if ((<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('right')) != null || (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('left')) != null) {
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('right')).setValue(null);
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('left')).setValue(null);
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('right')).setValidators(null);
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('left')).setValidators(null);
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('right')).setErrors(null);
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('left')).setErrors(null);
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('right')).disable();
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('left')).disable();
      }
      if ((<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('text')) != null) {
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('text')).setValue(null);
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('text')).setValidators(null);
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('text')).setErrors(null);
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(subQuestionName)).get('text')).disable();
      }
    }
  }

  eligibilityDetermining(visitIndex: number, tab?: string, subQuestionName?: string) {
    if (subQuestionName) {
      this.isExclusionaryFindingsSubQuestion(visitIndex, tab, subQuestionName);
    }

    if (this.getCurrentVisits()[visitIndex].type !== VisitType.UNSCHEDULED_REGULAR && this.currentReading.readingLevel !== ReadingLevel.PATIENT){
      const leftQualityAssessment = (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('left')).value;
      const rightQualityAssessment = (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('qualityAssessment')).get('right')).value;
      const isAnyExclusionarySelected = this.isAnyOfExclusionaryChoosen(visitIndex, tab)[0];

      if(tab === 'bilateralKnees' || tab === 'kneesMRI' || tab === 'bilateralHips') {
        const rightTotalArthroplasty = (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('presenceOfPriorArthroplasty')).get('totalArthroplasty')).get('isRight')).value;
        const leftTotalArthroplasty = (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('presenceOfPriorArthroplasty')).get('totalArthroplasty')).get('isLeft')).value;
        const rightHemiArthroplasty = (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('presenceOfPriorArthroplasty')).get('hemiArthroplasty')).get('isRight')).value;
        const leftHemiArthroplasty = (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('presenceOfPriorArthroplasty')).get('hemiArthroplasty')).get('isLeft')).value;
        const isAnyRightExclusionaryKneesSelected = this.isAnyOfExclusionaryChoosen(visitIndex, tab)[1];
        const isAnyLeftExclusionaryKneesSelected = this.isAnyOfExclusionaryChoosen(visitIndex, tab)[2];

        if(tab === 'bilateralKnees' || tab === 'bilateralHips') {
          if (rightTotalArthroplasty === 'Yes' || rightHemiArthroplasty === 'Yes') {
            (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('kAndL')).get('right')).setValue('N/A');
          }

          if (leftTotalArthroplasty === 'Yes' || leftHemiArthroplasty === 'Yes') {
            (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('kAndL')).get('left')).setValue('N/A');
          }
        }

        if (tab !== 'bilateralKnees' &&
        (leftQualityAssessment === 'Not Readable' ||
        rightQualityAssessment === 'Not Readable' ||
        isAnyExclusionarySelected)
        ) {
          if(tab === 'kneesMRI' && (rightQualityAssessment === 'Not Readable' || isAnyRightExclusionaryKneesSelected)){
            (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('right')).setValue('Not Eligible');
          } else if(tab === 'kneesMRI' && (rightQualityAssessment !== 'Not Readable' && rightQualityAssessment !== null && !isAnyRightExclusionaryKneesSelected)) {
            (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('right')).setValue('Eligible');
          }

          if(tab === 'kneesMRI' && (leftQualityAssessment === 'Not Readable' || isAnyLeftExclusionaryKneesSelected)){
            (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('left')).setValue('Not Eligible');
          } else if(tab === 'kneesMRI' && (leftQualityAssessment !== 'Not Readable' && leftQualityAssessment !== null && !isAnyLeftExclusionaryKneesSelected)) {
            (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('left')).setValue('Eligible');
          }

          if(tab !== 'kneesMRI') {
            (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('result')).setValue('Not Eligible');
          }
          return;
        } else {
          if(tab === 'bilateralKnees') {
            const rightKandL = (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('kAndL')).get('right')).value;
            const leftKandL = (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('kAndL')).get('left')).value;

            if((rightKandL === '0' || rightKandL === '1' || rightKandL === 'N/A') ||
              rightQualityAssessment === 'Not Readable' ||
              isAnyRightExclusionaryKneesSelected
            ) {
              (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('right')).setValue('Not Eligible');
            } else if(rightKandL != null && rightQualityAssessment != null) {
              (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('right')).setValue('Eligible');
            } else {
              (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('right')).setValue(null);
              (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('right')).markAsTouched();
            }

            if((leftKandL === '0' || leftKandL === '1' || leftKandL === 'N/A') ||
              leftQualityAssessment === 'Not Readable' ||
              isAnyLeftExclusionaryKneesSelected
            ) {
              (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('left')).setValue('Not Eligible');
            } else if(leftKandL != null && leftQualityAssessment != null) {
              (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('left')).setValue('Eligible');
            } else {
              (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('left')).setValue(null);
              (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('left')).markAsTouched();
            }

          } else {
            if (tab === 'kneesMRI') {
              if ((isAnyRightExclusionaryKneesSelected || rightQualityAssessment === 'Not Readable')
              && (isAnyLeftExclusionaryKneesSelected || leftQualityAssessment === 'Not Readable')) {
                (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('right')).setValue('Not Eligible');
                (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('left')).setValue('Not Eligible');
              } else if ((isAnyRightExclusionaryKneesSelected || rightQualityAssessment === 'Not Readable')) {
                (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('right')).setValue('Not Eligible');
                if (!isAnyLeftExclusionaryKneesSelected && leftQualityAssessment === null) {
                  (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('left')).setValue(null);
                }
              } else if ((isAnyLeftExclusionaryKneesSelected || leftQualityAssessment === 'Not Readable')) {
                (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('left')).setValue('Not Eligible');
                if (!isAnyRightExclusionaryKneesSelected && rightQualityAssessment === null) {
                  (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('right')).setValue(null);
                }
              } else {
                if (rightQualityAssessment != null && leftQualityAssessment != null) {
                    (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('right')).setValue('Eligible');
                    (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('left')).setValue('Eligible');
                  } else if (rightQualityAssessment != null) {
                    (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('right')).setValue('Eligible');
                  } else if(leftQualityAssessment != null) {
                    (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('left')).setValue('Eligible');
                  } else {
                    (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('right')).setValue(null);
                    (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('right')).markAsTouched();
                    (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('left')).setValue(null);
                    (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('left')).markAsTouched();
                  }
              }
            } else {
              if(leftQualityAssessment != null && rightQualityAssessment != null) {
                  (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('result')).setValue('Eligible');
              } else {
                  (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('result')).setValue(null);
                  (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('result')).markAsTouched();
              }
            }
          }
        }
      } else if (tab !== 'other') {
        if (leftQualityAssessment === 'Not Readable' ||
            rightQualityAssessment === 'Not Readable' ||
            isAnyExclusionarySelected
        ) {
          (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('result')).setValue('Not Eligible');
          return;
        } else {
          if(leftQualityAssessment != null && rightQualityAssessment != null) {
            (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('result')).setValue('Eligible');
          } else {
            (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('result')).setValue(null);
            (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('jointStatus')).get('result')).markAsTouched();
          }
        }
      }
    }
  }

  isAnyOfExclusionaryChoosen(visitIndex: number, tab: string): Array<boolean> {
    const exclusionaryFindingQuestions = this.exclusionaryFindingQuestions(visitIndex);
    for (let i = 0 ; i < exclusionaryFindingQuestions.length ; i++) {
      if (
        (tab === 'kneesMRI' || tab === 'bilateralKnees')  &&
        (<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(exclusionaryFindingQuestions[i].value)) != null &&
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(exclusionaryFindingQuestions[i].value)).get(exclusionaryFindingQuestions[i].isChoosen)) != null &&
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(exclusionaryFindingQuestions[i].value)).get(exclusionaryFindingQuestions[i].isChoosen)).value != null &&
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(exclusionaryFindingQuestions[i].value)).get(exclusionaryFindingQuestions[i].isChoosen)).value === true &&
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(exclusionaryFindingQuestions[i].value)).get('isRight')) != null &&
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(exclusionaryFindingQuestions[i].value)).get('isRight')).value != null &&
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(exclusionaryFindingQuestions[i].value)).get('isRight')).value === true &&
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(exclusionaryFindingQuestions[i].value)).get('isLeft')) != null &&
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(exclusionaryFindingQuestions[i].value)).get('isLeft')).value != null &&
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(exclusionaryFindingQuestions[i].value)).get('isLeft')).value === true
      ) {
        return [false, true, true];
      } else
      if (
        (tab === 'kneesMRI' || tab === 'bilateralKnees')  &&
        (<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(exclusionaryFindingQuestions[i].value)) != null &&
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(exclusionaryFindingQuestions[i].value)).get(exclusionaryFindingQuestions[i].isChoosen)) != null &&
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(exclusionaryFindingQuestions[i].value)).get(exclusionaryFindingQuestions[i].isChoosen)).value != null &&
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(exclusionaryFindingQuestions[i].value)).get(exclusionaryFindingQuestions[i].isChoosen)).value === true &&
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(exclusionaryFindingQuestions[i].value)).get('isRight')) != null &&
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(exclusionaryFindingQuestions[i].value)).get('isRight')).value != null &&
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(exclusionaryFindingQuestions[i].value)).get('isRight')).value === true
      ) {
        return [false, true, false];
      } else
      if (
        (tab === 'kneesMRI' || tab === 'bilateralKnees')  &&
        (<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(exclusionaryFindingQuestions[i].value)) != null &&
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(exclusionaryFindingQuestions[i].value)).get(exclusionaryFindingQuestions[i].isChoosen)) != null &&
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(exclusionaryFindingQuestions[i].value)).get(exclusionaryFindingQuestions[i].isChoosen)).value != null &&
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(exclusionaryFindingQuestions[i].value)).get(exclusionaryFindingQuestions[i].isChoosen)).value === true &&
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(exclusionaryFindingQuestions[i].value)).get('isLeft')) != null &&
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(exclusionaryFindingQuestions[i].value)).get('isLeft')).value != null &&
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(exclusionaryFindingQuestions[i].value)).get('isLeft')).value === true
      ) {
        return [false, false, true];
      } else
      if(
        tab !== 'kneesMRI' &&
        tab !== 'bilateralKnees'  &&
        (<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(exclusionaryFindingQuestions[i].value)) != null &&
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(exclusionaryFindingQuestions[i].value)).get(exclusionaryFindingQuestions[i].isChoosen)) != null &&
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(exclusionaryFindingQuestions[i].value)).get(exclusionaryFindingQuestions[i].isChoosen)).value != null &&
        (<FormControl>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).get(tab)).get('exclusionaryFindings')).get(exclusionaryFindingQuestions[i].value)).get(exclusionaryFindingQuestions[i].isChoosen)).value === true
      ) {
        return [true, false, false];
      }
    }
    return [false, false, false];
  }

  addOrRemoveOtherTab(visitIndex:number, bool: boolean, visitType?: string) {
    if (bool) {
      const otherFormGroup: FormGroup = this.fb.group({
        anatomicalRegion: new FormControl(null, [Validators.required]),
        qualityAssessment: this.qualityAssessmentFormGroupMaker(visitType, this.currentReading.readingLevel),
        exclusionaryFindings: this.exclusionaryFindingsFormGroupMaker(),
        nonExclusionaryFindings: new FormControl(null),
      });
      if (this.getCurrentVisits()[visitIndex].type === VisitType.UNSCHEDULED_REGULAR || this.currentReading.readingLevel === ReadingLevel.PATIENT){
        otherFormGroup.addControl('modality', new FormControl(null, [Validators.required]));
      }
      // tslint:disable-next-line:max-line-length
      (<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).addControl(this.otherTabCount === 1 ? 'other' : 'other' + this.otherTabCount.toString(), otherFormGroup);
      // tslint:disable-next-line:max-line-length
      this.question_tabs[visitIndex].push({title: this.otherTabCount === 1 ? 'Other' : 'Other ' + this.otherTabCount.toString(), value: this.otherTabCount === 1 ? 'other' : 'other' + this.otherTabCount.toString()});
      this.otherTabCount++;
    } else {
      if (this.otherTabCount > 1) {
        this.otherTabCount--;
        // tslint:disable-next-line:max-line-length
        (<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get('questions')).removeControl(this.otherTabCount === 1 ? 'other' : 'other' + this.otherTabCount.toString());
        this.question_tabs[visitIndex].pop();
      }
    }
  }

  exclusionaryFindingQuestions(visitIndex?: number): Array<{title:string, value:string, isChoosen:string}> {
    if(this.getCurrentVisits()[visitIndex].type === VisitType.UNSCHEDULED_REGULAR || this.currentReading.readingLevel === ReadingLevel.PATIENT) {
      return [
        {title: 'Subchondral Insufficiency Fracture', value: 'subchondralInsufficiencyFracture', isChoosen: 'isSubchondralInsufficiencyFracture'},
        {title: 'Osteonecrosis', value: 'osteonecrosis', isChoosen: 'isOsteonecrosis'},
        {title: 'Pathologic Fracture', value: 'pathologicFracture', isChoosen: 'isPathologicFracture'},
        {title: 'Primary Or Metastatic Tumor', value: 'primaryOrMetastaticTumor', isChoosen: 'isPrimaryOrMetastaticTumor'},
        {title: 'Recent Fracture', value: 'recentFracture', isChoosen: 'isRecentFracture'},
        {title: 'Joint Infection', value: 'jointInfection', isChoosen: 'isJointInfection'},
        {title: 'Stress Fracture', value: 'stressFracture', isChoosen: 'isStressFracture'},
        {title: 'Osteochondritis Dissecans', value: 'osteochondritisDissecans', isChoosen: 'isOsteochondritisDissecans'},
        {title: 'Posterior Meniscal Root Tear', value: 'posteriorMeniscalRootTear', isChoosen: 'isPosteriorMeniscalRootTear'},
        {title: 'Rapid Progressive OA (RPOA) Type 1', value: 'rapidProgressiveOAType1', isChoosen: 'isRapidProgressiveOAType1'},
        {title: 'Rapid Progressive OA (RPAO) Type 2', value: 'rapidProgressiveOAType2', isChoosen: 'isRapidProgressiveOAType2'},
        {title: 'Other', value: 'other', isChoosen: 'isOther'},
      ];
    }
    return [
      {title: 'Subchondral Insufficiency Fracture', value: 'subchondralInsufficiencyFracture', isChoosen: 'isSubchondralInsufficiencyFracture'},
      {title: 'Significant Articular Bone Loss', value: 'significantArticularBoneLoss', isChoosen: 'isSignificantArticularBoneLoss'},
      {title: 'Articular Bone Fragmentation Or Collapse', value: 'articularBoneFragmentationOrCollapse', isChoosen: 'isArticularBoneFragmentationOrCollapse'},
      {title: 'Osteonecrosis', value: 'osteonecrosis', isChoosen: 'isOsteonecrosis'},
      {title: 'Pathologic Fracture', value: 'pathologicFracture', isChoosen: 'isPathologicFracture'},
      {title: 'Primary Or Metastatic Tumor', value: 'primaryOrMetastaticTumor', isChoosen: 'isPrimaryOrMetastaticTumor'},
      {title: 'Extensive Subchondral Cysts', value: 'extensiveSubchondralCysts', isChoosen: 'isExtensiveSubchondralCysts'},
      {title: 'Recent Fracture', value: 'recentFracture', isChoosen: 'isRecentFracture'},
      {title: 'Excessive Malalignment Of The Knee', value: 'excessiveMalalignmentOfTheKnee', isChoosen: 'isExcessiveMalalignmentOfTheKnee'},
      {title: 'Inflammatory Joint Disease (RA, PsA, lupus, gout)', value: 'inflammatoryJointDisease', isChoosen: 'isInflammatoryJointDisease'},
      {title: 'Joint Infection', value: 'jointInfection', isChoosen: 'isJointInfection'},
      {title: 'Paget\'s Disease', value: 'pagetsDisease', isChoosen: 'isPagetsDisease'},
      {title: 'Atrophic OA', value: 'atrophicOA', isChoosen: 'isAtrophicOA'},
      {title: 'Stress Fracture', value: 'stressFracture', isChoosen: 'isStressFracture'},
      {title: 'Osteochondritis Dissecans', value: 'osteochondritisDissecans', isChoosen: 'isOsteochondritisDissecans'},
      {title: 'CHD With Degenerative Joint Disease', value: 'CHDWithDegenerativeJointDisease', isChoosen: 'isCHDWithDegenerativeJointDisease'},
      {title: 'Posterior Meniscal Root Tear', value: 'posteriorMeniscalRootTear', isChoosen: 'isPosteriorMeniscalRootTear'},
      {title: 'Other', value: 'other', isChoosen: 'isOther'},
    ];
  }

  getCurrentVisits() {
    return this.currentReading.readingVersion === ReadingVersion.IF_Multi_Tab ?
    this.currentReading.visits.filter(visit => visit.status !== VisitStatus.DONE) : this.currentReading.visits;
  }
  getDoneVisits(selectedVisit) {
    return this.currentReading.readingVersion === ReadingVersion.IF_Historical ?
    this.currentReading.visits.filter((visit) =>visit.status === VisitStatus.DONE && selectedVisit.id !== visit.id) : [];
  }

}

interface IncidentalFindingsData {
  id: number;
  reading: any;
  bucketName: string;
  comment: string;
  seriesId: number;
  sliceNumber: number;
  fileUrl: string;
  fileUrlPreview: string;
  storageFileName: string;
  storageFileNamePreview: string;
  storagePath: string;
  modalityId: number;
  modalityName: string;
  sequenceLabelId: number;
  sequenceLabelName: string;
}

interface ConfigModel {
  readers: any[];
  endpoint: any;
  questions: ConfigModelQuestion[];
  modalities: any[];
  readingType: string;
  readingLevel: string;
  screenshotComments: any[];
  amiScreenshotFeature?: boolean;
  screenshotFeature?: boolean;
  enableCommentOptionsForScreenshot: boolean;
}

interface ConfigModelQuestion {
  uuid: string;
  answerType: string;
  questionText: string;
  answeringOption1?: string;
  answeringOption2?: string;
  answer?: any;
  comment?: string;
  formControl?: FormControl;
}
