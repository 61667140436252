import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import {NewTabScoringFormKind, ReadingFormScoring, ReadingFormScoringComponent} from '../reading-form-scoring.component';
import {BehaviorSubject, EMPTY, Observable, Subscription, forkJoin} from 'rxjs';
import {BasicResponse} from '../../../core/interfaces/basic-response';
import {ReadingRAPNOService} from '../../../_services/reading-rapno.service';
import {ActivatedRoute} from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';
import {Store} from '@ngxs/store';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { updateTabHeaderWidth } from '../helper/mat-tab-helper';
import { AddLesionRequest, EditLesionRequest, GlobalLesionModel, OncologyLesionType } from 'src/app/_models/Oncology/global-lesion-model';
import { RAPNOReadingScoringFormComponent } from './rapno-reading-scoring-form/rapno-reading-scoring-form.component';
import { PrimaryBodyLocationService, ReadingConfigFlexibleService } from 'src/app/_services';
import { RAPNOVisitModel } from 'src/app/_models/RAPNO/rapno-visit-model';

export enum RAPNOCondition {
  HGG = 'HGG',
  DIPG = 'DIPG',
  Ependymoma = 'Ependymoma'
};

export class RAPNOLesionSPPDData {
  lesionType: OncologyLesionType;

  visitsScore: RAPNOVisitSPPDData[];
};

export class RAPNOVisitSPPDData {
  visitConfigId: number;
  visitOrder: number; // can be used to identify prior visit score

  isBaseline: boolean;

  sppd: number | string;

  lesions: GlobalLesionModel[];
};

@Component({
  selector: 'app-rapno-reading-form',
  templateUrl: './rapno-reading-form.component.html',
  styleUrls: ['./rapno-reading-form.component.css'],
})
export class RAPNOReadingFormComponent extends ReadingFormScoringComponent
  implements OnInit, OnDestroy, ReadingFormScoring {

    @ViewChildren(RAPNOReadingScoringFormComponent) visitScoringForms: QueryList<RAPNOReadingScoringFormComponent>;

    modalSpinnerSubscription: Subscription;
    showModalSpinnerLoadingData = true;

    toastOptions: ToastOptions = {
      title: '',
      showClose: true,
      timeout: 10000,
      theme: 'material',
    };

    currentReading: any = {
      studyId: 'NA',
      patientId: 'NA',
      aquisitionDate: 'NA',
      patientCode: 'NA',
      readingCondition: null,
    };

    allLesionsSPPDData: RAPNOLesionSPPDData[] = [];

    flexibleConfig: any;
    condition: RAPNOCondition;
    readerId: number;

    hiddenForm: boolean = true;
    selectedTabIndex: number = 0;

  constructor(
              private store: Store,
              private readingService: ReadingRAPNOService,
              private readingConfigFlexibleService: ReadingConfigFlexibleService,
              private primaryBodyLocationService: PrimaryBodyLocationService,
              private toastyService: ToastyService,
              private route: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(new SetPageHeaderTitle('RAPNO Scoring Form'));
    this.readerId = +JSON.parse(localStorage.getItem('userId'));
    //this.viewerEnabledSubject.next(true);
    this.newTabScoringFormEnabledSubject.next(NewTabScoringFormKind.OPEN);

    const sideNav = document.getElementsByTagName('mat-sidenav');


    this.confirmMarkerSubject.subscribe(marker => {
      this.confirmLesion(this, marker);
    });

    this.readingService.requestScreenshot.subscribe(event => {
      this.requestSnapshot(event);
    });

    this.confirmSnapshotSubject.subscribe(snapshot => {
      this.confirmScreenshot(this, snapshot);
    })

    this.modalSpinnerSubscription = this.readingService.modalSpinner
      .subscribe(state => {
        this.showModalSpinnerLoadingData = state;
      });

    this.initOnCurrentReading();

    this.hideScoringFormSubject.subscribe(hidden => {

      this.hiddenForm = hidden;

      (sideNav[0] as HTMLElement).style.display = hidden ? 'inherit' : 'none';
    });

    this.readingSeriesInitiated.subscribe(() => {
      // necessary to filter series for currently selected visit for initial state
      this.onChangeActivatedVisit(this.selectedTabIndex);
    })
  }

  ngOnDestroy(): void {
    if (!this.confirmMarkerSubject.closed)
      this.confirmMarkerSubject.unsubscribe();
    if (!this.confirmSnapshotSubject.closed)
      this.confirmSnapshotSubject.unsubscribe();
    if (!this.modalSpinnerSubscription.closed)
      this.modalSpinnerSubscription.unsubscribe();
  }

  initOnCurrentReading(): void {
    this.currentReadingSubject.pipe(
      map(currentReading => this.currentReading = currentReading),
      map(() => {
        this.condition = this.currentReading.readingCondition;
        this.currentReading.visits = this.currentReading.visits.sort((a, b) => a.visitOrder - b.visitOrder);
        // set current index to first editable visit
        this.selectedTabIndex = this.getVisits().findIndex(f => !this.isReadonlyVisit(f));
        this.selectedTabIndex = this.selectedTabIndex >= 0 ? this.selectedTabIndex : 0;

        forkJoin([
          this.readingConfigFlexibleService.getById(this.currentReading.flexibleConfigId),
          this.readingService.getLesionsForReading(this.currentReading.studyId, this.currentReading.id),
          this.primaryBodyLocationService.findAll()
        ])
        .subscribe(([flexibleConfig, allReadingLesions, allPrimaryLocations]) => {
          if (allPrimaryLocations.responseCode == 200) {
            this.readingService.allPrimaryLocationsRequest(allPrimaryLocations.data);
          }
          if (flexibleConfig?.data && allReadingLesions) {
            this.flexibleConfig = flexibleConfig.data.config;
            this.readingService.locationRequest(this.flexibleConfig.locations);

            this.allLesionsSPPDData = this.calculateReadingSPPD(this.currentReading.visits, allReadingLesions);
            // notify that sppd data was loaded
            this.readingService.baselineNadirSPPDChange(this.allLesionsSPPDData);
          }
        }, e => {
          this.showErrorMessage("Failed to load reading");
        }, () => this.readingService.modalSpinnerChangeState(false));
      })).subscribe();
  }

  calculateReadingSPPD(visits: RAPNOVisitModel[], lesions: GlobalLesionModel[]) : RAPNOLesionSPPDData[] {
    const result: RAPNOLesionSPPDData[] = [];
    for (var i = 0; i < lesions.length; i++) {
      const lesion = lesions[i];
      var lesionTypeData = result.find(f => f.lesionType == lesion.type);
      if (!lesionTypeData) {
        lesionTypeData = {
          lesionType: lesion.type,
          visitsScore: [],
        };
        result.push(lesionTypeData);
      }

      var visitScore = lesionTypeData.visitsScore.find(f => f.visitConfigId == lesion.visitConfigId);
      if (!visitScore) {
        const visit = visits.find(f => f.visitConfigId == lesion.visitConfigId);
        visitScore = {
          visitConfigId: lesion.visitConfigId,
          visitOrder: visit?.visitOrder,
          isBaseline: visit?.baseline,
          sppd: 0,
          lesions: [],
        };
        lesionTypeData.visitsScore.push(visitScore);
      }
      visitScore.lesions.push(lesion);

      if (visitScore.sppd !== 'NA' && visitScore.sppd !== 'NE') {
        if (lesion.ppd == 'NA') {
          visitScore.sppd = 'NA';
        } else if (lesion.ppd == 'NE') {
          visitScore.sppd = 'NE';
        } else {
          visitScore.sppd += lesion.ppd;
        }
      }
    }

    // sort lesions
    result.forEach(f => {
      f.visitsScore.forEach(ff => {
        ff.lesions = ff.lesions.sort((a, b) => a.id - b.id);
      });
      f.visitsScore = f.visitsScore.sort((a, b) => a.visitOrder - b.visitOrder);
    })
    return result;
  }

  getVisits() {
    return this.currentReading?.visits?.filter(f => f.status === 'NEW_EDITABLE' || f.status === 'IN_PROGRESS' || f.status === 'DONE' ).sort((a, b) => a.visitOrder - b.visitOrder)
       ?? [];
  }

  isReadonlyVisit(visit) {
    return !(visit && visit.status === 'NEW_EDITABLE' || visit.status === 'IN_PROGRESS');
  }

  changeTabHeaderWidth(): void {
    updateTabHeaderWidth();
  }

  onChangeActivatedVisit(index) {
    const visit = this.getVisits()[index];
    this.activedVisitSubject.next(visit);
  }

  get formTitle() {
    if (this.currentReading.readingCondition === RAPNOCondition.HGG) {
      return 'HGG Reading Form';
    }

    if (this.currentReading.readingCondition === RAPNOCondition.DIPG) {
      return 'DIPG Reading Form';
    }

    if (this.currentReading.readingCondition === RAPNOCondition.Ependymoma) {
      return 'Ependymoma Reading Form';
    }
    return 'RAPNO Scoring Form';
  }

  getEndpointName(): string {
    return 'RAPNO_BASIC';
  }

  loadReadings(studyId: number, readerId: number): Observable<BasicResponse<any>> {
    return this.readingService.getReadingByStudyIdAndReaderId(studyId, readerId);
  }

  startReading(studyId: number, readingId: number): Observable<BasicResponse<any>> {
    return this.readingService.startReading(studyId, readingId);
  }

  updateReading(studyId: number, readingId: number, data: { spentSeconds: number; }, justSaveTime?: boolean): Observable<BasicResponse<any>> {
    if (!this.hiddenForm)
      return this.readingService.logTime(studyId, readingId, data);
    //return EMPTY;
    // can not return empty, since it is necessary to execute code in subscribe
    return Observable.of({responseCode: 200, responseMessage: '', data: {}});
  }

  submitReading(studyId: number, data: { spentSeconds: number; }): Observable<BasicResponse<any>> {

    const submitData = {
      scoring: {},
      imageQualityAssessment: 'Optimal',
      imageQualityAssessmentComment: 'test',
      disagreement: true,
      timeSpent: data.spentSeconds,
      comment: 'test'
    };

    return this.readingService.completeReading(studyId, this.currentReading.id, submitData);
  }

  clearForm(): void {

  }

  notifyLockVisit(visit) {

    this.readingService.getReadingById(this.currentReading.studyId, this.currentReading.readerId, this.currentReading.id)
    .subscribe(response => {
      this.currentReading = response.data;

      const nextVisitOrder = this.getVisits().findIndex(f => f.visitOrder > visit.visitOrder);
      if (nextVisitOrder >= 0) {
        this.selectedTabIndex = nextVisitOrder;
      }
      else {
        // notify to move to another reading
        if (!this.readingListUpdatedSubject.closed)
          this.readingListUpdatedSubject.next(this.currentReading);
      }

    }, error => {
      this.showErrorMessage("Failed to reload reading");
    });
  }


  showErrorMessage(response: string): void {
    this.showModalSpinnerLoadingData = false;
    this.toastOptions.title = 'ERROR ';
    this.toastOptions.msg = response;
    this.toastyService.error(this.toastOptions);
  }

  notifyAddLesion(event: AddLesionRequest) {
    console.log('RAPNO notifyAddLesion: Visit Form: AddLesion event: ', event);
    if (!this.addMarkerSubject.closed) {
      this.addMarkerSubject.next(event);
    }
  }

  notifyEditLesion(event: EditLesionRequest) {
    console.log('RAPNO: Visit Form: EditLesion');
    if (!this.editMarkerSubject.closed) {
      this.editMarkerSubject.next(event);
    }
  }

  notifyGoToLesion(event: GlobalLesionModel) {
    console.log("RAPNO: Visit Form: Go To Lesion");
    if (!this.selectMarkerSubject.closed)
      this.selectMarkerSubject.next(event);
  }

  confirmLesion(me, marker) {
    // TODO: find correct visit based on seriesId
    const visitConfigId = marker.visitConfigId;
    console.log('Confirm Marker for visit', visitConfigId, marker);

    const foundVisit = this.getVisits().filter((f, i) => f.series.some(s => s.seriesId == marker.seriesId))[0];
    if (foundVisit) {
      const visitScoringForm = me.visitScoringForms.find(f => f.visit.visitConfigId == foundVisit.visitConfigId);
      if (visitScoringForm) {
        this.selectedTabIndex = this.getVisits().findIndex(f => f.id === foundVisit.id);
        visitScoringForm.confirmLesion(marker);
      }
    }
  }

  requestSnapshot(event) {
    console.log("RAPNO: Visit Form: Request Snapshot");
    if (!this.requestSnapshotSubject.closed)
      this.requestSnapshotSubject.next(event);
  }

  confirmScreenshot(me, data) {
    console.log('RAPNO: Visit Form: Confirm Screenshot data', data);
    this.readingService.putScreenshotData(data);
    return data;
  }
}
