import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {forkJoin, interval, Subscription} from 'rxjs';
import { SNAPSHOT_RAW_DATA } from 'src/app/_models/Oncology/local-storage-keys.model';
import {
  GlobalLesionModel,
  OncologyLesionEditType,
  OncologyLesionType,
  Snapshot
} from 'src/app/_models/Oncology/global-lesion-model';
import {finalize, map, take, takeWhile} from "rxjs/operators";
import {Observable} from "rxjs/src/Observable";
import { BodySubLocationModel } from 'src/app/_models/ImagingProject/ImagingProjectBodypartDataset/body-sub-location-model';
import { PrimaryBodyLocationService } from 'src/app/_services/primary-body-location.service';
import { ReadingRAPNOService } from 'src/app/_services/reading-rapno.service';
import { ToastService } from 'src/app/_services/internal/toast.service';

export interface LesionDialogFormData {
  lesionName: string,
  studyId: number,
  readingId: number,
  readerId: number,
  patientId: number,
  endpointName: string,
  visitConfigId: number,
  isBaseline: boolean,
  markerType:string,
  editDialog:boolean,
  lesionType: OncologyLesionType,
  editType:string,
  lesionData:any,
  screenShotData:any,
  viewerData?:any,
  eCRFOnly:boolean,
  viewerLess: boolean,
  hasComment: boolean
};

export const RAPNO_TargetLesions = [
  OncologyLesionType.TARGET_LESION,
  OncologyLesionType.PONTINE_TARGET_LESION,
  OncologyLesionType.EXTRAPONTINE_TARGET_LESION,
  OncologyLesionType.SPINE_TARGET_LESION,
  OncologyLesionType.PRIMARY_TARGET_LESION,
  OncologyLesionType.METASTATIC_TARGET_LESION,
]

@Component({
  selector: 'app-rapno-lesion-dialog',
  templateUrl: './rapno-lesion-dialog.component.html',
  styleUrls: ['./rapno-lesion-dialog.component.css']
})
export class RAPNOLesionDialogComponent implements OnInit, OnDestroy {
  OncologyLesionType = OncologyLesionType;
  configPrimaryLocations: any[];
  allPrimaryLocations: any[];

  public get editType(): typeof OncologyLesionEditType {
    return OncologyLesionEditType;
  }

  minimized = false;
  showModalSpinnerLoadingData = false;
  imageUrl = '';
  imageAttached = false;
  attachMessage: string;
  locationSubscription: Subscription;
  screenshotSubscription: Subscription;

  lesionForm: FormGroup = new FormGroup({});
  loadingScreenshot = false;
  timer$: Observable<string>;

  constructor(public dialogRef: MatDialogRef<RAPNOLesionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: LesionDialogFormData,
              private readingService: ReadingRAPNOService,
              private primaryBodyLocationService: PrimaryBodyLocationService,
              private toastService: ToastService,
              private fb: FormBuilder
  ) { }

  attachImage() {

    console.log('Dialog: Request Screenshot data ');
    this.readingService.requestScreenshotData();
    // Send request that requires screenshot
    this.enableCountDown();
  }

  ngOnInit(): void {

    this.initlesionForm();

    if (this.data.editDialog) {
      this.onEditForm();
    }

    const userId = +JSON.parse(localStorage.getItem('userId'));
    this.readingService.location.subscribe(
      (response) => {
        if (response) {
          this.configPrimaryLocations = response;
        }
      }
    )

    this.locationSubscription = this.primaryBodyLocationService.findAll().subscribe(
      (response) => {
        if (response.responseCode == 200) {
          this.allPrimaryLocations = response.data;
        }
      }
    )
    this.screenshotSubscription = this.readingService.screenshot.subscribe(
        (data) => {
          if (data) {
            this.setScreenShotToImgFromWebsocket(data);
          }
        }
    );

    if (!this.data.editDialog || (this.data.editType === this.editType.MEASUREMENT || this.data.editType === this.editType.SCREENSHOT))
        this.attachImage();
  }

  get bodyLocations(){
    return this.configPrimaryLocations?.map((location) => location.name) || [];
  }


  get bodySubLocations() {
    const currentPrimaryLocation = this.lesionForm.get('primaryLocation')?.value;
    return this.configPrimaryLocations?.find((location) => location.name === currentPrimaryLocation)?.subLocations?.map((subLocation: BodySubLocationModel) => subLocation.name) || [];
  }

  get isNodalLocation() {

    const currentPrimaryLocation = this.lesionForm.get('primaryLocation')?.value;
    return this.allPrimaryLocations?.find((location) => location.name === currentPrimaryLocation)?.nodal;
  }

  get isDwiLesion() {
    return this.data.lesionType === OncologyLesionType.DWI;
  }

  minimizeDialog(){
    const dialog = document.getElementsByClassName('cdk-overlay-pane');
    dialog[0].classList.add('minimized');
    const top = ((dialog[0] as HTMLElement).offsetTop + 60) + 'px';

    document.documentElement.style.setProperty('--dialog-display', "translate3d(0 , calc(100vh - " + top + ") , 0)");
    document.documentElement.style.setProperty('--dialog-background', "#e99425");
    document.documentElement.style.setProperty('--dialog-color', "#fff");
    this.minimized = true;
  }
  maximizeDialog(){
    const dialog = document.getElementsByClassName('cdk-overlay-pane');
    dialog[0].classList.remove('minimized');

    document.documentElement.style.setProperty('--dialog-display', "");
    document.documentElement.style.setProperty('--dialog-background', "#fff");
    document.documentElement.style.setProperty('--dialog-color', "");
    this.minimized = false;
  }

  initlesionForm(){
    console.log('this.data.lesionData this.data', this.data);

    this.lesionForm = this.fb.group(new GlobalLesionModel(
      this.data.lesionType,
      this.data.patientId,
      this.data.readerId,
      this.data.readingId,
      this.data.visitConfigId,
      this.data.endpointName,
      this.data.isBaseline ? true : false,
      this.data.markerType
    ));
    if(this.data.viewerData)
      this.setViewerDataToForm(this.data.viewerData);

    if (this.data.lesionType === OncologyLesionType.DWI) {
      this.lesionForm.patchValue({
        lesionName: this.data.lesionData.lesionName,
        primaryLocation: this.data.lesionData.primaryLocation,
        subLocation: this.data.lesionData.subLocation,
      })
    }
  }

  onEditForm() {
    this.lesionForm.setValue(this.data.lesionData);

      if(this.data.lesionData.snapshot){
        this.imageUrl = this.data.lesionData.snapshot.fileUrl;
      }

      if(this.data.viewerData) {
        this.setViewerDataToForm(this.data.viewerData);
      }
  }

  setViewerDataToForm(viewerData) {
    const ALDUnit = "mm";
    const SAXUnit = "mm";
    const PPDUnit = "cm2";
    const ALDDecimal = 2;
    const SAXDecimal = 2;
    const PPDDecimal = 2;
    const axialLongDiameter = !viewerData.axialLongDiameter ? null : (+viewerData.axialLongDiameter).toFixed(2);
    const shortAxis = !viewerData.shortAxis ? null : (+viewerData.shortAxis).toFixed(2);
    const ppd = axialLongDiameter == null || shortAxis == null
      ? null
      : ((+axialLongDiameter * +shortAxis)/100).toFixed(2);

    this.lesionForm.patchValue({
        type: this.data.lesionType,
        frameNumber: viewerData.frameNumber,
        objectLocationX: viewerData.objectLocationX,
        objectLocationY: viewerData.objectLocationY,
        sopInstanceUid: viewerData.sopInstanceUid,
        sliceNumber: viewerData.sliceNumber,
        seriesId: viewerData.seriesId,
        axialLongDiameter: axialLongDiameter ?? 'NA',
        shortAxis: shortAxis ?? 'NA',
        ppd: ppd ?? 'NA'
    });
    if (RAPNO_TargetLesions.find(f => f == this.data.lesionType)) {
      this.lesionForm.patchValue({
        notMeasurabilityReason: this.data.lesionData?.notMeasurabilityReason ?? 'PRESENT',
        isMeasurable: true,
      })
    } else {
      this.lesionForm.patchValue({
        status: 'PRESENT',
      })
    }
  }

  private enableCountDown() {
    this.loadingScreenshot = true;
    const stepsInOneSec = 10;
    const time = 10 * stepsInOneSec // 10 seconds
    let period = 1000 / stepsInOneSec;
    this.timer$ = interval(period) // 1000 = 1 second
      .pipe(
        take(time),
        takeWhile(_ => this.loadingScreenshot),
        map((v) => ((time - 1) - v) / stepsInOneSec), // to reach zero
        map(v => v.toFixed(2)),
        finalize(() => this.loadingScreenshot = false)
      )
    // .subscribe((v) => console.log('Countdown: ', v))
  }

  setScreenShotToImgFromWebsocket(snapshot: any) {
    console.log('Screenshot data received: ', snapshot);
    this.data.screenShotData = {
      seriesId: snapshot.cellInfo.seriesId,
      image: snapshot.image,
      preview: snapshot.image,
      sopInstanceUid: snapshot.cellInfo.seriesUID,
      sliceNumber: snapshot.sliceNum + 1,
      modalityName: snapshot.cellInfo.modality,
      sequenceLabelName: snapshot.cellInfo.label
    }
    this.imageUrl = this.data.screenShotData.image;
    this.imageAttached = true;
    this.attachMessage = "";
    this.loadingScreenshot = false;
  }

  setScreenShotToImg(snapshot: any) {
    // @ts-ignore ldb code is set in index.html file. Read ldb document on https://github.com/DVLP/localStorageDB.
    ldb.get(SNAPSHOT_RAW_DATA, (image) => {
      this.data.screenShotData = {
        seriesId: snapshot.cellInfo.seriesId,
        image: image,
        preview: image,
        sopInstanceUid: snapshot.cellInfo.seriesUID,
        sliceNumber: snapshot.sliceNum + 1,
        modalityName: snapshot.cellInfo.modality,
        sequenceLabelName: snapshot.cellInfo.label
      }
      this.imageUrl = this.data.screenShotData.image;
      this.imageAttached = true;
      this.attachMessage = "";

      // @ts-ignore ldb code is set in index.html file. Read ldb document on https://github.com/DVLP/localStorageDB.
      ldb.set(SNAPSHOT_RAW_DATA, '', () => {
      });
    });
  }

  get isMeasurementAvailable() {
    return (this.data.editType === this.editType.MEASUREMENT || !this.data.editType)
      && ([
          OncologyLesionType.TARGET_LESION,
          OncologyLesionType.EXTRAPONTINE_TARGET_LESION,
          OncologyLesionType.SPINE_TARGET_LESION,
          OncologyLesionType.PONTINE_TARGET_LESION,
          OncologyLesionType.PRIMARY_TARGET_LESION].includes(this.data.lesionType)
        || this.data.lesionData.notMeasurabilityReason === 'SPLIT_IN_MULTIPLE_LESION');
  }

  saveImageToCloud(screenShotData) {
    this.lesionForm.patchValue({
      isNode: this.isNodalLocation
    });
    this.showModalSpinnerLoadingData = true;
    // convert base64 string to Blob object ( array of 8 bit integers)
    const b64toBlob = (b64Data, contentType, sliceSize = 512) => {
      const byteCharacters = window.atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: contentType});
      return blob;
    };

    this.readingService.getSnapshotUploadLink(this.data.studyId, this.data.readingId,screenShotData.seriesId, screenShotData.sliceNumber)
      .subscribe(response => {

        const fdImage = new FormData();
        const blob = b64toBlob(screenShotData.image.split(',')[1], 'image/jpeg');
        fdImage.append('file', blob, 'filename.jpg');

        const fdImagePreview = new FormData();
        const blobPreview = b64toBlob(screenShotData.preview.split(',')[1], 'image/jpeg');
        fdImagePreview.append('file', blobPreview, 'filename_preview.jpg');

        forkJoin([
          this.readingService.uplodFileToCloud(response.fileUploadUrl, blob),
          this.readingService.uplodFileToCloud(response.fileUploadUrlPreview, blobPreview)
        ]).subscribe(([imgUploadRes, imgPreviewUploadRes]) => {

          const snapshot = new Snapshot();
          snapshot.seriesId = this.data.screenShotData.seriesId;
          snapshot.sliceNumber = this.data.screenShotData.sliceNumber;
          snapshot.storageFileName = response.storageFileName;
          snapshot.storageFileNamePreview = response.storageFileNamePreview;
          snapshot.bucketName = response.bucketName;
          snapshot.storagePath = response.storagePath;
          snapshot.fileUrl = response.fileUploadUrl;
          snapshot.fileUrlPreview = response.fileUploadUrlPreview;
          snapshot.modalityName = this.data.screenShotData.modalityName;
          snapshot.sequenceLabelName = this.data.screenShotData.sequenceLabelName;
          snapshot.sopInstanceUid = this.data.screenShotData.sopInstanceUid;
          this.lesionForm.patchValue({
            snapshot : snapshot
          })
          this.showModalSpinnerLoadingData = false;

          this.dialogRef.close(this.lesionForm.value);

        }, () => {
          this.showModalSpinnerLoadingData = false;
          this.toastService.error('Unexpected error', 'Something went wrong. Try again or contact support team.');
        });
    }, () => {
      this.showModalSpinnerLoadingData = false;
      this.toastService.error('Unexpected error', 'Something went wrong. Try again or contact support team.');
    });
  }

  OnSave(){
      if(this.imageUrl=='')
        this.lesionForm.patchValue({
          snapshot : null
        })
      if (this.imageAttached) {
        this.saveImageToCloud(this.data.screenShotData);
      } else {
        this.lesionForm.patchValue({
          isNode: this.isNodalLocation
        });
        this.dialogRef.close(this.lesionForm.value)
      }
  }

  closeClick(){
    this.dialogRef.close();
  }

  validForm() {
    let valid = true;
    if (this.data.editDialog && !this.lesionForm.dirty)
      valid = false;

    if (
      this.data.editDialog &&
      this.data.editType == this.editType.SCREENSHOT &&
      this.imageUrl
    )
      valid = true;
    else if(
        this.data.editDialog &&
          this.data.editType == this.editType.MEASUREMENT &&
          this.imageUrl &&
          this.lesionForm.valid
      )
      valid = true;
    else
      valid = false;

    if (this.data.editDialog && this.lesionForm.dirty &&
      (this.data.editType == this.editType.LOCATION || this.data.editType == this.editType.COMMENT))
      valid = true;

    if (!this.data.editDialog && (!this.lesionForm.valid || !this.imageAttached))
      valid = false;

    if (!this.data.editDialog && this.lesionForm.valid && this.imageAttached)
      valid = true;

    return valid;
  }

  ngOnDestroy() {
    if (this.locationSubscription)
      this.locationSubscription.unsubscribe();

    if (this.screenshotSubscription)
      this.screenshotSubscription.unsubscribe();
  }
}
