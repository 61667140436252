import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import {ReadingRAPNOService} from '../../../../_services/reading-rapno.service';
import {ActivatedRoute} from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {Store} from '@ngxs/store';
import { AddLesionRequest, EditLesionRequest, GlobalLesionModel, OncologyAddLesionData, OncologyLesionType, OncologyResponseType } from 'src/app/_models/Oncology/global-lesion-model';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { RAPNOCondition } from '../rapno-reading-form.component';
import { RAPNOUpdateVisitModel, RAPNOVisitModel } from 'src/app/_models/RAPNO/rapno-visit-model';
import { RAPNOLesionTableComponent } from '../rapno-lesion-table/rapno-lesion-table.component';
import { RAPNOResponseDialogComponent } from '../rapno-response-dialog/rapno-response-dialog.component';
import { Subscription, forkJoin } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-rapno-reading-scoring-form',
  templateUrl: './rapno-reading-scoring-form.component.html',
  styleUrls: ['./rapno-reading-scoring-form.component.css']
})
export class RAPNOReadingScoringFormComponent
  implements OnInit, OnDestroy {

    @ViewChildren(RAPNOLesionTableComponent) lesionTables: QueryList<RAPNOLesionTableComponent>;

    @Input() endpointName: string;
    @Input() readerId: number;
    @Input() reading: any  = {
      studyId: 'NA',
      patientId: 'NA',
      aquisitionDate: 'NA',
      patientCode: 'NA',
      readingCondition: null,
    };
    @Input() visit: RAPNOVisitModel;
    @Input() condition: RAPNOCondition;
    @Input() readonly: boolean;

    @Output('onAddLesion') onAddLesion: EventEmitter<AddLesionRequest> = new EventEmitter<AddLesionRequest>();
    @Output('onEditLesion') onEditLesion: EventEmitter<AddLesionRequest> = new EventEmitter<AddLesionRequest>();
    @Output('onDeleteLesion') onDeleteLesion: EventEmitter<GlobalLesionModel> = new EventEmitter<GlobalLesionModel>();
    @Output('onGoToLesion') onGoToLesion: EventEmitter<GlobalLesionModel> = new EventEmitter<GlobalLesionModel>();
    @Output('onLockVisit') onLockVisit: EventEmitter<any> = new EventEmitter<any>();

    dataLoaded = false;
    dataSubmitting = false;

    imageQualityControl: FormGroup = new FormGroup({});
    readingForm: FormGroup;

    toastOptions: ToastOptions = {
      title: '',
      showClose: true,
      timeout: 10000,
      theme: 'material',
    };

    addUpdateLesionRequest: OncologyAddLesionData;

    listOfLesions: GlobalLesionModel[];

    baselineSPPD: any;
    nadirSPPD: any;
    priorTimepointSPPD: any;
    historicalNadir: any[];
    nadirVisitConfigId: number;

    // hold lesion status from all tables
    lesionReviewedSubscription: Subscription;
    lesionsReviewed: any = {};

  constructor(
              private store: Store,
              private fb: FormBuilder,
              private readingService: ReadingRAPNOService,
              private toastyService: ToastyService,
              private dialog: MatDialog,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {

    this.lesionReviewedSubscription = this.readingService.lesionReviewed.subscribe(result => {
      if (result) {
        if (result.visitConfigId === this.visit.visitConfigId) {
          this.lesionsReviewed[result.status.type] = result.status.reviewed;
        }
      }
    });

    // Init current visit form
    this.initForm();
  }

  ngOnDestroy(): void {
    if (this.lesionReviewedSubscription) {
      this.lesionReviewedSubscription.unsubscribe();
    }
  }

  initForm(): void {

    this.initImageQualityControl();

    this.nadirSPPD = 0;
    this.baselineSPPD = 0;

    this.getLesionLists();
    this.dataLoaded = true;
  }

  private initImageQualityControl(): void {
    this.imageQualityControl = new FormGroup({
      imageQualityAssessment: new FormControl(this.visit.imageQualityAssessment, [Validators.required]),
      imageQualityAssessmentComment: new FormControl(this.visit.imageQualityAssessmentComment, [])
    });
    this.imageQualityControl.valueChanges.subscribe(value => {
      this.visit.imageQualityAssessment = value.imageQualityAssessment;
      this.visit.imageQualityAssessmentComment = value.imageQualityAssessmentComment;


      const imageQualityAssessmentComment = this.imageQualityControl.get('imageQualityAssessmentComment');
      imageQualityAssessmentComment.setValidators( this.isCommentRequired ? Validators.required : null);
      if (this.isCommentRequired) {
        imageQualityAssessmentComment.markAsTouched();
      }

      imageQualityAssessmentComment.updateValueAndValidity({emitEvent: false});
    });
  }

  get formTitle() {
    if (this.reading.readingCondition === RAPNOCondition.HGG) {
      return 'HGG Reading Form';
    }

    if (this.reading.readingCondition === RAPNOCondition.DIPG) {
      return 'DIPG Reading Form';
    }

    if (this.reading.readingCondition === RAPNOCondition.Ependymoma) {
      return 'Ependymoma Reading Form';
    }
    return 'RAPNO Scoring Form';
  }

  getLesionTypes() {
    var lesionTypes = [];
    switch(this.reading.readingCondition) {
      case RAPNOCondition.HGG:
        lesionTypes = [
          OncologyLesionType.TARGET_LESION,
          OncologyLesionType.NON_TARGET_LESION,
          OncologyLesionType.DWI,
        ];
        break;
      case RAPNOCondition.DIPG:
        lesionTypes = [
          OncologyLesionType.PONTINE_TARGET_LESION,
          OncologyLesionType.EXTRAPONTINE_TARGET_LESION,
          OncologyLesionType.NON_TARGET_LESION,
          OncologyLesionType.SPINE_TARGET_LESION,
         ];
         break;

      case RAPNOCondition.Ependymoma:
        lesionTypes = [
          OncologyLesionType.PRIMARY_TARGET_LESION,
          OncologyLesionType.METASTATIC_TARGET_LESION,
          OncologyLesionType.NON_TARGET_LESION,
         ];
         break;
    }

    if (!this.visit.baseline) {
      lesionTypes.push(OncologyLesionType.NEW_LESION);
    }
    return lesionTypes;
  }

  getResponseTypes() {
    var responseTypes = [];
    switch(this.reading.readingCondition) {
      case RAPNOCondition.HGG:
        responseTypes = [
          OncologyResponseType.TARGET_LESION,
          OncologyResponseType.NON_TARGET_LESION,
          OncologyResponseType.DWI,
        ];
        break;
      case RAPNOCondition.DIPG:
        responseTypes = [
          OncologyResponseType.PONTINE_TARGET_LESION,
          OncologyResponseType.EXTRAPONTINE_TARGET_LESION,
          OncologyResponseType.NON_TARGET_LESION,
          OncologyResponseType.SPINE_TARGET_LESION,
         ];
         break;

      case RAPNOCondition.Ependymoma:
        responseTypes = [
          OncologyResponseType.PRIMARY_TARGET_LESION,
          OncologyResponseType.METASTATIC_TARGET_LESION,
          OncologyResponseType.NON_TARGET_LESION,
         ];
         break;
    }

    if (!this.visit.baseline) {
      responseTypes.push(OncologyResponseType.NEW_LESION);
    }
    return responseTypes;
  }

  getLesionLists() {
    this.readingService.modalSpinnerChangeState(true);

    this.readingService.getLesionsForVisit(this.reading.studyId, this.reading.id, this.visit.visitConfigId)
      .subscribe(response => {
        this.listOfLesions = response;
        this.readingService.addToAllLesions(this.listOfLesions);
        this.readingService.modalSpinnerChangeState(false);
      }, () => {
        this.showErrorMessage('Failed to load lesions data');
      });
  }

  /* Lesions management */
  notifyAddLesion(event: OncologyAddLesionData) {
    this.addUpdateLesionRequest = event;
    if (this.onAddLesion) {
      this.onAddLesion.emit(event.requestedData);
    }
  }

  notifyEditLesion(event: OncologyAddLesionData) {
    console.log('notifyEditLesion event', event);
    this.addUpdateLesionRequest = event;

    if (this.onEditLesion) {
      const editRequest: EditLesionRequest = {
        lesionName: event.requestedData.lesionName,
        lesionType: event.requestedData.lesionType,
        markerType: event.requestedData.markerType,
        readingId: event.requestedData.readingId,
        visitConfigId: event.requestedData.visitConfigId,
        visitId: event.requestedData.visitId,
        seriesId: event.data?.seriesId,
        sliceNumber: event.data?.sliceNumber,
      };

      this.onEditLesion.emit(editRequest);
    }
  }

  notifyGoToLesion(event: GlobalLesionModel) {

    if (this.onGoToLesion) {
      this.onGoToLesion.emit(event);
    }
  }

  // Method is called back when lesion is confirmed on UI
  confirmLesion(marker) {
    console.log('Confirm Marker: Scoring', marker);
    if (this.addUpdateLesionRequest) {
      this.addUpdateLesionRequest.viewerData = marker;
      const lesionTable = this.lesionTables.find(f => f.lesionType === this.addUpdateLesionRequest.requestedData.lesionType);
      if (lesionTable) {
        lesionTable.confirmLesion(this.addUpdateLesionRequest);
      }
    }
  }

  checkAllLesionsReviewed() {
    let count = 0;
    this.getLesionTypes().forEach(type => {
      if (this.lesionsReviewed[type] === false)
        count++;
    });
    return count === 0;
  }

  checkValidity() {
    if (false) {
      //this.switchSubmitBtnDisabledSubject.next(true);
    } else {
      this.readingForm.updateValueAndValidity();
      //this.switchSubmitBtnDisabledSubject.next(this.readingForm.invalid);
    }
  }

  clearForm(): void {
    this.imageQualityControl.reset();
  }

  isShowLesionTable(type: OncologyLesionType) {
    return (this.visit && !this.visit.baseline) || type !== OncologyLesionType.NEW_LESION;
  }

  get isCommentRequired() {
    return ['Sub_Optimal', 'Poor'].includes(this.imageQualityControl.get('imageQualityAssessment').value);
  }

  showErrorMessage(message: string): void {
    this.readingService.modalSpinnerChangeState(false);
    this.toastOptions.title = 'ERROR ';
    this.toastOptions.msg = message;
    this.toastyService.error(this.toastOptions);
  }

  isTargetLesion(lesionType: OncologyLesionType) {
    return lesionType === OncologyLesionType.TARGET_LESION
      || lesionType === OncologyLesionType.PONTINE_TARGET_LESION
      || lesionType === OncologyLesionType.EXTRAPONTINE_TARGET_LESION
      || lesionType === OncologyLesionType.SPINE_TARGET_LESION
      || lesionType === OncologyLesionType.PRIMARY_TARGET_LESION
      || lesionType === OncologyLesionType.METASTATIC_TARGET_LESION;
  }

  get allLesionsValid() {
    if (this.visit.baseline) {
      return true;
    }


    return this.listOfLesions
        .filter(l => this.isTargetLesion(l.type))
        .filter(lesion => (lesion.snapshot == null || lesion.shortAxis == null || lesion.axialLongDiameter == null || lesion.ppd == null) && lesion.ppd !== 'NOT_EVALUABLE').length === 0
      && this.listOfLesions
        .filter(l => !this.isTargetLesion(l.type))
        .filter(lesion => lesion.snapshot == null && lesion.status !== 'ABSENT' && lesion.status !== 'NOT_EVALUABLE').length === 0;
  }

  isLockVisitEnabled() {
    return (this.visit && this.visit.status === 'NEW_EDITABLE' || this.visit.status === 'IN_PROGRESS')
      && this.imageQualityControl.valid
      && (this.visit.imageQualityAssessment === 'Optimal' || this.visit.imageQualityAssessmentComment)
      && this.allLesionsValid
      && !this.dataSubmitting;
  }



  onScoreLock() {
    if (!this.visit.baseline) {
      this.readingService.modalSpinnerChangeState(true);
      // compute current follow up response
      // tslint:disable-next-line:max-line-length
      this.readingService.generateResponsesForVisit(this.reading.studyId, this.reading.id, this.visit.visitConfigId, this.readerId)
        .subscribe(allResponses => {
          this.readingService.getResponsesForReading(this.reading.studyId, this.reading.id)
          .subscribe(allReadingResponses => {

            this.readingService.modalSpinnerChangeState(false);

            const dialogRef = this.dialog.open(RAPNOResponseDialogComponent, {
              width: '800px',
              height: '612.05px',
              panelClass: 'response-dialog-container',
              data: {
                studyId: this.reading.studyId,
                readingId: this.reading.id,
                readerId: this.readerId,
                endpointName: this.endpointName,
                visit: this.visit,
                visits: this.reading.visits,
                condition: this.condition,
                allResponses: allReadingResponses.allVisitResponseDtos,
                allResponseTypes: this.getResponseTypes(),
              },
              disableClose: false,
              hasBackdrop: false
            });
            dialogRef.afterClosed().subscribe((result) => {
              if (result.submit) {
                this.visit.comment = result.comment;
                this.lockVisit();
              }
            });
          }, err => {
            this.showErrorMessage('Failed to get all responses');
            this.readingService.modalSpinnerChangeState(false);
          })
        }, err => {
          this.showErrorMessage('Failed to generate responses');
          this.readingService.modalSpinnerChangeState(false);
        })
    } else {
      this.lockVisit();
    }
  }

  lockVisit() {
    this.dataSubmitting = true;
    this.readingService.modalSpinnerChangeState(true);
    const data : RAPNOUpdateVisitModel = {
      comment: this.visit.comment,
      imageQualityAssessment: this.visit.imageQualityAssessment,
      imageQualityAssessmentComment: this.visit.imageQualityAssessmentComment,
      scoring: {},
      doneVisit: true,
    }
    return this.readingService.updateVisit(this.reading.studyId, this.visit.id, data)
      .subscribe(() => {
        this.readingService.modalSpinnerChangeState(false);
        if (this.onLockVisit) {
          this.onLockVisit.emit(this.visit);
        }

        this.dataSubmitting = false;

        this.toastOptions.title = 'INFO: Timepoint locked';
        this.toastOptions.msg = 'Timepoint is sucessfully locked';
        this.toastyService.success(this.toastOptions);
      },
      err => {
        this.dataSubmitting = false;
        this.showErrorMessage('Failed to lock visit');
        this.readingService.modalSpinnerChangeState(false);
      });
  }
}
