export const LestionTableColumns = {
    TARGET_LESION : [ 'lesionName', 'location', 'bidim', 'comment', 'ppd', 'ppdChgNadir', 'notMeasurabilityReason', 'screenshot', 'operations'],
    NON_TARGET_ENHANCING_LESION : [ 'lesionName', 'location', 'comment', 'marker', 'screenshot', 'status', 'operations'],
    NON_TARGET_NON_ENHANCING_LESION : [ 'lesionName', 'location', 'comment', 'marker', 'screenshot', 'status', 'operations'],
    NEW_LESION : [ 'lesionName', 'location', 'comment', 'marker', 'screenshot', 'status', 'changeStatus', 'operations'],
    MEASURABLE_LESION : [ 'lesionName', 'location', 'type', 'bidim', 'comment', 'operations'],
};
export const EfficacyLestionTableColumns = {
    TARGET_LESION: ['lesionName', 'location', 'efficacyComment', 'ALD', 'SAX', 'bidiRuler', 'openSnapshot', 'EditSnapshot', 'meppd', 'meppdChgNadir', 'notMeasurabilityReason', 'operations'],
    NON_TARGET_LESION: ['lesionName', 'location', 'efficacyComment', 'appearance', 'measurabilityStatus', 'ALD', 'SAX', 'measurementTool', 'openSnapshot', 'EditSnapshot', 'meppd', 'diseaseState', 'operations'],
    NEW_LESION: ['lesionName', 'location', 'efficacyComment', 'measurabilityStatus', 'ALD', 'SAX', 'measurementTool', 'openSnapshot', 'EditSnapshot', 'meppd', 'diseaseState', 'operations'],
};
export const HeaderNames: any[] = [
    ['lesionName' , 'Lesion ID'],
    ['location', 'Location'],
    ['efficacyComment', 'Comments'],
    ['appearance', 'Appearance'],
    ['measurabilityStatus', 'Measurability Status'],
    ['ALD', 'LAD (mm)'],
    ['SAX', 'SAD (mm)'],
    ['bidiRuler', 'Open BIDI ruler'],
    ['measurementTool', 'Open measurement tool'],
    ['marker', 'Marker'],
    ['bidim', 'Diameters (dynamicUnit)'],
    ['ppd', 'dynamicUnit'],
    ['ppdChgNadir', 'dynamicUnit'],
    ['meppd', 'PPD (cm2)'],
    ['meppdChgNadir', 'PPD % Chg from Nadir'],
    ['comment', 'Lesion comment'],
    ['screenshot', 'Screenshot'],
    ['snapshot', 'Snapshot'],
    ['openSnapshot', 'Open snapshot'],
    ['EditSnapshot', 'Edit snapshot'],
    ['diseaseState', 'disease state'],
    ['notMeasurabilityReason', 'Not Measurability Reason'],
    ['status', 'Status'],
    ['changeStatus', 'Change'],
    ['operations', ''],
    ['type', 'Type'],
    ['diamChgNadir', 'Dia %'],
    ['area', 'Area ID']
  ];
