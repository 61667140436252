import {Component, OnInit} from '@angular/core';
import {FormControl, ValidationErrors, Validators} from '@angular/forms';
import {AuthenticationService, UserService} from '../../../_services';
import {Store} from '@ngxs/store';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';
import {ToastService} from '../../../_services/internal/toast.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  loadingChangePassword = false;
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  passwordRegExp = '^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W)[\\S]+$';
  oldPasswordCtrl = new FormControl('', [Validators.required]);
  newPasswordCtrl = new FormControl('', [Validators.required, Validators.minLength(12), Validators.pattern(this.passwordRegExp)]);
  confirmNewPasswordCtrl = new FormControl('', [Validators.required, Validators.minLength(12),
    Validators.pattern(this.passwordRegExp), this.confirmPasswordValidator]);

  constructor(private authenticationService: AuthenticationService,
              private userService: UserService,
              private toastService: ToastService, private store: Store) {
  }

  ngOnInit() {
    this.store.dispatch(new SetPageHeaderTitle('Change Password'));
  }

  get confirmPasswordValidator() {
    return (): ValidationErrors | null => {
      return this.confirmNewPasswordCtrl?.value !== this.newPasswordCtrl?.value
        ? {notEqualsError: 'Passwords are not equals'} : null;
    };
  }

  changePassword(): void {
    if (this.newPassword !== this.confirmNewPassword) {
      this.toastService.error('ERROR', 'New password and confirm new password fields does not match');
    }
    this.loadingChangePassword = true;
    this.userService.changePassword(
      JSON.parse(localStorage.getItem('userId')),
      this.newPassword,
      this.oldPassword
    ).subscribe(
      (response: any) => {
        this.loadingChangePassword = false;
        if (response.responseCode === 200) {
          this.toastService.success('Success', 'Password successfully changed');
        } else {
          this.toastService.respError(response);
        }
      }
    );
  }

  isPasswordValid(): boolean {
    return this.oldPasswordCtrl.valid && this.newPasswordCtrl.valid && this.confirmNewPasswordCtrl.valid;
  }
}
