// ts
import { forkJoin } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastOptions } from 'ng2-toasty';
import { AutoCreationBatchConfigDialogComponent } from '../../../../components/controls/auto-creation-batch-config-dialog/auto-creation-batch-config-dialog.component';
import { FormMode } from '../../../../core/constants/form-mode';
import { ReadingType } from '../../../../core/constants/reading-type';
import { ReadingVersion } from '../../../../core/constants/reading-version';
import { FlexibleConfig } from '../../../../core/interfaces/flexible-config';
import { AutoBatchConfigPayload } from '../../../../core/payload/auto-batch-config-payload';
import { BatchConfigModel } from '../../../../_models/BatchLogic/batch-config-model';
import { MranoBasicConfigModel, MRANOEfficacyVisitModel } from '../../../../_models/ImagingProject/MRANO/mrano-basic-config-model';
import { ModalityModel } from '../../../../_models/ImagingProject/modality-model';
import { SequenceLabelModel } from '../../../../_models/ImagingProject/sequence-label-model';
import { ReadingConfigFlexibleService, StudySequenceLabelService, StudyService, StudyUserService } from '../../../../_services';
import { defaultBasicAutoBatchConfig } from '../imaging-project-reading-selector/imaging-project-reading-selector.component';
import { BatchLogicService } from '../../../../_services/batch-logic.service';
import { ReadingLevelOptionsDialogComponent } from '../../../../components/controls/reading-level-options-dialog/reading-level-options-dialog.component';
import { ReadingTypeSequentialOptionsDialogComponent } from '../../../../components/controls/reading-type-sequential-options-dialog/reading-type-sequential-options-dialog.component';
import { RanoImageViewerConfigDialogComponent } from '../../../../components/controls/rano-image-config-dialog/rano-image-config-dialog.component';
import { ImagingProjectReadingBasicComponent } from '../../imaging-project-reading-basic.component';
import {PrimaryBodyLocationService} from '../../../../_services';
import { VisitModalityConfiguratorDialogComponent } from '../visit-modality-configurator-dialog/visit-modality-configurator-dialog.component';
import * as moment from 'moment';
import { VisitType } from '../../../../_models/ImagingProject/patient-model';

export enum ReadingConfigFlexibleStatus {
  Active = 'Active',
  Deleted = 'Deleted'
}
export interface ImagingProjectSubmitEvent {
  result: 'submit' | 'cancel';
  data: any;
  level1BatchConfig?: AutoBatchConfigPayload;
  adjudicationBatchConfig?: AutoBatchConfigPayload;
}

@Component({
  selector: 'app-imaging-project-reading-rapno',
  templateUrl: './imaging-project-reading-rapno.component.html',
  styleUrls: ['./imaging-project-reading-rapno.component.css']
})
export class ImagingProjectReadingRapnoComponent extends ImagingProjectReadingBasicComponent implements OnInit {
  readonly readingVersions = ReadingVersion;

  @Output()
  public clickCancel = new EventEmitter<any>();
  @Output()
  public clickSubmit = new EventEmitter<any>();
  @Input() public mode: FormMode;
  readonly formModes = FormMode;
  @Input('configModel')
  set configModel(value: any) {
    if (value != null) {
      this._configModel = value;
      if (this._configModel.readingLevel === 'patient') {
        this.modalities = this._configModel.modalities;
        this.modalities.sort((a, b) => a.name.localeCompare(b.name));
        this.selectedModality = this._configModel.modalities;
      } else {
        this.selectedModality = this._configModel.modalities;
      }
    }
  }

  @Input() selectedConfig: FlexibleConfig<MranoBasicConfigModel>;
  get configModel(): any {
    return this._configModel;
  }
  public get ReadingVersion(): typeof ReadingVersion {
    return ReadingVersion;
  }

  readonly EARLY_TERMINATION_VISIT = 'Early Termination';
  readingVersionField = new FormControl('', [
    Validators.required
  ]);
  readingLevelField = new FormControl('', [
    Validators.required
  ]);
  modalityField = new FormControl('', [
    Validators.required
  ]);
  readingTypeField = new FormControl('', [
    Validators.required
  ]);
  hideVisitChronologyField = new FormControl('', [
    Validators.required
  ]);
  imageViewerConfigurationField = new FormControl('', [
    Validators.required
  ]);
  locationsField = new FormControl('');
  readersField = new FormControl('', [Validators.required]);
  studySequenceLabels: SequenceLabelModel[] = [];
  anatomySelectorControl: FormControl = new FormControl('', [Validators.required]);
  availableSequenceLabels: SequenceLabelModel[] = [];
  selectedSequenceLabels?: SequenceLabelModel[];
  sequenceValidateText: string;

  readingVersion: any;
  readingLevel: string;
  selectedModality: ModalityModel[];
  readingType: string;
  hideVisitChronologyType: string;
  imageViewerConfigurationType: string;
  moderationReadingConfiguratorType: string;
  fingersToScore: any;
  regionsToScore: any;
  dataSourceReaders: any;
  dataSourceLocations: MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedReadersColumns: string[] = ['id', 'userName', 'email', 'actionDelete'];
  displayedLocationsColumns: string[] = ['id', 'name', 'subName', 'actionRemove'];

  minReaders = 1;
  maxReaders = 20;
  readers: any[] = [];
  minLocations = 1;
  locations: any[] = [];

  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };

  studyId: number;
  _configModel = {
    endpointReadingVersion: 'basic',
    modalities: [] as ModalityModel[],
    readingLevel: 'longitudinal',
    readingLevelConfig: ['SMART', 'SEQUENTIAL'],
    readingTypeConfig: ['AUTO'],
    readingType: 'sequential',
    hideVisitHistory: null,
    readers: [],
    locations: [],
    readingVersion: ReadingVersion.RAPNO_BASIC,
    imageViewerConfigurationType: 'mRANO mode',
    moderationReadingConfiguratorType: 'Disabled',
    imageViewerConfiguration: {
      autoSegmentation: true
    },
    endpoint: null,
    anatomySelector: [],
    assessmentConfigs: [],
    visit: [],
  };


  formValid = false;
  selectedReadersids = [];
  selectedLocationsids = [];
  modalities: ModalityModel[];
  selectedReaders = [];
  selectedLocations = [];
  selectedQuestionTemplate: string;
  visitReadingLevelModality: any = {};
  unscheduledAllowed: boolean;

  // for child configs
  status = ReadingConfigFlexibleStatus;
  readingConfigs: any[];
  selectedAssessmentConfigs;
   assessmentConfigsField = new FormControl(null);
  studyVisits: any[] = [];
  showSpinnerLoadingData = true;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private readingConfigFlexibleService: ReadingConfigFlexibleService,
    private studySequenceLabelService: StudySequenceLabelService,
    private studyUserService: StudyUserService,
    private flowViewer: MatDialog,
    private dialog: MatDialog,
    private batchLogicService: BatchLogicService,
    private primaryBodyLocationService: PrimaryBodyLocationService,
    private studyService: StudyService) {
    super();
  }

  ngOnInit() {
    this.formValid = false;
    this.selectedReaders = [];
    this.selectedLocations = [];
    this.showSpinnerLoadingData = true;

    this._configModel.readers.forEach(ri => {
      this.selectedReadersids.push(ri['id']);
    });

    this.readingVersion = ReadingVersion.RAPNO_BASIC;
    this.readingLevel = 'longitudinal';
    this.readingType = 'sequential';
    this.hideVisitChronologyType = 'show_scan_dates';
    this.imageViewerConfigurationType = 'mRANO mode';
    this.moderationReadingConfiguratorType = 'Disabled';
    if (this.mode === FormMode.EDIT) {
      this.readingVersion = this._configModel.readingVersion;
      this.readingLevel = this._configModel.readingLevel;
      this.readingType = this._configModel.readingType;
      this.imageViewerConfigurationType = this._configModel.imageViewerConfigurationType;
      this.moderationReadingConfiguratorType = this._configModel.moderationReadingConfiguratorType;
      this.hideVisitChronologyType = this._configModel.hideVisitHistory;
    }

    this.studyId = parseInt(this.route.snapshot.params.id, 10);

    this.studySequenceLabelService.getGroupedStudyModalitiesByStudyId(this.studyId).subscribe(respgroupedModalitiesResp => {
      if (respgroupedModalitiesResp.data != null) {
        this.modalities = respgroupedModalitiesResp.data;
        this.modalities.sort((a, b) => a.name.localeCompare(b.name));
        if (this.mode === FormMode.EDIT) {
          this.selectedModality = respgroupedModalitiesResp.data.filter(x => this._configModel.modalities.find(m => m.name === x.name));
        } else {
          this._configModel.modalities = this.modalities;
        }
          // modality config
          const studyServices = [
            this.studyService.getVisitsByStudyId(this.studyId),
            this.studyService.getById(this.studyId),
          ];
          forkJoin(studyServices).subscribe(([studyVisitResponse, studyResponse]) => {
            this.studyVisits = [...studyVisitResponse['data']];
            this.initStudyVisits(studyResponse['data']);
          });
      }


      this.studyUserService.getReadersByStudyId(this.studyId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            this.readers = response.data;
            const activeReader: any[] = [];
            this.readers.forEach(r => {

              if (this.selectedReadersids.includes(r.id)) {
                activeReader.push(r);
              }
            });

            this._configModel.readers = activeReader;
            this.dataSourceReaders = this._configModel.readers.sort((a, b) => a?.firstName - b?.firstName);
            this.selectedReaders = this._configModel.readers;
            this.selectedReadersids = [];
            this._configModel.readers.forEach(r => {
              const cur = this.readers.indexOf(r);
              this.readers.splice(cur, 1);
            });

            this.studySequenceLabelService.getSequenceLabelsByStudyId(this.studyId).subscribe(studySequenceResp => {
              this.prepareSequenceLabels(studySequenceResp);
              this.validateForm();
              this.showSpinnerLoadingData = false;
            });
          }
        });
    });
    this.primaryBodyLocationService.findAll().subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.locations = response.data.filter(data => data.bodySubLocations.length > 0).sort((a, b) => a.name - b.name);
          if (this.mode === FormMode.EDIT && this.readingVersion === ReadingVersion.RAPNO_BASIC) {
            this._configModel.locations.forEach(l => {
             this.selectedLocationsids.push(l.id);
            });
            this.onAddSelectedLocations();
          }
        }
      });

    this.getAllFlexibleConfigs();
  }
  // modality config
  initStudyVisits(study: any) {
    // sort study visits by id
    this.studyVisits = this.sortVisits(this.studyVisits);
    this.unscheduledAllowed = study.unscheduledAllowed;
    // add unschaduled visits and early termination
    const unscheduled = new MRANOEfficacyVisitModel();
    unscheduled.name = 'Unscheduled';
    unscheduled.type = VisitType.UNSCHEDULED_REGULAR;
    this.studyVisits.push(unscheduled);

    const earlyTermination = new MRANOEfficacyVisitModel();
    earlyTermination.name = this.EARLY_TERMINATION_VISIT;
    earlyTermination.type = VisitType.UNSCHEDULED_EARLY_TERMINATION;
    this.studyVisits.forEach(visit => visit.isAvailable = true);
    this.studyVisits.push(earlyTermination);
    if (this.mode !== FormMode.EDIT) {
      this._configModel.visit = this.studyVisits;
      this._configModel.visit.forEach(
        v => v['modalities'] = !this.unscheduledAllowed && v.type === VisitType.UNSCHEDULED_REGULAR ? [] : this.modalities
      );
    }
  }

  sortVisits(visits) {
    visits.forEach(visit => {
      let visitDate = moment(visit.create);
      if (visit.durationTimeUnit === 'D') {
        visitDate = moment(visit.create).add(visit.durationTimeValue, 'd');
      } else if (visit.durationTimeUnit === 'W') {
        visitDate = moment(visit.create).add(visit.durationTimeValue, 'w');
      } else if (visit.durationTimeUnit === 'M') {
        visitDate = moment(visit.create).add(visit.durationTimeValue, 'M');
      }
      visit.visitDate = visitDate;
    });
    visits.sort((a, b) => {
      if (a.visitDate < b.visitDate) {
        return -1;
      }
      if (a.visitDate > b.visitDate) {
        return 1;
      }
      return 0;
    });
    const baselineIndex = visits.indexOf(visits.find(this.isBaseline));
    visits.unshift(visits.find(this.isBaseline));
    visits.splice(baselineIndex + 1, 1);
    return visits;
  }
  isBaseline(visit) {
    return visit.baseline === true;
  }

  getAllFlexibleConfigs() {
    this.readingConfigFlexibleService.getByStudyId(this.studyId).subscribe(response => {
      if (response['data'] != null) {
        this.readingConfigs = (response['data'] as any[]).filter(
          c => c.status?.name === this.status.Active
            && c.config.readingVersion === ReadingVersion.RAPNO_BASIC );
        if (this.selectedConfig) {
          this.readingConfigs = this.readingConfigs.filter(c => c.id !== this.selectedConfig.id);
        }
        if (this.mode === FormMode.EDIT) {
          this.selectedAssessmentConfigs = this.selectedConfig.config.assessmentConfigs;
          this.assessmentConfigsField.updateValueAndValidity();
        }
      }
    });
  }
  onAssessmentConfigsChange(event) {
    this._configModel.assessmentConfigs = event.value;
    this.validateForm();
  }

  prepareSequenceLabels(studySequenceResp) {
    this.studySequenceLabels = studySequenceResp.data;
    if (this.mode === FormMode.EDIT) {
      if (!!this.configModel?.anatomySelector) {
        this.initSequenceLabels(this.configModel.anatomySelector);
      } else {
        this.initDefaultSequenceLabels(this.modalities);
      }
    } else {
      this.initDefaultSequenceLabels(this.modalities);
    }
  }

  initDefaultSequenceLabels(modalities) {
    this.availableSequenceLabels = this.getAvailableSequenceLabels(modalities);
    this.selectedSequenceLabels = this.availableSequenceLabels;
    this._configModel.anatomySelector = this.selectedSequenceLabels;
  }

  initSequenceLabels(sequenceLabels: { id: number }[]) {
    const modalities = this.modalities.filter(m => this.selectedConfig.config.modalities.find(cm => cm.name === m.name));
    this.availableSequenceLabels = this.getAvailableSequenceLabels(modalities);
    this.selectedSequenceLabels = this.studySequenceLabels
      .filter(label => sequenceLabels.find(targetLabel => label.id === targetLabel.id));
  }

  getAvailableSequenceLabels(modalities): SequenceLabelModel[] {
    const configLabels: Set<SequenceLabelModel> = new Set<SequenceLabelModel>();
    for (const modality of modalities) {
      for (const label of modality.sequenceLabels) {
        configLabels.add(label);
      }
    }

    return this.studySequenceLabels
      .filter(label => Array.from(configLabels).find(targetLabel => targetLabel.id === label.id));
  }

  onSequenceLabelSelect(e: SequenceLabelModel[]) {
    this.selectedSequenceLabels = e;
    this.validateForm();
  }
  onChangeReadingVersion(event) {
    this.locationsField.validator = null;
    this.availableSequenceLabels = [];
    this.selectedSequenceLabels = [];

    this.readingType = 'sequential';
    this.readingLevel = 'longitudinal';
    this.imageViewerConfigurationType = 'mRANO mode';
    this.locationsField.validator = Validators.required;
    this.initDefaultSequenceLabels(this.modalities);

    this._configModel.readingType = this.readingType;
    this.validateForm();
  }

  onModalityTypeChange(event) {
    this.selectedModality = [];
    this._configModel.modalities = [];
    event.value.forEach(element => {

      this.selectedModality.push(element);
      this._configModel.modalities.push(element);
    });
    this.initDefaultSequenceLabels(this._configModel.modalities);
    this.validateForm();
  }
  // modality config
  openModalitiesDialog() {
    ImagingProjectReadingBasicComponent.clearFocus();
    const dialogRef = this.dialog.open(VisitModalityConfiguratorDialogComponent, {
      data: {
        visits: this._configModel.visit,
        modalities: this.modalities,
        unscheduledAllowed: this.unscheduledAllowed
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.filterVisits(result.visits);
        const uniqueModality = this.getAllVisitsUniqueModalities(result.visits);
        this._configModel.modalities = [...uniqueModality];
        this.initDefaultSequenceLabels(this._configModel.modalities);
        this.validateForm();
      }
    });
  }

  getAllVisitsUniqueModalities(visits: any[]) {
    let modalities = [];
    visits.forEach((visit, visitIndex) => {
      this._configModel.visit[visitIndex]
        .modalities = [...visit.modalities];
      modalities = [...modalities, ...visit.modalities];
    });
     const uniqueModality = [];
     modalities.forEach((modality) => {
       const index = uniqueModality.findIndex((m) => m.id === modality.id) === -1;
       if (index) { uniqueModality.push(modality); }
     });
     return uniqueModality;
  }

  private filterVisits(visits) {
    visits.forEach(visit => {
      if (!visit.modalities || visit.modalities.length < 1) {
        this.studyVisits.find(v => (v.id === visit.id) || (!v.id && v.name === visit.name)).isAvailable = false;
      } else {
        this.studyVisits.find(v => (v.id === visit.id) || (!v.id && v.name === visit.name)).isAvailable = true;
      }
    });
  }

  openReadingLevelConfiguration() {
    let inputOptions: string[];
    const val = this._configModel.readingLevelConfig;
    if (!val) {
      inputOptions = [];
    } else {
      inputOptions = [...val];
    }
    const dialogRef = this.dialog.open(ReadingLevelOptionsDialogComponent, {
      width: '500px',
      data: inputOptions
    });

    dialogRef.afterClosed().subscribe((result: string[]) => {
      if (result) {
        if (!result) {
          result = [];
        }
        this._configModel.readingLevelConfig = result;
      }
      ImagingProjectReadingBasicComponent.clearFocus();
      this.validateForm();
    });
  }

  openReadingTypeConfiguration() {
    let inputOptions: string[];
    const val = this._configModel.readingTypeConfig;
    if (!val) {
      inputOptions = [];
    } else {
      inputOptions = [...val];
    }
    const dialogRef = this.dialog.open(ReadingTypeSequentialOptionsDialogComponent, {
      width: '500px',
      data: inputOptions
    });

    dialogRef.afterClosed().subscribe((result: string[]) => {
      if (result) {
        this._configModel.readingTypeConfig = result;
      }
      ImagingProjectReadingBasicComponent.clearFocus();
    });
  }

  openImageViewerConfiguration() {
    const val = this.imageViewerConfigurationField.value;
    const dialogRef = this.dialog.open(RanoImageViewerConfigDialogComponent, {
      width: '500px',
      data: {
        reading: 'RANO',
        readonlyDialog: !val,
        imageConfig: {
          autoSegmentation: this._configModel.imageViewerConfiguration.autoSegmentation,
        }
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (this._configModel.imageViewerConfiguration.autoSegmentation !== result.autoSegmentation) {
          this.formValid = true;
        }
        this._configModel.imageViewerConfiguration = result;
      }
      ImagingProjectReadingBasicComponent.clearFocus();
    });
  }

  onReadingLevelChange(event: { value: any; }) {
    this._configModel.readingLevel = event.value;

    if (this._configModel.readingLevel === 'visit') {
      this.setVisitModalityText(this.modalities);
      this.modalities.unshift(this.visitReadingLevelModality);
      this.selectedModality = this.visitReadingLevelModality;
    } else {
      if (this.modalities[0].id === -1) {
        this.modalities.shift();
      }
      this.selectedModality = null;
    }
    this.validateForm();
  }

  onChangeMranoEfficacyDependency() {
    this.validateForm();
  }

  onChangeMranoAssessmentDependency() {
    this.validateForm();
  }

  onReadingTypeChange(event: { value: any; }) {
    this.formValid = this._configModel.readingType !== event.value;
    this._configModel.readingType = event.value;
    this.validateForm();
  }

  onReaderSelectionChange(event: { value: any[]; }) {
    this.selectedReadersids = event.value;
  }

  onLocationSelectionChange(event: { value: any[]; }) {
    this.selectedLocationsids = event.value;
  }
  onChangeImageConfiguration() {
    this.validateForm();
  }
  onChangeModerationConfigurator() {
    this.validateForm();
  }
  onEnableToldMRIChange() {
    this.validateForm();
  }

  /**
   * validate form when change the IsGBMSegmentation radio button */
  onChangeIsGBMSegmentation(): void {
    this.validateForm();
  }

  // this code must check and make sure the Reader is only added to the list if it is not already in it!
  onAddSelectedReaders() {

    if (this.selectedReadersids != null) {
      let counter = this._configModel.readers.length;
      this.selectedReadersids.forEach(readerId => {
        if (counter < this.maxReaders) {
          const reader = this.readers.find(r => r.id === readerId);
          if ((reader != null) && (!this._configModel.readers.includes(reader))) {
            this._configModel.readers.push(reader);
            const index = this.readers.indexOf(reader);
            this.readers.splice(index, 1);
            this.validateForm();
          }
          counter += 1;
        }
      });

      this.selectedReadersids = [];
    }
    this.dataSourceReaders = new MatTableDataSource<any>(this._configModel.readers.sort((a, b) => a?.firstName - b?.firstName));
    this.selectedReaders = this._configModel.readers;
    if (this._configModel.readers.length > 0) {
      this.readersField.clearValidators();
    }
  }

  onAddSelectedLocations(addButtons: boolean = false) {
    const selectedLocations = this.locations.filter(loc => this.selectedLocationsids.includes(loc.id));
    // add selected locations to dataSource
    selectedLocations.forEach(location => {
      location.bodySubLocations.forEach(sublocation => {

        const loc = Object();
        loc.id = location.id;
        loc.name = location.name;
        loc.subId = sublocation.id;
        loc.subName = sublocation.name;
        if (this.mode === FormMode.EDIT && !addButtons ) {
          if (this._configModel.locations.find(lc => lc.id === location.id).subLocations.findIndex(sb => sb.id === sublocation.id) !== -1) {
          this.dataSourceLocations.data.push(loc);
          }
        } else {
          this.dataSourceLocations.data.push(loc);
        }
      });
    });

    this.dataSourceLocations = new MatTableDataSource<any>(this.dataSourceLocations.data.sort((a, b) => a.name - b.name));
    this.selectedLocations.push(...selectedLocations);
    // add selected locations to config model
    const addedLocations = this.selectedLocations.filter(
      l => this._configModel.locations.findIndex(cl => cl.id === l.id) === -1).map(sl => (
        {
          id: sl.id,
          name: sl.name,
          subLocations: sl.bodySubLocations.map(sb => {
            return {
              id: sb.id,
              name: sb.name
            };
          })
        }
    ));
    this._configModel.locations.push(...addedLocations);


    this.locations = this.locations.filter(lc => !this.selectedLocationsids.includes(lc.id));
    this.selectedLocationsids = [];
    if (this._configModel.locations.length > 0) {
      this.locationsField.clearValidators();
    }
    this.validateForm();
  }

  deleteReader(reader: any) {
    const index = this._configModel.readers.indexOf(reader);
    this._configModel.readers.splice(index, 1);
    this.dataSourceReaders = new MatTableDataSource<any>(this._configModel.readers.sort((a, b) => a?.firstName - b?.firstName));
    this.selectedReadersids = this._configModel.readers;
    this.readers.unshift(reader);
    this.selectedReadersids = [];

    if (this._configModel.readers.length === 0) {
      this.readersField.validator = Validators.required;
    }
    if (this._configModel.readers.length > 0) {
      this.validateForm();
    } else {
      this.formValid = false;
    }

  }

  deleteLocation(location: any) {
    this.dataSourceLocations = new MatTableDataSource<any>(this.dataSourceLocations.data.filter(
      lc => lc.subId !== location.subId).sort((a, b) => a.name - b.name)
    );
    const selectedBodyLocation = this._configModel.locations.find(lc => lc.id === location.id).subLocations;
    this._configModel.locations.find(
      lc => lc.id === location.id).subLocations = selectedBodyLocation.filter(sb => sb.id !== location.subId
    );

    if (this._configModel.locations.find(lc => lc.id === location.id).subLocations.length === 0) {
      this._configModel.locations = this._configModel.locations.filter(lc => lc.id !== location.id);
      const recoverLocation = this.selectedLocations.find(l => l.id === location.id);
      this.locations.unshift(recoverLocation);
      this.selectedLocations = this.selectedLocations.filter(l => l.id !== location.id);
    }
    this.selectedLocationsids = [];

    if (this._configModel.locations.length === 0) {
      this.locationsField.validator = Validators.required;
    }
    if (this._configModel.locations.length > 0) {
      this.validateForm();
    } else {
      this.formValid = false;
    }

  }

  clickSubmitBtn() {
    const readers = [];
    this._configModel.readers.forEach(r => {
      readers.push({id: r.id});
    });

    this._configModel.anatomySelector = this.selectedSequenceLabels.map(l => {
      return {id: l.id};
    });

    if (this.readingVersion !== ReadingVersion.RAPNO_BASIC) {
      this._configModel.modalities = [];
      if (this.selectedModality == null) {
        this._configModel.modalities = this.modalities;
      } else {
        this.modalities.forEach(m => {
          if (this.selectedModality.find(x => x.id === m.id) ) {
            this._configModel.modalities.push(m);
          }
        });
      }
    }

    this._configModel.readingVersion = this.readingVersion;
    this._configModel.hideVisitHistory = this.hideVisitChronologyType;
    this._configModel['endpointReadingVersion'] = 'basic';
    this._configModel.readers = readers;
    this._configModel.anatomySelector = this.selectedSequenceLabels;
    this._configModel.readingType = this.readingType;
    this._configModel.readingLevel = this.readingLevel;
    this._configModel.imageViewerConfigurationType = this.imageViewerConfigurationType;
    this._configModel.moderationReadingConfiguratorType = this.moderationReadingConfiguratorType;
    this._configModel.assessmentConfigs = this.selectedAssessmentConfigs ? this.selectedAssessmentConfigs : [];
    this._configModel.visit = this.readingVersion === ReadingVersion.MRANO_BASIC ?
      this._configModel.visit.slice(0, 2)
      : this._configModel.visit;
    const submitEvent: ImagingProjectSubmitEvent = {
      result: 'submit',
      data: this._configModel,
    };
    this.clickSubmit.emit(submitEvent);
  }

  clickCancelBtn() {
    this.clickCancel.emit({result: 'cancel'});
  }

  validateForm() {
    let ans = this.formValid = true;

    if (this._configModel.readingLevel == null) {
      ans = false;
    }

    if (this._configModel.readingType == null) {
      ans = false;
    }

    if (this._configModel.readers.length < this.minReaders || this._configModel.readers.length > this.maxReaders) {
      ans = false;
    }

    if (this._configModel.locations.length < this.minLocations && this.readingVersion === ReadingVersion.RAPNO_BASIC) {
      ans = false;
    }

    if (this.hideVisitChronologyField.invalid) {
      ans = false;
    }

    if (this.imageViewerConfigurationField.invalid) {
      ans = false;
    }

    if (this.assessmentConfigsField.invalid) {
      ans = false;
    }

    this.sequenceValidateText = null;
    if (!!this.selectedSequenceLabels && !this.selectedSequenceLabels.length) {
      this.sequenceValidateText = 'at least one sequence label must be selected';
      ans = false;
    }

    if (!this.selectedSequenceLabels  || this.selectedSequenceLabels.length === 0) {
      ans = false;
    }

    this.formValid = ans;
  }

  setVisitModalityText(modalities) {
    let mText = '';
    modalities.forEach(m => {
      mText += m.name + ', ';
    });

    this.visitReadingLevelModality.id = -1;
    this.visitReadingLevelModality.created = null;
    this.visitReadingLevelModality.name = mText.substr(0, mText.length - 2);
    this.visitReadingLevelModality.sequenceLabels = [];
  }

  public objectComparator = function (option, value): boolean {
    return option.id === value.id;
  };

}
