import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';

import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import * as _moment from 'moment';
import { Moment } from 'moment';
import {MatDatepicker} from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from 'src/app/components/controls/message-dialog/message-dialog.component';
const moment = _moment;


@Component({
  selector: 'app-edtf-edit',
  templateUrl: './edtf-edit.component.html',
  styleUrls: ['./edtf-edit.component.css'],
})
export class EdtfEditComponent implements OnInit {

  edtf = null;

  selectedVisit = {};
  @Input() public visits: any;
  @Input() public edtfTemplate: any;
  // validation data
  @Input() public patientCode: any;
  // @Input() public visitId: any;
  @Input() public patientDaeOfBirth: any;
  @Input('disabled') disabled: boolean;
  @Input() isEnableToldMRI: boolean;
  @Input('modalitiesSelected')
  @Input() visitUploadPage: boolean = false;
  @Input() baselineConditioninValue;
  public modalitiesSelected: any;
  baselineDifferent = false;

  @Output() public updateValidationRulesEvent = new EventEmitter();

  rights = {
    canCreateOrUploadEDTF:true,
    canViewEDTF:true,
  };

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
    this.edtf = null;
    if(this.edtfTemplate != null)
      this.edtf = (JSON.parse(JSON.stringify(this.edtfTemplate)));
    this.initVisits();
  }

  ngAfterViewChecked(){
    this.updateValidationRulesEvent.emit(this.edtf);
  }

  getPickerID(id1,id2){
    return 'block'+id1+'picker'+id2;
  }

  changeEdtfDate(event,target){
    target=event;
  }


  initVisits(){
    if(this.edtf != null) {
      this.edtf.blocks.forEach(block => {
        if('group' in block){
          this.checkListControl(block['group']);
        }
      });
    }
  }

  checkListControl(groups){
    groups.forEach(group => {
      if('inputSelect' in group){
        if(this.visits != null)
          group.inputSelect.selectionValues = this.visits;
      }
    });
  }

  setEdtf(edtf) {
    this.edtf = edtf;
    this.initVisits();
  }

  initValidation(patientCode, visitCode, dateOfBirth){
    if(this.edtf !== null){
      this.edtf.blocks.forEach(block => {
        if('group' in block){
          let groups = block['group'];
          this.parseGroup(groups,patientCode,visitCode,dateOfBirth);
        };
      });
    }
  }

  parseGroup(groups,patientCode,visitCode,dateOfBirth){
    groups.forEach( group => {
      if(group.hasOwnProperty('inputText')){
        let inputText = group['inputText'];
        if(inputText.hasOwnProperty('validation')){
          let validations = inputText['validation'];
          validations.forEach(validation => {
            if(inputText.hasOwnProperty('validationid')){
              if(inputText.validationid == 'patientCode'){
                validation.value = patientCode;
              }
            }
          });
        }
      };
      if(group.hasOwnProperty('inputSelect')){
        let inputSelect = group['inputSelect'];
        if(inputSelect.hasOwnProperty('validation')){
          let validations = inputSelect['validation'];
          validations.forEach(validation => {
            if(inputSelect.hasOwnProperty('validationid')){
              if(inputSelect.validationid == 'visitCode'){
                validation.value = visitCode;
              }
            }
          });
        }
      };
      if(group.hasOwnProperty('dataPicker')){
        let dataPicker = group['dataPicker'];
        if(dataPicker.hasOwnProperty('validation')){
          let validations = dataPicker['validation'];
          validations.forEach(validation => {
            if(dataPicker.hasOwnProperty('validationid')){
              if(dataPicker.validationid == 'dateOfBirth'){
                validation.value = dateOfBirth;
              }
            }
          });
        }
      };

    });
  }
  onChangeAquisitionDate(event, subitem: any) {
    const date = this.formatDate(event.value.toString());
    subitem['value'] = date;
  }

  showRadioButtonQuestions(edtfGroup) {
    if (!edtfGroup?.radioButton) return false;

    const radioButtonTitle = edtfGroup.radioButtonTitle?.trim()?.toLowerCase();
    const question1 = 'Is this upload for TOLD MRI visit?'.trim().toLowerCase();
    const question2 = 'Is this Told MRI pre or post dose?'.trim().toLowerCase();
    const questionExists = [question1, question2].includes(radioButtonTitle);

    return !questionExists || this.isEnableToldMRI;
  }


  formatDate(date: string) {
    const newDate = new Date(date);
    let month = '' + (newDate.getMonth() + 1),
      day = '' + newDate.getDate(),
      year = newDate.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  isValidField(element,validationRule){
    if(validationRule.type == 'warningNotEqual')
      if(element.value != validationRule.value)
        return false;
    if (validationRule.type == 'warningIsEmpty')
      if (!element.value)
        return false
    return true;
  }

  isValidRequiredField(element, validationRule) {
    if (validationRule.type == 'warningIsEmpty' &&
      element.radioButtonTitle.toString().trimEnd().trimStart().toLowerCase() === 'gbm eligibility version')
      if (element.radioButtonValue === '' || !element.radioButtonValue)
        return false;
    if (validationRule.type == 'warningIsEmpty' &&
      element.radioButtonTitle.toString().trimEnd().trimStart().toLowerCase() === 'was the subject at first progression treated by re-resection or biopsy to confirm progression?') {
        return this.isValidFieldPatientHasSurgery(element);
    }
    if (validationRule.type == 'warningIsEmpty' && !element.radioButtonValue) {
      return false
    }
    return true;
  }

  isValidFieldPatientHasSurgery(element) {
    if ((element.radioButtonValue === '' || !element.radioButtonValue)
    && this.getGBMVersionAnswer()?.toLocaleLowerCase() === 'protocol amendment v5.0')
      return false;
    return true;
  }

  getGBMVersionAnswer(){
    let answer = '';
    if (this.edtf) {
      const edtf = this.edtf;
      if (edtf) {
        edtf.blocks.forEach(block => {
          if (block.hasOwnProperty('radioButton')) {
            if (block['radioButton'] === true &&
             block['question'].toString().trimEnd().trimStart().toLowerCase() === 'gbm eligibility version')
              answer = block['radioButtonValue'];
          }
        });
      }
    }
    return answer;
  }

  isValidFieldDateOfBirth(element,validationRule){
    if(validationRule.type == 'warningNotEqual') {
      let dateElement = moment(element.value).format('MM/YYYY');
      let dateValidation = moment(validationRule.value).format('MM/YYYY');
      if (dateElement != dateValidation)
        return false;
    }
    return true;
  }

  onChangeRadioButton(event, question) {
    if ((question.toString().trimEnd().trimStart().toLowerCase() === 'was the subject at first progression treated by re-resection or biopsy to confirm progression?') &&
      event.value.toString().trimEnd().trimStart().toLowerCase() === 'yes' && this.visitUploadPage) {
      const dialogref = this.dialog.open(MessageDialogComponent, {
        data: {
          message: 'Please be sure that the baseline scans have been uploaded'
        }
      });
    }

    this.baselineDifferent = false;
    if (this.baselineConditioninValue && question.toString().trimEnd().trimStart().toLowerCase() === 'please select below condition, only one option should be allowed and mandatory to select from these conditions.') {
      if (event.value.toString().trimEnd().trimStart().toLowerCase() !== this.baselineConditioninValue.trimEnd().trimStart().toLowerCase()) {
        this.baselineDifferent = true;
      }
    }
  }

  invalidCheckBoxField(item) {
    if (item.question) {
      if (item.question.toLowerCase().trim() === 'select laterality of the hand') {
        if (item.group?.length) {
          return item.group.filter(g => g.checkbox?.value === true).length === 0;
        }
      }
    }
    return false;
  }

}
