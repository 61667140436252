import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MessageDialogComponent} from 'src/app/components/controls/message-dialog/message-dialog.component';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {PrimaryBodyLocationService} from 'src/app/_services';
import {Subscription} from 'rxjs';
import {HeaderNames} from 'src/app/_models/Oncology/lesion-display-columns-model';
import {
  AddLesionRequest,
  GlobalLesionModel,
  MarkerType,
  OncologyAddLesionData,
  OncologyLesionEditType,
  OncologyLesionType,
  OncologyResponseEnum,
  OncologyResponseType,
  ResponseOptions,
} from 'src/app/_models/Oncology/global-lesion-model';

import {RAPNOVisitModel} from 'src/app/_models/RAPNO/rapno-visit-model';
import {ReadingRAPNOService} from 'src/app/_services/reading-rapno.service';
import {RAPNOLesionDialogComponent} from '../rapno-lesion-dialog/rapno-lesion-dialog.component';
import {RAPNOLesionSPPDData, RAPNOVisitSPPDData} from '../rapno-reading-form.component';


export const RAPNOLesionSettings = {
  TARGET_LESION : {
    TABLE_NAME: 'Target Lesions',
    COLUMNS: [
      'lesionName',
      'location',
      'comment',
      'bidim',
      'ppd',
      'ppdChgNadir',
      'notMeasurabilityReason',
      'snapshot',
      'operations'
    ],
    LESION_PREFIX: 'T',
    STATUS_OPTIONS: [
      { value: 'PRESENT', text: 'Present' },
      { value: 'ABSENT', text: 'Resolved (LAD = SAD = 0mm)' },
      { value: 'TOO_SMALL_TO_MEASURE', text: 'Too small to measure (LAD = SAD = 5mm )' },
      { value: 'MERGED_WITH_ANOTHER_LESION', text: 'Merged with lesion Txx (LAD = SAD = 0mm)' },
      { value: 'SPLIT_IN_MULTIPLE_LESION', text: 'Split into multiple lesions' },
      { value: 'NOT_EVALUABLE', text: 'Not Evaluable' },
    ],
    MARKER_TYPE: MarkerType.BI_RULER,
    MAX_LESIONS: 3,
  },
  NON_TARGET_LESION: {
    TABLE_NAME: 'Non Target Lesions',
    COLUMNS: [ 'lesionName', 'location', 'comment', 'marker', 'snapshot', 'status', 'operations' ],
    LESION_PREFIX: 'NT',
    STATUS_OPTIONS: [
      { value: 'PRESENT', text: 'Present' },
      { value: 'ABSENT', text: 'Absent' },
      { value: 'UNEQUIVOCAL_PROGRESSION', text: 'Unequivocal Progression' },
      { value: 'NOT_EVALUABLE', text: 'Not Evaluable' },
    ],
    MARKER_TYPE: MarkerType.MARKER,
  },
  DWI: {
    TABLE_NAME: 'DWI',
    COLUMNS: [ 'area', 'location', 'comment', 'marker', 'snapshot', 'status', 'operations' ],
    LESION_PREFIX: '',
    STATUS_OPTIONS: [
      { value: 'PRESENT', text: 'Present' },
      { value: 'ABSENT', text: 'Complete Resolution' },
      { value: 'DECREASED', text: 'Decreased Size' },
      { value: 'INCREASED', text: 'Increased Size' },
      { value: 'NOT_EVALUABLE', text: 'Not Evaluable' },
    ],
    MARKER_TYPE: MarkerType.MARKER,
  },
  NEW_LESION: {
    TABLE_NAME: 'New Lesions',
    COLUMNS: [ 'lesionName', 'location', 'comment', 'marker', 'snapshot', 'status', 'operations' ],
    LESION_PREFIX: 'NL',
    STATUS_OPTIONS: [
      { value: 'PRESENT', text: 'Present' },
      { value: 'ABSENT', text: 'Absent' },
      { value: 'NOT_EVALUABLE', text: 'Not Evaluable' },
    ],
    MARKER_TYPE: MarkerType.MARKER,
    MAX_LESIONS: 10,
  },

  PONTINE_TARGET_LESION: {
    TABLE_NAME: 'Pontine Target Lesions',
    COLUMNS: [ 'lesionName', 'location', 'comment', 'bidim', 'ppd', 'ppdChgNadir', 'notMeasurabilityReason', 'snapshot', 'operations'],
    LESION_PREFIX: 'PT',
    STATUS_OPTIONS: [ ],
    MAX_LESIONS: 1,
    MARKER_TYPE: MarkerType.BI_RULER,
  },
  EXTRAPONTINE_TARGET_LESION: {
    TABLE_NAME: 'Extrapontine Target Lesions',
    COLUMNS: [ 'lesionName', 'location', 'comment', 'bidim', 'ppd', 'ppdChgNadir', 'notMeasurabilityReason', 'snapshot', 'operations'],
    LESION_PREFIX: 'EPT',
    STATUS_OPTIONS: [ ],
    MAX_LESIONS: 3,
    MARKER_TYPE: MarkerType.BI_RULER,
  },
  SPINE_TARGET_LESION: {
    TABLE_NAME: 'Spine Target Lesions',
    COLUMNS: [ 'lesionName', 'location', 'comment', 'bidim', 'ppd', 'ppdChgNadir', 'notMeasurabilityReason', 'snapshot', 'operations'],
    LESION_PREFIX: 'ST',
    STATUS_OPTIONS: [ ],
    MAX_LESIONS: 3,
    MARKER_TYPE: MarkerType.BI_RULER,
  },

  PRIMARY_TARGET_LESION: {
    TABLE_NAME: 'Primary Target Lesions',
    COLUMNS: [ 'lesionName', 'location', 'comment', 'bidim', 'ppd', 'ppdChgNadir', 'notMeasurabilityReason', 'snapshot', 'operations'],
    LESION_PREFIX: 'T',
    STATUS_OPTIONS: [ ],
    MAX_LESIONS: 1,
    MARKER_TYPE: MarkerType.BI_RULER,
  },

  METASTATIC_TARGET_LESION: {
    TABLE_NAME: 'Metastatic Target Lesions',
    COLUMNS: [ 'lesionName', 'location', 'comment', 'bidim', 'ppd', 'ppdChgNadir', 'notMeasurabilityReason', 'snapshot', 'operations'],
    LESION_PREFIX: 'MT',
    STATUS_OPTIONS: [ ],
    MAX_LESIONS: 3,
    MARKER_TYPE: MarkerType.BI_RULER,
  },

};

export const RAPNOHeaderNames: any[] = [
  ['lesionName' , 'Lesion ID'],
  ['location', 'Location'],
  ['efficacyComment', 'Comments'],
  ['appearance', 'Appearance'],
  ['measurabilityStatus', 'Measurability Status'],
  ['ALD', 'LAD (mm)'],
  ['SAX', 'SAD (mm)'],
  ['bidiRuler', 'Open BIDI ruler'],
  ['measurementTool', 'Open measurement tool'],
  ['marker', 'Marker'],
  ['bidim', 'Diameters (dynamicUnit)'],
  ['ppd', 'dynamicUnit'],
  ['ppdChgNadir', 'dynamicUnit'],
  ['meppd', 'PPD (cm2)'],
  ['meppdChgNadir', 'PPD % Chg from Nadir'],
  ['comment', 'Lesion comment'],
  ['snapshot', 'Snapshot'],
  ['openSnapshot', 'Open snapshot'],
  ['EditSnapshot', 'Edit snapshot'],
  ['diseaseState', 'disease state'],
  ['notMeasurabilityReason', 'Not Measurability Reason'],
  ['status', 'Status'],
  ['changeStatus', 'Change'],
  ['operations', ''],
  ['type', 'Type'],
  ['diamChgNadir', 'Dia %'],
  ['area', 'Area ID']
];

  @Component({
    selector: 'app-rapno-lesion-table',
    templateUrl: './rapno-lesion-table.component.html',
    styleUrls: ['./rapno-lesion-table.component.css']
  })
  export class RAPNOLesionTableComponent implements OnInit, OnDestroy, OnChanges {

    displayedColumns: string[] = [];
    dataSource = new MatTableDataSource<any>();
    lesionsDataSource: GlobalLesionModel[] = [];

    responseType = OncologyResponseType;
    responseEnum = OncologyResponseEnum;
    responseOptions = ResponseOptions;

    public get editType(): typeof OncologyLesionEditType {
      return OncologyLesionEditType;
    }

    public get OncologyLesionType(): typeof OncologyLesionType {
      return OncologyLesionType;
    }

    @ViewChild(MatSort) sort: MatSort;
    @Input() endpointName: string;
    @Input() studyId: number;
    @Input() readingId: number;
    @Input() patientId: number;
    @Input() readerId: number;
    @Input() eCRFOnly: boolean;

    @Input() visit: RAPNOVisitModel;
    @Input() lesionType: OncologyLesionType;
    @Input() lesionList: GlobalLesionModel[] = [];

    @Input() readonly: false;

    @Output('onAddLesion') onAddLesion: EventEmitter<OncologyAddLesionData> = new EventEmitter<OncologyAddLesionData>();
    @Output('onEditLesion') onEditLesion: EventEmitter<OncologyAddLesionData> = new EventEmitter<OncologyAddLesionData>();
    @Output('onDeleteLesion') onDeleteLesion: EventEmitter<GlobalLesionModel> = new EventEmitter<GlobalLesionModel>();
    @Output('onGoToLesion') onGoToLesion: EventEmitter<GlobalLesionModel> = new EventEmitter<GlobalLesionModel>();

    toastOptions: ToastOptions = {
      title: '',
      showClose: true,
      timeout: 10000,
      theme: 'material',
    };

    locationSubscription: Subscription;
    primaryLocations = [];

    baselineSPPD: any;
    nadirSPPD: any;
    priorTimepointSPPD: any;

    nadirVisitData: RAPNOVisitSPPDData;
    lesionsHistory: RAPNOLesionSPPDData;

    allLesionSubscription: Subscription;
    readingAllLesions: any[];
    dwiDraftLesions: any[] = [];

    constructor(private dialog: MatDialog,
                private toastyService: ToastyService,
                private dialogMessagePreview: MatDialog,
                private rapnoService: ReadingRAPNOService
    ) { }

    ngOnInit(): void {
      // for baseline visit we do not need to show PPD change in table
      this.displayedColumns = RAPNOLesionSettings[this.lesionType].COLUMNS.filter(f =>
        // for non baseline visit we should not allow remove records, only for new created new lesion
        (!this.isBaselineVisit() && (f !== 'operations' || this.lesionType === OncologyLesionType.NEW_LESION))
        || f !== 'ppdChgNadir');

      console.log('ngOnInit this.displayedColumns: ', this.displayedColumns);

      //get all locations
      this.locationSubscription = this.rapnoService.allPrimaryLocations.subscribe(
        (response) => {
          this.primaryLocations = response;
        }
      );

      //get all lesions
      this.allLesionSubscription = this.rapnoService.allLesions.subscribe(response => {
        if (response) {
          this.readingAllLesions = response;
        }
      });

      // Get SPPD calculated data
      this.rapnoService.baselineNadirSPPD.subscribe(response => {
          this.lesionsHistory = response?.find(f => f.lesionType == this.lesionType);
          const sppds = this.lesionsHistory?.visitsScore ?? [];
          this.baselineSPPD = sppds.find(f => f.isBaseline)?.sppd;

          const priorVisits = sppds.filter(f => f.visitOrder < this.visit.visitOrder);

          if (priorVisits && priorVisits.length > 0) {
          // find nadir within prior visits
            var minSPPD: number = null;
            this.nadirVisitData = null;
            priorVisits.forEach(priorVisit => {
              if (priorVisit.sppd !== 'NA' && priorVisit.sppd !== 'NE' && (!minSPPD || minSPPD > +priorVisit.sppd)) {
                minSPPD = +priorVisit.sppd;
                this.nadirVisitData = priorVisit;
              }
            })
            this.nadirSPPD = this.nadirVisitData?.sppd;
            this.priorTimepointSPPD = priorVisits[priorVisits.length - 1]?.sppd;
          } else {
            this.nadirVisitData = null;
            this.nadirSPPD = null;
            this.priorTimepointSPPD = null;
          }
          this.refreshLesionSource();
      });
    }

    //get new lesion list if changed from dynamic scoring form
    ngOnChanges(changes: SimpleChanges) {
      if (changes.lesionList) {
        this.lesionList = changes.lesionList.currentValue;
        this.refreshLesionSource();
      }
    }

    ngOnDestroy(): void {
      if (!this.allLesionSubscription.closed) {
        this.allLesionSubscription.unsubscribe();
      }

      if (!this.locationSubscription.closed) {
        this.locationSubscription.unsubscribe();
      }
    }

    // Lesions management
    notifyAddLesion(marker: OncologyAddLesionData) {
      if (this.onAddLesion) {
        this.onAddLesion.emit(marker);
      }
    }

    notifyEditLesion(marker: OncologyAddLesionData){
      if (this.onEditLesion) {
        this.onEditLesion.emit(marker);
      }
    }

    notifyDeleteLesion(lesion?: GlobalLesionModel){
      if (this.onDeleteLesion) {
        this.onDeleteLesion.emit(lesion);
      }
    }

    //request (to viewer) to go to lesion
    goToLesion(lesion?: GlobalLesionModel) {
      if (this.onGoToLesion) {
        this.onGoToLesion.emit(lesion);
      }
    }

    confirmLesion(lesion: OncologyAddLesionData) {
      this.openLesionDialog(lesion);
    }

    //get proper table header names
    getHeaderName(key: string) {
      const column = HeaderNames.find(c => c[0] === key)
      if (column) {
        switch (column?.[0]) {
          case 'bidim':
            return column[1].split('dynamicUnit').join('mm'/*this.criteria.ALDUnit()*/)
          case 'comment':
            return column[1];
          case 'ppd':
            return 'PPD'/*this.criteria.PPDName()*/ + ' (' + column[1].split('dynamicUnit').join('cm2'/*this.criteria.PPDUnit()*/) + ')';
          case 'ppdChgNadir':
            return column[1].split('dynamicUnit').join('PPD % change'/* this.criteria.PPDNadirName()*/)
          default:
            return column[1];
        }
      }
      return key;
    }

    getTableName(key: OncologyLesionType) {
      return RAPNOLesionSettings[key]?.TABLE_NAME ?? key;
    }

    getType(lesion: GlobalLesionModel) {
      const location = this.primaryLocations.find(el => { return el.name == lesion.primaryLocation });

      if (location) {
        return location.nodal ? 'Nodal' : 'Non-nodal';
      }
      return '';
    }

    //set lesions to table
    refreshLesionSource() {
      if (this.lesionList || this.dwiDraftLesions) {
        this.lesionsDataSource = this.filterLesions(this.lesionList || []);
        if (this.lesionType === OncologyLesionType.DWI) {
          this.lesionsDataSource = [ ...this.dwiDraftLesions, ...this.lesionsDataSource];
        }
        this.dataSource = new MatTableDataSource(this.lesionsDataSource);
        this.dataSource.sort = this.sort;

        this.rapnoService.modalSpinnerChangeState(false);
        this.rapnoService.onEditLesionMeasurement(null);
        this.checkAllLesionsReviewed();
      }
    }

    //check if all lesions are reviewed
    checkAllLesionsReviewed() {
      let reviewedLesion = false ;
      if (!this.visit.baseline) {
        if (this.isTargetLesion(this.lesionType)) {
          // tslint:disable-next-line:max-line-length
          reviewedLesion = this.lesionsDataSource.filter(lesion => { return (lesion.snapshot == null || lesion.shortAxis == null || lesion.axialLongDiameter == null || lesion.ppd == null) && lesion.ppd !== 'NOT_EVALUABLE' }).length === 0;
        } else {
          reviewedLesion = this.lesionsDataSource.filter(lesion => { return lesion.snapshot == null && lesion.status !== 'ABSENT' && lesion.status !== 'NOT_EVALUABLE' }).length === 0;
        }

        this.rapnoService.sendLesionReviewed({ type: this.lesionType, reviewed: reviewedLesion });
      }
    }

    allLesionsValid() {
      if (!this.visit.baseline) {
        if (this.isTargetLesion(this.lesionType)) {
          return this.lesionsDataSource.filter(lesion => (lesion.snapshot == null || lesion.shortAxis == null || lesion.axialLongDiameter == null || lesion.ppd == null) && lesion.ppd !== 'NOT_EVALUABLE').length === 0;
        } else {
          return this.lesionsDataSource.filter(lesion => lesion.snapshot == null && lesion.status !== 'ABSENT' && lesion.status !== 'NOT_EVALUABLE').length === 0;
        }
      }

      return true;
    }

    isLesionInvalid(lesion) {
      if (!this.visit.baseline) {
        if (this.isTargetLesion(this.lesionType)) {
          return (lesion.snapshot == null || lesion.shortAxis == null || lesion.axialLongDiameter == null || lesion.ppd == null) && lesion.ppd !== 'NOT_EVALUABLE';
        } else {
          return lesion.snapshot == null && lesion.status !== 'ABSENT' && lesion.status !== 'NOT_EVALUABLE';
        }
      }

      return lesion.draft === true;
    }

    //filter lesions by unique constraints
    filterLesions(lesions: GlobalLesionModel[]): GlobalLesionModel[] {
      return lesions.filter(
        (item) => {
          return item.type === this.lesionType &&
                  item.visitConfigId === this.visit.visitConfigId &&
                  item.readingId === this.readingId &&
                  item.endpointName === this.endpointName;
        }
      );
    }

    editBodyLocations(lesion: GlobalLesionModel) {
      const dialogRef = this.dialog.open(RAPNOLesionDialogComponent, {
        width: '500px',
        data: {
          lesionName: lesion.lesionName,
          studyId: this.studyId,
          readingId: this.readingId,
          readerId: this.readerId,
          patientId: this.patientId,
          endpointName: this.endpointName,
          visitConfigId: this.visit.visitConfigId,
          isBaseline: this.visit.baseline,
          markerType: RAPNOLesionSettings[this.lesionType]?.MARKER_TYPE,
          editDialog: true,
          lesionData: lesion,
          screenShotData: lesion.snapshot,
          editType: this.editType.LOCATION,
          lesionType: this.lesionType,
          viewerData: null,
          eCRFOnly: this.eCRFOnly,
        },
        hasBackdrop:false,
        disableClose:false
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.addOrUpdateLesion(result, true)
        }
      });
    }

    onChangeSublocation(event,lesion:GlobalLesionModel) {
      lesion.subLocation = event.value;
      this.addOrUpdateLesionService(lesion, true);
    }

    //send request (to viewer side) to add a lesion
    onAddLesionClick() {
      if (this.lesionType === OncologyLesionType.DWI) {
        this.dwiDraftLesions.push({
          lesionName: '',
          draft: true,
        });
        this.refreshLesionSource();
      } else {
        console.log('onAddLesionClick', this.lesionType);
        const requestData: AddLesionRequest = {
          readingId: this.readingId,
          visitId: this.visit.id,
          visitConfigId: this.visit.visitConfigId,
          lesionType: this.lesionType,
          markerType: RAPNOLesionSettings[this.lesionType]?.MARKER_TYPE,
          lesionName: this.generateLesionName()
        };

        const request: OncologyAddLesionData = {
          requestedData: requestData,
          editType: null,
          edit: false,
          data: null,
        };

        this.notifyAddLesion(request);
      }
    }

    //edit measurement of lesion
    onEditMeasurement(editType?: OncologyLesionEditType, currentLesionData?: any) {
      const tempCurrentLesionData = { ...currentLesionData };
      if (currentLesionData && currentLesionData.snapshot) {
        tempCurrentLesionData.snapshot = {
          seriesId: currentLesionData.snapshot.seriesId,
          sliceNumber: currentLesionData.snapshot.sliceNumber
        };
      }

      const requestData: AddLesionRequest = {
        readingId: this.readingId,
        visitId: this.visit.id,
        visitConfigId: this.visit.visitConfigId,
        lesionType: this.lesionType,
        markerType: RAPNOLesionSettings[this.lesionType]?.MARKER_TYPE,
        lesionName: currentLesionData.lesionName,
      };

      const request: OncologyAddLesionData = {
        requestedData: requestData,
        editType: currentLesionData.draft ? null : editType,
        edit: !currentLesionData.draft,
        data: tempCurrentLesionData,
      };

      console.log('onEditMeasurement request: ', request);
      if (currentLesionData.draft) {
        this.notifyAddLesion(request);
      } else {
        this.notifyEditLesion(request);
      }

    }

    //open brain lesion dialog
    openLesionDialog(sentData: OncologyAddLesionData) {
      console.log('openLesionDialog sentData: ', sentData);
      //check match with table:
      if (sentData.editType == this.editType.MEASUREMENT || !sentData.editType)
        if (sentData.requestedData?.visitId != this.visit.id ||
            sentData.requestedData?.readingId != this.readingId ||
            (sentData.requestedData?.lesionType != this.lesionType))
          return;

      const maxLesionNo = RAPNOLesionSettings[this.lesionType]?.MAX_LESIONS ?? 10;

      if (this.lesionsDataSource.length >= maxLesionNo && this.visit.baseline
        && !sentData.edit && this.isTargetLesion(this.lesionType)) {
        this.toastOptions.title = 'ERROR Cannot add lesion';
        this.toastOptions.msg = `Cannot add more than ${maxLesionNo} target lesions`;
        this.toastyService.error(this.toastOptions);
        return;
      }

      const dialogData = {
        lesionName: sentData.edit && this.lesionType !== OncologyLesionType.DWI ? sentData.data.lesionName : this.generateLesionName(),
        studyId: this.studyId,
        readingId: this.readingId,
        readerId: this.readerId,
        patientId: this.patientId,
        endpointName: this.endpointName,
        visitConfigId: this.visit.visitConfigId,
        isBaseline: this.visit.baseline,
        markerType: RAPNOLesionSettings[this.lesionType]?.MARKER_TYPE,
        editDialog: sentData.edit,
        lesionData: (sentData.data ? sentData.data : {}),
        screenShotData: sentData?.data?.snapshot,
        editType: sentData.editType,
        lesionType: this.lesionType,
        viewerData: sentData.viewerData,
        eCRFOnly: this.eCRFOnly,
        // notMeasurabilityReason: RAPNOLesionSettings[this.lesionType]?.STATUS_OPTIONS ?? [],
      };

      console.log('openLesionDialog dialogData: ', dialogData);
      const dialogRef = this.dialog.open(RAPNOLesionDialogComponent, {
        width: '500px',
        data: dialogData ,
        hasBackdrop:false,
        disableClose:true
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          console.log('openLesionDialog result: ', result, ' sentData: ', sentData);
          this.addOrUpdateLesion(result, sentData.edit);
        }
        //clear local storage causes DOM exception
        // this.clearAllLocalStorageKeys();
      });
    }

    getLesionStatus() {
      //return this.visit.baseline ?
    }

    //generate proper lesion name
    generateLesionName() {
      const objectLength = this.lesionsDataSource.length + 1;

      const prefix = RAPNOLesionSettings[this.lesionType]?.LESION_PREFIX;
      return objectLength < 10
        ? (`${prefix}0${objectLength}`).toString()
        : (`${prefix}${objectLength}`).toString();
    }

    getDecimalValue(value: number) {
      return value;
    }

    //prepare to update/add lesion
    addOrUpdateLesion(data: any, edit: boolean) {
      let lesion: GlobalLesionModel = data;
      if (!edit && this.lesionType !== OncologyLesionType.DWI) {
        lesion.lesionName = this.generateLesionName();
      }

      this.addOrUpdateLesionService(lesion, edit);
    }

    sortedScreenshots() {
      const screenshots = this.lesionsDataSource.sort((l1, l2) => l1.id - l2.id);
      return screenshots;
    }

    onShowImage(lesion: GlobalLesionModel) {
      let title = "There is no image to preview";
      let message = "";
      if (lesion.snapshot) {
        title = 'Lesion: ' + lesion.lesionName + ',  Modality: ' + lesion.snapshot.modalityName
              + ', Label: ' + lesion.snapshot.sequenceLabelName
              + ', Slice: ' + lesion.snapshot.sliceNumber;
        message = '<img width="100%" src="' + lesion.snapshot.fileUrl + '"/>';
      }
      const dialogMessaegRef = this.dialogMessagePreview.open(MessageDialogComponent, {
        disableClose: true,
        data: {title: title, message: message, html: true}
      });

      dialogMessaegRef.afterClosed().subscribe(result => { });
    }

    onDeleteLesionRow(row) {
      const message ='Do you want to delete the current lesion?';

      const dialogRef = this.dialog.open(MessageDialogComponent, {
        height: '200px',
        width: '600px',
        disableClose: true,
        data: {
            title: 'Confirmation',
            message: message,
            showOk: true,
            showCancel: true,
            html : false
        }
      });

      dialogRef.afterClosed().subscribe(result => {
          if (result === 'ok') {
            this.deleteLesionService(row);
          }
      });
    }

    disableModifyLesionLocation(lesion: GlobalLesionModel) {
      if (this.isTargetLesion(this.lesionType))
        return false;

      if (!this.visit.baseline)
        return this.enableEditNewLesion(lesion);

      return false
    }

    //add/update lesion service
    addOrUpdateLesionService(data: any, edit: boolean) {
      this.rapnoService.modalSpinnerChangeState(true);

      if (edit) {
        this.rapnoService.updateLesion(data).subscribe(response => {
          const lesion = response;

          for (let i = 0; i < this.lesionList.length; i++) {
            if (this.lesionList[i].id === lesion.id) {
              this.lesionList[i] = lesion;
              break;
            }
          }
          this.refreshLesionSource();
          this.rapnoService.updateAllLesions(lesion);

          this.toastOptions.title = 'ID 44: Lesion has been updated successfully';
          this.toastOptions.msg = 'Lesion is updated';
        },
        (error) => {
          this.rapnoService.modalSpinnerChangeState(false);

          this.toastOptions.title = 'ID 45: Lesion updating failure';
          this.toastOptions.msg = 'Lesion is not updated due to some reasons. Resolve issues on the form or contact support team';
          this.toastyService.error(this.toastOptions);
        });
      } else {
        this.rapnoService.createLesion(data).subscribe(response => {
          const lesion = response[0];

          this.lesionList.push(lesion);
          if (this.lesionType === OncologyLesionType.DWI) {
            console.log('FILTER');
            this.dwiDraftLesions = this.dwiDraftLesions.filter(l => l.lesionName !== lesion.lesionName);
          }
          this.refreshLesionSource();
          this.rapnoService.addToAllLesions([lesion]);

          this.rapnoService.modalSpinnerChangeState(false);
          this.toastOptions.title = 'ID 42: Lesion has been created successfully';
          this.toastOptions.msg = 'Lesion is now available in the list of lesions';

          this.toastyService.success(this.toastOptions);


        },
        (error) => {
          this.toastOptions.title = 'ID 43: Lesion creation failure';
          this.toastOptions.msg = 'Lesion is not created due to some reason';
          this.rapnoService.modalSpinnerChangeState(false);
          this.toastyService.error(this.toastOptions);
        }
        );
      }
    }

    //delete lesion service
    deleteLesionService(lesion:any) {
      this.rapnoService.modalSpinnerChangeState(true);

      this.rapnoService.deleteLesion(this.studyId, this.readingId, lesion.id).subscribe(response => {

            const removedLesions = response;

            removedLesions.forEach(d => {
              d.snapshot = this.lesionList.find(l => l.id === d.id).snapshot
            })
            this.lesionList = this.lesionList.filter(l => { return l.id !== lesion.id });
            this.refreshLesionSource();
            this.rapnoService.deleteFromAllLesions(lesion, removedLesions);

            // TODO: check if we need this
            this.rapnoService.triggerLesionList(lesion.visitConfigId);
            this.notifyDeleteLesion(lesion);

            this.rapnoService.baselineNadirSPPDRequest(true);
            this.rapnoService.modalSpinnerChangeState(false)
            this.toastOptions.title = 'ID 44: Lesion has been updated successfully';
            this.toastOptions.msg = 'Lesion is removed';
            this.toastyService.success(this.toastOptions);
          },
          error =>{
            this.rapnoService.modalSpinnerChangeState(false)
            this.toastOptions.title = 'ID 45: Lesion updating failure';
            this.toastOptions.msg = 'Lesion is not removed due to some reasons. Resolve issues on the form or contact support team';
            this.toastyService.error(this.toastOptions);
          }
        );
    }

    // get list of lesion to merge
    getOtherOptionsForMeasurabilityStatus(lesion:GlobalLesionModel){
      return this.lesionsDataSource.filter(l => {
        return l.lesionName !== lesion.lesionName
      }).map(l => {return l.lesionName})
    }

    onChangeMeasurabilityStatus(event, lesion: GlobalLesionModel) {
      console.log('onChangeMeasurabilityStatus: ', event, lesion);
      const eventValue = event.value.split(' ')[0];
      lesion.notMeasurabilityReason = eventValue;
      lesion.mergedWith = null;
      lesion.isMerged = null;
      lesion.snapshot = null;
      lesion.isMeasurable = event.value === 'PRESENT';

      switch (eventValue) {
        case 'PRESENT':
        case 'SPLIT_IN_MULTIPLE_LESION':
          lesion.axialLongDiameter = 'NA';
          lesion.shortAxis = 'NA';
          lesion.ppd = 'NA';
          break;
        case 'RESOLVED':
          lesion.axialLongDiameter = 0;
          lesion.shortAxis = 0;
          lesion.ppd = 0;
          break;
        case 'TOO_SMALL_TO_MEASURE':
          lesion.axialLongDiameter = 5;
          lesion.shortAxis = 5;
          lesion.ppd = 0.25;
          break;
        case 'MERGED_WITH_ANOTHER_LESION':
          lesion.mergedWith = event.source.triggerValue.split(' ')[2];
          lesion.isMerged = true;
          lesion.axialLongDiameter = 0;
          lesion.shortAxis = 0;
          lesion.ppd = 'NA';
          break;
        case 'NOT_EVALUABLE':
          lesion.axialLongDiameter = 'NE';
          lesion.shortAxis = 'NE';
          lesion.ppd = 'NE';
          break;
        default:
          break;
      }
      this.addOrUpdateLesionService(lesion, true);
    }

    onChangeStatus(event, lesion: GlobalLesionModel){
      lesion.status = event.value;
      lesion.snapshot = null;
      if (this.visit.baseline) {
        lesion.status = 'NA';
      }

      this.addOrUpdateLesionService(lesion, true);
    }

    onChangeArea(event, lesion) {
      const originalLesion = this.lesionList.find(l => l.lesionName === event.value.replace('D-', ''));
      lesion.lesionName = event.value;
      lesion.primaryLocation = originalLesion.primaryLocation;
      lesion.subLocation = originalLesion.subLocation;
    }

    enableEditNewLesion(lesion: GlobalLesionModel) {
      return this.lesionType === OncologyLesionType.NEW_LESION || this.readingAllLesions.filter(l => l.lesionName === lesion.lesionName).length > 1;
    }

    /* Lesion status */
    showStatusOptions() {
      return RAPNOLesionSettings[this.lesionType]?.STATUS_OPTIONS ?? [];
    }

    getAreaOptions(currentLesion: any) {
      const dwiLesions = this.lesionList.filter(l => l.type === OncologyLesionType.DWI && l.lesionName !== currentLesion.lesionName).map(l => l.lesionName.replace('D-', ''));
      const draftLesions = this.dwiDraftLesions.map(l => l.lesionName).filter(f => f !== currentLesion.lesionName);
      const options =  this.lesionList
        .filter(l => l.type !== OncologyLesionType.DWI && !dwiLesions.includes(l.lesionName) && !draftLesions.includes(l.lesionName))
        .map(l => l.lesionName);

      return options;
    }

    onChangeChangeStatus(event, lesion: GlobalLesionModel) {
      lesion.changeStatus = event.value;
      this.addOrUpdateLesionService(lesion, true);
    }

    correctPPD(sppd?: string | number) {
      switch (sppd) {
        case 'NA':
        case 'NE':
        case null:
          return sppd;
      }
      return +sppd;
    }

    correctNadirChangePPD(lesion) {
      const nadirVisitLesion = this.nadirVisitData?.lesions?.find(f => f.lesionName === lesion.lesionName);

      if (nadirVisitLesion) {
        if (!lesion.ppd || lesion.ppd === 'NE' || lesion.ppd === 'NA') {
          if (nadirVisitLesion.ppd > 0) {
            return -100;
          }

          return lesion.ppd;
        }
        if (!nadirVisitLesion.ppd || nadirVisitLesion.ppd === 'NE' || nadirVisitLesion.ppd === 'NA') {
          return nadirVisitLesion.ppd;
        }

        return (100.0 * (lesion.ppd - nadirVisitLesion.ppd) / nadirVisitLesion.ppd).toFixed(2);
      }

      return 'NA';
    }

    calculateSummary() {
      return this.sppdCalculation(this.lesionsDataSource);
    }

    comparisonSPPD(sppd:any){
      let sppdComparison = 0;
      if (sppd == 'NA' || !sppd)
        return 'NA';
      if (sppd == 'NE')
        return 'NE';
      sppd = +sppd.toFixed(2);

      const currentSPPD = this.sppdCalculation(this.lesionsDataSource);
      if (currentSPPD == 'NA' || currentSPPD == 'NE') return currentSPPD;
      if (currentSPPD >= 0)
        sppdComparison = (currentSPPD - sppd) * 100 / sppd;

      return +sppdComparison.toFixed(2);
    }

    sppdCalculation(lesionSource: GlobalLesionModel[]) {
      let sppd : any = 0;
      if(lesionSource)
        lesionSource.forEach(l => {
          let ppd = l.ppd;
          if(sppd !== 'NA' && sppd !== 'NE' && ppd && ppd !== 'NA' && ppd !== 'NE')
            sppd += +ppd;
          else if (ppd == 'NA')
            sppd = 'NA';
          else if (ppd == 'NE')
            sppd = 'NE';
        });
      return sppd !== 'NE' && sppd !== 'NA' ? +sppd.toFixed(2) : sppd;
    }

    isBaselineVisit() {
      return !this.visit || this.visit.baseline;
    }

    isTargetLesion(lesionType: OncologyLesionType) {
      return lesionType === OncologyLesionType.TARGET_LESION
        || lesionType === OncologyLesionType.PONTINE_TARGET_LESION
        || lesionType === OncologyLesionType.EXTRAPONTINE_TARGET_LESION
        || lesionType === OncologyLesionType.SPINE_TARGET_LESION
        || lesionType === OncologyLesionType.PRIMARY_TARGET_LESION
        || lesionType === OncologyLesionType.METASTATIC_TARGET_LESION;
    }

    isAllowedLesionsCount() {
      return this.lesionsDataSource && (RAPNOLesionSettings[this.lesionType]?.MAX_LESIONS ?? 10) > this.lesionsDataSource.length;
    }

    isStatusChangeOrDeleteEnabled(lesion) {
      return this.isBaselineVisit() ||
        ([OncologyLesionType.NEW_LESION, OncologyLesionType.DWI].includes(lesion.type)
          // lesion was added for that visit - absent in prior visits (was not copied)
          && !this.lesionsHistory?.visitsScore?.find(f =>
              f.visitOrder < this.visit.visitOrder && f.lesions?.find(a => a.lesionName === lesion.lesionName)));
    }

    isAddLesionEnabled() {
      if (this.lesionType === OncologyLesionType.DWI) {
        const dwiLesionsCount = (this.lesionList ?? []).filter(l => l.type === OncologyLesionType.DWI).length;
        const nonDwiLesionsCount = (this.lesionList ?? []).filter(l => l.type !== OncologyLesionType.DWI).length;

        const ret = dwiLesionsCount < (nonDwiLesionsCount + this.dwiDraftLesions.length);

        return ret;
      }

      return (this.isBaselineVisit() || this.lesionType === OncologyLesionType.NEW_LESION)
        && this.isAllowedLesionsCount();
    }

    getWarningForDimentions(lesion: GlobalLesionModel) {
      return this.isTargetLesion(lesion.type) && (lesion.axialLongDiameter < 10 || lesion.shortAxis < 10);
    }

    canEditDiameters(lesion: GlobalLesionModel) {
      return !['RESOLVED', 'TOO_SMALL_TO_MEASURE', 'NOT_EVALUABLE'].includes(lesion.notMeasurabilityReason);
    }

  }
