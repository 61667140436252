import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';
import { RAPNOReadingModel, RAPNOUpdateReadingModel } from '../_models/RAPNO/rapno-reading-model';
import { RAPNOUpdateVisitModel } from '../_models/RAPNO/rapno-visit-model';
import { GlobalLesionModel } from '../_models/Oncology/global-lesion-model';
import { RAPNOLesionSPPDData } from '../modules/Reading/RAPNO/rapno-reading-form.component';
import { RAPNOReadingResponseModel, RAPNOVisitResponseModel } from '../_models/RAPNO/rapno-response-model';

const SERVICE_API_URL = 'https://' + environment.MSReadingRAPNOVersion + 'msreadingrapno-dot-' + environment.apiUrl;

// const SERVICE_API_URL = 'http://localhost:8080/api/reading';

@Injectable({
  providedIn: 'root'
})
export class ReadingRAPNOService {

  private seriesLockedStatusSource = new BehaviorSubject([]);
  seriesLockedStatus = this.seriesLockedStatusSource.asObservable();

  private deletedAnnObjectsSource = new BehaviorSubject([]);
  deletedAnnObjects = this.deletedAnnObjectsSource.asObservable();

  private modalSpinnerSource  = new BehaviorSubject<boolean>(true);
  modalSpinner =this.modalSpinnerSource.asObservable();

  private openLTViewerSource = new BehaviorSubject<any>(null);
  openLTViewerData = this.openLTViewerSource.asObservable();

  private editLesionMeasurementSource = new BehaviorSubject<any>(null);
  editLesionMeasurement = this.editLesionMeasurementSource.asObservable();

  private refereshLesionListSource = new BehaviorSubject<any>(null);
  refereshLesionList = this.refereshLesionListSource.asObservable();

  private baselineNadirSPPDSource = new BehaviorSubject<RAPNOLesionSPPDData[]>(null);
  baselineNadirSPPD = this.baselineNadirSPPDSource.asObservable();

  private historicalBaselineNadirSource = new BehaviorSubject<any>(null);
  historicalBaselineNadir = this.historicalBaselineNadirSource.asObservable();

  private lesionReviewedSource = new BehaviorSubject<any>(null);
  lesionReviewed = this.lesionReviewedSource.asObservable();

  private baselineNadirRequestSource = new BehaviorSubject<boolean>(null);
  baselineNadirRequest = this.baselineNadirRequestSource.asObservable();

  private locationSource = new BehaviorSubject<any>(null);
  location = this.locationSource.asObservable();

  private allPrimaryLocationSource = new BehaviorSubject<any>(null);
  allPrimaryLocations = this.allPrimaryLocationSource.asObservable();

  private allLesionsSource = new BehaviorSubject<any>(null);
  allLesions = this.allLesionsSource.asObservable();
  
  readingLesions: any[] = [];
  private criteriaSettingSource = new BehaviorSubject(null);
  criteriaSetting = this.criteriaSettingSource.asObservable();

  private requestScreenshotSource = new BehaviorSubject<any>(null);
  requestScreenshot = this.requestScreenshotSource.asObservable();

  private screenshotSource = new BehaviorSubject<any>(null);
  screenshot = this.screenshotSource.asObservable();

  constructor(private http: HttpClient) {
  }

  modalSpinnerChangeState(state:boolean){
    this.modalSpinnerSource.next(state);
  }
  openLTViewer(data:any){
    this.openLTViewerSource.next(data)
  }
  onEditLesionMeasurement(data?:any){
    this.editLesionMeasurementSource.next(data);
  }
  addSeriestoSeriesLockedStatus(list:any){
    this.seriesLockedStatusSource.next(list)
  }
  deleteAnnObjectsSource(AnnObjects:any){
    this.deletedAnnObjectsSource.next(AnnObjects)
  }
  triggerLesionList(visitConfigId:number) {
    this.refereshLesionListSource.next(visitConfigId);
  }
  baselineNadirSPPDChange(data: RAPNOLesionSPPDData[]) {
    this.baselineNadirSPPDSource.next(data);
  }
  baselineNadirSPPDRequest(data: boolean) {
    this.baselineNadirRequestSource.next(data);
  }
  locationRequest(data: any) {
    this.locationSource.next(data);
  }
  allPrimaryLocationsRequest(data: any) {
    this.allPrimaryLocationSource.next(data);
  }

  sendLesionReviewed(data: any) {
    this.lesionReviewedSource.next(data);
  }

  addToAllLesions(lesions: any[]) {
    this.readingLesions = this.readingLesions.concat(lesions);
    this.allLesionsSource.next(this.readingLesions);
  }

  updateAllLesions(lesion: any) {
    const index = this.readingLesions.findIndex(l => l.id === lesion.id);
    this.readingLesions[index] = lesion;
    this.allLesionsSource.next(this.readingLesions);
  }

  deleteFromAllLesions(lesion: any, data: any[]) {
    this.readingLesions = this.readingLesions.filter(l => { return l.id !== lesion.id });
    data.forEach(d => { this.readingLesions.find(l => l.id === d.id).lesionName =  d.lesionName });
    this.allLesionsSource.next(this.readingLesions);
  }

  clearAllLesions() {
    this.readingLesions = [];
    this.allLesionsSource.next([])
  }

  putScreenshotData(data: any) {
    console.log('Confirm Screenshot data');
    this.screenshotSource.next(data);
  }

  requestScreenshotData() {
    console.log('Request Screenshot data');
    this.requestScreenshotSource.next({});
  }

  getNumberOfAvailableReadings(studyId: number, readerId: number): Observable<BasicResponse<number>> {
    return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL}/readings/available-count/studyId-${studyId}/readerId-${readerId}`);
  }

  getReadingByStudyIdAndReaderId(studyId: number, readerId: number): Observable<BasicResponse<RAPNOReadingModel[]>> {
    return this.http.get<BasicResponse<RAPNOReadingModel[]>>(`${SERVICE_API_URL}/readings/available/studyId-${studyId}/readerId-${readerId}`);
  }

  getReadingById(studyId: number, readerId: number, readingId: number): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL}/readings/studyId-${studyId}/readerId-${readerId}/${readingId}`);
  }

  startReading(studyId: number, readingId: number): Observable<BasicResponse<any>> {
    return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}/readings/${readingId}/start`, null);
  }

  logTime(studyId: number, readingId: number, data: { spentSeconds: number; }): Observable<BasicResponse<any>> {
    return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}/readings/${readingId}/log-time`, data);
  }

  updateReading(studyId: number, readingId: number, spentSeconds): Observable<BasicResponse<any>> {
    const submitData = {
      timeSpent: spentSeconds
    };
    return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}/readings/${readingId}/update`, submitData);
  }

  completeReading(studyId: number, readingId: number, data: RAPNOUpdateReadingModel): Observable<BasicResponse<any>> {
    return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}/readings/${readingId}/complete`, data);
  }

  updateVisit(studyId: number, visitId: number, data: RAPNOUpdateVisitModel): Observable<BasicResponse<any>> {
    return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}/visits/${visitId}`, data);
  }

  // Lesions
  createLesion(lesionData: any): Observable<GlobalLesionModel[]> {
    return this.http.post<GlobalLesionModel[]>(`${SERVICE_API_URL}/lesions`, lesionData);
  }

  updateLesion(lesionData: any): Observable<GlobalLesionModel> {
    return this.http.put<GlobalLesionModel>(`${SERVICE_API_URL}/lesions`, lesionData);
  }

  deleteLesion(studyId: number, readingId: number, lesionId: number): Observable<GlobalLesionModel[]> {
    return this.http.delete<GlobalLesionModel[]>(`${SERVICE_API_URL}/lesions/${readingId}/${lesionId}`);
  }

  getLesion(studyId: number, readingId: number, lesionId: number): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL}/lesions/${readingId}/${lesionId}`);
  }

  getLesionsForReading(studyId: number, readingId: number): Observable<GlobalLesionModel[]> {
    return this.http.get<GlobalLesionModel[]>(`${SERVICE_API_URL}/lesions/list/${readingId}`);
  }

  getLesionsForVisit(studyId: number, readingId: number, visitConfigId: number): Observable<GlobalLesionModel[]> {
    return this.http.get<GlobalLesionModel[]>(`${SERVICE_API_URL}/lesions/list/${readingId}/${visitConfigId}`);
  }

  getSnapshotUploadLink(studyId: number, readingId: number, seriesId: number, sliceNumber: number): Observable<any> {
    return this.http.get(`${SERVICE_API_URL}/lesions/snapshot/upload-link/${readingId}/${seriesId}/${sliceNumber}`);
  }

  uplodFileToCloud(uploadUrl, formData) {
    return this.http.put(uploadUrl,formData);
  }
  // end Lesions

  // Responses
  generateResponsesForVisit(studyId: number, readingId: number, visitConfigId: number, readerId: number): Observable<RAPNOVisitResponseModel> {
    return this.http.post<RAPNOVisitResponseModel>(`${SERVICE_API_URL}/responses/generate-all/${studyId}/${readingId}/${visitConfigId}/${readerId}`, {});
  }

  getResponsesForReading(studyId: number, readingId: number): Observable<RAPNOReadingResponseModel> {
    return this.http.get<RAPNOReadingResponseModel>(`${SERVICE_API_URL}/responses/all/${studyId}/${readingId}`);
  }

  updateResponse(studyId: number, readingId: number, visitConfigId: number, response: any): Observable<BasicResponse<any>> {
    return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}/responses/${studyId}/${readingId}/${visitConfigId}`, response);
  }
  // end Responses


}
