/////////////////////////////////////////////////////////////////////////////////
// This is environment template file. Will be populated with values from configuration
const projectId = 'dynamika-6-dev';
const webUrl = projectId + '.appspot.com';
const apiUrl = webUrl + '/api';

// Note: Dynamika6 supports only currently deployed app version
// uat-dot-, dev-dot- or for production with no prefix
const MSImagingProjectVersion = 'uat-dot-';
const MSUploadVersion = 'uat-dot-';
const MSImageAnalysisVersion = 'uat-dot-';
const MSUserVersion = 'uat-dot-';
const MSAuditTrailsVersion = 'uat-dot-';
const MSQualityControlVersion = 'uat-dot-';
const MSQueriesVersion = 'uat-dot-';
const MSReadingIFVersion = 'uat-dot-';
const MSReadingDXAVersion = 'uat-dot-';
const MSReadingKandLVersion = 'uat-dot-';
const MSReadingGENANTVersion = 'uat-dot-';
const MSReadingKandLAutoVersion = 'uat-dot-';
const MSBatchLogicVersion = 'uat-dot-';
const MSDashboardStatVersion = 'uat-dot-';
const WebSocketsServerVersion = 'uat-dot-';
const MSReadingNovadip5Version = 'uat-dot-';
const MSReadingMRANOVersion = 'uat-dot-';
const MSReadingAdvancedAnalysisVersion = 'uat-dot-';
const MSReadingPSMAVersion = 'uat-dot-';
const MSReadingPSAMRISVersion = 'uat-dot-';
const MSReadingWBMRIVersion = 'uat-dot-';
const MSReadingCandenSparccVersion = 'uat-dot-';
const MSReadingHEELVersion = 'uat-dot-';
const MSCommandsVersion = 'uat-dot-';
const MSReadingJSWVersion = 'uat-dot-';
const MSReadingOARSIVersion = 'uat-dot-';
const MSReadingDEMRIQVersion = 'uat-dot-';
const MSReadingL3SMIVersion = 'uat-dot-';
const MSReadingLuganoVersion = 'uat-dot-';
const MSReadingRecistVersion = 'uat-dot-';
const MSReadingMOAKSVersion = 'uat-dot-';
const MSReadingATYRVersion = 'uat-dot-';
const MSReadingGuerbetVersion = 'uat-dot-';
const MSReportGeneratorVersion = 'uat-dot-';
const MSReadingRAPNOVersion = 'uat-dot-';
const MSReadingMEPOVersion = 'uat-dot-';
const MSMerger = 'uat-dot-';

function removeTrailingSlash(url) {
  return url.replace(/\/+$/, '');
}

function getHostUrl(urlPath: string): string {
  try {
      const url = new URL(urlPath, "http://dummy"); // Fallback base
      return url.host;
  } catch (error) {
      console.error("Invalid URL:", urlPath);
      return "";
  }
}

export const environment = {
  production: false,
  compactToken: true,
  // environment constants
  zitelab_psamris_db_prefix: 'IAG_PSAMRIS_UAT_',
  zitelab_canden_sparcc_db_prefix: 'IAG_CANDEN_SPARCC_UAT_',
  zitelab_wbmri_db_prefix: 'IAG_WBMRI_UAT_',
  zitelab_heel_db_prefix: 'IAG_HEEL_UAT_',
  // URL of development API
  url: projectId + '.appspot.com',
  apiUrl: apiUrl,
  // Need to remove backslash
  ziteLabUrl: removeTrailingSlash('https://samri-test.zitelab.eu/'),
  MSUserVersion: MSUserVersion,
  MSCommandsVersion: MSCommandsVersion,
  MSImagingProjectVersion: MSImagingProjectVersion,
  MSUploadVersion: MSUploadVersion,
  MSAuditTrailsVersion: MSAuditTrailsVersion,
  MSQualityControlVersion: MSQualityControlVersion,
  MSImageAnalysisVersion: MSImageAnalysisVersion,
  MSQueriesVersion: MSQueriesVersion,
  MSBatchLogicVersion: MSBatchLogicVersion,
  MSReadingIFVersion: MSReadingIFVersion,
  MSReadingKandLVersion: MSReadingKandLVersion,
  MSReadingKandLAutoVersion: MSReadingKandLAutoVersion,
  WebSocketsServerVersion: WebSocketsServerVersion,
  MSReadingPSAMRISVersion: MSReadingPSAMRISVersion,
  MSReadingWBMRIVersion: MSReadingWBMRIVersion,
  MSReadingMOAKSVersion: MSReadingMOAKSVersion,
  MSReadingNovadip5Version: MSReadingNovadip5Version,
  MSReadingCandenSparccVersion: MSReadingCandenSparccVersion,
  MSReadingJSWVersion: MSReadingJSWVersion,
  MSReadingL3SMIVersion: MSReadingL3SMIVersion,
  MSReadingLuganoVersion: MSReadingLuganoVersion,
  MSReadingRecistVersion: MSReadingRecistVersion,
  MSReadingMRANOVersion: MSReadingMRANOVersion,
  MSReadingAdvancedAnalysisVersion: MSReadingAdvancedAnalysisVersion,
  MSReadingHEELVersion: MSReadingHEELVersion,
  MSReadingDEMRIQVersion: MSReadingDEMRIQVersion,
  MSReadingPSMAVersion: MSReadingPSMAVersion,
  MSDashboardStatVersion: MSDashboardStatVersion,
  MSReadingOARSIVersion: MSReadingOARSIVersion,
  MSReadingGENANTVersion: MSReadingGENANTVersion,
  MSReadingDXAVersion: MSReadingDXAVersion,
  MSReadingGuerbetVersion: MSReadingGuerbetVersion,
  MSReportGeneratorVersion: MSReportGeneratorVersion,
  MSReadingATYRVersion: MSReadingATYRVersion,
  MSReadingRAPNOVersion: MSReadingRAPNOVersion,
  MSReadingMEPOVersion: MSReadingMEPOVersion,
  MSMerger: MSMerger,
  // GBM service
  GBM_API_URL: removeTrailingSlash('https://gbm-compute-uat.imageanalysisgroup.com/'),
  // Dashboard
  Leadtools: {
    // LT url from config does not have /api at the end, but in web client must have
    rootURL: removeTrailingSlash('https://dynamika-dev-lead-tools.imageanalysisgroup.com/SecureUAT') + '/api',
    services: {
        authenticationServiceName: 'auth',
        queryLocalServiceName: 'query',
        queryPacsServiceName: 'pacsquery',
        optionsServiceName: 'options',
        objectRetrieveLocalServiceName: 'retrieve',
        pacsRetrieveServiceName: 'pacsretrieve',
        objectStoreLocalServiceName: 'store',
        monitorCalibrationServiceName: 'monitorcalibration',
        exportServiceName: 'export',
        auditLogServiceName: 'audit',
        patientServiceName: 'patient',
        patientAccessRightsServiceName: 'patientaccessrights',
        templateServiceName: 'template',
        autoServiceName: 'auto',
        threeDServiceName: 'threed'
    },
    defaultUserName: 'iag', defaultPassword: 'imageanalysis',
    LazyLoadingMobileThreshold: 10,
    LazyLoadingThreshold: 10,
    LazyLoadingBuffer: 5,
    IsCaching: true, CachingSize: 4,
  },
  whitelistedDomains: [
    MSUserVersion + 'msuser-dot-' + webUrl,
    MSImagingProjectVersion + 'msimagingproject-dot-' + webUrl,
    MSUploadVersion + 'msupload-dot-' + webUrl,
    MSQualityControlVersion + 'msqualitycontrol-dot-' + webUrl,
    MSCommandsVersion + 'mscommands-dot-' + webUrl,
    MSImageAnalysisVersion + 'msimageanalysis-dot-' + webUrl,
    MSQueriesVersion + 'msqueries-dot-' + webUrl,
    MSBatchLogicVersion + 'msbatchlogic-dot-' + webUrl,
    MSReadingIFVersion + 'msreadingif-dot-' + webUrl,
    MSReadingJSWVersion + 'msreadingjsw-dot-' + webUrl,
    MSReadingOARSIVersion + 'msreadingoarsi-dot-' + webUrl,
    MSReadingL3SMIVersion + 'msreadingl3smi-dot-' + webUrl,
    MSReadingKandLVersion + 'msreadingkandl-dot-' + webUrl,
    MSReadingKandLAutoVersion + 'msreadingkandlauto-dot-' + webUrl,
    MSReadingPSAMRISVersion + 'msreadingpsamris-dot-' + webUrl,
    MSReadingWBMRIVersion + 'msreadingwbmri-dot-' + webUrl,
    MSReadingMOAKSVersion + 'msreadingmoaks-dot-' + webUrl,
    MSReadingCandenSparccVersion + 'msreadingcandensparcc-dot-' + webUrl,
    MSDashboardStatVersion + 'msdashboardstat-dot-' + webUrl,
    MSReadingNovadip5Version + 'msreadingnovadip5-dot-' + webUrl,
    MSReadingLuganoVersion + 'msreadinglugano-dot-' + webUrl,
    MSReadingRecistVersion + 'msreadingrecist-dot-' + webUrl,
    MSReadingMRANOVersion + 'msreadingmrano-dot-' + webUrl,
    MSReadingAdvancedAnalysisVersion + 'msreadingadvanalysis-dot-' + webUrl,
    MSReadingHEELVersion + 'msreadingheel-dot-' + webUrl,
    MSReadingDEMRIQVersion + 'msreadingdemriq-dot-' + webUrl,
    MSReadingPSMAVersion + 'msreadingpsmapet-dot-' + webUrl,
    MSAuditTrailsVersion + 'msaudittrails-dot-' + webUrl,
    MSReadingGENANTVersion + 'msreadinggenant-dot-' + webUrl,
    MSReadingDXAVersion + 'msreadingdxa-dot-' + webUrl,
    MSReadingATYRVersion + 'msreadingatyr-dot-' + webUrl,
    MSReadingGuerbetVersion + 'msreadingguerbet-dot-' + webUrl,
    MSReportGeneratorVersion + 'msreportgenerator-dot-' + webUrl,
    MSReadingRAPNOVersion + 'msreadingrapno-dot-' + webUrl,
    MSReadingMEPOVersion + 'msreadingmepo-dot-' + webUrl,
    MSMerger + 'msmerger-dot-' + webUrl,
    'datastudio.google.com',
    'localhost:8080',
    getHostUrl('https://dynamika-dev-lead-tools.imageanalysisgroup.com/SecureUAT/auth/AuthenticateUser')
  ],
  blacklistedRoutes: [
    MSUserVersion + 'msuser-dot-' + apiUrl + '/user/authenticate-with-email-address',
    /uat-dot-msreportgenerator-dot-dynamika-6-dev.appspot.com\/api\/report\/sign\/\d+\/\d+/,
    /uat-dot-msreadingmrano-dot-dynamika-6-dev.appspot.com\/api\/reading\/recist-eligibility\/complete\/\d+\/\d+/,
    /uat-dot-msreadingmoaks-dot-dynamika-6-dev.appspot.com\/api\/reading\/complete\/\d+/,
    /uat-dot-msreadingjsw-dot-dynamika-6-dev.appspot.com\/api\/reading\/complete\/\d+/,
    /uat-dot-msreadingguerbet-dot-dynamika-6-dev.appspot.com\/api\/readings\/\d+\/complete/,
    /uat-dot-msreadingguerbet-dot-dynamika-6-dev.appspot.com\/api\/readings\/sign\/\d+\/\d+/,
    /uat-dot-msreadingkandl-dot-dynamika-6-dev.appspot.com\/api\/reading\/complete\/\d+\/\d+/,
    /uat-dot-msreadingmepo-dot-dynamika-6-dev.appspot.com\/api\/readings\/\d+\/complete/,
    /uat-dot-msreadingmepo-dot-dynamika-6-dev.appspot.com\/api\/visits\/\d+/,
    /assets\/images\/viewer/
  ],
  version: ''
};
