import {Component, NgZone, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
  ValidationErrors
} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {ActivatedRoute, Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {AuthenticationService, LoginResponseData, UserService} from '../../_services';
import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import {environment} from '../../../environments/environment';
import {Store} from '@ngxs/store';
import {SetAuthInfo} from '../../core/data-management/actions/auth-info.action';
import {AuthActivity} from '../../core/interfaces/auth-activity';
import {sha256} from 'js-sha256';
import {ToastService} from '../../_services/internal/toast.service';
import {BasicResponse} from '../../core/interfaces/basic-response';
import {Base64} from '../../_helpers/base64';
import {Path} from '../../core/constants/path';
import {CompactJwt} from "../../_helpers/CompactJwt";

const helper = new JwtHelperService();

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

declare const grecaptcha: any;

declare global {
  interface Window {
    grecaptcha: any;
    toastalert: any;
    reCaptchaLoad: () => void;
  }
}

export const DEFAULT_ROUTE = Path.IMAGING_PROJECT;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [AuthenticationService]
})
export class LoginComponent implements OnInit {

  readonly passwordRegExp = '^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W)[\\S]+$';

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private toastService: ToastService,
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private fb: FormBuilder,
    private idle: Idle,
    private store: Store) {
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  get cpf() {
    return this.changePasswordForm.controls;
  }

  get fpf() {
    return this.forgotPasswordForm.controls;
  }

  title = 'login';
  json: string;

  loginForm: FormGroup;
  changePasswordForm: FormGroup;
  forgotPasswordForm: FormGroup;
  loading = false;
  loadingChangePassword = false;
  loadingForgotPassword = false;
  forgotPasswordCapcha = false;
  temporaryPassword = false;
  submitted = false;
  returnUrl: string;

  userId: number;
  passwordHistory: { created: Date, password: string }[];

  matcher = new MyErrorStateMatcher();

  ngOnInit() {
    // reset login status
    localStorage.clear();
    document.getElementById('changePasswordPanel').style.display = 'none';
    document.getElementById('forgotPasswordPanel').style.display = 'none';
    this.initLoginForm();
    this.initChangePasswordForm();
    this.initForgotPasswordForm();
    this.checkExternalLinks();
    this.registerReCaptchaCallback();
    this.addScript();
  }

  checkExternalLinks(): void {
    // get return url from route parameters or default to '/'
    this.returnUrl = '/' + DEFAULT_ROUTE;
    if (this.route.snapshot.queryParams['returnUrl'] != null) {
      if (this.route.snapshot.queryParams['returnUrl'].includes('/queries/edcf/detail/') === true) {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
      } else if (this.route.snapshot.queryParams['returnUrl'].includes('/leadtools/') === true) {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
      } else if (this.route.snapshot.queryParams['returnUrl'].includes('/download/reading/report/') === true) {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
      }
    }
  }

  initLoginForm(): void {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  initChangePasswordForm(): void {
    this.changePasswordForm = this.fb.group({
      currentPassword: ['', [Validators.required, Validators.minLength(8)]],
      newPassword: ['', [Validators.required, Validators.minLength(12), Validators.pattern(this.passwordRegExp)]],
      confirmNewPassword: ['', [Validators.required, Validators.minLength(12), 
        Validators.pattern(this.passwordRegExp), this.confirmPasswordValidator]]
    });
    this.initCurrentPasswordListener(this.changePasswordForm.controls.currentPassword);
  }

    get confirmPasswordValidator() {
      return (): ValidationErrors | null => {
        return this.changePasswordForm?.controls?.newPassword?.value !== this.changePasswordForm?.controls?.confirmNewPassword?.value
          ? {notEqualsError: 'Passwords are not equals'} : null;
      };
    }
  
  initForgotPasswordForm(): void {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  initCurrentPasswordListener(control: AbstractControl): void {
    control.valueChanges.subscribe(password => {
      let matched = false;
      const sha256Password = sha256(password);
      for (const pass of this.passwordHistory) {
        matched = pass.password === sha256Password;
        if (matched) {
          return;
        }
      }
      if (!matched) {
        control.setErrors({incorrectPassword: true});
      }
    });
  }

  loadPasswordHistory(userId: number): void {
    this.authenticationService.getUserPasswordHistory(userId).subscribe(response => {
      this.passwordHistory = response.data;
    });
  }

  addScript() {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?onload=reCaptchaLoad&render=explicit`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  registerReCaptchaCallback() {
    window.reCaptchaLoad = () => {
      try {
        document.getElementById('recap').style.display = '';
        grecaptcha.render('recap', {
          'sitekey': '6LdPVnUUAAAAAI1sCYJi6Ef6ToPWnfaDiuJElxIj',
          'callback': this.onSubmit.bind(this)
        });
      } catch (error) {
        console.warn('reCaptcha reloaded');
      }
    };
  }

  ngOnLogin() {
    if (environment.production) {
      if (grecaptcha && grecaptcha.getResponse(0) === '') {
        grecaptcha.execute(0);
      } else {
        grecaptcha.reset(0);
        grecaptcha.execute(0);
      }
    } else {
      this.onSubmit();
    }
  }

  onTrimPassword(event) {
    this.f.password.setValue(event.target.value.trim());
    this.f.password.updateValueAndValidity();
  }

  onTrimCurrentPassword(event) {
    this.cpf.currentPassword.setValue(event.target.value.trim());
    this.cpf.currentPassword.updateValueAndValidity();
  }

  onSubmit() {
    if (this.forgotPasswordCapcha) {
      this.forgotPassword();
      return;
    }
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    if (environment.production) {
      this.authenticationService.login(this.f.username.value.toLowerCase().trim(), this.f.password.value, grecaptcha.getResponse(0))
        .subscribe(
          (response) => {
            // login successful if there's a jwt token in the response
            if (response.responseCode === 200) {
              this.afterSuccessfulLogin(response);
            } else if (response.responseCode === 1009) {
              this.ngZone.run(() => this.showToast('ERROR', response.responseMessage));
            } else if (response.responseCode === 1021) {
              this.ngZone.run(() => this.showToast('ERROR', 'Maximum login attempts reached. Your account was locked.'));
            } else {
              this.ngZone.run(() => this.showToast('ERROR', 'The username or password are incorrect.'));
            }
          }, (err) => {
            this.loading = false;
            this.showToast('ERROR', 'Incorrect username or password, please try again.');
          }
        );
    } else {
      this.authenticationService.loginWithEmailNoCaptcha(this.f.username.value.toLowerCase().trim(), this.f.password.value)
        .subscribe(
          (response: any) => {
            // login successful if there's a jwt token in the response
            if (response.responseCode === 200) {
              this.afterSuccessfulLogin(response);
            } else if (response.responseCode === 1009) {
              this.ngZone.run(() => this.showToast('ERROR', response.responseMessage));
            } else if (response.responseCode === 1021) {
              this.ngZone.run(() => this.showToast('ERROR', 'Maximum login attempts reached. Your account was locked.'));
            } else {
              this.ngZone.run(() => this.showToast('ERROR', 'The username or password are incorrect.'));
            }
          }, (err) => {
            this.loading = false;
            this.showToast('ERROR', 'Incorrect username or password, please try again.');
          }
        );
    }
  }

  afterSuccessfulLogin(loginResponse: BasicResponse<LoginResponseData>) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem('currentUser', JSON.stringify(loginResponse.data.jwt));
    this.applyAuthInfo(loginResponse.data);
    let s = localStorage['userId'];
    s = s.replace(/[\"]+/g, ''); // remove the "" characters from both ends of the string.
    this.userId = parseInt(s, 10);

    this.loadPasswordHistory(this.userId);

    if (!this.isDeactivedeUser()) {
      // TODO: change from email to id
      //this.userService.getEmailUser(JSON.parse(localStorage.getItem('subject'))).subscribe(
      this.userService.getById(this.userId).subscribe(
        (userResponse: any) => {
          if (userResponse.responseCode === 200) {
            if (userResponse.data.temporaryPassword) {
              this.temporaryPassword = true;
              document.getElementById('loginPanel').style.display = 'none';
              document.getElementById('changePasswordPanel').style.display = 'block';
              localStorage.clear();
            } else {
              if (localStorage.getItem('currentUser')) {
                document.getElementById('recap').style.display = 'None';
                this.checkIdleUser();
                this.ngZone.run(() => this.router.navigate([this.returnUrl])).then();
              }
              this.loading = false;
              grecaptcha.reset(0);
            }
          } else {
            this.ngZone.run(() => this.showToastOnly(userResponse.responseCode, userResponse.responseMessage));
          }
        }
      );
    }
  }

  showToast(code, message) {
    this.toastService.error('ERROR', message);
    this.loading = false;
    grecaptcha.reset(0);
  }

  showToastOnly(code, message) {
    this.toastService.error('ERROR ' + code, message);
  }

  changePassword() {
    if (this.cpf.newPassword.value !== this.cpf.confirmNewPassword.value) {
      this.toastService.error('ERROR', 'New password and confirm new password fields does not match');
      return;
    }
    if (this.cpf.newPassword.errors || this.cpf.confirmNewPassword.errors) {
      this.toastService.error('ERROR', 'Choose a password in accordance with the validation rules');
      return;
    }
    this.loadingChangePassword = true;

    this.authenticationService.loginWithEmailNoCaptcha(this.f.username.value.toLowerCase().trim(), this.cpf.currentPassword.value)
      .subscribe((response) => {
        if (response.responseCode === 200) {
          document.getElementById('changePasswordPanel').style.display = 'none';
          document.getElementById('loginPanel').style.display = 'none';

          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(response.data.jwt));
          this.applyAuthInfo(response.data);
          this.userService.validatePassword(
            JSON.parse(localStorage.getItem('userId')), this.cpf.newPassword.value).subscribe((validate: any) => {
            if (validate.responseCode === 200) {
              this.userService.changePassword(JSON.parse(localStorage.getItem('userId')), this.cpf.newPassword.value, this.cpf.currentPassword.value).subscribe(
                (resp: any) => {
                  if (resp.responseCode === 200) {

                    this.loadingChangePassword = false;
                    document.getElementById('changePasswordPanel').style.display = 'none';
                    document.getElementById('loginPanel').style.display = 'none';
                    grecaptcha.reset(0);

                    if (localStorage.getItem('currentUser')) {
                      document.getElementById('recap').style.display = 'None';
                      this.checkIdleUser();
                      this.ngZone.run(() => this.router.navigate([this.returnUrl])).then();
                    }
                    this.toastService.success('', 'Password successfully changed');
                  } else {
                    this.toastService.respError(response);
                    this.loadingChangePassword = false;
                  }
                }
              );
            } else {
              this.toastService.error('ERROR', validate.responseMessage);
              this.loadingChangePassword = false;
              grecaptcha.reset(0);
            }
          });
        } else {
          this.toastService.error('ERROR', 'The current password is incorrect');
          this.loadingChangePassword = false;
          grecaptcha.reset(0);
        }
      });
  }

  applyAuthInfo(authInfo: LoginResponseData) {
    const jwt = CompactJwt.decodeActivities(helper.decodeToken(authInfo.jwt));

    const userId = JSON.stringify(jwt.jti);
    const activities = JSON.stringify(jwt.permissions.activities);
    const globals = JSON.stringify(jwt.permissions.globals);
    const subject = JSON.stringify(jwt.sub);
    const encodedRolesString = Base64.encode(JSON.stringify(authInfo.roles));

    localStorage.setItem('activities', activities);
    localStorage.setItem('globals', globals);
    localStorage.setItem('subject', subject);
    localStorage.setItem('userId', userId);
    localStorage.setItem(Base64.encode('roles'), encodedRolesString);

    this.store.dispatch(new SetAuthInfo({
      userId: +userId,
      token: jwt,
      activities: JSON.parse(activities) as AuthActivity[],
      roles: Array.from(authInfo.roles)
    }));
  }

  forgotPasswordCaptcha() {
    this.forgotPasswordCapcha = true;
    if (grecaptcha && grecaptcha.getResponse(0) === '') {
      grecaptcha.execute(0);
    }
  }

  forgotPassword() {
    this.fpf.email.setValue(null);
    document.getElementById('loginPanel').style.display = 'none';
    document.getElementById('forgotPasswordPanel').style.display = 'block';
    this.forgotPasswordCapcha = false;
  }

  resetPassword() {
    this.loadingForgotPassword = true;
    this.userService.resetPasswordWithEmail(this.fpf.email.value.toLowerCase().trim()).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.loadingForgotPassword = false;
          document.getElementById('recap').style.display = 'none';
          grecaptcha.reset(0);
          document.getElementById('loginPanel').style.display = 'block';
          document.getElementById('forgotPasswordPanel').style.display = 'none';
          this.toastService.success('Success',
            'Your new password has been sent to your email address. Please check your email');
        } else {
          this.loadingForgotPassword = false;
          if (response.data) {
            this.toastService.success('Success', 'Your new password has been sent to your email address. Please check your email');
          } else {
            this.toastService.error('Error', response.responseMessage);
          }
        }
      }, err => {
        this.loadingForgotPassword = false;
        this.toastService.error('Error', "Reset password failed");
      }
    );
  }

  checkIdleUser() {
    this.idle.setIdle(15 * 60);
    this.idle.setTimeout(1);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onTimeout.subscribe(() => this.logout());
    this.idle.watch();
    setTimeout(() => this.logout(), 8 * 60 * 60 * 1000);
  }

  logout() {
    this.authenticationService.logout();
  }

  isDeactivedeUser(): boolean {

    let status = false;
    this.userService.getById(this.userId).subscribe((response: any) => {
      if (response.responseCode === 200) {
        status = response.data.status;
        if (status) {
          this.showToast('ERROR', 'User has been deactivated');
          /* logout */
          this.logout();
          localStorage.clear();
          return status;
        }
      }
    });

    return status;
  }
}
