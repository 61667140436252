import { IStatData } from './../../../_models/stat-data';
'use strict';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { ViewerAuthenticationService } from '../../../_services/interfaces/viewer/viewer-authentication-service';
import { DicomLoaderService } from '../../../_services/interfaces/viewer/dicom-loader-service';
import { ActionManagerService } from '../../../_services/interfaces/viewer/action-manager-service';
import { SeriesManagerService } from '../../../_services/interfaces/viewer/series-manager.service';
import { ObjectRetrieveService } from '../../../_services/interfaces/viewer/object-retrieve-service';
import { ViewerToolbar } from '../../../_services/interfaces/viewer/toolbar';
import { ImagingProjectService } from 'src/app/_services/imaging-project.service';
import { OverlayManagerService } from 'src/app/_services/interfaces/viewer/overlay-manager-service';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { ROILabellingDialogComponent } from './roi-labelling-dialog/roi-labelling-dialog.component';
import { MessageDialogComponent } from 'src/app/components/controls/message-dialog/message-dialog.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ROIRow } from '../../../_models/viewer/RoiRowModel';
import { forkJoin, from, Observable, Subject, Subscription } from 'rxjs';
import { AnnoTypeEnum } from '../../../_models/viewer/AnnoTypeEnum';
import { SelectionChange } from '@angular/cdk/collections/selection-model';
import debounce from 'lodash/debounce';
import { Utils } from '../../../_services/leadtools/lead-tools-utils';
import { HangingProtocolService } from '../../../_services/hanging-protocol.service';
import * as hangingModels from '../../../_models/viewer/hangingProtocol';
import { HangingProtocolModel } from '../../../_models/viewer/hangingProtocol';
import { combineAll, map } from 'rxjs/operators';
import { QueryArchiveService } from 'src/app/_services';
import { MapData, PMInfo, PointROI, QueryCell, QueryContainer, QueryDEMRIQ, QueryFrame, QueryROI, QueryVOI } from 'src/app/_models/viewer/QueryOptions';
import get from 'lodash/get';
import { EventNames, EventService } from '../../../_services/leadtools/event.service';
import { ViewerAuditableContext, ViewerConfigModel } from '../../../_models/viewer/ViewerConfigModel';
import { MarkerType } from 'src/app/_models/Oncology/global-lesion-model';

const DEV_KEY = 'ng/z2yYcaRNLr2q42kzk6SNYAsLYxghH';
const LICENSE_URI = '/assets/libs/leadtools/LEADTOOLS.lic.txt';
const CELL_LAYOUT = { rows: 1, cols: 1 };

@Component({
  selector: 'app-viewer-leadtools',
  templateUrl: './viewer-core-lt.component.html',
  styleUrls: ['./viewer-core-lt.component.scss'],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewerCoreLtComponent implements OnInit, OnDestroy {

  constructor(private authenticationService: ViewerAuthenticationService,
              private objectRetrieveService: ObjectRetrieveService,
              private queryArchiveService: QueryArchiveService,
              public seriesManagerService: SeriesManagerService,
              private dicomLoaderService: DicomLoaderService,
              private imagingProjectService: ImagingProjectService,
              private overlayManagerService: OverlayManagerService,
              public actionManagerService: ActionManagerService,
              private hangingProtocolService: HangingProtocolService,
              public utils: Utils,
              private toastyService: ToastyService,
              public dialog: MatDialog,
              private route: ActivatedRoute,
              private router: Router,
              private cd: ChangeDetectorRef,
              private eventService: EventService) {
    // Init viewer toolbar
    this.viewerToolbar = ViewerToolbar.getInstance();
    this.viewerConfig = get(this.route, 'snapshot.data.viewerConfig', null);
    const userRoles = get(this.route, 'snapshot.data.userRoles.currentUserInfo.roles', null);
    if (this.viewerConfig) {
      this.viewerToolbar.applyConfig(this.viewerConfig.toolbarConfig, userRoles);
    }

    const initialSelection = [];
    const allowMultiSelect = true;
    this.selection1 = new SelectionModel<ROIRow>(allowMultiSelect, initialSelection);
    this.selection2 = new SelectionModel<ROIRow>(allowMultiSelect, initialSelection);
    this.viewerData = null;

    this.eventService.subscribe(EventNames.RESIZE_VIEWER, () => this.asyncResizeTrigger());


    this.utilsSetVisibilityEventSubscription$ = this.utils.setVisibilityEvent.subscribe(({ row, visibility }) => {
      this.seriesManagerService.silentPropertyChange = true;
      row.child.set_isVisible(visibility);
      this.seriesManagerService.silentPropertyChange = false;
      this.actionManagerService.viewerComponent = this;
    });

    this.selection1.changed.subscribe(debounce((item: SelectionChange<any>) => {
      this.seriesManagerService.toggleROISelections({ viewerComponent: this }, true);
    }, 10));

    // link viewer Component to others
    dicomLoaderService.viewerComponent = this;
    this.actionManagerService.viewerComponent = this; //?? @TODO remove
  }

  @ViewChild('container', { read: ElementRef }) container: ElementRef;
  @ViewChild('viewerparent', { read: ElementRef }) viewerparent: ElementRef;
  @ViewChild('sliceROIs', { read: ViewContainerRef }) sliceROIs: ViewContainerRef;

  @Input() shouldShowDICOM = true;
  @Input() useHangingProtocol = false;
  @Input() protocolType: string = null;
  @Input() seriesIds: string[] = [];
  @Input() cell: lt.Controls.Medical.Cell = null;

  @Output() onClose = new EventEmitter<any>();
  @Output() onCellReady = new EventEmitter<lt.Controls.Medical.Cell>();
  @Output() onRemoveAllCells = new EventEmitter<number[]>();
  @Output() onMakeSnapShotListner = new EventEmitter<any>();
  @Output() onConfirmLesion = new EventEmitter<any>();
  @Output() onChangeActiveCell = new EventEmitter<boolean>();
  @Output() onConfirmMarker = new EventEmitter<any>();
  @Output() onDemriqStatData = new EventEmitter<any>();
  @Output() OnConfirmContours = new EventEmitter<any>();


  @ViewChild('viewerLT')
  public diagram: any;

  viewer: lt.Controls.Medical.MedicalViewer;
  cellOverlays: any = {};
  viewerToolbar: ViewerToolbar = null;
  seriesOpened: any = {};
  seriesOpenedCount = 0;
  preventClose = false;
  ROIPanelEnabled = false;
  isROIPerSlice = false;
  isROIGroupSelection = false;
  isViewerActionInProgress = false;
  isIntensityChartActionInProgress = false;
  shouldApplyProtocol = false;
  viewerProgressActionText = '';
  defaultROILabels: Array<string> = [];
  utilsSetVisibilityEventSubscription$: Subscription;
  isMCVisible: boolean;
  selectedSeries: any = {};
  selectInputBeforeChangeDump: string = '';

  divIDs: string[] = [];
  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };

  resizeInterval: any = null;
  splitConfig: any = {
    size1: 100,
    size2: 0,
    disabled: true,
    gutterSize: 0
  };
  splitConfigInfo: any = {
    size1: 100,
    size2: 0,
    disabled: true,
    gutterSize: 0
  };
  splitLeftConfig: any = {
    size1: 20,
    size2: 20,
    size3: 20,
    size4: 20,
    size5: 20,
    disabled: true,
    gutterSize: 11
  };

  queue = [];
  interval = null;

  // general viewer data
  viewerData = null;
  protocol: HangingProtocolModel;

  // Table 1: Slice ROIs
  roiLabels: string[] = [];
  private _rowData1: ROIRow[] = [];
  displayedColumns1: string[] = ['select1', 'name', 'label', 'type1', 'area1', 'slice1', 'mprtype', 'createmethod1', 'lasteditedby1', 'lasteditedon1'];
  private _dataSource1: MatTableDataSource<ROIRow> = new MatTableDataSource();
  selection1: SelectionModel<ROIRow>;
  @ViewChild('paginator1', { static: true }) paginator1: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort1: MatSort;

  // Table 2: Volume ROIs
  rowData2: ROIRow[] = [];
  private _rowData2: ROIRow[] = [];
  displayedColumns2: string[] = ['select2', 'name2', 'color', 'volume', 'slice2', 'createmethod2', 'lasteditedby2', 'lasteditedon2'];
  private _dataSource2: MatTableDataSource<ROIRow> = new MatTableDataSource();
  dataSource2: MatTableDataSource<ROIRow>;
  selection2: SelectionModel<ROIRow>;
  @ViewChild('paginator2', { static: true }) paginator2: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort2: MatSort;

  // intensity table
  displayedColumnsIntensity: string[] = ['formula', 'value'];
  dataSourceIntensity: MatTableDataSource<any>;
  @ViewChild('intensitySort') sortIntensity: MatSort;

  // normal table
  displayedColumnsNormal: string[] = ['formula', 'value'];
  dataSourceNormal: MatTableDataSource<any>;
  @ViewChild('normalSort') sortNormal: MatSort;

  // autoscale table
  displayedColumnsScale: string[] = ['formula', 'value'];
  dataSourceScale: MatTableDataSource<any>;
  @ViewChild('scaleSort') sortScale: MatSort;

  // parametric maps table
  displayedColumnsParMaps: string[] = ['formula', 'min', 'max', 'mean', 'std'];
  dataSourceParMaps: MatTableDataSource<any>;
  @ViewChild('parMapsSort') sortParMaps: MatSort;

  // pixel maps table
  displayedColumnsPixel: string[] = ['formula', 'pixel', 'area'];
  dataSourcePixel: MatTableDataSource<any>;
  @ViewChild('pixelSort') sortPixel: MatSort;

  lastAction = 'Pan';
  previousAction = '';
  lastInfo = null;
  isExternalViewer = false;

  dataSourceDiam: MatTableDataSource<any>[];
  displayedColumnsDiam: string[] = ['name', 'color', 'value', 'major', 'minor', 'spdp', 'volume'];
  showDiameterFlag = false;
  isDemriqProject = false;
  isJSWProject = false;
  isIagTechUser = false;
  isQcPage = false;

  readingDataDemriq: any;
  selectedRows: any[] = [];
  tabControlValue = 0;
  showMotionCorrectionPanel = true;
  selectedRegions: any;
  // TODO: will be set by a json config file or an API
  viewerSettings = {
    general: {},
    toolbar: {
      isStatisticsPanel: true,
      isROIPanel: false,
      isVOIPanel: false,
      isIntChartPanel: false
    },
    layout: {
      auto: true,
      cols: 1,
      rows: 1
    }
  };

  viewerConfig: ViewerConfigModel;
  isImageDCE: boolean = false;
  isImageOK: boolean = false;

  selectedRow: ROIRow = null;
  // These variables use for resizeing ROIS, VOIs and MCs panels
  ROIsPanelClass: string = 'height50';
  VOIsPanelClass: string = 'height25';
  StatDataPanelClass: string = 'height25';
  MCsPanelClass: string = 'height25';

  statData: IStatData = null;
  statDatas: any[] = [];

  audit: ViewerAuditableContext = new ViewerAuditableContext();

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {

    // copy ROIs sclices
    // keyCode 67 is c character
    if ((event.metaKey || event.ctrlKey) && event.code === 'KeyC' && this.utils.isDemriqProject()) {
      this.showROICopyDelete(true);
      event.preventDefault();
    }

    //delete ROIs sclices
    if (event.code === 'Delete' && (event.metaKey || event.shiftKey) && this.utils.isDemriqProject()) {
      this.showROICopyDelete(false);
      event.preventDefault();
    }

    //Zoom in on active cell's Image
    if (event.code === 'NumpadAdd' && !event.ctrlKey && !event.shiftKey && !event.metaKey) {
      const info = { viewerComponent: this, tableIndex: 1 };
      this.actionManagerService.runActionCommand('ZoomIn', info);
      event.preventDefault();
    }

    //Zoom out on active cell's Image
    if (event.code === 'NumpadSubtract' && !event.ctrlKey && !event.shiftKey && !event.metaKey) {
      const info = { viewerComponent: this, tableIndex: 1 };
      this.actionManagerService.runActionCommand('ZoomOut', info);
      event.preventDefault();
    } else if ((event.key === 'Delete' || event.key === 'Backspace') && (event.srcElement as any).localName !== 'input' && (event.srcElement as any).localName !== 'textarea') {
      if (!this.utils.isDemriqProject() && !this.isJSWProject) {
        const info = { viewerComponent: this, tableIndex: 1 };
        const subj = new Subject<lt.Annotations.Engine.AnnObject>();
        subj.subscribe(result => {
          info.viewerComponent.selection1.clear();
          info.viewerComponent.selection1.selected.push([result['rowData']][0]);
          this.isViewerActionInProgress = true;
          this.viewerProgressActionText = `Deleting Series ROIs...`;
          this.deleteROIsClick(1);
        });
        this.seriesManagerService.deleteSelectedAnnotations(subj);
      } else {
        const cell = this.seriesManagerService.getActiveCell();
        const automation = cell.get_automation();
        const currentEditObject: lt.Annotations.Engine.AnnObject = automation.get_currentEditObject();
        let roiGUID = currentEditObject.guid;

        if (currentEditObject === null) {
          return;
        }
        const containers: lt.Annotations.Engine.AnnContainerCollection = automation.containers;
        this.utils.allowRemovingObject = false;
        containers.toArray().forEach((container: lt.Annotations.Engine.AnnContainer) => {
          container.children.toArray().forEach((child: lt.Annotations.Engine.AnnObject) => {
            if (child.guid === currentEditObject.guid) {
              container.children.remove(currentEditObject);
            }
          });
        });

        this.utils.allowRemovingObject = true;

        const info = { viewerComponent: this, tableIndex: 1 };

        // Saving annotation after any change for simple ROIs and right after unselect for complex ROIs
        this.seriesManagerService.saveActiveContainerROIs(info, roiGUID);
        this.seriesManagerService.autoVolumeCalculations(info, cell, currentEditObject.metadata.Label).then((rowData2) => {
          this.rowData2 = rowData2;
        });

        if (this.lastAction === 'OnAnnotationFreehand') {
          setTimeout(() => {
            this.actionManagerService.runActionCommand(info.viewerComponent.lastAction, info.viewerComponent.lastInfo);
          }, 10);
        }

        this.asyncResizeTrigger();
      }
    }
    if ((event.metaKey || event.ctrlKey) && event.key === 'z') {
      const info = { viewerComponent: this };
      const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
      const activeContainerROIsBefore = cell.get_automation().get_activeContainer().get_children().toArray();
      this.actionManagerService.runActionCommand('OnUndoAction', info);
      const activeContainerROIsAfter = cell.get_automation().get_activeContainer().get_children().toArray();
      let removedROIGUID: string = '';
      activeContainerROIsBefore.forEach(x => {
        let isROIRemoved: boolean = true;
        activeContainerROIsAfter.forEach(y => {
          if (x.guid === y.guid) {
            isROIRemoved = false;
          }
        });
        if (isROIRemoved) {
          removedROIGUID = x.guid;
        }
      });
      // if (removedROIGUID !== '') {
      //   this.seriesManagerService.saveActiveContainerROIs(info, removedROIGUID);
      // } else {
      //   this.seriesManagerService.saveActiveContainerROIs(info);
      // }
    }
    if ((event.metaKey || event.altKey) && event.key === 'n') {
      const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
      const automation = cell.get_automation();
      const currentEditObject: lt.Annotations.Engine.AnnObject = automation.get_currentEditObject();
      currentEditObject.set_isVisible(false);
      this.asyncResizeTrigger();
    }
    if ((event.metaKey || event.altKey) && event.key === 'm') {
      const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
      const automation = cell.get_automation();
      const containerAnnotations = Array.from(automation.container.children['W']);
      if (containerAnnotations.length > 0) {
        containerAnnotations.forEach((Annotation: lt.Annotations.Engine.AnnObject) => {
          Annotation.set_isVisible(false);
        });
      }
      this.asyncResizeTrigger();
    }
    if (((event.metaKey || event.shiftKey) && event.key === 'S') && (event.srcElement as any).localName !== 'input' && (event.srcElement as any).localName !== 'textarea') {
      let cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
      let viewer: lt.Controls.Medical.MedicalViewer = cell.get_viewer();
      let showOverlay: boolean = this['actionManagerService']['showOverlays'];
      viewer.layout.get_items().toArray().forEach((cellItem: lt.Controls.Medical.Cell) => {
        cellItem.set_overlayTextVisible(!showOverlay);
      });
      this['actionManagerService']['showOverlays'] = !showOverlay;
      if (!showOverlay) {
        this.viewerToolbar.setItemProperty('ShowHideOverlays', 'tooltip', 'Hide Metadata');
      } else {
        this.viewerToolbar.setItemProperty('ShowHideOverlays', 'tooltip', 'Show Metadata');
      }
    }

    // Assign Select as current action
    if ((event.metaKey || event.altKey) && event.key === 's') {
      const info = { viewerComponent: this };
      info['annType'] = 'Select';
      this.runCommand('OnAnnotationSelect', info);
      this.asyncResizeTrigger();
    }

    // Activate Rapid Drawing feature
    if ((event.metaKey || event.altKey) && event.key === 'r') {
      const info = { viewerComponent: this };
      (this.actionManagerService as any).isRapidAction = false;
      this.actionManagerService.OnRapidAction(info);
      this.asyncResizeTrigger();
    }

    // Assign last action selected in viewer as current action
    if ((event.metaKey || event.altKey) && event.key === 'l' && this.previousAction != undefined && this.previousAction != '') {
      const info = { viewerComponent: this };
      if (this.isDemriqProject && this.previousAction === 'OnAnnotationFreehand') {
        (this.actionManagerService as any).isRapidAction = false;
        this.actionManagerService.OnRapidAction(info);
      } else {
        this.actionManagerService.runActionCommand(this.previousAction, info);
      }
      this.asyncResizeTrigger();
    }

    if ((event.metaKey || event.ctrlKey) && event.key === 'b') {
      const info = { viewerComponent: this };
      this.actionManagerService.OnAddMarker(info, MarkerType.BI_RULER);
      this.asyncResizeTrigger();
    }

    if ((event.metaKey || event.ctrlKey) && event.key === 'r') {
      const info = { viewerComponent: this };
      this.actionManagerService.OnAddMarker(info, MarkerType.RULER);
      this.asyncResizeTrigger();
    }

    if ((event.metaKey || event.ctrlKey) && event.key === 'm') {
      const info = { viewerComponent: this };
      this.actionManagerService.OnAddMarker(info, MarkerType.MARKER);
      this.asyncResizeTrigger();
    }

    if ((event.metaKey || event.altKey) && event.key === 'c') {
      const info = { viewerComponent: this };
      this.actionManagerService.OnConfirmMarker(info);
      this.asyncResizeTrigger();
    }

    if ((event.metaKey || event.altKey) && event.key === 'd') {
      const info = { viewerComponent: this };
      this.actionManagerService.OnRemoveMarker(info);
      this.asyncResizeTrigger();
    }
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    if ((event.metaKey || event.altKey) && event.key === 'n') {
      const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
      const automation = cell.get_automation();
      const currentEditObject: lt.Annotations.Engine.AnnObject = automation.get_currentEditObject();
      currentEditObject.set_isVisible(true);
      this.asyncResizeTrigger();
    } else if ((event.metaKey || event.altKey) && event.key === 'm') {
      const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
      const automation = cell.get_automation();
      const containerAnnotations = Array.from(automation.container.children['W']);
      if (containerAnnotations.length > 0) {
        containerAnnotations.forEach((Annotation: lt.Annotations.Engine.AnnObject) => {
          Annotation.set_isVisible(true);
        });
        this.asyncResizeTrigger();
      }
    }
  }

  public created(args: Object): void {
    // Reverts the last action performed
    this.diagram.undo();
    // Restores the last undone action
    this.diagram.redo();
  }

  ngOnInit() {
    let seriesId = '-1';

    // Set viewer spinner
    this.eventService.subscribe(EventNames.VIEWER_LOADING, (flag: boolean = true) => {
      this.isViewerActionInProgress = flag;
      this.cd.detectChanges();
    });

    // Viewer Toolbar events
    this.eventService.subscribe(EventNames.TOOLBAR_SET_PROPERTY, () => {
    });

    if (this.useHangingProtocol) {
      this.hangingProtocolService.load(this.protocolType).subscribe((protocols: hangingModels.HangingProtocolListModel) => {
        // add hanging protocol dropdown
        if (protocols && protocols.items.length) {
          const menuItem = {
            id: 'HangingProtocols',
            caption: 'Hanging Protocols',
            tooltip: 'Select Hanging Protocol',
            action: 'ShowHangingProtocols',
            cssIconClass: 'Configure',
            type: 'button',
            disabled: false,
            readonly: false,
            items: []
          };

          this.protocol = protocols.items.find(p => p.default) || protocols.items[0];
          protocols.items.forEach(p => {
            menuItem.items.push({
              id: `HangingProtocols__${p.name}`,
              caption: p.name,
              tooltip: p.name,
              action: 'ApplyHangingProtocol',
              info: { viewerComponent: this, protocol: p },
              cssIconClass: 'Configure',
              type: 'button',
              disabled: false,
              readonly: false,
              default: p.default,
              items: []
            });
          });

          this.viewerToolbar.addItem(menuItem, 'ViewerLayout');
          this.shouldApplyProtocol = true;
        }
      });
    }

    // If seriesId exists in the current URL
    if (this.route.snapshot.params.seriesId) {
      const paramStr = this.route.snapshot.params.seriesId;
      const slug = paramStr.split('&').pop();

      if (slug) {
        seriesId = paramStr.replace(`&${slug}`, '');

        this.dicomLoaderService.cellReady.subscribe(e => {
          this.actionManagerService.runActionCommand(slug, { viewerComponent: this });
        });
        this.isExternalViewer = true;
      } else {
        seriesId = paramStr;
      }
    }

    this.register_lt(LICENSE_URI, DEV_KEY);

    // Get the parent DIV
    const imageViewerDiv = document.getElementById('main-viewer') as HTMLDivElement;

    // Create the medical viewer control, and specify the number or rows and columns.
    this.viewer = new lt.Controls.Medical.MedicalViewer(imageViewerDiv, this.viewerSettings.layout.rows, this.viewerSettings.layout.cols);

    this.eventService.publish(EventNames.VIEWER_READY, this.viewer);

    this.dicomLoaderService.cellReady.subscribe(e => {
      console.log('cellReady...');
      const cell: lt.Controls.Medical.Cell = this.cell && this.cell instanceof lt.Controls.Medical.Cell
        ? this.cell : this.seriesManagerService.getActiveCell();

      // Listen to slice change
      this.eventService.subscribe(EventNames.OFFSET_CHANGED, (val: lt.Controls.Medical.Cell) => {
        if (this._isROIVisible()) {
          this._filterROIData(val);
        }
      });

      if (this.isROIPerSlice) {
        this.eventService.subscribe(EventNames.OFFSET_CHANGED, (cell: lt.Controls.Medical.Cell) => {
          // Lazy load approach to preselect ROIs
          cell.add_currentOffsetChanged((item: lt.Controls.Medical.Cell) => {
            // console.log('add_currentOffsetChanged...');
            const cellIndex = item.get_currentOffset() + 1;
            const selectedItems = this.selection1.selected.filter(i => +i.slice === cellIndex);
            const sliceROIs = this.rowData1.filter(i => +i.slice === cellIndex);
            if (sliceROIs.length) {
              sliceROIs.map(i => {
                if (i.child) {
                  i.child.isSelected = selectedItems.indexOf(i) !== -1;
                }
              });
              this.asyncResizeTrigger();
            }
          });
        });
      }

      cell.add_currentOffsetChanged((val: lt.Controls.Medical.Cell) => {
        this.eventService.publish(EventNames.OFFSET_CHANGED, val);
      });

      this.onCellReady.emit(cell);

      // set auto & manual synch to readonly if one is enabled
      if (this.dicomLoaderService.synchControls.SlicePositionSynch.enabled) {
        // console.log("viewerComponent:", this);
        this.viewerToolbar.setItemProperty('AutoSynchronization', 'readonly', true);
        this.viewerToolbar.setItemProperty('ManualSynchronization', 'readonly', true);
        this.viewerToolbar.setItemProperty('DisableAllSynch', 'readonly', false);
      }
    });

    // Assign last action on cell selection change
    this.eventService.subscribe(EventNames.SELECTION_CHANGED, (sender, args) => {
      this.onChangeActiveCell.emit(true);
      const selectedCell: any = this.viewer.layout.selectedItem || null;
      const cellDivID: string = selectedCell ? selectedCell.get_divID() : null;

      if (selectedCell) {

        this.viewer.layout.get_items().toArray().forEach((cellItem: lt.Controls.Medical.Cell) => {
          if (cellItem && cellItem.get_selected()) {
            cellItem.set_borderThickness(6);
          } else if (cellItem) {
            cellItem.set_borderThickness(2);
          }
        });
        this.asyncResizeTrigger();

        try {
          if (this.actionManagerService.isReferenceLine && selectedCell.mprType != undefined) {
            this.viewer.layout.get_items().toArray().forEach(cellItem => {
              if (cellItem.mprType !== undefined) {
                const automation = cellItem.automation;
                const containers: lt.Annotations.Engine.AnnContainerCollection = automation.containers;
                containers.toArray().forEach((container: lt.Annotations.Engine.AnnContainer) => {
                  container.get_children().toArray().forEach((item: lt.Annotations.Engine.AnnObject) => {
                    if (item.get_metadata()["Label"] == "ReferenceLine") {
                      container.get_children().remove(item);
                    }
                  });
                });
                automation.invalidate(lt.LeadRectD.empty);
              }
            });
            switch (selectedCell.mprType) {
              case 0:
                this.actionManagerService.referenceLineStatus = 'Axial';
                break;
              case 1:
                this.actionManagerService.referenceLineStatus = 'Sagital';
                break;
              case 2:
                this.actionManagerService.referenceLineStatus = 'Coronal';
                break;

              default:
                break;
            }

            this.actionManagerService.refrenceCellSliceCount = selectedCell.automation.containers.count;

            if (cellDivID.includes('_Axial') || cellDivID.includes('_Sagital') || cellDivID.includes('_Coronal')) {
              this.actionManagerService.addingReferenceLines(selectedCell.GC);
            } else {
              this.actionManagerService.addingReferenceLines(selectedCell.get_currentOffset());
            }
          }
        } catch (error) {
          console.log('==> error... ', error);
        }

        if (selectedCell != null && cellDivID.length > 0) {
          this.dicomLoaderService.setActiveCell(cellDivID);
        }

        if (!this.utils.isDemriqProject()) {
          this.actionManagerService.runActionCommand(this.lastAction, this.lastInfo);
        }
        // refresh toolbar sort items
        const sortData = selectedCell ? this.actionManagerService.sortData[selectedCell.divID] : null;
        if (sortData) {
          Object.keys(sortData).forEach(sort_type => {
            if (sortData[sort_type].enabled) {
              this.update_toolbar_sort_items(selectedCell, sort_type);
            }
          });
        }

        // update PM & MC items on active cell changes
        // console.log('update PM & MC items...');
        const grpItems = this.overlayManagerService.toolbarItems[selectedCell.divID];
        if (grpItems) {
          this.viewerToolbar.getItem('ParametricMaps').items = grpItems.pm_subMenuItems;
          this.viewerToolbar.getItem('MotionCorrection').items = grpItems.mc_subMenuItems;
        }

        // ensure W/L status is properly set on the cell selected
        const targetCmds = [];
        if (!this.dicomLoaderService.synchControls.WindowLevelSynch.enabled) {
          const linkableCells = this.viewer.layout.get_items().toArray();
          this.actionManagerService.unlinkCells(linkableCells, targetCmds);
        }

      }
    });

    this.viewer.add_selectionChanged((sender, args) => {
      this.eventService.publish(EventNames.SELECTION_CHANGED, sender, args);
    });

    this.seriesManagerService.activeCellChanging.subscribe(_data => {
      try {
        this.isViewerActionInProgress = true;
        this.viewerProgressActionText = `Active Cell is Changing...`;
        const activeCell = this.seriesManagerService.getSeriesCellById(_data.activeCellID);
        const info = { viewerComponent: this, previousCell: null};
        let previousCell: any = null;
        if (_data.previousCellID !== null && _data.previousCellID !== '') {
          previousCell = this.seriesManagerService.getSeriesCellById(_data.previousCellID);
          info.previousCell = previousCell;
        }
        if (activeCell !== null) {
          const cellInfo = this.seriesManagerService.getSeriesInfo(activeCell);
          this.actionManagerService.enableSelectionForRois(info, !(<any>activeCell).IsAnnoLoaded);
          this.seriesManagerService.setROIData(activeCell, this.dataSource1.data);
          this._resetROIData(activeCell);
          this._resetVOIData(activeCell);
          if(this.utils.isAdvancedAnalysis || this.utils.isMranoProject()) {
            const visitConfigId = (cellInfo.visitConfigId === undefined || cellInfo.visitConfigId === null) ?
                              JSON.parse(localStorage.getItem('visitConfigIds')).find(i => i.seriesId === seriesId)?.visitConfigId :
                              cellInfo.visitConfigId;
            this.setStatData(visitConfigId);
          }
        }
        if (info.viewerComponent.lastAction !== undefined && info.viewerComponent.lastAction !== ''
          && (!this.isDemriqProject || (this.isDemriqProject && this.lastAction !== 'OnAnnotationFreehand'))) {
          this.actionManagerService.runActionCommand(this.lastAction, info);
        }
        if (this.utils.isDemriqProject()) {
          if (previousCell !== null) {
            (<any>previousCell).lastDrawnROIs = this.utils.lastDrawnROI;
            // saving cell's active container's ROIs before changing active cell
            this.seriesManagerService.saveActiveContainerROIs(info);
          }
          if (activeCell !== null && (<any>activeCell).lastDrawnROIs) {
            this.utils.lastDrawnROI = (<any>activeCell).lastDrawnROIs;
          } else {
            this.utils.lastDrawnROI = [];
          }

          if ((this.actionManagerService as any).activeJoint && this.lastAction === 'OnAnnotationFreehand') {
            const activeJoint = (this.actionManagerService as any).activeJoint;
            if (activeJoint.color !== '') {
              activeCell.get_automation().cancel();
              this.actionManagerService.drawCustomFreehand(activeJoint.color, activeJoint.regionToScore, activeJoint.primaryLocation, info);
            }
          }
          this.seriesManagerService.autoVolumeCalculations(info, activeCell).then((rowData2) => {
            this.rowData2 = rowData2;
          });
        }

        // update PM & MC items on active cell changes
        this.refresh_pm_mc_items(activeCell);
        this.isViewerActionInProgress = false;

      } catch (error) {
        console.log('error... ', error);
        this.isViewerActionInProgress = false;
      }

    });

    // Download and process new series
    if (seriesId !== '-1') {
      if (this.viewerData === null || this.viewerData === undefined
        || !this.viewerData[seriesId] || !this.viewerData[seriesId]['timepoint']
        || !this.viewerData['patient'] || !this.viewerData['authenticationCode']) {
        // init viewer data based on seriesid selected
        this.imagingProjectService.getSeriesInstanceUID([parseInt(seriesId, 10)]).subscribe((rsp: any) => {
          const seriesList = rsp.data;
          if (seriesList != null) {
            seriesList.forEach(item => {
              // Get general viewer data
              item.seriesId = item.id;
              item.seriesInstanceUID = item.seriesUID;
              this.initViewerData(item).then(() => {
                this.openNewSer((this.seriesIds && this.seriesIds.length > 1) ? this.seriesIds : [seriesId], this.viewerData);
              });
            });
          } else {
            this.handleErr(null, seriesId);
            this.lastAction = 'Pan';
            this.lastInfo = null;
            this.onClose.emit({
              serID: null,
              shouldCloseViewer: true
            });
          }
        });
        this.actionManagerService.createIconRegistry();
      }
    } else {
      this.openNewSer((this.seriesIds && this.seriesIds.length > 1) ? this.seriesIds : [seriesId], this.viewerData);
    }

    // Overlay subscribe on close
    this.overlayManagerService.removeSeriesCell
    .subscribe(e => {
      if(e !== null) {
        this.removeCell(e);
        this.overlayManagerService.clearRemoveButtonSubscription()
      }
    });

    // Action Manager subscribe on close
    this.actionManagerService.removeSeriesCell.subscribe(e => {
      if (e.isGBMComing) {
        this.removeCell(e.cell, false, true);
      } else {
        this.removeCell(e, true);
      }
    });

    // Take snapshot
    this.actionManagerService.takeSnapShot.subscribe(data => {
      this.takeSnapShot(data);
    });

    this.dicomLoaderService.annotationIsLoaded.subscribe(cell => {
      try {
        const info = { viewerComponent: this };
        if (cell != null && (this.splitConfig.size2 !== 0 || this.utils.isMranoProject())) {
          this.actionManagerService.enableSelectionForRois(info, true);
        }

        if (this.utils.isDemriqProject()) {
          this.actionManagerService.onLoadObjects(info, false);

          if ((this.actionManagerService as any).activeJoint && this.lastAction === 'OnAnnotationFreehand') {
            const activeJoint = (this.actionManagerService as any).activeJoint;
            const activeCell = this.seriesManagerService.getActiveCell();
            if (activeJoint.color !== '') {
              activeCell.get_automation().cancel();
              this.actionManagerService.drawCustomFreehand(activeJoint.color, activeJoint.regionToScore, activeJoint.primaryLocation, info);
            }
          }
        }

        if (cell != null) {
          const seriesVisitConfigs: any = JSON.parse(localStorage.getItem('visitConfigIds'));
          const SeriesID = (<any>cell).seriesId as number;
          let visitConfigID = 0;
          if (seriesVisitConfigs != undefined && seriesVisitConfigs.length > 0) {
            Array.from(seriesVisitConfigs).forEach((item: any) => {
              if (SeriesID && item.seriesId == SeriesID) {
                visitConfigID = item.visitConfigId;
              }
            });
          }
          if (this.utils.isMranoProject()) {
            if (visitConfigID != 0) {
              this.actionManagerService.fillGBMSeriesList(3, SeriesID, visitConfigID);
            } else {
              this.actionManagerService.fillGBMSeriesList(3, SeriesID, 1);
            }
          }

          setTimeout(() => {
            this.eventService.publish(EventNames.SELECTION_CHANGED);
          }, 500);
        }
      } catch (error) {
        console.log('>>> Error: ', error)
      }
    });

    // Add listener for empty divs
    this.viewer.get_emptyDivs().get_items().add_collectionChanged(
      (e, args) => {
        this.seriesManagerService.onEmptyDivsCollectionChanged(e, args,
          { 'viewer': this.viewer, 'viewerComponent': this });
      });

    // set default label filter value for MRANO projects
    if (this.utils.isMranoProject()) {
      this.actionManagerService.fillGBMSeriesList(1);
      this.defaultROILabels = ['Enhancing'];
    }

    this.actionManagerService.newCell.subscribe((seriesObjs: any[]) => {
      this.seriesOpened = {};
      this.seriesOpenedCount = 0;
      if (this.utils.isMranoProject()) {
        this.actionManagerService.fillGBMSeriesList(0);
      }
      const seriesIDs = [];
      seriesObjs.forEach(item => {
        seriesIDs.push(item);
        // add new series object to viewerData
        if (!this.viewerData[item]) {
          this.viewerData[item] = item;
        }
      });
      this.openNewSer((this.seriesIds && this.seriesIds.length > 1) ? this.seriesIds : seriesIDs, this.viewerData);
    });

    this.actionManagerService.newCellDiam.subscribe(diamData => {
      if (diamData.ID == 0) {
        this.showDiameterFlag = true;
      } else if (diamData.ID == 1) {
        this.showDiameterFlag = false;
      }
      this.dataSourceDiam = diamData.Data;
    });

    // set proper setting for viewer just for DEMRIQ project like hidden ROI table
    if (this.utils.isDemriqProject()) {
      this.isDemriqProject = true;
    }

    this.actionManagerService.createIconRegistry();
  }

  setStatData(visitConfigId: number) {
    if(this.statDatas.length !== 0 || this.statDatas !== null) {
      this.statData = this.statDatas.find(v => v.visitConfigId === visitConfigId)?.scoring || null;
    }
  }

  refresh_pm_mc_items(cell) {
    // console.log('refresh_pm_mc_items...');
    const grpItems = this.overlayManagerService.toolbarItems[cell.divID];
    if (this.viewerToolbar) {
      this.viewerToolbar.getItem('ParametricMaps').items = get(grpItems, 'pm_subMenuItems', []);
      this.viewerToolbar.getItem('MotionCorrection').items = get(grpItems, 'mc_subMenuItems', []);  
    }
  }

  ngOnDestroy() {
    this.utilsSetVisibilityEventSubscription$.unsubscribe();
    this.actionManagerService.isReferenceLine = false;
    this.eventService.unsubscribeByPrefix('VIEWER');
    this.viewerToolbar = null;
  }

  update_toolbar_sort_items(cell, sort_type): void {
    const order_mark = cell.SortOrderAcsending ? '\u21E7' : '\u21E9';

    switch (sort_type) {
      case 'AcquisitionTime':
        // update toolbar item
        this.viewerToolbar.setItemProperty('SortByAcquisitionTime', 'tooltip', '\u2713 Acquisition Time ' + order_mark);
        this.viewerToolbar.setItemProperty('SortByAxis', 'tooltip', 'Axis');
        this.viewerToolbar.setItemProperty('SortByInstanceNumber', 'tooltip', 'Instance Number');
        this.viewerToolbar.setItemProperty('SortSeries', 'tooltip', '\u2713 Acquisition Time ' + order_mark);
        this.viewerToolbar.setItemProperty('SortSeries', 'caption', 'By Acq. Time ' + order_mark);
        break;

      case 'Axis':
        // update toolbar item
        this.viewerToolbar.setItemProperty('SortByAcquisitionTime', 'tooltip', 'Acquisition Time');
        this.viewerToolbar.setItemProperty('SortByAxis', 'tooltip', '\u2713 Axis ' + order_mark);
        this.viewerToolbar.setItemProperty('SortByInstanceNumber', 'tooltip', 'Instance Number');
        this.viewerToolbar.setItemProperty('SortSeries', 'tooltip', '\u2713 Axis ' + order_mark);
        this.viewerToolbar.setItemProperty('SortSeries', 'caption', 'By Axis ' + order_mark);
        break;

      case 'InstanceNumber':
        // update toolbar item
        this.viewerToolbar.setItemProperty('SortByAcquisitionTime', 'tooltip', 'Acquisition Time');
        this.viewerToolbar.setItemProperty('SortByAxis', 'tooltip', 'Axis');
        this.viewerToolbar.setItemProperty('SortByInstanceNumber', 'tooltip', '\u2713 Instance Number ' + order_mark);
        this.viewerToolbar.setItemProperty('SortSeries', 'tooltip', '\u2713 Instance Number ' + order_mark);
        this.viewerToolbar.setItemProperty('SortSeries', 'caption', 'By Inst. Number ' + order_mark);
        break;

      default:
        // update toolbar item
        this.viewerToolbar.setItemProperty('SortByAcquisitionTime', 'tooltip', 'Acquisition Time');
        this.viewerToolbar.setItemProperty('SortByAxis', 'tooltip', '\u2713 Axis ' + order_mark);
        this.viewerToolbar.setItemProperty('SortByInstanceNumber', 'tooltip', 'Instance Number');
        this.viewerToolbar.setItemProperty('SortSeries', 'tooltip', '\u2713 Axis ' + order_mark);
        this.viewerToolbar.setItemProperty('SortSeries', 'caption', 'By Axis ' + order_mark);
        break;
    }
  }

  saveAllROIs(tableIndex) {
    if (!this.utils.isDemriqProject()) {
      const info = { 'viewerComponent': this, 'tableIndex': tableIndex };
      this.isViewerActionInProgress = true;
      this.viewerProgressActionText = `Saving All ROIs...`;
      this.seriesManagerService.saveAllROIs(info).subscribe(() => {
        this.isViewerActionInProgress = false;
        this.cd.detectChanges();
      });
    }
  }

  saveCellROIs(tableIndex) {
    if (!this.utils.isDemriqProject()) {
      const info = { 'viewerComponent': this, 'tableIndex': tableIndex };
      const activeCell = this.seriesManagerService.getActiveCell();
      this.isViewerActionInProgress = true;
      this.viewerProgressActionText = `Saving Series ROIs...`;
      return forkJoin([this.seriesManagerService.saveCellROIs(activeCell, info)]).subscribe(() => {
        this.isViewerActionInProgress = false;
        this.cd.detectChanges();
      }, err => {
        this.toastOptions.title = 'ERROR';
        this.toastOptions.msg = 'Something went wrong...';
        this.toastyService.error(this.toastOptions);
      }, () => {
        this.isViewerActionInProgress = false;
      });
    }
  }

  private _deleteROIs(cell, rows: Array<ROIRow>, info) {
    if (!this.utils.isDemriqProject()) {
      return this.seriesManagerService.deleteCellROIs(cell, rows, info).then((result: boolean) => {
        // trigger an event to refresh the viewer
        this.asyncResizeTrigger();
        return result;
      });
    }
  }

  deleteROIsClick(tableIndex) {
    if (!this.utils.isDemriqProject()) {
      const info = { 'viewerComponent': this, 'tableIndex': tableIndex };
      const activeCell = this.seriesManagerService.getActiveCell();
      this.isViewerActionInProgress = true;
      this.viewerProgressActionText = `Deleting Series ROIs...`;
      this.selection1.selected.forEach(r => this.uncheckRow(r));
      this._deleteROIs(activeCell, this.selection1.selected, info).then(() => {
        this.selection1.clear();
        this.isViewerActionInProgress = false;
      });
    }
  }

  groupAsVolume(): void {
    this.selection1.selected.forEach(r => this.uncheckRow(r));
    this.seriesManagerService.groupAsVolume({ viewerComponent: this });
  }

  autoVolumes(): void {
    this.seriesManagerService.autoVolumes({ viewerComponent: this });
  }

  confirmLesion(isVolume: boolean) {
    let confirm = true;
    if (this.getUnsavedROIsCount() > 0) {
      this.toastOptions.title = 'ERROR Cannot confirm unsaved object';
      this.toastOptions.msg = 'object must be saved before confirm';
      this.toastyService.error(this.toastOptions);
      return;
    }
    let rowX = new ROIRow();
    const confirmData = {
      objectType: '',
      objectLocationX: 0,
      objectLocationY: 0,
      axialLongDiameter: '',
      shortAxis: '',
      diam: '',
      sliceNumber: '',
      seriesId: '',
      sopInstanceUid: '',
      frameNumber: '',
      voi: {},
      roi: [],
      metadatas: []
    };
    const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
    let frame: lt.Controls.Medical.Frame;
    let childIndex;
    let child;
    if (!isVolume) {
      if (this.selection1.selected.length <= 0) {
        return;
      }
      if (this.selection1.selected.length === 1 && this.selection1.selected[0].type === AnnoTypeEnum.RULER) {
        this.selection1.selected.forEach((row) => {
          rowX = row;
          frame = cell.get_frames().get_item(parseInt(rowX.slice, 10) - 1);
          childIndex = frame.get_container().get_children().toArray().findIndex(item => item.get_metadata()['Name'] === rowX.name);
          child = frame.get_container().get_children().get_item(childIndex);
          confirmData.objectType = 'Ruler';
          confirmData.roi.push(rowX);
          confirmData.metadatas.push(child.get_metadata());
          confirmData.seriesId = cell['seriesId'];
          try {
            confirmData.diam = (child['labels']['RulerLength']['text'] as string).replace(' mm', '');
          } catch (error) {
            console.log('Error... ', error);
          }
          this.selection1.clear();
        });
      } else {
        this.toastOptions.title = 'multiple selection/type error';
        this.toastOptions.msg = 'Please just select 1 Ruler in the table to Confirm as MIDLINESHIFT';
        this.toastyService.info(this.toastOptions);
        this.selection1.clear();
      }
    } else {
      if (this.selection2.selected.length <= 0) {
        return;
      }
      if (this.selection2.selected.length == 1) {
        this.selection2.selected.forEach((row) => {
          rowX = row;
          confirmData.objectType = 'TARGET_LESION';
          confirmData.voi = rowX;
          rowX.srcChildren.forEach((child: any) => {
            confirmData.metadatas.push(child.get_metadata());
            if (child.get_metadata()['AnnoType'] === 'CrossRuler') {
                const X: number = parseFloat((child['labels']['RulerLength']['text'] as string).replace(' mm', ''));
                const Y: number = parseFloat((child['labels']['SecondaryRulerLength']['text'] as string).replace(' mm', ''));
                if (X >= Y) {
                  confirmData.axialLongDiameter = X + '';
                  confirmData.shortAxis = Y + '';
                } else {
                  confirmData.axialLongDiameter = Y + '';
                  confirmData.shortAxis = X + '';
              }
              confirmData.frameNumber = child.get_metadata()['FrameNumber'] || 1;
              confirmData.sliceNumber = child.get_metadata()['SliceNumber'];
              confirmData.sopInstanceUid = child.get_metadata()['SOPInstanceUID'];
            }
          });
          confirmData.seriesId = cell['seriesId'];
          if ((rowX.srcChildren[0] as any).get_metadata()['HasCrossRuler'] == 'false') {
            this.toastOptions.title = 'ERROR Cannot find cross ruller';
            this.toastOptions.msg = 'The cross ruler must be added to volume';
            this.toastyService.info(this.toastOptions);
            confirm = false;
          }
          this.selection2.clear();
        });
      } else {
        this.toastOptions.title = 'multiple selection error';
        this.toastOptions.msg = 'Please just select 1 Volume in the table to Confirm as MEASURABLE';
        this.toastyService.info(this.toastOptions);
        this.selection2.clear();
      }
    }
    if (confirm) {
      console.log('confirmData... ', confirmData);
      this.onConfirmLesion.emit(confirmData);
    }
  }

  ungroupToSlice(): void {
    this.selection2.selected.forEach(r => this.uncheckRow(r));
    this.seriesManagerService.ungroupToSlice({ viewerComponent: this });
  }

  hideOrShowROIs(): void {
    this.seriesManagerService.hideOrShowROIs({ viewerComponent: this });
  }

  hideOrShowVOIs(): void {
    this.seriesManagerService.hideOrShowVOIs({ viewerComponent: this });
  }

  showROIsSettings(): void {
    this.seriesManagerService.showROIsSettings({ viewerComponent: this });
  }

  onROIRowClick(row): void {
    const info = { 'viewerComponent': this };
    this.seriesManagerService.onROIRowClick(row, info);
  }

  showVOIsSettings(): void {
    this.seriesManagerService.showVOIsSettings({ viewerComponent: this });
  }

  showROICopyDelete(isCopy: boolean): void {
    this.seriesManagerService.showROICopyDelete({ isCopy: isCopy, viewerComponent: this });
  }

  isAllSelected(idx): boolean {
    const numSelected = idx === 1 ? this.selection1.selected.length : this.selection2.selected.length;
    const numRows = idx === 1 ? this.dataSource1.data.length : this.dataSource2.data.length;
    return numSelected === numRows;
  }

  isAllROIsSelected(): boolean {
    const numSelected = this.selection1.selected.length;
    const numRows = this.dataSource1.filteredData.length;
    return numSelected === numRows;
  }

  masterToggleROIs(): void {
    if (this.isAllROIsSelected()) {
      this.selection1.selected.forEach(roi => {
        this.removeSelectedRow(roi);
      });
      this.selection1.clear();
      if (this.selectedRows.length === 0) {
        this.disable_statistics_panel();
      }
      this.selectedRow = null;
    } else {
      this.dataSource1.filteredData.forEach(row => {
        this.selection1.select(row);
        this.addSelectedRow(row);
        this.findROIStatistics(row);
      });
      this.selectedRow = this.dataSource1.filteredData[this.dataSource1.filteredData.length];
      if (this.selectedRows.length > 0 && this.splitConfigInfo.disabled && this.isDemriqProject) {
        this.enable_statistics_panel();
      }
    }
  }

  removeSelectedRow(row: any) {
    const index = this.selectedRows.indexOf(row);
    if (index > -1) {
      this.selectedRows.splice(index, 1);
    }
  }

  addSelectedRow(row: any) {
    const index = this.selectedRows.indexOf(row);
    if (index === -1) {
      this.selectedRows.push(row);
    }
  }

  isAllVOIsSelected(): boolean {
    const numSelected = this.selection2.selected.length;
    const numRows = this.dataSource2.data.length;
    return numSelected === numRows;
  }

  masterToggleVOIs(event): void {
    if (this.isAllVOIsSelected()) {
      this.selection2.selected.forEach(voi => {
        this.addSelectedRow(voi);
      });
      this.selection2.clear();
    } else {
      this.dataSource2.data.forEach(row => {
        this.addSelectedRow(row);
        this.selection2.select(row);
        this.findVOIStatistics(row);
      });
      if (this.selectedRows.length > 0 && this.splitConfigInfo.disabled && this.isDemriqProject) {
        this.enable_statistics_panel();
      }
    }
    if (!event.checked) {
      this.selectedRows = [];
      this.disable_statistics_panel();
    }
  }

  onOpened(isOpened, label) {
    if (isOpened) {
      this.selectInputBeforeChangeDump = label
    }
  }

  onLabelChanged(event, row): void {
    const option = event.value;
    // event.value = 'None';

    if (option === 'New Label') {
      const dialogRef = this.dialog.open(ROILabellingDialogComponent, {
        height: 'auto',
        width: '250px',
        disableClose: true,
        data: {
          // label: "New ROI",
          viewerComponent: this,
          row: row,
          event: event,
          oldValue: this.selectInputBeforeChangeDump
        }
      });
    } else if (option != null && option !== '') {
      if (row.child) {
        row.child.get_metadata()['Label'] = option;
      }
      row.isChanged = true;
    }
  }

  register_lt(licenseUri, developerKey) {
    lt.RasterSupport.setLicenseUri(licenseUri, developerKey,
      function (setLicenseResult) {
        if (setLicenseResult.result) {
          console.log('LEADTOOLS client license set successfully');
        } else {
          let msg = 'LEADTOOLS License is missing, invalid or expired\nError:\n';
          // Add the message provided by LEADTOOLS for more information.
          msg += setLicenseResult.message;
          console.log(msg);
        }

      }
    );
  }

  runCommand(action: string, info?: any, replaceButton?: any): void {
    const actions_mpr: string[] = ['OnMPRVolumeWith3D', 'OnMPRVolumeWithout3D', 'OnMIP', 'OnVRT'];
    // if action is one of actions array above, open series with this action in new tab(MPR)
    if (actions_mpr.some(item => action === item)) {
      const url = `${(location.hostname === 'localhost') ? 'http://localhost:4200' : ''}/leadtools/`;
      const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();

      const cellInfo = this.seriesManagerService.getSeriesInfo(cell);
      const serID = cellInfo.id[0];

      window.open(`${url}${serID}&${action}`, '_blank');
      return;
    }

    const actions_ann = [
      'OnLoadAnnotations', 'OnAnnotationRectangle',
      'OnAnnotationEllipse', 'OnAnnotationCurve', 'OnAnnotationLine',
      'OnAnnotationFreehand', 'OnAnnotationPolygon',
      'OnLoadObjects', 'OnAnnotationRuler',
      'OnAnnotationPolyRuler', 'OnAnnotationProtractor',
      'OnAnnotationCrossRuler', 'OnAnnotationCobbAngle', 'OnAnnotationLine',
      'OnAxialOrientation', 'OnCoronalOrientation', 'OnSagitalOrientation',
      'OnCrossHairLine', 'OnGBMStation', 'On2D3DDiam', 'OnTumorCorrection',
      'OnSlicePositionSynch', 'OnPanZoomSynch', 'OnWindowLevelSynch',
      'OnIntraVisitSynch', 'OnDisableAllSynch', 'OnAutoSynchronization',
      'OnManualSynchronization', 'OnRapidAction',
      'OnViewerLayout1x1', 'OnViewerLayout1x2', 'OnViewerLayout1x3', 'OnViewerLayout1x4',
      'OnViewerLayout2x1', 'OnViewerLayout2x2', 'OnViewerLayout2x3', 'OnViewerLayout2x4',
      'OnViewerLayout3x3', 'OnCustomViewerLayout', 'OnViewerLayoutAuto',
      'OnLoadStatistics', 'OnAnnotationSelect'
    ];
    if (actions_ann.some(item => action === item)) {
      info = { 'viewerComponent': this };
    }

    // actions that needs to be enable on all viewer cells
    const actions_multi = [
      'InteractiveMagnifyGlass', 'Pan', 'ZoomToInteractiveMode',
      'WindowLevelInteractiveMode', 'OnAnnotationRuler', 'OnAnnotationPolyRuler',
      'OnAnnotationProtractor', 'OnAnnotationCobbAngle', 'OnAnnotationCrossRuler',
      'OnProbeTool', 'OnAnnotationRectangle', 'OnAnnotationEllipse', 'OnAnnotationCurve', 'OnAnnotationLine',
      'OnAnnotationFreehand', 'OnAnnotationPolygon', 'OnAnnotationHighlight', 'OnCursor3D', 'OnAnnotationSelect'
    ];

    this.previousAction = this.lastAction;

    if (actions_multi.some(item => action === item)) {
      this.lastAction = action;
      this.lastInfo = info;
    }
    this.replaceMenuButton(replaceButton);
    this.actionManagerService.runActionCommand(action, { ...info, ...{ viewerComponent: this } });
  }


  replaceMenuButton(replaceButton) {
    if (replaceButton && replaceButton.oldButtonId && replaceButton.newButtonId) {
      const oldButton = this.viewerToolbar.items.filter((item) => item.id === replaceButton.oldButtonId)[0];
      const newButton = oldButton.items.filter((item) => item.id === replaceButton.newButtonId)[0];

      if (newButton.cssIconClass) {
        oldButton.caption = newButton.caption;
        oldButton.tooltip = newButton.tooltip;
        oldButton.cssIconClass = newButton.cssIconClass;
      }
    }
  }

  getNumberOfImages() {
    let counter = 0;
    Object.values(this.seriesOpened).forEach(item => {
      counter = counter + Number(item);
    });
    return counter;
  }

  takeSnapShot(data) {
    const { cell, sliceNum, image } = data;
    const cellInfo = this.seriesManagerService.getSeriesInfo(cell);

    if (document.querySelector('app-qcdetails') || this.isExternalViewer) {
      // save screenshot locally from QC
      const strMime = 'image/jpeg';
      const strDownloadMime = 'image/octet-stream';
      this.saveFile(image.replace(strMime, strDownloadMime), cellInfo.seriesDescription + '_' + sliceNum + '.jpg');
    } else {
      // upload to server in Readings
      const eventData = {
        seriesId: cellInfo.seriesUID,
        sliceNum: sliceNum,
        image: image,
        preview: image
      };
      // emit enevt with snapshot data and preview data
      this.onMakeSnapShotListner.emit(eventData);
    }
  }

  saveFile(strData, filename) {
    const link = document.createElement('a');
    link.download = filename;
    link.href = strData;
    link.click();
  }

  setInterval(redrawGrid = this.viewerSettings.layout.auto) {
    this.interval = setInterval(() => {
      const firstSerID = this.queue.shift();

      if (firstSerID == null) {
        // debounce queue for 300
        setTimeout(() => {
          clearInterval(this.interval);
          this.interval = null;
        }, 300);
        return;
      }

      this.openSingleSer(firstSerID, null, redrawGrid);
    }, 2500);
  }

  startQueue(seriesIds: string[], redrawGrid = this.viewerSettings.layout.auto) {
    this.queue = [...this.queue, ...seriesIds.flat(Infinity)];

    if (this.interval == null) {
      const firstSerID = this.queue.shift();
      this.openSingleSer(firstSerID, null, redrawGrid);
      this.setInterval(redrawGrid);
    }
  }

  openNewSer(seriesIds: string[] = [], viewerData, redrawGrid = this.viewerSettings.layout.auto, async = false, fullDownload = false) {
    if (this.viewerData === null || (viewerData !== this.viewerData && viewerData != null)) {
      this.viewerData = viewerData;
    }

    if (this.useHangingProtocol && this.shouldApplyProtocol) {
      this.shouldApplyProtocol = false;
      this.applyHangingProtocol();
      return async ? from(seriesIds) : seriesIds;
    } else {
      return async ? new Observable<any>(subscriber => {
        this.isViewerActionInProgress = true;
        this.viewerProgressActionText = `Loading the Image... Please Wait`;
        from(seriesIds).pipe(
          map(item => this.openSingleSer(item, null, redrawGrid, fullDownload)),
          combineAll()
        ).subscribe(r => {
          this.isViewerActionInProgress = false;
          this.asyncResizeTrigger();
          subscriber.next(r);
          subscriber.complete();
        }, err => subscriber.error(err));
      }) : this.startQueue(seriesIds, redrawGrid);
    }
  }

  ensureCommonAuditData() {
    if (!this.audit.userId || !this.audit.userName) {
      const jwtData = this.utils.getJWTData();
      this.audit.userId = +jwtData.userId;
      this.audit.userName = jwtData.subject;
    }
    if (!this.audit.patientId) {
      this.audit.patientId = this.viewerData?.patient.id;
    }
    if (!this.audit.studyId) {
      const project = JSON.parse(localStorage.getItem('project'));
      this.audit.studyId = project.id;
    }
  }

  getSeriesAuditData(seriesId) {
    this.ensureCommonAuditData();

    const auditData = new ViewerAuditableContext();
    auditData.userId = this.audit.userId;
    auditData.userName = this.audit.userName;

    auditData.patientId = this.audit.patientId;
    auditData.studyId = this.audit.studyId;

    auditData.visitConfigId = (this.viewerData && this.viewerData[seriesId]) ? this.viewerData[seriesId].visitConfigId : null;
    return auditData;
  }


  initViewerData(series) {
    this.viewerData = {};
    series.timepoint = series.timepoint ? series.timepoint : 'Missing';

    // authentication code
    return this.authenticationService.authenticate().toPromise().then((response: any) => {
      this.viewerData.authenticationCode = response;

      // visit data
      return this.imagingProjectService.getVisitFromSeriesID(series.seriesId).toPromise().then((visit_rsp: any) => {
        series.timepoint = visit_rsp.data.visitName ? visit_rsp.data.visitName : series.timepoint;

        this.audit.visitConfigId = visit_rsp.data.id;
        // patient data
        return this.imagingProjectService.getPatientFromSeriesID(series.seriesId).toPromise().then((patient_rsp: any) => {
          this.viewerData.patient = patient_rsp.data;

          // bucket name
          const project = JSON.parse(localStorage.getItem('project'));

          return this.imagingProjectService.getStudy(project.id).toPromise().then(studyResponse => {
            this.viewerData.bucket = studyResponse['data'].bucketLocation;

            // series data
            this.viewerData[series.seriesId] = series;
          });
        });
      });
    });
  }

  openSingleSer(seriesId, dragDropData?, redrawGrid = this.viewerSettings.layout.auto, fullDownload = false): Observable<any> {
    if (seriesId !== '-1') {
      if (this.viewerData === null || this.viewerData === undefined
        || !this.viewerData[seriesId] || !this.viewerData[seriesId]['timepoint']
        || !this.viewerData['patient'] || !this.viewerData['authenticationCode']) {
        console.log('Error: General viewer data are required, found:', JSON.parse(JSON.stringify(this.viewerData)));
        const err_msg = `${seriesId} cannot be loaded as viewer data is not ready or missing. Please try again or contact support team`;
        this.handleErr(null, seriesId, err_msg);
        if (redrawGrid) {
          this.onClose.emit({
            serID: null,
            shouldCloseViewer: !this.preventClose
          });
          return;
        }
      }

      if (this.seriesOpened) {
        this.seriesOpened[seriesId] = this.seriesOpened[seriesId] ? this.seriesOpened[seriesId] + 1 : 1;
      }

      const seriesInfo = JSON.parse(JSON.stringify(this.viewerData[seriesId]));
      seriesInfo['visits'] = { [seriesId]: this.viewerData[seriesId]['timepoint'] };
      seriesInfo['patient'] = this.viewerData['patient'];
      seriesInfo['bucket'] = this.viewerData['bucket'];
      seriesInfo['id'] = seriesId;
      seriesInfo['studyUserRoles'] = this.viewerData['studyUserRoles'];
      seriesInfo['selectedPage'] = this.viewerData['selectedPage'];
      seriesInfo['viewerComponent'] = this;
      console.log('seriesId:', seriesId);

      const auditData = this.getSeriesAuditData(seriesId);

      if(this.utils.isAdvancedAnalysis || this.utils.isMranoProject()) {
        const visitConfigId = (seriesInfo.visitConfigId === undefined || seriesInfo.visitConfigId === null) ?
                              JSON.parse(localStorage.getItem('visitConfigIds')).find(i => i.seriesId === seriesId).visitConfigId :
                              seriesInfo.visitConfigId;
        this.setStatData(visitConfigId);
      }

      this.isIagTechUser = this.utils.isUserHasRole(this.viewerData['studyUserRoles'], 'IAG Technologist');
      this.isQcPage = this.router.url.includes('qc');

      try {
        let imageViewerDiv = document.getElementsByClassName('ser-label ng-star-inserted') as HTMLCollectionOf<Element>;
        if (imageViewerDiv.length === 0) {
          imageViewerDiv = document.getElementsByClassName('visitName-label') as HTMLCollectionOf<Element>;
        }
        Array.from(imageViewerDiv).forEach((element: any) => {
          if (element.innerText === seriesInfo['timepoint']) {
            seriesInfo['color'] = element.style.color;
          }
        });
      } catch (error) {
        console.log("==> error... ", error);
      }

      // Get authenticationCode
      if (!this.authenticationService.authenticationCode) {
        this.authenticationService.authenticationCode = this.viewerData['authenticationCode'];
      }

      // Save and reset ROI panel datasource before actice cell changed
      const activeCell = this.seriesManagerService.getActiveCell();
      if (activeCell !== null && this.splitConfig.size2 !== 0) {
        this._dataSource1.data = [];
        this._rowData1 = [];
        this.rowData2 = [];
        this.dataSource2 = null;
      }

      // enable full-download for selected MRANO or IF projects
      if (this.utils.isMranoProject() || this.utils.isIFProject()) {
        fullDownload = true;
      }

      const seriesinstanceUID = seriesInfo.seriesInstanceUID;

      const cell = this.createCell(seriesinstanceUID, dragDropData, undefined, seriesInfo.color);

      seriesInfo.fullDownload = fullDownload;

      return from(this.imagingProjectService.getPatientFromSeriesID(seriesId).toPromise().then((patient_rsp: any) => {
        this.viewerData.patient = patient_rsp.data;
        seriesInfo['patient'] = patient_rsp.data;
        const index = 0;
        return this.objectRetrieveService.getSeriesStacks(seriesinstanceUID).toPromise().then((seriesStacks: any) => {
          if (seriesStacks.length === 0) {
            // upload the data again and try to load it
            return this.objectRetrieveService.customUpload(seriesInfo['bucket'], seriesId, seriesinstanceUID, auditData).subscribe((resultT: any) => {
              if (resultT ===  'DONE') {
                // get seriesStacks via a custom API
                return this.objectRetrieveService.getInstanceStacks(seriesinstanceUID).subscribe((result: any) => {
                  const seriesStacks = [{ SopInstanceUIDs: result }];
                  console.log('get seriesStacks via a custom API, seriesStacks.length:', seriesStacks.length);
                  return this.multiLoadSeries(index, cell, seriesStacks, seriesInfo, seriesId, dragDropData, redrawGrid);
                });
              }
            });
          } else {
            return this.multiLoadSeries(index, cell, seriesStacks, seriesInfo, seriesId, dragDropData, redrawGrid);
          }
        }).catch(e => {
           // upload the data again and try to load it
           return this.objectRetrieveService.customUpload(seriesInfo['bucket'], seriesId, seriesinstanceUID, auditData).subscribe((resultT: any) => {
            if (resultT ===  'DONE') {
              // get seriesStacks via a custom API
              return this.objectRetrieveService.getInstanceStacks(seriesinstanceUID).subscribe((result: any) => {
                const seriesStacks = [{ SopInstanceUIDs: result }];
                console.log('get seriesStacks via a custom API, seriesStacks.length:', seriesStacks.length);
                return this.multiLoadSeries(index, cell, seriesStacks, seriesInfo, seriesId, dragDropData, redrawGrid);
              });
            } else {
              const err_msg = `${seriesId} cannot be loaded as the requested SeriesIUID is missing. Please try again or contact support team`;
              this.handleErr(e, seriesId, err_msg);
              if (redrawGrid) {
                this.removeCell(cell);
              }
            }
          });
        });
      }));
    }

    // this.actionManagerService.createIconRegistry();
    return from(seriesId);
  }

  initMotionCorrectionPanel(cell: any): Observable<any> {
    const seriesInfo = this.seriesManagerService.getSeriesInfo(cell);
    const seriesInfoCall = this.imagingProjectService.getSeriesInfo(seriesInfo.id);

    seriesInfoCall.subscribe(({ data }) => {
      this.selectedSeries = seriesInfo;
      this.selectedSeries.generatedSeriesVersion = data.generatedSeriesVersion;
      this.selectedSeries.referenceFrame = data.referenceFrame;
      this.selectedSeries.generatedAlgorithm = data.generatedAlgorithm;
      this.selectedSeries.baselineRange = data.baselineRange;
      this.isMCVisible = seriesInfo.projectModality === 'DCE-MRI' && this.seriesOpenedCount === 1;
    });
    return seriesInfoCall;
  }

  multiLoadSeries(index, cell, seriesStacks, seriesInfo,
    seriesId, dragDropData?, redrawGrid = this.viewerSettings.layout.auto): Observable<any> {
    // update series opened count
    this.seriesOpenedCount++;
    (<any>cell).seriesId = seriesId;

    // reset cells position ONLY if it is not a drop-drop action
    if (dragDropData === null && this.viewerSettings.layout.auto) {
      this.actionManagerService.resetCellsPosition(this.viewer);
    }
    if (!seriesStacks.length) {
      this.handleErr(null, seriesId);
      this.removeCell(cell);
      return;
    }

    const element = seriesStacks[index];
    const sopInstanceUIDs = (element.SopInstanceUIDs != 'undefined') ? element.SopInstanceUIDs : [];

    cell.SOPInstanceUIDs = sopInstanceUIDs;

    const auditData = this.getSeriesAuditData(seriesId);
    return from(this.dicomLoaderService.loadSeries(cell, sopInstanceUIDs, seriesInfo, this.shouldShowDICOM, auditData).then((result) => {
      if (result !== undefined) {
        this.dicomLoaderService.setActiveCell(cell.divID);

        this.initMotionCorrectionPanel(cell).subscribe(({ data }) => {
          // update MC overlay text
          if (data.generatedAlgorithm === 'MOTION_CORRECTION_2D') {
            this.overlayManagerService.setCellMCOverlayText(cell, data.mapName);
          }
        });

        const windowLevelCustom = this.viewerToolbar.getItem('WindowLevelCustom');
        if (windowLevelCustom) {
          try {
            windowLevelCustom.items = this.actionManagerService.initializeWindowLevelCustomSubMenu(cell);
          } catch (error) {
          }
        }

        const parametricMaps = this.viewerToolbar.getItem('ParametricMaps');
        const motionCorrection = this.viewerToolbar.getItem('MotionCorrection');
        if (parametricMaps && motionCorrection) {
          const auditData = this.getSeriesAuditData(seriesId);
          this.overlayManagerService.initializeParametricMapsSubMenu(cell, auditData).then((grpItems) => {
            const activeCell = this.seriesManagerService.getActiveCell();
            // console.log('parametricMaps.items:', grpItems.pm_subMenuItems);
            // console.log('(<any>cell).seriesId:', (<any>cell).seriesId);
            // console.log('activeCell.seriesId:', activeCell.seriesId);
            if (activeCell.seriesId === (<any>cell).seriesId) {
              parametricMaps.items = grpItems.pm_subMenuItems;
              motionCorrection.items = grpItems.mc_subMenuItems;
            }
            // store toolbarItems per cell
            if (!this.overlayManagerService.toolbarItems[cell.divID]) {
              this.overlayManagerService.toolbarItems[cell.divID] = {
                pm_subMenuItems: grpItems.pm_subMenuItems,
                mc_subMenuItems: grpItems.mc_subMenuItems
              };
            } else {
              this.overlayManagerService.toolbarItems[cell.divID].pm_subMenuItems = grpItems.pm_subMenuItems;
              this.overlayManagerService.toolbarItems[cell.divID].mc_subMenuItems = grpItems.mc_subMenuItems;
            }
          });
        }

        this.initMotionCorrectionPanel(cell).subscribe(({ data }) => {
          // update MC overlay text
          if (data.generatedAlgorithm === 'MOTION_CORRECTION_2D') {
            this.overlayManagerService.setCellMCOverlayText(cell, data.mapName);
          }
        });

        if (windowLevelCustom) {
          try {
            windowLevelCustom.items = this.actionManagerService.initializeWindowLevelCustomSubMenu(cell);
          } catch (error) {
            console.log('error:', error);
          }
        }

        const info = { viewerComponent: this };
        this.actionManagerService.addContainerEvents(cell, info);
        this.lastInfo = info;

        if (index > 0) {
          cell = this.createCell(cell.seriesInstanceUID, dragDropData, this.seriesOpenedCount, seriesInfo.color);
        }

        this.isImageOK = true;
        window.dispatchEvent(new Event('resize'));

        // recursively load other series stacks
        index += 1;
        const isDCE = result['isDCE'] ? result['isDCE'] : false;
        this.isImageDCE = isDCE;
        if (index < seriesStacks.length && isDCE) {
          this.multiLoadSeries(index, cell, seriesStacks, seriesInfo, seriesId);
        }
        if (this.viewerData['sliceNumber']) {
          cell.currentOffset = parseInt(this.viewerData['sliceNumber'], 10) - 1;
        }
        // ensure empty cells have been removed
        try {
          this.viewer.layout.get_items().toArray().forEach(cellItem => {
            if ((cellItem.mprType && cellItem.mprType === -1) && (cellItem.seriesNumber === 0) && redrawGrid && (cellItem.seriesInstanceUID === null || cellItem.seriesInstanceUID === '')) {
              this.removeCell(cellItem);
            }
          });
          if (redrawGrid && dragDropData === null) {
            // ensure viewer layout update with user selection
            try {
              (this.actionManagerService as any).OnViewerLayoutAuto(info, true);
            } catch (error) {
              console.log('Error... ', error);
            }
          }
        } catch (error) {
          console.log("==> error... ", error);
        }

        return new Observable<any>(subscriber => {
          this.dicomLoaderService.cellReady.subscribe(() => {
            subscriber.next({ cell, seriesId });
            subscriber.complete();
          }, err => subscriber.error(err));
        });
      }
    })
      .catch(e => {
        this.handleErr(e, seriesId);
        // prevent cell from being removed is there is at least 1 frame opened
        if ((!this.isImageDCE && !this.isImageOK) || this.isImageDCE) {
          this.removeCell(cell);
        }
      }));
  }

  handleErr(e, serID, msg?) {
    this.toastOptions.title = 'ID 12: Series loading failure';
    this.toastOptions.msg = (msg == null) ? `${serID} cannot be loaded due to error. Please contact support team` : msg;
    this.toastyService.info(this.toastOptions);
  }

  createCell(seriesinstanceUID = null, dragDropData = null, seriesCount?: number, color?: any) {
    // console.log('createCell...');
    const cellID = UUID.genV4().toString();
    const cell = new lt.Controls.Medical.Cell(this.viewer, cellID, CELL_LAYOUT.rows, CELL_LAYOUT.cols);
    cell.set_showFrameBorder(true);
    cell.get_progress().setColor(255, 152, 0);
    const selectedBorderColor = color ? color : 'rgb(255, 152, 0)';
    cell.set_selectedBorderColor(selectedBorderColor);
    const unselectedBorderColor = color ? color : '#000000';
    cell.set_unselectedBorderColor(selectedBorderColor);
    cell.set_selectedSubCellBorderColor('#000000');
    cell.set_seriesInstanceUID(seriesinstanceUID);

    // Add drop listener to the series loaded
    this.seriesManagerService.enableDropTarget(
      cell.get_div(), { viewer: this.viewer, viewerComponent: this });

    // Add the cell to the viewer layout before selection
    this.viewer.layout.get_items().add(cell);
    // cell.set_selected(true);

    this.divIDs = [...this.divIDs, cell.divID];

    // enable spinner loader on the cell
    if (seriesinstanceUID !== null) {
      this.dicomLoaderService.enableSpinner(cell);
    }

    // Check drag-drop data
    if (dragDropData != null) {
      if (dragDropData.position !== -1) {
        cell.set_position(dragDropData.position);
      }
      cell.set_rowPosition(dragDropData.rowPosition);
      cell.set_columnsPosition(dragDropData.colPosition);
      cell.set_bounds(dragDropData.bounds);
      this.viewer.layout.endUpdate();
    }

    return cell;
  }

  removeCell(cell, is_mprCell = false, isGBMComing?: boolean, closeViewer = true) {
    const info = { 'viewerComponent': this };
    let unsavedROIsCount = this.getUnsavedROIsCount();
    const isCollectionChanged = !!cell?.automation.container?.userData?.IsChanged;
    const isQcOrReader = this.utils.isUserHasRole(this.viewerData['studyUserRoles'], 'IAG Technologist')
      || this.utils.isUserHasRole(this.viewerData['studyUserRoles'], 'Central Reade');
    if (isQcOrReader  && (unsavedROIsCount > 0 || isCollectionChanged)) {
      const changeMessage = unsavedROIsCount > 0 ? `There is ${unsavedROIsCount} unsaved ROIs` : 'Collection changed';
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        height: '270px',
        width: '600px',
        disableClose: true,
        data: {
          title: 'Unsaved ROIs',
          message: `${changeMessage}. Do you want to save?`,
          showOk: true,
          showCancel: true,
          html: true
        }
      });

      if (!this.utils.isDemriqProject()) {
        dialogRef.afterClosed().subscribe(result => {
          if (result === 'ok') {
            this.isViewerActionInProgress = true;
            this.viewerProgressActionText = `Saving Series ROIs...`;

            this.seriesManagerService.saveCellROIs(cell, info).then(() => {
              this.isViewerActionInProgress = false;
              this.toastOptions.msg = 'Unsaved ROIs are now successfully SAVED';
              this.toastyService.info(this.toastOptions);

              this.cd.detectChanges();
              this.removeCellInternal(cell, is_mprCell, isGBMComing, 10);
              this.afterSave();
            });
          } else {
            this.removeCellInternal(cell, is_mprCell, isGBMComing, 10);
            this.afterSave();
          }

        });
      }
    } else {
      // saving cell's active container's ROIs before removing the cell
      this.seriesManagerService.saveActiveContainerROIs(info);

      if (this.viewer.exploded) {
        this.viewer.explode(cell, false);
      }

      try {
        let cellInfo;
        if (is_mprCell) {
          const cellId = this.getRealId(cell.divID);
          const src_cell = this.seriesManagerService.getSeriesCellById(cellId);
          cellInfo = this.seriesManagerService.getSeriesInfo(src_cell); // @TODO remove unused variable
        } else {
          cellInfo = this.seriesManagerService.getSeriesInfo(cell); // @TODO remove unused variable
        }

        this.actionManagerService.clearRoomFor(this.viewer, 1);
        const is_deleted = this.actionManagerService.deleteCell(this.viewer, cell);
        // console.log('======is_deleted:', is_deleted);
        if (!is_deleted) {
          return;
        }

        if (this.viewerSettings.layout.auto) {
          this.actionManagerService.resetCellsPosition(this.viewer);
        }

        let numbOfOpened = this.seriesOpened[(<any>cell).seriesId];

        const cnt_items = this.viewer.layout.get_items().count;
        const cnt_empty = this.viewer.get_emptyDivs().get_items().count;
        const cnt_rows = (!isNaN(this.viewer.gridLayout.rows)) ? this.viewer.gridLayout.rows : 0;
        const cnt_cols = (!isNaN(this.viewer.gridLayout.columns)) ? this.viewer.gridLayout.columns : 0;
        let shouldCloseViewer = false;
        if (cnt_rows * cnt_cols == cnt_empty && (cnt_items == 0 || this.seriesOpenedCount == 0)) {
          numbOfOpened = 0;
          shouldCloseViewer = true;
        }
        if (isGBMComing) {
          shouldCloseViewer = false;
        }
        if (numbOfOpened <= 1) {
          delete this.seriesOpened[(<any>cell).seriesId];

          this.lastAction = 'Pan';
          this.lastInfo = null;

          if (closeViewer) {
            this.onClose.emit({
              serID: (<any>cell).seriesId,
              shouldCloseViewer: shouldCloseViewer && !this.preventClose
            });
          }
        } else {
          this.seriesOpened[(<any>cell).seriesId] = this.seriesOpened[(<any>cell).seriesId] - 1;
        }

        delete this.overlayManagerService.cellOverlays[cell.divID];
        this.seriesManagerService.removeCell(cell);

        // update series opened count
        this.seriesOpenedCount--;
        this.seriesOpenedCount = (this.seriesOpenedCount < 0) ? 0 : this.seriesOpenedCount;

        this.divIDs = this.divIDs.filter(item => item !== cell.divID);
      } catch (e) {
        console.log('err:', e);
        const cnt_empty = this.viewer.get_emptyDivs().get_items().count;
        const cnt_rows = this.viewer.gridLayout.rows;
        const cnt_cols = this.viewer.gridLayout.columns;
        if (cnt_rows * cnt_cols == cnt_empty) {
          this.lastAction = 'Pan';
          this.lastInfo = null;

          this.onClose.emit({
            serID: null,
            shouldCloseViewer: !this.preventClose
          });
        }
      }

      this.afterSave();
    }
  }

  afterSave() {
    // set active the next cell => will the reset ROI panel
    const cell_cnt = this.viewer.layout.get_items().count;
    if (cell_cnt > 0) {
      this._rowData1 = [];
      this._dataSource1.data = [];
      this.rowData2 = [];
      this.dataSource2 = null;
      const nextCell = cell_cnt === 1 ? this.viewer.layout.get_items().toArray()[0] : this.viewer.layout.get_items().toArray()[1];
      this.seriesManagerService.setActiveCell(nextCell.divID);
      // update PM & MC items on active cell changes
      this.refresh_pm_mc_items(nextCell);
    } else {
      this.rowData1 = [];
      this.dataSource1 = new MatTableDataSource(this.rowData1);
      this.dataSource1.paginator = this.paginator1;
      this.dataSource1.sort = this.sort1;
      this.rowData2 = [];
      this.dataSource2 = new MatTableDataSource(this.rowData2);
      this.dataSource2.paginator = this.paginator2;
      this.dataSource2.sort = this.sort2;
      this.statData = null;
    }

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }
  removeCellInternal(cell, is_mprCell, isGBMComing, timeout ) {
    setTimeout(() => {
      if (this.viewer.exploded) {
        this.viewer.explode(cell, false);
      }

      try {
        let cellInfo;
        if (is_mprCell) {
          const cellId = this.getRealId(cell.divID);
          const src_cell = this.seriesManagerService.getSeriesCellById(cellId);
          cellInfo = this.seriesManagerService.getSeriesInfo(src_cell);
        } else {
          cellInfo = this.seriesManagerService.getSeriesInfo(cell);
        }

        this.actionManagerService.clearRoomFor(this.viewer, 1);
        const is_deleted = this.actionManagerService.deleteCell(this.viewer, cell);
        if (!is_deleted) {
          return;
        }

        if (this.viewerSettings.layout.auto) {
          this.actionManagerService.resetCellsPosition(this.viewer);
        }

        let numbOfOpened = this.seriesOpened[(<any>cell).seriesId];

        const cnt_items = this.viewer.layout.get_items().count;
        const cnt_empty = this.viewer.get_emptyDivs().get_items().count;
        const cnt_rows = (!isNaN(this.viewer.gridLayout.rows)) ? this.viewer.gridLayout.rows : 0;
        const cnt_cols = (!isNaN(this.viewer.gridLayout.columns)) ? this.viewer.gridLayout.columns : 0;

        let shouldCloseViewer = false;
        if (cnt_rows * cnt_cols == cnt_empty && (cnt_items == 0 || this.seriesOpenedCount == 0)) {
          numbOfOpened = 0;
          shouldCloseViewer = true;
        }
        if (isGBMComing || this.utils.isMranoProject()) {
          shouldCloseViewer = false;
        }
        if (numbOfOpened <= 1) {
          delete this.seriesOpened[(<any>cell).seriesId];

          this.lastAction = 'Pan';
          this.lastInfo = null;
          this.onClose.emit({
            serID: (<any>cell).seriesId,
            shouldCloseViewer: shouldCloseViewer
          });
        } else {
          this.seriesOpened[(<any>cell).seriesId] = this.seriesOpened[(<any>cell).seriesId] - 1;
        }

        delete this.overlayManagerService.cellOverlays[cell.divID];
        this.seriesManagerService.removeCell(cell);

        // update series opened count
        this.seriesOpenedCount--;
        this.seriesOpenedCount = (this.seriesOpenedCount < 0) ? 0 : this.seriesOpenedCount;

        this.divIDs = this.divIDs.filter(item => item !== cell.divID);
      } catch (e) {
        // console.log('err:', e);
        const cnt_empty = this.viewer.get_emptyDivs().get_items().count;
        const cnt_rows = this.viewer.gridLayout.rows;
        const cnt_cols = this.viewer.gridLayout.columns;
        if (cnt_rows * cnt_cols == cnt_empty) {
          this.lastAction = 'Pan';
          this.lastInfo = null;
          this.onClose.emit({
            serID: null,
            shouldCloseViewer: true
          });
        }
      }
    }, timeout);
  }
  getRealId(fullID: string) {
    const index = fullID.lastIndexOf('_');
    const stripEngineID = (index == -1 ? fullID : fullID.substr(0, index));
    return stripEngineID;
  }

  removeAllCells() {
    const ids = [...this.divIDs];

    ids.map(id => {
      const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getSeriesCellById(id);
      this.removeCell(cell);
    });
  }

  // split screen with ROIs
  dragStart(e) {
    this.resizeInterval = setInterval(() => {
      window.dispatchEvent(new Event('resize'));
    }, 30);
  }

  dragEnd(e) {
    clearInterval(this.resizeInterval);
    this.asyncResizeTrigger();
  }

  splitScreen(splitConfig = null) {
    if (splitConfig == null) {
      this.splitConfig = {
        size1: 50,
        size2: 50,
        disabled: false,
        gutterSize: 11
      };
    } else {
      this.splitConfig = splitConfig;
    }
    this.asyncResizeTrigger();
  }

  scrollHorizontal(direction: string, toolbar: HTMLElement): void {
    const leftPos = toolbar.scrollLeft;
    switch (direction) {
      case 'left':
        toolbar.scrollTo(leftPos - 80, 0);
        break;
      case 'right':
        toolbar.scrollTo(leftPos + 80, 0);
        break;
    }
  }

  getUnsavedROIsCount(): number {
    if (this.isDemriqProject) {
      return 0;
    }

    return this.rowData1.filter(row => row.isChanged).length;
  }

  toggleGroupSelection({ checked }: MatSlideToggleChange): void {
    try {
      this.isROIGroupSelection = checked;

    } catch (error) {

    }
  }

  toggleRoiPerSlice({ checked }: MatSlideToggleChange): void {
    const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
    this.isROIPerSlice = checked;


    this._filterROIData(cell);
  }

  private _applyROIOffsetFilter(data: Array<ROIRow>, currentOffset: number): Array<ROIRow> {
    return this.isROIPerSlice ? data.filter(i => i.slice === currentOffset.toString()) : data;
  }

  private _applyROIGroupSelection(data: Array<ROIRow>, groupLabel: string, rowChecked: boolean, roiName: string): Array<ROIRow> {
    return this.isROIGroupSelection ? data.filter(row => {
      if (row.label === groupLabel && row.name !== roiName) {
        if (rowChecked) {
          this.selection1.select(row);
        } else {
          this.selection1.deselect(row);
        }
        return row;
      }
      return row;
    }) : data;
  }

  private _isROIVisible(): boolean {
    return this.splitConfig.size2 !== 0;
  }

  private _filterROIData(cell: lt.Controls.Medical.Cell): void {
    const data = this.seriesManagerService.getROIData(cell);

    if (data && this.dataSource2) {
      const currentOffset = cell.get_currentOffset() + 1;
      if (this.dataSource2.data.length === 0 && this.dataSource1.data.length !== data.length) {
        this.dataSource1.data = this._applyROIOffsetFilter(data, currentOffset);
      }
    }
  }

  private _groupSelectionROIData(cell: lt.Controls.Medical.Cell, groupLabel: string, rowchecked: boolean, roiName: string): void {
    try {
      const data = this.dataSource1.data;
      if (data && this.isROIGroupSelection) {
        this.dataSource1.data = this._applyROIGroupSelection(data, groupLabel, rowchecked, roiName);
      }
    } catch (error) {

    }
  }

  private _resetROIData(cell: lt.Controls.Medical.Cell): void {
    const data = this.seriesManagerService.getROIData(cell);
    const currentOffset = cell.get_currentOffset() + 1;
    this._dataSource1.data = this._applyROIOffsetFilter(data ? data : [], currentOffset);
    this._rowData1 = this._dataSource1.data;
  }

  private _resetVOIData(cell: lt.Controls.Medical.Cell): void {
    const data = this.seriesManagerService.getVOIData(cell);
    this.dataSource2 = null;
    this.rowData2 = [];
    // Rendering and loading VOIStatus
    const info = { viewerComponent: this };
    if (!this.utils.isDemriqProject()) {
      this.seriesManagerService.renderingVolumes(info);
    }
     else {
      this.seriesManagerService.autoVolumeCalculations(info, cell).then((rowData2) => {
        this.rowData2 = rowData2;
      });
    }
  }

  set rowData1(data) {
    const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
    this._rowData1 = data;
    this.seriesManagerService.setROIData(cell, data);
  }

  get rowData1() {
    return this._rowData1;
  }

  set dataSource1(sourceData: MatTableDataSource<ROIRow>) {
    const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
    this.seriesManagerService.setROIData(cell, sourceData.data);
    const currentOffset = cell.get_currentOffset() + 1;
    this._dataSource1 = sourceData;
    this._dataSource1.data = this._applyROIOffsetFilter(sourceData.data, currentOffset);
    this._dataSource1.sort = this.sort1;
  }

  get dataSource1() {
    return this._dataSource1;
  }

  closeTab(row: any) {
    if (row.name.startsWith('ROI')) {
      this.selection1.toggle(row);
      this.rowROICheckedChange(row, false);
    } else {
      this.selection2.toggle(row);
      this.rowVOICheckedChange(row, false);
    }
  }

  rowROICheckedChange(row, checked) {
    const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
    if (row.label !== 'None' && row.label.length > 0) {
      this._groupSelectionROIData(cell, row.label, checked, row.name);
    }
    if (checked) {
      // handle statistics display
      this.findROIStatistics(row);
    }
    if (!checked) {
      this.uncheckRow(row);
    }
  }

  rowVOICheckedChange(row, checked) {
    if (checked) {
      // handle statistics display
      this.findVOIStatistics(row);
    }
    if (!checked) {
      this.uncheckRow(row);
    }
  }

  uncheckRow(row) {
    this.removeSelectedRow(row);
    if (this.selectedRows.length === 0) {
      this.disable_statistics_panel();
    } else {
      if (this.tabControlValue !== 0) {
        this.tabControlValue -= 1;
      }
      this.buildRowDataSourcesForStatistics(this.selectedRows[this.tabControlValue]);
    }
  }

  disable_statistics_panel() {
    this.splitConfigInfo = {
      size1: 100,
      size2: 0,
      disabled: true,
      gutterSize: 0
    };
    this.asyncResizeTrigger();
  }

  enable_statistics_panel() {
    this.splitConfigInfo = {
      size1: 50,
      size2: 50,
      disabled: false,
      gutterSize: 11
    };
    this.asyncResizeTrigger();
  }

  getPixelSpacing(cell) {
    const frame: lt.Controls.Medical.Frame = cell.get_frames().get_item(0);
    if (frame && frame.JSON['00280030']) {
      return frame.JSON['00280030']['Value'];
    } else {
      return 1;
    }
  }

  getSliceThickness(cell) {
    const frame: lt.Controls.Medical.Frame = cell.get_frames().get_item(0);
    if (frame.JSON['00180050']) {
      return frame.JSON['00180050']['Value'][0];
    } else {
      return 1;
    }
  }

  findROIStatistics(row) {
    this.addSelectedRow(row);
    if (this.viewerSettings.toolbar.isStatisticsPanel && this.utils.isDemriqProject()) {

      if (this.splitConfigInfo.disabled) {
        this.enable_statistics_panel();
      }

      const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
      const seriesInfo = this.seriesManagerService.getSeriesInfo(cell);
      const numbOfSlices = seriesInfo.numbOfSlices;
      const parametricMaps = this.overlayManagerService.getParametricMaps(cell);

      const queryROI = new QueryROI();
      queryROI.roiName = row.child.get_metadata().Name;
      queryROI.roiType = row.child.get_metadata().AnnoType;
      queryROI.sopInstanceUID = row.child.get_metadata().SOPInstanceUID;
      queryROI.seriesInstanceUID = cell.get_seriesInstanceUID();
      // queryROI.imageScale =  Number(row.child.get_metadata().ImageScale);
      queryROI.imageScale = this.getImageScale(cell);
      queryROI.sliceNumber = Number(row.child.get_metadata().SliceNumber);
      queryROI.seriesId = Number((<any>cell).seriesId);
      queryROI.jointType = row.child.get_metadata().regionToScore ? row.child.get_metadata().regionToScore : '';
      queryROI.primaryLocation = row.child.get_metadata().primaryLocation ? row.child.get_metadata().primaryLocation : '';
      queryROI.visitId = row.child.get_metadata().visitConfigId ? Number(row.child.get_metadata().visitConfigId) : -1;
      queryROI.pixelSpacing = this.getPixelSpacing(cell);
      queryROI.sliceThickness = this.getSliceThickness(cell);
      // queryROI.isChanged = row.child.get_metadata().isChanged === 'true';
      queryROI.isChanged = true;

      // replace special caracter
      if (queryROI.roiName && queryROI.roiName.length) {
        queryROI.roiName = queryROI.roiName.replace('/', '_');
      }

      row.child.points.W.forEach(pt => {
        const pointROI = new PointROI();
        pointROI.x = pt.x;
        pointROI.y = pt.y;
        queryROI.roiPoints.push(pointROI);
      });

      if (queryROI.sliceNumber > numbOfSlices) {
        queryROI.sliceNumber = this.utils.getRemainder(queryROI.sliceNumber, numbOfSlices);
      }
      const sliceOffset = queryROI.sliceNumber - 1;

      // TODO: get frame index from seriesInfo or an API
      if (seriesInfo.isDCE) {
        for (let index = 0; index < 2; index++) {
          const cellFrame = cell.get_frames().get_item(index * numbOfSlices + sliceOffset);
          queryROI.baselines.push(cellFrame.Instance.SOPInstanceUID);
        }
      }

      const isAscending = this.utils.isInstanceAscending(cell);
      const orderType = isAscending ? 'ASC' : 'DESC';

      const getInstanceStacks = async (pmInfo) => {
        const seriesiuid = pmInfo.seriesUID;

        if (!pmInfo.sopInstanceUIDs) {
          return this.objectRetrieveService.getInstanceStacks(
            seriesiuid, 'InstanceNumber', orderType).toPromise().then((result: any) => {
            pmInfo.sopInstanceUIDs = result;
            const mapData = new MapData();
            mapData.name = pmInfo.mapName;
            mapData.sopInstanceUID = pmInfo.sopInstanceUIDs[sliceOffset];
            queryROI.maps.push(mapData);
          });
        } else {
          const mapData = new MapData();
          mapData.name = pmInfo.mapName;
          mapData.sopInstanceUID = pmInfo.sopInstanceUIDs[sliceOffset];
          queryROI.maps.push(mapData);
          return new Promise<any>(resolve => {
            resolve('');
          });
        }
      };

      Promise.all(parametricMaps.pmInfos.map(
        (pmInfo) => getInstanceStacks(pmInfo))).then(() => {
          // update PM to avoid duplicate actions
          this.overlayManagerService.setParametricMaps(cell, parametricMaps);
          // find ROI statistics
          if (row.statisticsData === undefined || row.isChanged) {
            this.queryArchiveService.findROIStatistics([queryROI]).toPromise().then(data => {
              row.statisticsData = data;
              if (this.selectedRows[this.tabControlValue].name === row.name) {
                this.buildRowDataSourcesForStatistics(row);
              }
            });
          } else {
            if (this.selectedRows[this.tabControlValue].name === row.name) {
              this.buildRowDataSourcesForStatistics(row);
            }
          }
        }
      );
    }
  }

  buildRowDataSourcesForStatistics(row) {
    if (row.statisticsData && row.statisticsData.length) {
      this.dataSourceIntensity = this.getFormulaValueDataSourceFromObject(get(row, 'statisticsData[0].intensity', {}));
      this.dataSourceNormal = this.getFormulaValueDataSourceFromObject(get(row, 'statisticsData[0].normal', {}));
      this.dataSourceParMaps = new MatTableDataSource(Object.entries(get(row, 'statisticsData[0].parametric_maps', {})).map(value => {
        const result = { formula: this.getReadableName(value[0]) };
        Object.entries(value[1]).forEach(val => {
          result[val[0]] = val[1];
        });
        return result;
      }));
      this.dataSourceScale = this.getFormulaValueDataSourceFromObject(get(row, 'statisticsData[0].autoscale', {}));
      this.dataSourcePixel = new MatTableDataSource(Object.entries(row.statisticsData[0].pixel).map(value => {
        const result = { formula: this.getReadableName(value[0]) };
        Object.entries(value[1]).forEach(val => {
          result[val[0]] = val[1];
        });
        return result;
      }));
    }
  }

  getFormulaValueDataSourceFromObject(obj: any): MatTableDataSource<any> {
    return new MatTableDataSource(Object.entries(obj).map(value => {
      return { formula: this.getReadableName(value[0]), value: value[1] };
    }));
  }

  getReadableName(value: string) {
    if (StatisticsFormulaNames[value]) {
      return StatisticsFormulaNames[value];
    } else {
      return value;
    }
  }

  getImageScale(cell: lt.Controls.Medical.Cell) {
    const frame = cell.get_frames().get_item(0);
    const container = cell.get_automation().get_container();
    const scale_H = frame.get_height() / container.get_size().get_height();
    const scale_W = frame.get_width() / container.get_size().get_width();
    const scale = scale_H > scale_W ? scale_H : scale_W;
    return scale;
  }

  buildQueryVOI4Vol(row) {
    const cell = row?.parentCell ? row.parentCell : this.seriesManagerService.getActiveCell();
    const queryVOI = new QueryVOI();
    queryVOI.voiName = row.name;
    queryVOI.voiName = queryVOI.voiName.replace('/', '_');

    const srcChildren = row.srcChildren;

    // add the ROI itself to children if children is emtpy
    try {
      if (srcChildren.length <= 0) {
        srcChildren.push(row.child);
      }
    } catch (error) {
      console.log('Error... ', error);
    }

    srcChildren.forEach(child => {
      const queryROI = new QueryROI();
      queryROI.roiName = child.get_metadata().Name;
      queryROI.roiType = child.get_metadata().AnnoType;
      queryROI.sopInstanceUID = child.get_metadata().SOPInstanceUID;
      queryROI.seriesInstanceUID = cell.get_seriesInstanceUID();
      queryROI.imageScale = this.getImageScale(cell);
      queryROI.isChanged = child.get_metadata().isChanged === 'true';
      queryROI.sliceNumber = Number(child.get_metadata().SliceNumber);
      queryROI.seriesId = Number((<any>cell).seriesId);
      queryROI.pixelSpacing = this.getPixelSpacing(cell);
      queryROI.sliceThickness = this.getSliceThickness(cell);
      // replace special caracter
      if (queryROI.roiName && queryROI.roiName.length) {
        queryROI.roiName = queryROI?.roiName?.replace('/', '_');
      }

      child.points.W.forEach(pt => {
        const pointROI = new PointROI();
        pointROI.x = pt.x;
        pointROI.y = pt.y;
        queryROI.roiPoints.push(pointROI);
      });
      queryVOI.queryROIs.push(queryROI);
    });

    if (queryVOI.queryROIs.length > 0) {
      queryVOI.seriesInstanceUID = queryVOI.queryROIs[0].seriesInstanceUID;
    }
    return queryVOI;
  }

  buildQueryVOI(row, cell) {
    // console.log('buildQueryVOI...');
    const seriesInfo = this.seriesManagerService.getSeriesInfo(cell);
    const numbOfSlices = seriesInfo.numbOfSlices;
    const parametricMaps = this.overlayManagerService.getParametricMaps(cell);

    const queryVOI = new QueryVOI();
    queryVOI.voiName = row.name;
    queryVOI.voiName = queryVOI.voiName.replace('/', '_');
    const sopInstanceUID_to_offset = {};
    const maps_ALL = {};

    const srcChildren = row.srcChildren;

    // add the ROI itself to children if children is emtpy
    try {
      if (srcChildren.length <= 0) {
        srcChildren.push(row.child);
      }
    } catch (error) {
      console.log('Error... ', error);
    }

    srcChildren.forEach(child => {
      const queryROI = new QueryROI();
      queryROI.roiName = child.get_metadata().Name;
      queryROI.roiType = child.get_metadata().AnnoType;
      queryROI.sopInstanceUID = child.get_metadata().SOPInstanceUID;
      queryROI.seriesInstanceUID = cell.get_seriesInstanceUID();
      // queryROI.imageScale =  Number(child.get_metadata().ImageScale);
      queryROI.imageScale = this.getImageScale(cell);
      queryROI.isChanged = child.get_metadata().isChanged === 'true';
      queryROI.sliceNumber = Number(child.get_metadata().SliceNumber);
      queryROI.seriesId = Number((<any>cell).seriesId);
      queryROI.jointType = child.get_metadata().regionToScore ? child.get_metadata().regionToScore : '';
      queryROI.primaryLocation = child.get_metadata().primaryLocation ? child.get_metadata().primaryLocation : '';
      queryROI.visitId = child.get_metadata().visitConfigId ? Number(child.get_metadata().visitConfigId) : -1;
      queryROI.pixelSpacing = this.getPixelSpacing(cell);
      queryROI.sliceThickness = this.getSliceThickness(cell);

      // replace special caracter
      if (queryROI.roiName && queryROI.roiName.length) {
        queryROI.roiName = queryROI.roiName.replace('/', '_');
      }

      child.points.W.forEach(pt => {
        const pointROI = new PointROI();
        pointROI.x = pt.x;
        pointROI.y = pt.y;
        queryROI.roiPoints.push(pointROI);
      });

      const sliceOffset = parseInt(child.get_metadata().SliceNumber) - 1;

      // TODO: get frame index from seriesInfo or an API
      if (seriesInfo.isDCE) {
        for (let index = 0; index < 2; index++) {
          const cellFrame = cell.get_frames().get_item(index * numbOfSlices + sliceOffset);
          queryROI.baselines.push(cellFrame.Instance.SOPInstanceUID);
        }
      }
      queryVOI.queryROIs.push(queryROI);
      sopInstanceUID_to_offset[queryROI.sopInstanceUID] = sliceOffset;
      maps_ALL[queryROI.sopInstanceUID] = [];
    });

    if (queryVOI.queryROIs.length > 0) {
      queryVOI.seriesInstanceUID = queryVOI.queryROIs[0].seriesInstanceUID;
    }
    const isAscending = this.utils.isInstanceAscending(cell);
    const orderType = isAscending ? 'ASC' : 'DESC';

    const getInstanceStacks = async (pmInfo) => {
      const seriesiuid = pmInfo.seriesUID;

      if (!pmInfo.sopInstanceUIDs) {
        return this.objectRetrieveService.getInstanceStacks(
          seriesiuid, 'InstanceNumber', orderType).toPromise().then((result: any) => {
          pmInfo.sopInstanceUIDs = result;
          for (const [roi_sopInstanceUID, roi_sliceOffset] of Object.entries(sopInstanceUID_to_offset)) {
            const mapData = new MapData();
            mapData.name = pmInfo.mapName;
            mapData.sopInstanceUID = pmInfo.sopInstanceUIDs[Number(roi_sliceOffset)];
            maps_ALL[roi_sopInstanceUID].push(mapData);
          }
        });
      } else {
        for (const [roi_sopInstanceUID, roi_sliceOffset] of Object.entries(sopInstanceUID_to_offset)) {
          const mapData = new MapData();
          mapData.name = pmInfo.mapName;
          mapData.sopInstanceUID = pmInfo.sopInstanceUIDs[Number(roi_sliceOffset)];
          maps_ALL[roi_sopInstanceUID].push(mapData);
        }
        return new Promise<any>(resolve => {
          resolve('');
        });
      }
    };

    if (parametricMaps && parametricMaps.pmInfos) {
      return Promise.all(parametricMaps.pmInfos.map(
        (pmInfo) => getInstanceStacks(pmInfo))).then(() => {
          // update PM to avoid duplicate actions
          this.overlayManagerService.setParametricMaps(cell, parametricMaps);

          queryVOI.queryROIs.forEach(queryROI => {
            queryROI.maps = maps_ALL[queryROI.sopInstanceUID];
          });
          return queryVOI;
        }
      );
    } else {
      return new Promise<any>(resolve => {
        resolve(queryVOI);
      });
    }
  }

  findVOIStatistics(row) {
    // console.log('findVOIStatistics...');
    this.addSelectedRow(row);
    if (this.viewerSettings.toolbar.isStatisticsPanel && this.utils.isDemriqProject()) {

      if (this.splitConfigInfo.disabled) {
        this.enable_statistics_panel();
      }

      const parentCell = row?.parentCell ? row.parentCell : this.seriesManagerService.getActiveCell();
      this.buildQueryVOI(row, parentCell).then((queryVOI) => {
        if (row.statisticsData === undefined || row.isChanged) {
          // find ROI statistics
          this.queryArchiveService.findVOIStatistics([queryVOI]).toPromise().then(data => {
            // TODO: display statisitcs data to the viewer
            row.statisticsData = data;
          });
        } else {
          // TODO: display statistical data from row.statisticsData
          console.log('row.statisticsData:', row.statisticsData);
        }
      });
    }
  }

  onIndexTabChanged(event) {
    if (event > -1) {
      this.tabControlValue = event;
      const row = this.selectedRows[event];
      this.buildRowDataSourcesForStatistics(row);
    }
  }

  get isMultipleCellsLayout(): boolean {
    const viewer = this.actionManagerService.getViewerInstance();
    if (viewer) {
      const cntRows = viewer.gridLayout.rows;
      const cntCols = viewer.gridLayout.columns;
      return cntCols > 1 || cntRows > 1;
    } else {
      return false;
    }
  }

  asyncResizeTrigger() {
    this.utils.syncResizeOn = Date.now();
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 30);
  }

  roiFilterChanged() {
    try {
      this.selection1.clear();
      this.asyncResizeTrigger();
    } catch (error) {

    }
  }


  applyHangingProtocol(protocol: HangingProtocolModel = this.protocol) {
    if (protocol) {
      this.runCommand('ApplyHangingProtocol', { viewerComponent: this, protocol }, {
        oldButtonId: 'HangingProtocols',
        newButtonId: `HangingProtocols__${protocol.name}`
      });
    }
  }

  deleteLastROI(info) {
    try {
      console.log(">>> deleteLastROI...");
      if (this.utils.lastDrawnROI.length > 0) {
        this.selection2.selected.forEach(r => this.uncheckRow(r));
        const roiToDelete: string = this.utils.lastDrawnROI[this.utils.lastDrawnROI.length - 1];
        this.utils.lastDrawnROI.splice(this.utils.lastDrawnROI.length - 1, 1);
        const cell = this.seriesManagerService.getActiveCell();
        const automation = cell.get_automation();
        const containers: lt.Annotations.Engine.AnnContainerCollection = automation.get_containers();
        let roiGUID = '';
        let annObjectLabel: string = '';
        this.utils.allowRemovingObject = false;
        containers.toArray().forEach((container: lt.Annotations.Engine.AnnContainer, index) => {
          container.get_children().toArray().forEach((item: lt.Annotations.Engine.AnnObject) => {
            if (item.get_metadata()['Name'] === roiToDelete) {
              cell.set_currentOffset(index);
              roiGUID = item.guid;
              annObjectLabel = item.metadata.Label;
              container.get_children().remove(item);
            }
          });
        });
        this.utils.allowRemovingObject = true;
        // Saving annotation after any change for simple ROIs and right after unselect for complex ROIs
        this.seriesManagerService.saveActiveContainerROIs(info, roiGUID);
        this.seriesManagerService.autoVolumeCalculations(info, cell, annObjectLabel).then((rowData2) => {
          this.rowData2 = rowData2;
        });
        this.asyncResizeTrigger();
      }
    } catch (error) {
      this.utils.allowRemovingObject = true;
      this.asyncResizeTrigger();
    }
  }

  detailsOfROIs() {
    try {
      const cell = this.seriesManagerService.getActiveCell();
      const seriesInfo = this.seriesManagerService.getSeriesInfo(cell);
      const visitConfigId = seriesInfo.visitConfigId;
      const viewerData = Object({
        visitConfigId: visitConfigId,
        roisCount: []
      })

      const automation = cell.get_automation();
      const containers: any = automation.get_containers().toArray();

      for (let i = 0; i < containers.length; i++) {
        const container: lt.Annotations.Engine.AnnContainer = containers[i];
        container.get_children().toArray().forEach((annotation: lt.Annotations.Engine.AnnObject) => {
          if (annotation.get_metadata()['Label']) {
            const region = annotation.get_metadata()['Label'].toLowerCase().split('_')[0];
            const location = annotation.get_metadata()['Label'].toLowerCase().split('_')[1];
            const roiObject = viewerData.roisCount.find(r => r.region === region && r.location === location);
            if(roiObject)
              roiObject.rois += 1;
            else
              viewerData.roisCount.push({ region: region, location: location, rois: 1 })
          }
        });
      }

      return viewerData;

    } catch (error) {}
  }

  addMarker(markerType: string, locationX?: number, locationY?: number, lesionName?: string) {
    if (this.ROIPanelEnabled && markerType === 'BI_RULER')
      return;
    try {
      const info = { viewerComponent: this };
      if (lesionName != undefined && lesionName != null) {
        (this.actionManagerService as any).lesionName = lesionName;
      }
      if (locationX === undefined && locationY === undefined) {
        this.actionManagerService.OnAddMarker(info, markerType);
        this.asyncResizeTrigger();
        return;
      }
      const activeCell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
      const activeFrame: any = activeCell.get_frames().get_item(activeCell.currentOffset);
      const activeContainer: lt.Annotations.Engine.AnnContainer = activeFrame.get_container();
      const cell_w = activeCell.get_div().clientWidth;
      const cell_h = activeCell.get_div().clientHeight;
      const image_w = activeFrame.width;
      const image_h = activeFrame.height;
      const container_w = activeFrame.get_container().get_size().get_width();
      const container_h = activeFrame.get_container().get_size().get_height();
      const scaleImage = Math.min(cell_w / image_w, cell_h / image_h);
      const xSpace = (cell_w - image_w * scaleImage) / 2;
      const ySpace = (cell_h - image_h * scaleImage) / 2;
      const scaleCanvas = Math.max(container_w / cell_w, container_h / cell_h);

      locationX = (locationX - xSpace) * scaleCanvas;
      locationY = (locationY - ySpace) * scaleCanvas;

      const seriesInfo = this.seriesManagerService.getSeriesInfo(activeCell);
      const visitConfigId = seriesInfo.visitConfigId;
      const timepoint = seriesInfo.timepoint;
      const currentOffset = activeCell.get_currentOffset();
      const frameCount = seriesInfo.numbOfFrames;
      const sliceCount = seriesInfo.numbOfSlices;
      const currentFrame = parseInt(((currentOffset / sliceCount) + 1) + '');

      switch (markerType) {
        case 'RULER':
          const annPolyRulerObjectObj: lt.Annotations.Engine.AnnPolyRulerObject = new lt.Annotations.Engine.AnnPolyRulerObject();
          const annPolyRulerMetadata = annPolyRulerObjectObj.get_metadata();
          annPolyRulerObjectObj.points.add(lt.LeadPointD.create(locationX - 200, locationY));
          annPolyRulerObjectObj.points.add(lt.LeadPointD.create(locationX + 200, locationY));
          annPolyRulerObjectObj.stroke.set_strokeThickness(lt.LeadLengthD.create(0.25));
          annPolyRulerObjectObj.measurementUnit = lt.Annotations.Engine.AnnUnit.millimeter;

          annPolyRulerMetadata['Label'] = 'Marker_RULER';
          annPolyRulerMetadata['AnnoType'] = 'Ruler';
          annPolyRulerMetadata['SliceNumber'] = (currentOffset + 1) + '';
          annPolyRulerMetadata['FrameNumber'] = currentFrame + '';
          annPolyRulerMetadata['FrameCount'] = frameCount + '';
          annPolyRulerMetadata['CreateMethod'] = 'Marker';
          annPolyRulerMetadata['LastEditedOn'] = this.utils.getCurrentDatetime();
          annPolyRulerMetadata['Subject'] = 'Marker';
          annPolyRulerMetadata['VisitConfigId'] = visitConfigId;
          annPolyRulerMetadata['Timepoint'] = timepoint;
          annPolyRulerMetadata['SeriesId'] = (activeCell as any).seriesId;
          annPolyRulerMetadata['SopInstanceUid'] = (activeCell as any).SOPInstanceUIDs[currentOffset];

          try {
            if (this.viewerData && this.viewerData.studyUserRoles) {
              const JWTData = this.utils.JWTData ? this.utils.JWTData : this.utils.getJWTData();
              let userRoles = <Array<any>>(this as any).studyUserRoles;
              if (userRoles == undefined) {
                userRoles = <Array<any>>this.viewerData.studyUserRoles;
              }
              const userRoleNames = userRoles.map(item => item.roleName);
              annPolyRulerMetadata['LastEditedBy'] = JWTData.subject;
              annPolyRulerMetadata['CreatedBy'] = JWTData.subject;
              annPolyRulerMetadata['Institution'] = JWTData.institution;
              annPolyRulerMetadata['UserRoleNames'] = userRoleNames.toString();
              if (this.viewerData.selectedPage === 'Reading' && this.viewerData.readingID !== undefined && this.viewerData.readingID !== '') {
                annPolyRulerMetadata['ReadingID'] = this.viewerData.readingID;
              }
            }
          } catch (error) {

          }

          if (lesionName != undefined && lesionName != null) {
            const label_RULER: lt.Annotations.Engine.AnnLabel = annPolyRulerObjectObj.labels["AnnObjectName"];
            label_RULER.isVisible = true;
            label_RULER.foreground = lt.Annotations.Engine.AnnSolidColorBrush.create('orange');
            label_RULER.text = lesionName;
          }

          // Add the object to the automation container
          activeContainer.get_children().add(annPolyRulerObjectObj);

          break;
        case 'BI_RULER':
          // Add a crossproduct object
          const crossProductObj: lt.Annotations.Engine.AnnCrossProductObject = new lt.Annotations.Engine.AnnCrossProductObject();
          const crossProductMetadata = crossProductObj.get_metadata();
          // Set the points for the first ruler
          crossProductObj.firstStartPoint = lt.LeadPointD.create((locationX - 200), locationY);
          crossProductObj.firstEndPoint = lt.LeadPointD.create((locationX + 200), locationY);
          // Set the points for the second ruler
          crossProductObj.secondStartPoint = lt.LeadPointD.create(locationX, (locationY - 200));
          crossProductObj.secondEndPoint = lt.LeadPointD.create(locationX, (locationY + 200));

          crossProductObj.measurementUnit = lt.Annotations.Engine.AnnUnit.millimeter;
          // Calculate the intersection point
          crossProductObj.stroke.set_strokeThickness(lt.LeadLengthD.create(0.25));

          crossProductObj.updateIntersectionPoint();

          crossProductMetadata['Label'] = 'Marker_BI_RULER';
          crossProductMetadata['AnnoType'] = 'CrossRuler';
          crossProductMetadata['SliceNumber'] = (currentOffset + 1) + '';
          crossProductMetadata['FrameNumber'] = currentFrame + '';
          crossProductMetadata['FrameCount'] = frameCount + '';
          crossProductMetadata['CreateMethod'] = 'Marker';
          crossProductMetadata['LastEditedOn'] = this.utils.getCurrentDatetime();
          crossProductMetadata['Subject'] = 'Marker';
          crossProductMetadata['VisitConfigId'] = visitConfigId;
          crossProductMetadata['Timepoint'] = timepoint;
          crossProductMetadata['SeriesId'] = (activeCell as any).seriesId;
          crossProductMetadata['SopInstanceUid'] = (activeCell as any).SOPInstanceUIDs[currentOffset];

          try {
            if (this.viewerData && this.viewerData.studyUserRoles) {
              const JWTData = this.utils.JWTData ? this.utils.JWTData : this.utils.getJWTData();
              let userRoles = <Array<any>>(this as any).studyUserRoles;
              if (userRoles == undefined) {
                userRoles = <Array<any>>this.viewerData.studyUserRoles;
              }
              const userRoleNames = userRoles.map(item => item.roleName);
              crossProductMetadata['LastEditedBy'] = JWTData.subject;
              crossProductMetadata['CreatedBy'] = JWTData.subject;
              crossProductMetadata['Institution'] = JWTData.institution;
              crossProductMetadata['UserRoleNames'] = userRoleNames.toString();
              if (this.viewerData.selectedPage === 'Reading' && this.viewerData.readingID !== undefined && this.viewerData.readingID !== '') {
                crossProductMetadata['ReadingID'] = this.viewerData.readingID;
              }
            }
          } catch (error) {

          }

          if (lesionName != undefined && lesionName != null) {
            const label_BI_RULER: lt.Annotations.Engine.AnnLabel = crossProductObj.labels["AnnObjectName"];
            label_BI_RULER.isVisible = true;
            label_BI_RULER.foreground = lt.Annotations.Engine.AnnSolidColorBrush.create('orange');
            label_BI_RULER.text = lesionName;
          }

          // Add the object to the automation container
          activeContainer.get_children().add(crossProductObj);

          break;
        case 'MARKER':
          // Create polyline object and add it the automation container
          const annPolyLineObjectObj: lt.Annotations.Engine.AnnPolylineObject = new lt.Annotations.Engine.AnnPolylineObject();
          const annPolyLineMetadata = annPolyLineObjectObj.get_metadata();
          annPolyLineObjectObj.points.add(lt.LeadPointD.create(locationX - 200, locationY));
          annPolyLineObjectObj.points.add(lt.LeadPointD.create(locationX + 200, locationY));

          annPolyLineMetadata['Label'] = 'Marker_MARKER';
          annPolyLineMetadata['AnnoType'] = 'Marker';
          annPolyLineMetadata['SliceNumber'] = (currentOffset + 1) + '';
          annPolyLineMetadata['FrameNumber'] = currentFrame + '';
          annPolyLineMetadata['FrameCount'] = frameCount + '';
          annPolyLineMetadata['CreateMethod'] = 'Marker';
          annPolyLineMetadata['LastEditedOn'] = this.utils.getCurrentDatetime();
          annPolyLineMetadata['Subject'] = 'Marker';
          annPolyLineMetadata['VisitConfigId'] = visitConfigId;
          annPolyLineMetadata['Timepoint'] = timepoint;
          annPolyLineMetadata['SeriesId'] = (activeCell as any).seriesId;
          annPolyLineMetadata['SopInstanceUid'] = (activeCell as any).SOPInstanceUIDs[currentOffset];

          try {
            if (this.viewerData && this.viewerData.studyUserRoles) {
              const JWTData = this.utils.JWTData ? this.utils.JWTData : this.utils.getJWTData();
              let userRoles = <Array<any>>(this as any).studyUserRoles;
              if (userRoles == undefined) {
                userRoles = <Array<any>>this.viewerData.studyUserRoles;
              }
              const userRoleNames = userRoles.map(item => item.roleName);
              annPolyLineMetadata['LastEditedBy'] = JWTData.subject;
              annPolyLineMetadata['CreatedBy'] = JWTData.subject;
              annPolyLineMetadata['Institution'] = JWTData.institution;
              annPolyLineMetadata['UserRoleNames'] = userRoleNames.toString();
              if (this.viewerData.selectedPage === 'Reading' && this.viewerData.readingID !== undefined && this.viewerData.readingID !== '') {
                annPolyLineMetadata['ReadingID'] = this.viewerData.readingID;
              }
            }
          } catch (error) {

          }

          // Create arrow line ending style and set some properties
          const arrowStyle: lt.Annotations.Engine.AnnArrowLineEnding = new lt.Annotations.Engine.AnnArrowLineEnding();
          arrowStyle.closed = true;
          arrowStyle.reversed = false;
          arrowStyle.fill = lt.Annotations.Engine.AnnSolidColorBrush.create('orange');

          // Set the created arrow style to polyline start style
          annPolyLineObjectObj.startStyle = arrowStyle;

          if (lesionName != undefined && lesionName != null) {
            const label_MARKER: lt.Annotations.Engine.AnnLabel = annPolyLineObjectObj.labels["AnnObjectName"];
            label_MARKER.isVisible = true;
            label_MARKER.foreground = lt.Annotations.Engine.AnnSolidColorBrush.create('orange');
            label_MARKER.text = lesionName;
          }

          // Add the object to the automation container
          activeContainer.children.add(annPolyLineObjectObj);

          break;

        default:
          break;
      }

      info['annType'] = 'Select';
      (this.actionManagerService as any).runCommand(15, '', info);
      // this.asyncResizeTrigger();

    } catch (error) {

    }
  }

  removeMarkerName() {
    (this.actionManagerService as any).lesionName = '';
  }

  setMarkerName(name: string) {
    (this.actionManagerService as any).lesionName = name;
  }

  confirmMarker() {
    try {
      const confirmData = {
        objectType: '',
        objectLocationX: 0,
        objectLocationY: 0,
        axialLongDiameter: '',
        shortAxis: '',
        diam: '',
        sliceNumber: '',
        seriesId: '',
        sopInstanceUid: '',
        frameNumber: '',
        voi: {},
        roi: [],
        metadatas: []
      };
      const activeCell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
      const cellAutomation = activeCell.get_automation();
      const currentEditObject: any = cellAutomation.get_currentEditObject();
      const activeFrame: lt.Controls.Medical.Frame = activeCell.get_frames().get_item(activeCell.currentOffset);
      let srcImage_w = 0;
      let srcImage_h = 0;
      let scaleX = 0;
      let scaleY = 0;

      if (activeFrame != undefined && activeFrame != null && activeFrame.width != undefined) {
        srcImage_w = activeFrame.width;
        srcImage_h = activeFrame.height;
        scaleX = activeFrame.get_container().get_size().get_width() / srcImage_w;
        scaleY = activeFrame.get_container().get_size().get_height() / srcImage_h;
      }

      if (currentEditObject != null) {
        const rotateCenterX = parseInt(currentEditObject.rotateCenter.x);
        const rotateCenterY = parseInt(currentEditObject.rotateCenter.y);
        
        confirmData.objectLocationX = parseInt(rotateCenterX / scaleX + '');
        confirmData.objectLocationY = parseInt(rotateCenterY / scaleY + '');
        confirmData.objectType = currentEditObject.get_metadata()['AnnoType'];
        if (confirmData.objectType === 'CrossRuler') {
          const X: number = parseFloat((currentEditObject['labels']['RulerLength']['text'] as string).replace(' mm', ''));
          const Y: number = parseFloat((currentEditObject['labels']['SecondaryRulerLength']['text'] as string).replace(' mm', ''));
          if (X >= Y) {
            confirmData.axialLongDiameter = X + '';
            confirmData.shortAxis = Y + '';
          } else {
            confirmData.axialLongDiameter = Y + '';
            confirmData.shortAxis = X + '';
          }
        }
        if (confirmData.objectType === 'Ruler') {
          confirmData.diam = (currentEditObject['labels']['RulerLength']['text'] as string).replace(' mm', '');
        }
        confirmData.sliceNumber = currentEditObject.get_metadata()['SliceNumber'];
        confirmData.seriesId = currentEditObject.get_metadata()['SeriesId'] || (<any>activeCell).seriesId;
        confirmData.sopInstanceUid =
          currentEditObject.get_metadata()['SopInstanceUid'] || currentEditObject.get_metadata()['SOPInstanceUID'];
        confirmData.frameNumber = currentEditObject.get_metadata()['FrameNumber'] || activeFrame['FrameNumber'];
        confirmData.roi = currentEditObject;
        confirmData.metadatas.push(currentEditObject.get_metadata());
      }
      console.log('>>> confirmData... ', confirmData);
      this.onConfirmMarker.emit(confirmData);
      this.saveCellROIs(activeCell);
    } catch (error) {
      console.log('>>> error ...', error);
    }
  }

  confirmContours(contoursData) {
    this.OnConfirmContours.emit(contoursData);
  }

  showMCPanel() {
    this.showMotionCorrectionPanel = !this.showMotionCorrectionPanel;
  }

  demriqStatData(isAdvancedAnalysis: boolean = false) {
    if (isAdvancedAnalysis) {
      this.toastOptions.title = 'Advance analysis statistic calculation run'
      this.toastOptions.msg = 'Please wait until the process is done'
      this.toastyService.info(this.toastOptions);
    }
    try {
      const queryDEMRIQs = this.buildQueryDEMRIQ(isAdvancedAnalysis);
      if(queryDEMRIQs.length > 0){
        this.queryArchiveService.fetchDEMRIQData(queryDEMRIQs, isAdvancedAnalysis).toPromise().then(data => {
          this.statData = data;
          console.log('DEMRIQ data:', data);
          this.onDemriqStatData.emit(data);
        }).catch(error => {
          console.log('>>> error ...', error);
          this.onDemriqStatData.emit(null);
        });
      } else console.log('Error > No query data, found:', queryDEMRIQs);

    } catch (error) {
      console.log('>>> error ...', error);
      this.onDemriqStatData.emit(null);
    }
  }

  buildQueryDEMRIQ(isAdvancedAnalysis?: boolean){
    const viewerCells = isAdvancedAnalysis ? [this.seriesManagerService.getActiveCell()] : this.viewer.layout.get_items().toArray();
    const queryDEMRIQs = [];

    viewerCells.forEach(_cell => {
      const queryDEMRIQ = new QueryDEMRIQ();

      const cell: lt.Controls.Medical.Cell = _cell;
      const seriesInfo = this.seriesManagerService.getSeriesInfo(cell);
      const isAscending = this.utils.isInstanceAscending(cell);
      const parametricMaps = this.overlayManagerService.getParametricMaps(cell);
      const mprType = this.utils.getCellMPRType(cell);

      // Get existing annotations
      const celldivID = this.utils.getRealId(cell.divID);
      const cellSeriesUID = this.utils.getRealId(cell.seriesInstanceUID);
      const annotations = this.seriesManagerService.getAnnotationIDs(cellSeriesUID, celldivID);

      const queryCell = new QueryCell;
      queryCell.seriesId = Number((<any>cell).seriesId);
      queryCell.seriesInstanceUID = cell.get_seriesInstanceUID();
      queryCell.numFrames = cell.get_frames().count;
      queryCell.numOfSlices = seriesInfo.numbOfSlices;
      queryCell.orderType = isAscending ? 'ASC' : 'DESC';
      queryCell.pixelSpacing = this.getPixelSpacing(cell);
      queryCell.sliceThickness = this.getSliceThickness(cell);
      queryCell.pmInfos = [];

      parametricMaps.pmInfos.forEach(_pmInfo => {
        const pmInfo = new PMInfo();
        pmInfo.mapName = _pmInfo.mapName;
        pmInfo.seriesInstanceUID = _pmInfo.seriesUID;
        pmInfo.sopInstanceUIDs = []; // will be filled on the back-end side
        queryCell.pmInfos.push(pmInfo);
      });
      queryDEMRIQ.cell = queryCell;

      const queryFrames = [];
      cell.get_frames().toArray().forEach(_frame => {
        const frame: lt.Controls.Medical.Frame = _frame;
        const queryFrame = new QueryFrame();
        queryFrame.width = frame.get_width();
        queryFrame.height = frame.get_height();
        queryFrame.sopInstanceUID = (<any>frame).Instance.SOPInstanceUID;

        const container = new QueryContainer();
        container.width = frame.get_container().get_size().get_width();
        container.height = frame.get_container().get_size().get_height();
        queryFrame.containter = container;

        queryFrames.push(queryFrame);
      });
      queryDEMRIQ.frames = queryFrames;

      queryDEMRIQ.psSopInstanceUID = null;
      // select the annotation related to the MPR type
      const sel_annotation = annotations.find(a => a.ContentDescription === mprType);
      if (sel_annotation != null) {
        queryDEMRIQ.psSopInstanceUID = sel_annotation.SOPInstanceUID;
      }

      // extra data for ROIs filtering
      const JWTData = this.utils.getJWTData();
      queryDEMRIQ.subject = JWTData.subject;
      queryDEMRIQ.readingId = this.viewerData.readingID;
      queryDEMRIQ.institution = JWTData.institution;

      if(queryDEMRIQ.psSopInstanceUID !== null){
        queryDEMRIQs.push(queryDEMRIQ);
      }
    });
    return queryDEMRIQs;
  }

  // This function use for resizeing ROIS, VOIs and MCs panels
  expandShrink(panel: string) {
    switch (panel) {
      case 'ROIs':
        this.ROIsPanelClass = 'height100';
        this.VOIsPanelClass = 'height0';
        this.MCsPanelClass = 'height0';
        this.StatDataPanelClass = 'height0';
        break;
      case 'VOIs':
        this.ROIsPanelClass = 'height0';
        this.VOIsPanelClass = 'height100';
        this.MCsPanelClass = 'height0';
        this.StatDataPanelClass = 'height0';
        break;
      case 'MCs':
        this.ROIsPanelClass = 'height0';
        this.VOIsPanelClass = 'height0';
        this.MCsPanelClass = 'height100';
        this.StatDataPanelClass = 'height0';
        break;
      case 'Stat':
        this.ROIsPanelClass = 'height0';
        this.VOIsPanelClass = 'height0';
        this.MCsPanelClass = 'height0';
        this.StatDataPanelClass = 'height100';
        break;

      default:
        this.ROIsPanelClass = 'height60';
        this.VOIsPanelClass = 'height25';
        this.MCsPanelClass = 'height25';
        this.StatDataPanelClass = 'height25';
        break;
    }
  }
}

export enum StatisticsFormulaNames {
  area_pixels = 'Area (pixels)',
  area_mm2 = 'Area (mm2)',
  max = 'Max',
  min = 'Min',
  mean = 'Mean',
  std = 'Std Dev',
  num_pos_ve = 'Num +ve',
  num_neg_ve = 'Num -ve',
  area_pos_ve = 'Area +ve',
  area_neg_ve = 'Area -ve',
  sum_pos_ve = 'Sum +ve',
  sum_neg_ve = 'Sum -ve',
  total_sum = 'Total Sum',
  plateau = 'Plateau',
  persistent = 'Persistent',
  washout = 'Washout',
  classified = 'Classified',
  non_enhancing = 'Non-enhancing',
  num_pixels = 'Num pixels',
  sum = 'Sum',
  area = 'Area'
}
