import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import {
  GlobalLesionModel,
  OncologyLesionType,
  OncologyResponseEnum,
  OncologyResponseModel,
  OncologyResponseType
} from 'src/app/_models/Oncology/global-lesion-model';
import { OncologyAssessmentVisitModel } from 'src/app/_models/Oncology/oncology-assessment-visit-model';
import { ReadingRAPNOService } from 'src/app/_services/reading-rapno.service';
import { RAPNOCondition } from '../rapno-reading-form.component';
import { RAPNOLesionSettings } from '../rapno-lesion-table/rapno-lesion-table.component';
import { RAPNOVisitResponseModel } from 'src/app/_models/RAPNO/rapno-response-model';

export const RAPNOResponseOptions = {
    HGG: [
        { text: 'CR', value: 'CR' },
        { text: 'PR', value: 'PR' },
        { text: 'MR', value: 'MR' },
        { text: 'SD', value: 'SD' },
        { text: 'PD', value: 'PD' },
        { text: 'NE', value: 'NE' },
        { text: 'NED', value: 'NED' },
        { text: 'NA', value: 'NA' },
    ],
    DIPG: [
        { text: 'CR', value: 'CR' },
        { text: 'PR', value: 'PR' },
        { text: 'SD', value: 'SD' },
        { text: 'PD', value: 'PD' },
        { text: 'NE', value: 'NE' },
        { text: 'NED', value: 'NED' },
        { text: 'NA', value: 'NA' },
    ],
    Ependymoma: 
    [
        { text: 'CR', value: 'CR' },
        { text: 'PR', value: 'PR' },
        { text: 'SD', value: 'SD' },
        { text: 'PD', value: 'PD' },
        { text: 'NE', value: 'NE' },
        { text: 'NED', value: 'NED' },
        { text: 'NA', value: 'NA' },
    ]
}

export interface ResponseDialogFormData {
  studyId: number,
  readingId: number,
  readerId: number,
  endpointName: string,
  visit: OncologyAssessmentVisitModel,
  visits: OncologyAssessmentVisitModel[],
  condition: RAPNOCondition,
  allResponses: RAPNOVisitResponseModel[],
  allResponseTypes: OncologyResponseType[],
}

@Component({
  selector: 'app-rapno-response-dialog',
  templateUrl: './rapno-response-dialog.component.html',
  styleUrls: ['./rapno-response-dialog.component.css']
})
export class RAPNOResponseDialogComponent implements OnInit {
  responseEnum = OncologyResponseEnum;
  responseTypes = OncologyResponseType;
  lesionType = OncologyLesionType;
  responseOptions = RAPNOResponseOptions;

  minimized = false;
  selectedTab = 0;
  showModalSpinnerLoadingData = false;
  commentForm: FormGroup;
  multiTP = false;
  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };
  updatedOverallResponse: OncologyResponseModel;
  

  constructor(public dialogRef: MatDialogRef<RAPNOResponseDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ResponseDialogFormData,
              private readingService: ReadingRAPNOService,
              private toastyService: ToastyService) {
    this.data.visits = this.data.visits.filter(visit => {
      return visit.id <= this.data.visit.id;
    });
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.commentForm = new FormGroup({
      comment: new FormControl(this.data.visit.comment ? this.data.visit.comment: '')
    });
  }

  changeCommentMandatory(required: boolean) {
    if (required) {
      this.commentForm.get('comment').setValidators([Validators.required]);
    } else {
      this.commentForm.get('comment').clearValidators();
    }

    this.commentForm.get('comment').updateValueAndValidity();
  }

  minimizeDialog() {
    let dialog = document.getElementsByClassName('cdk-overlay-pane');
    dialog[0].classList.add('minimized');
    let top = ((dialog[0] as HTMLElement).offsetTop + 60) + 'px';
    document.documentElement.style.setProperty('--dialog-display', 'translate3d(0 , calc(100vh - ' + top + ') , 0)');
    document.documentElement.style.setProperty('--dialog-background', '#e99425');
    document.documentElement.style.setProperty('--dialog-color', '#fff');
    this.minimized = true;
  }

  maximizeDialog() {
    let dialog = document.getElementsByClassName('cdk-overlay-pane');
    dialog[0].classList.remove('minimized');
    document.documentElement.style.setProperty('--dialog-display', '');
    document.documentElement.style.setProperty('--dialog-background', '#fff');
    document.documentElement.style.setProperty('--dialog-color', '');
    this.minimized = false;
  }

  showResponseOptions() {
    return this.responseOptions[this.data.condition];
  }

  getJustFollowups() {
    return this.data.visits.filter(visit => {
      return !visit.baseline;
    });
  }

  getTableName(key: OncologyResponseType) {
    return RAPNOLesionSettings[key]?.TABLE_NAME ?? key;
  }

  onChangeOverallResponse(event, visitConfigId: number) {
    const responseObject = this.getResponse(OncologyResponseType.OVERALL, visitConfigId);
    
    this.updatedOverallResponse = {
        id: responseObject.id,
        endpointName: this.data.endpointName,
        readingId: this.data.readingId,
        response: event.value,
        type: responseObject.type,
        userId: this.data.readerId,
        visitConfigId: visitConfigId
    };
    this.changeCommentMandatory(true);
  }

  getResponse(responseType: OncologyResponseType, visitConfigId: number) {
    const visitResponses = this.data.allResponses.find(response => response.visitConfigId === visitConfigId);
    if (visitResponses && visitResponses.responses) {
      return visitResponses.responses.find(f => f.type == responseType);
    }
    return null;
  }

  getResponseValue(responseType: OncologyResponseType, visitConfigId: number) {
    const visit = this.data.visits.find(v => v.visitConfigId === visitConfigId);
    if (visit.noUpload)
      return 'NA';
    
    const response = this.getResponse(responseType, visitConfigId);
    return response?.response ?? 'NA';
  }

  disableResponse(visit: any) {
    return visit.visitConfigId !== this.data.visit.visitConfigId;
  }

  closeClick() {
    this.dialogRef.close({ submit: false, responses: this.data.allResponses });
  }

  saveClick() {
    // Move response save an comment save here
    this.showModalSpinnerLoadingData = true;
    const comment = this.commentForm.get('comment').value;

    if (this.updatedOverallResponse) {
        
        const data = {
          endpointName: this.updatedOverallResponse.endpointName,
          readingId: this.updatedOverallResponse.readingId,
          visitConfigId: this.updatedOverallResponse.visitConfigId,
          type: this.updatedOverallResponse.type,
          response: this.updatedOverallResponse.response,
          userId: this.updatedOverallResponse.userId,
          comment: comment,
        };
        this.readingService.updateResponse(this.data.studyId, this.data.readingId, this.data.visit.visitConfigId, data)
            .subscribe(() => {
                this.showModalSpinnerLoadingData = false;
                this.toastOptions.title = 'SUCCESS: The Response is saved';
                this.toastOptions.msg = 'Response is successfully saved';
                this.toastyService.success(this.toastOptions);

                this.dialogRef.close({ submit: true, responses: this.data.allResponses, comment: comment });
            }, err => {
                this.showModalSpinnerLoadingData = false;
                this.toastOptions.title = 'FAILED: The Response is not saved';
                this.toastOptions.msg = 'Response save failed';
                this.toastyService.error(this.toastOptions);
            });
    } else {
        this.dialogRef.close({ submit: true, responses: this.data.allResponses, comment: comment });
    }
  }

}
