import {Component, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {BasicResponse} from '../../core/interfaces/basic-response';
import {BasicReading} from '../../_models/basic-reading';
import { AddLesionRequest, EditLesionRequest } from 'src/app/_models/Oncology/global-lesion-model';

export interface ReadingFormScoring<T = any> {

  getEndpointName(): string;

  loadReadings(studyId: number, readerId: number): Observable<BasicResponse<T>>;

  startReading(studyId: number, readingId: number): Observable<BasicResponse<T>>;

  updateReading(studyId: number, readingId: number, data: { spentSeconds: number }, justSaveTime?: boolean): Observable<BasicResponse<T>>;

  submitReading(studyId: number, data: { spentSeconds: number }): Observable<BasicResponse<T>>;

  saveReading?(studyId: number, data: { spentSeconds: number }): Observable<BasicResponse<T>>;

  onTakeScreenshot?(data): Observable<any>;

  onOpenImage?(value: boolean): void;

  clearForm?(): void;

  filterReading?(reading: T): boolean;

  getSubmitButtonLabel?(): string;

  getHideSubmitButton?(): boolean;
  getReadingId?(): number;
  getNextReadingUrl?(reading: any): string;
  getSignList?(): boolean;
}

export enum NewTabScoringFormKind {
  DISABLED = 0,
  ATTACHED_DETACHED = 1,
  OPEN = 2,
}

@Component({template: ''})
export class ReadingFormScoringComponent implements OnDestroy {

  stepSaveEnabled = true;

  openSeriesCountRestriction = 0;

  switchSubmitBtnDisabledSubject = new BehaviorSubject<boolean>(true);

  readingSeriesInitiated = new BehaviorSubject<boolean>(false);

  activedVisitSubject = new BehaviorSubject<any>(null);

  filterSeriesNavigatorContentSubject = new BehaviorSubject<any>(null);

  viewerEnabledSubject = new BehaviorSubject<boolean>(true);

  currentReadingSubject = new Subject<BasicReading>();

  readingListUpdatedSubject = new BehaviorSubject<any>(null);

  readingListUpdatedAfterVisitsDoneSubject = new BehaviorSubject<any>(null)

  hideScoringFormSubject = new BehaviorSubject<boolean>(false);

  newTabScoringFormEnabledSubject = new BehaviorSubject<NewTabScoringFormKind>(NewTabScoringFormKind.DISABLED);

  contoursDataSubject = new Subject<any>();

  viewerDataForStatisticDataSubject = new BehaviorSubject<any>(null);

  addMarkerSubject = new BehaviorSubject<AddLesionRequest>(null);

  editMarkerSubject = new BehaviorSubject<EditLesionRequest>(null);

  deleteMarkerSubject = new BehaviorSubject<any>(null);

  selectMarkerSubject = new BehaviorSubject<any>(null);

  confirmMarkerSubject = new Subject<any>();

  requestSnapshotSubject = new BehaviorSubject<any>(null);

  confirmSnapshotSubject = new Subject<any>();


  ngOnDestroy(): void {
    this.switchSubmitBtnDisabledSubject.unsubscribe();
    this.currentReadingSubject.unsubscribe();
    this.activedVisitSubject.unsubscribe();
    this.readingSeriesInitiated.unsubscribe();
    this.readingListUpdatedSubject.unsubscribe();
    this.hideScoringFormSubject.unsubscribe();
    this.viewerDataForStatisticDataSubject.unsubscribe();
    this.contoursDataSubject.unsubscribe();
    this.addMarkerSubject.unsubscribe();
    this.editMarkerSubject.unsubscribe();
    this.deleteMarkerSubject.unsubscribe();
    this.selectMarkerSubject.unsubscribe();
    this.requestSnapshotSubject.unsubscribe();
    this.confirmMarkerSubject.unsubscribe();
    this.confirmSnapshotSubject.unsubscribe();
  }

}
