import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {Observable, of, Subscription} from 'rxjs';
import {
  ImagingProjectService,
  ReadingConfigFlexibleService,
} from '../../../../_services';
import { MoaksBmlScoringFormComponent } from '../scoring-forms/moaks-bml-scoring-form/moaks-bml-scoring-form.component';
import { ReadingMoaksService } from '../../../../_services/reading-moaks.service';
import { MoaksCartilageScoringFormComponent } from '../scoring-forms/moaks-cartilage-scoring-form/moaks-cartilage-scoring-form.component';
import { MoaksOsteophytesScoringFormComponent } from '../scoring-forms/moaks-osteophytes-scoring-form/moaks-osteophytes-scoring-form.component';
import { MoaksMeniscalScoringFormComponent } from '../scoring-forms/moaks-meniscal-scoring-form/moaks-meniscal-scoring-form.component';
import { MoaksPeriarticularScoringFormComponent } from '../scoring-forms/moaks-periarticular-scoring-form/moaks-periarticular-scoring-form.component';
import { MoaksReadingModel } from '../../../../_models/MOAKS/moaks-reading-model';
import { Store } from '@ngxs/store';
import { SetPageHeaderTitle } from '../../../../core/data-management/actions/projects.action';
import { ToastOptions } from 'ng2-toasty';
import * as _ from 'lodash';
import { NewTabScoringFormKind, ReadingFormScoring, ReadingFormScoringComponent } from '../../reading-form-scoring.component';
import { ResponseCode } from 'src/app/core/constants/response-code';
import { BasicResponse } from 'src/app/core/interfaces/basic-response';
import { ReadingVersion } from 'src/app/core/constants/reading-version';
import {MatDialog} from '@angular/material/dialog';
import {ToastService} from '../../../../_services/internal/toast.service';
import {ConfirmSigningDialogComponent} from '../../../ReportSign/confirm-signing-dialog/confirm-signing-dialog.component';
import {switchMap} from 'rxjs/operators';
import {MoaksMocartScoringFormComponent} from '../scoring-forms/moaks-mocart-scoring-form/moaks-mocart-scoring-form.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PatientService } from 'src/app/_services/patient.service';
import { MoaksVisitModel } from 'src/app/_models/MOAKS/moaks-visit-model';

enum ScoringFormSelector {
  BML = 'Bml',
  CARTILAGE = 'Cartilage',
  OSTEOPHYTES = 'Osteophytes',
  MENISCAL = 'Meniscus',
  PERIARTICULAR = 'Periarticular',
  MOCART = 'Mocart'
}

@Component({
  selector: 'app-moaks-basic-form',
  templateUrl: './moaks-basic-form.component.html',
  styleUrls: ['./moaks-basic-form.component.css']
})
export class MoaksBasicFormComponent extends ReadingFormScoringComponent implements OnInit, OnDestroy, ReadingFormScoring {

  hiddenForm = false;

  currentReading: MoaksReadingModel;
  isMoaksMocart: boolean = false;
  scoringFormSelector = ScoringFormSelector;
  currentScoringFormSelector = ScoringFormSelector.BML;
  readingLevel: string;
  patientClinicalData: string = 'N/A';
  baselineVisitConfigId: number;
  visitConfigs: any[];

  @ViewChildren(MoaksBmlScoringFormComponent)
  bmlScoringFormComponents: QueryList<MoaksBmlScoringFormComponent> = null;
  @ViewChildren(MoaksCartilageScoringFormComponent)
  cartilageScoringFormComponents: QueryList<MoaksCartilageScoringFormComponent> = null;
  @ViewChildren(MoaksOsteophytesScoringFormComponent)
  osteophytesScoringFormComponents: QueryList<MoaksOsteophytesScoringFormComponent> = null;
  @ViewChildren(MoaksMeniscalScoringFormComponent)
  meniscalScoringFormComponents: QueryList<MoaksMeniscalScoringFormComponent> = null;
  @ViewChildren(MoaksPeriarticularScoringFormComponent)
  periarticularScoringFormComponents: QueryList<MoaksPeriarticularScoringFormComponent> = null;
  @ViewChildren(MoaksMocartScoringFormComponent)
  mocartScoringFormComponents: QueryList<MoaksMocartScoringFormComponent> = null;

  validationMessages: string[];
  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };
  validUids: string[] = [];
  flexibleSubscription: Subscription;
  
  imageQuality: FormGroup = this.fb.group({});

  constructor(
    private readingConfigFlexibleService: ReadingConfigFlexibleService,
    private readingMoaksService: ReadingMoaksService,
    private patientService: PatientService,
    private imagingProjectService: ImagingProjectService,
    private store: Store,
    private dialog: MatDialog,
    private toastService: ToastService,
    private fb: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(new SetPageHeaderTitle('MOAKS Scoring Form'));
    this.newTabScoringFormEnabledSubject.next(NewTabScoringFormKind.ATTACHED_DETACHED);
    this.initOnCurrentReading();

    this.hideScoringFormSubject.subscribe(hidden => {
      this.hiddenForm = hidden;
    });

  }

  resetScoringForms() {
    if (this.bmlScoringFormComponents && this.bmlScoringFormComponents.length) {
      this.bmlScoringFormComponents.reset(new Array<MoaksBmlScoringFormComponent>());
    }
    if (this.cartilageScoringFormComponents && this.cartilageScoringFormComponents.length) {
      this.cartilageScoringFormComponents.reset(new Array<MoaksCartilageScoringFormComponent>());
    }
    if (this.osteophytesScoringFormComponents && this.osteophytesScoringFormComponents.length) {
      this.osteophytesScoringFormComponents.reset(new Array<MoaksOsteophytesScoringFormComponent>());
    }
    if (this.meniscalScoringFormComponents && this.meniscalScoringFormComponents.length) {
      this.meniscalScoringFormComponents.reset(new Array<MoaksMeniscalScoringFormComponent>());
    }
    if (this.periarticularScoringFormComponents && this.periarticularScoringFormComponents.length) {
      this.periarticularScoringFormComponents.reset(new Array<MoaksPeriarticularScoringFormComponent>());
    }
    if (this.mocartScoringFormComponents && this.mocartScoringFormComponents.length) {
      this.mocartScoringFormComponents.reset(new Array<MoaksMocartScoringFormComponent>());
    }
    if (this.imageQuality && this.imageQuality.controls.length) {
      this.imageQuality.reset({});
    }
  }

  initDataValidation(reading: MoaksReadingModel) {
    this.validUids = [];
    reading.visits.forEach((visit, index) => {
      if (visit.bmlScorings.every(scoring => scoring.size && scoring.percent && (scoring.num || scoring.num === 0))) {
        this.validUids.push('bml' + index);
      }
      if (visit.cartilageScorings.every(scoring => scoring.loss)) {
        this.validUids.push('cartilage' + index);
      }
      if (visit.osteophytesScorings.every(scoring => scoring.score)) {
        this.validUids.push('osteophytes' + index);
      }
      if (
        visit.meniscalExtrusionScorings.every(scoring => scoring.extrusion) &&
        visit.meniscalMorphologyScorings.every(scoring => scoring.hyp && scoring.cyst && scoring.rootTear && scoring.signalTear)
      ) {
        this.validUids.push('meniscal' + index);
      }
      /*
      if (visit.meniscalMorphologyScorings.every(scoring => scoring.hyp && scoring.cyst && scoring.rootTear && scoring.signalTear)) {
          this.validUids.push('meniscal' + index);
      }*/
      if (
        visit.periarticularLigamentTendonScorings.every(scoring => scoring.ligamentTendon) &&
        visit.periarticularFeaturesScorings.every(scoring => scoring.feature) &&
        visit.periarticularScorings.filter(scoring => ['HSS', 'ESS'].includes(scoring.code)).every(scoring => scoring.scoring)
      ) {
        this.validUids.push('periarticular' + index);
      }
      /*if (visit.periarticularFeaturesScorings.every(scoring => scoring.feature)) {
          this.validUids.push('periarticular' + index);
      }
      if (visit.periarticularScorings.every(scoring => scoring.scoring)) {
          this.validUids.push('periarticular' + index);
      }*/
    });
  }

  isFormsValid(forms: QueryList<any>, uid0: string, uid1: string, formName: string): boolean {
    let isValid = forms != null;
    if (isValid) {
      if (forms.length) {
        forms.forEach(component => {
          if (isValid) {
            isValid = component[formName].valid;
          }
          if (isValid && !this.validUids.includes(component.uid)) {
            this.validUids.push(component.uid);
          } else if (!isValid) {
            const index = this.validUids.indexOf(component.uid);
            if (index > -1) {
              this.validUids.splice(index, 1);
            }
          }
        });
      } else {
        isValid = this.validUids.includes(uid0) && this.validUids.includes(uid1);
      }
    }
    return isValid;
  }

  isBmlScoringFormValid() {
    return this.isFormsValid(this.bmlScoringFormComponents, 'bml0', 'bml1', 'form');
  }

  isCartilageScoringFormValid() {
    return this.isFormsValid(this.cartilageScoringFormComponents, 'cartilage0', 'cartilage1', 'form');
  }

  isOsteophytesScoringFormValid() {
    return this.isFormsValid(this.osteophytesScoringFormComponents, 'osteophytes0', 'osteophytes1', 'form');
  }

  isMeniscalScoringFormValid() {
    let isValid = this.isFormsValid(this.meniscalScoringFormComponents, 'meniscal0', 'meniscal1', 'formExtrusion');
    if (isValid) {
      isValid = this.isFormsValid(this.meniscalScoringFormComponents, 'meniscal0', 'meniscal1', 'formMorphology');
    }
    return isValid;
  }

  isPeriarticularScoringFormValid() {
    let isValid = this.isFormsValid(this.periarticularScoringFormComponents, 'periarticular0', 'periarticular1', 'form');
    if (isValid) {
      isValid = this.isFormsValid(this.periarticularScoringFormComponents, 'periarticular0', 'periarticular1', 'formPerFeatures');
    }
    if (isValid) {
      isValid = this.isFormsValid(this.periarticularScoringFormComponents, 'periarticular0', 'periarticular1', 'formLigTendScoring');
    }
    return isValid;
  }

  isMocartScoringFormValid() {
    return this.isFormsValid(this.mocartScoringFormComponents, 'mocart1', 'mocart1', 'form');
  }

  isReadingValid() {
    this.validationMessages = [];
    if (!this.isBmlScoringFormValid()) {
      this.validationMessages.push('Bml Scoring Form is not Valid');
    }
    if (!this.isCartilageScoringFormValid()) {
      this.validationMessages.push('Cartilage Scoring Form is not Valid');
    }
    if (!this.isOsteophytesScoringFormValid()) {
      this.validationMessages.push('Osteophytes Scoring Form is not Valid');
    }
    if (!this.isMeniscalScoringFormValid()) {
      this.validationMessages.push('Meniscal Scoring Form is not Valid');
    }
    if (!this.isPeriarticularScoringFormValid()) {
      this.validationMessages.push('Periarticular Scoring Form is not Valid');
    }
    if (this.isMoaksMocart && !this.isMocartScoringFormValid()) {
      this.validationMessages.push('Mocart Scoring Form is not Valid');
    }
    if (this.isMoaksMocart && !this.imageQuality.valid) {
      this.validationMessages.push('Quality Assessment is not Valid');
      
    }
    return !this.validationMessages.length;
  }

  
  validForm(event: boolean) {
    event = event && (!this.isMoaksMocart || (this.imageQuality.valid && this.isMocartScoringFormValid()))
    this.switchSubmitBtnDisabledSubject.next(!event);
  }

  validImageQualityForm(event: boolean) {
    event = event && this.isPeriarticularScoringFormValid() && this.isMocartScoringFormValid();
    this.switchSubmitBtnDisabledSubject.next(!event);
  }

  validMocartForm(event: boolean) {
    event = event && this.isPeriarticularScoringFormValid() && (!this.isMoaksMocart || this.imageQuality.valid)
    this.switchSubmitBtnDisabledSubject.next(!event);
  }
  /////////////////////////////////////////////////

  ngOnDestroy(): void {
    if (this.flexibleSubscription) {
      this.flexibleSubscription.unsubscribe();
    }
  }

  initOnCurrentReading(): void {
    this.currentReadingSubject.subscribe(currentReading => {
      if (currentReading.readingVersion === ReadingVersion.MOAKS_3Visits || 
        currentReading.readingVersion === ReadingVersion.MOAKS_MOCART) {
        currentReading.visits.sort((a, b) => a.id - b.id);
      }
      this.currentReading = currentReading;
      // sort visits vased on order tables
      this.currentReading.visits.forEach(visit => this.sortVisitScores(visit));

      this.isMoaksMocart = this.currentReading.readingVersion === ReadingVersion.MOAKS_MOCART;

      let disabled = false;

      this.resetScoringForms();
      this.initDataValidation(currentReading);
      this.currentScoringFormSelector = ScoringFormSelector.BML;
      this.getFlexibleConfig();
      if (this.isMoaksMocart) {
        
        this.getBaselineVisitConfigId();

        this.imageQuality =  new FormGroup({
          imageQuality: new FormControl({value: this.currentReading?.imageQuality, disabled}, [Validators.required]),
          imageQualityComment: new FormControl({value: this.currentReading?.imageQualityComment, disabled},
            this.currentReading.imageQuality === 'Poor' || this.currentReading?.imageQuality === 'Suboptimal' ?
              [Validators.required] : []),
          imageQualityCommentOthers: new FormControl({value: this.currentReading?.imageQualityCommentOthers, disabled})
        });
        this.imageQuality.valueChanges.subscribe(() => {
          this.validImageQualityForm(this.imageQuality.valid);
        })
      }
    });
  }

  getIQControl(name) {
    return (<FormControl>(<FormGroup>(this.imageQuality)).get(name));
  }

  getBaselineVisitConfigId() {
    var visitconfigIds = this.currentReading?.visits?.map(m => m.visitConfigId) || [];
    if (this.currentReading && visitconfigIds) {
      this.imagingProjectService.getVisitConfigsByIds(visitconfigIds).subscribe(
        response => {
          this.baselineVisitConfigId = response.data.find(v => v.visit?.baseline)?.id;
          var visitConfigs = response.data;

          this.currentReading.visits.forEach(visit => {
            visit.timepoint = visitConfigs.find(f => f.id == visit.visitConfigId)?.visitName ?? visit.timepoint;
          });           
      })
    }
  }

  getFlexibleConfig() {
    this.flexibleSubscription = this.readingConfigFlexibleService.getById(this.currentReading.configId).subscribe(response => {
      if (response.responseCode === ResponseCode.OK) {
        this.currentReading.readingLevel = this.readingLevel = response.data.config.readingLevel;
        var clinicalData = response.data.config.mocartClinicalData ?? [];

        this.patientService.getById(this.currentReading.patientId).subscribe(resp => {
          var patientCode = resp.data.patientCode;

          this.patientClinicalData = this.getClinicalDataByPatientId(clinicalData, patientCode);
        });
      }
    });
  }

  getClinicalDataByPatientId(allClinicalData: any[], patientId: any) {
    return allClinicalData?.find(data => data.patientCode === patientId.toString())?.clinicalData || 'N/A';
  }

  getEndpointName(): string {
    return 'MOAKS';
  }

  loadReadings(studyId: number, readerId: number): Observable<BasicResponse<any>> {
    return this.readingMoaksService.getReadingByStudyIdAndReaderId(studyId, readerId);
  }

  startReading(studyId: number, readingId: number): Observable<BasicResponse<any>> {
    return this.readingMoaksService.startReading(studyId, readingId);
  }

  updateReading(studyId: number, readingId: number, data: { spentSeconds: number; }, justSaveTime?: boolean): Observable<BasicResponse<any>> {
    this.currentReading.timeSpent = data.spentSeconds;
    this.updateReadingNavigator(this.currentReading);
    return this.readingMoaksService.updateReading(studyId, readingId, data.spentSeconds);
  }

  submitReading(studyId: number, data: { spentSeconds: number; }): Observable<BasicResponse<any>> {
    const dialogRef = this.dialog.open(ConfirmSigningDialogComponent, {
      width: '500px',
      data: {
        title: 'Image Analysis Group requires you to authenticate your signature on this document.',
        studyId: this.currentReading.studyId,
        patientId: this.currentReading.patientId,
        visitConfigId: this.currentReading.configId
      }
    });

    return dialogRef.afterClosed()
      .pipe(
        switchMap((dialogResponse) => {
          if (!dialogResponse?.jwt) {
            if (!dialogResponse?.canceled) {
              this.toastService.error('ERROR', 'Sign Report Failed.<br/>Please enter valid credentials');
            }

            return of(null);
          }

          this.currentReading.timeSpent = data.spentSeconds;
          if (this.isMoaksMocart) {
            this.currentReading.imageQuality = this.getIQControl('imageQuality').value;
            this.currentReading.imageQualityComment = this.getIQControl('imageQualityComment').value;
            this.currentReading.imageQualityCommentOthers = this.getIQControl('imageQualityCommentOthers').value;  
          }
          
          this.bmlScoringFormComponents.forEach(component => {
            component.setSubmitData();
          });

          this.cartilageScoringFormComponents.forEach(component => {
            component.setSubmitData();
          });

          this.osteophytesScoringFormComponents.forEach(component => {
            component.setSubmitData();
          });

          this.meniscalScoringFormComponents.forEach(component => {
            component.setSubmitData();
          });

          this.periarticularScoringFormComponents.forEach(component => {
            component.setSubmitData();
          });

          this.mocartScoringFormComponents.forEach(component => {
            component.setSubmitData();
          });

          return this.readingMoaksService.completeReading(studyId, this.currentReading, dialogResponse.jwt);
        })
      );
  }

  updateReadingNavigator(reading: any) {
    if (!this.readingListUpdatedSubject.closed) {
      this.readingListUpdatedSubject.next(reading);
    }
  }

  clearForm(): void {
  }

  
  onImageQualityChange(value: string) {
    var control = this.getIQControl('imageQualityComment');
    if (control) {
      if (value === 'Optimal') {
        control.clearValidators();
        control.setValue('NA');
      } else {
        control.setValidators(Validators.required);
        control.setValue(null);
      }
      control.updateValueAndValidity();
      this.onImageQualityCommentChange(null);
    }
  }

  onImageQualityCommentChange(value: string) {
    var commentControl = this.getIQControl('imageQualityCommentOthers');
    if (commentControl) {
      if (value === 'Other') {
        commentControl.setValidators(Validators.required);
      } else {
        commentControl.clearValidators();
      }
      commentControl.updateValueAndValidity();
    }
  }

  sortElementsByOrderInArray(array: any[], orderArray: any[], key: string) {
    return [...array].sort((a, b) => orderArray.indexOf(a[key]) - orderArray.indexOf(b[key]));
  }

  sortVisitScores(visit: MoaksVisitModel) {
    // sort based on predefined list

    const bmlScoreOrder = [ 'MP', 'LP', 'MAF', 'MCF', 'MPF', 'MAT', 'MCT', 'MPT', 'LAF', 'LCF', 'LPF', 'LAT', 'LCT', 'LPT', 'ST' ];
    const cartilageScoreOrder = [ 'MP', 'LP', 'MAF', 'MCF', 'MPF', 'MAT', 'MCT', 'MPT', 'LAF', 'LCF', 'LPF', 'LAT', 'LCT', 'LPT', 'ST' ];

    const menExtrusionsScoreOrder = [ 'MMM', 'LML', 'MMA', 'LMA' ];
    const menMorphologyScoreOrder = [ 'MMAH', 'MMB', 'MMPH', 'LMAH', 'LMB', 'LMPH' ];
    const osteophytesScoreOrder = [ 'SP', 'IP', 'MP', 'LP', 'MAF', 'MPF', 'LAF', 'LPF', 'MMF', 'MLF', 'MMT', 'MLT' ];

    const perScoreOrder = ['HSS', 'ESS', 'TC', 'OF' ];
    const perFeaturesScoreOrder = [ 'PAB', 'ITBS', 'PC', 'IBS', 'PBS', 'GC', 'LB' ];
    const ligTendScoreOrder = [ 'ACL', 'PCL', 'PT' ];


    visit.bmlScorings = this.sortElementsByOrderInArray(visit.bmlScorings, bmlScoreOrder, 'code');
    visit.cartilageScorings = this.sortElementsByOrderInArray(visit.cartilageScorings, cartilageScoreOrder, 'code');
    visit.meniscalExtrusionScorings = this.sortElementsByOrderInArray(visit.meniscalExtrusionScorings, menExtrusionsScoreOrder, 'code');
    visit.meniscalMorphologyScorings = this.sortElementsByOrderInArray(visit.meniscalMorphologyScorings, menMorphologyScoreOrder, 'code');

    visit.osteophytesScorings = this.sortElementsByOrderInArray(visit.osteophytesScorings, osteophytesScoreOrder, 'code');

    visit.periarticularScorings = this.sortElementsByOrderInArray(visit.periarticularScorings, perScoreOrder, 'code')
    visit.periarticularFeaturesScorings = this.sortElementsByOrderInArray(visit.periarticularFeaturesScorings, perFeaturesScoreOrder, 'code');
    visit.periarticularLigamentTendonScorings = this.sortElementsByOrderInArray(visit.periarticularLigamentTendonScorings, ligTendScoreOrder, 'code');
  }

            
}
