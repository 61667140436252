import { HttpCacheService } from 'src/app/_services/http-cache/http-cache.service';
import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {first, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {DataUploadService} from '../../../_services/data-upload.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DragDropFilesComponent} from '../drag-drop-files/drag-drop-files.component';
import {EdtfEditComponent} from '../edtf-edit/edtf-edit.component';
import {MatDialog} from '@angular/material/dialog';
import {UploadDialogComponent} from '../upload-dialog/upload-dialog.component';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Observable} from 'rxjs';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {ImagingProjectService} from '../../../_services/imaging-project.service';
import {UserService} from '../../../_services/user.service';
import {StudySequenceLabelService} from '../../../_services/study-sequence-label.service';
import {QualityControlService} from '../../../_services/quality-control.service';
import {JwtUtilService} from '../../../_helpers/jwt-util.service';
import {PatientService} from '../../../_services/patient.service';

import * as _moment from 'moment';
import {Store} from '@ngxs/store';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';
import {ResponseCode} from '../../../core/constants/response-code';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {MatExpansionPanel} from '@angular/material/expansion';
import {MatSelectChange} from '@angular/material/select';
import { MatOptionSelectionChange } from "@angular/material/core";
import * as jszip from "jszip"
import {ReadingConfigFlexibleService} from '../../../_services';
import {environment} from 'src/environments/environment';

const moment = _moment;
const EARLY_TERMINATION_VISIT_TYPE = 'UNSCHEDULED_EARLY_TERMINATION';

const dxaFileTypes = ['df', 'me', 'r0', 'p0', 'nt', 'r1', 'p1'];
const documentFileTypes = ['pdf','doc','docx','txt','csv','png','jpg','jpeg','tiff','xls','xlsx'];
export interface ModalityElement {
  id: number;
  name: string;
}

class UploadFileInfo {
  id: number;
  name: string;
  size: number;
  status: string;
  resourceType: string;
}

@Component({
  selector: 'app-upload-visit-form',
  templateUrl: './upload-visit-form.component.html',
  styleUrls: ['./upload-visit-form.component.css']
})
export class UploadVisitFormComponent implements OnInit, OnDestroy, AfterViewInit  {

  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };

  isEnableToldMRI: boolean;
  isNewUpload = true;
  state = {
    siteConfigId: 0,
    siteCode: 0,
    patientId: 0,
    patientCode: 0,
    patientBirthDate: '',
    visitDataId: 0,
    visitConfId: 0,
    imageProject: 0,
    mode: 'upload',
    visit: {},
    bucketLocation: '',
    isUseEDTFUpload: false,
    qcVisitLock: false,
    unscheduled: false,
  };

  patient: {};

  allModalities = [];
  qcFilteredModalities = [];
  qcDisableUI = false;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredModality: Observable<ModalityElement[]>;

  isETLocked = false;
  isNoUploadSaveDisabled = false;
  baselineConditioninValue;
  flexibleConfig;

  @ViewChild('modalityInput') modalityInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('dragDropImages') selectedFiles: DragDropFilesComponent;
  @ViewChild('dragDropEdtf') selectedEdtfFile: DragDropFilesComponent;
  @ViewChild('edtfDataForm') edtfData: EdtfEditComponent;
  @ViewChild('edtfExpansionPanel') edtfExpantionPanel: MatExpansionPanel;
  edtfTemplate = null;

  uploadForm: FormGroup;

  errorGetUploadInformation = false;
  uploadFilesInfo = new Array();

  visitDetails: any[];

  activities: any[];
  profile = {
    rights: {
      canCreateOrUploadEDTF: false,
      canViewEDTF: false,
      iagProjectManager: false,
    }
  };

  _patient: any;
  _visitConfig: any;
  _siteConfig: any;
  _edtfVisits: any;

  noUpload = false;

  hasNoUpload = false;
  navigateAfterDialogClosed = true;
  constructor(
    private readingConfigFlexibleService: ReadingConfigFlexibleService,
    private router: Router,
    private route: ActivatedRoute,
    private imagingProjectSerivce: ImagingProjectService,
    private patientService: PatientService,
    private serviceDataUpload: DataUploadService,
    private userService: UserService,
    private studySequenceLabelService: StudySequenceLabelService,
    private qualityControlService: QualityControlService,
    private jwtUtilService: JwtUtilService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private store: Store,
    private toastyService: ToastyService,
    private qcService: QualityControlService,
    private _cache: HttpCacheService) {
    this.createForm();
  }

  ngOnInit() {
    this.store.dispatch(new SetPageHeaderTitle('Upload visit files'));
    // intercept visitId from url
    this.route.params.subscribe(params => {
      this.state.mode = 'upload';
      this.state.imageProject = +params['ipid'];
      this.state.siteConfigId = +params['sid'];
      this.state.patientId = +params['pid'];
      this.state.visitConfId = +params['vcid'];
      this.state.visitDataId = +params['vdid'];
      this.state.mode = params['mode'];
      this.state.unscheduled = params['unscheduled'];
      this.state.qcVisitLock = false;
      this.qcDisableUI = false;

      this.getFlexibleConfig();

      this.imagingProjectSerivce.getSiteConfig(this.state.siteConfigId).subscribe(siteConfResp => {
        this._siteConfig = siteConfResp['data'];
        this.patientService.getByIdWithVisitConfig(this.state.patientId).subscribe(patientResp => {
          this._patient = patientResp['data'];
          const etVisit = this._patient.visitConfigs.find(vc => vc.visitName.toLowerCase() === 'et');
          if (!!etVisit) {
            this.qcService.getQCVisitByVisitWithoutRelations(this.state.imageProject, etVisit.id).subscribe(resp => {
              if (!!resp['data'] && resp['data'].lockFlag === true) {
                this.isETLocked = true;
              }
            });
          }

          this.updateDTFValidation();

          this.state.patientBirthDate = moment(this._patient['patientBirthDate']).format('MM/YYYY');
          this.imagingProjectSerivce.getVisitConfig(this.state.visitConfId.toString()).subscribe(visitConfigResp => {
            this._visitConfig = visitConfigResp['data'];
            this.noUpload = this._visitConfig.noUploads;
            this.hasNoUpload = this._visitConfig.noUploads;
            if (this.hasNoUpload) {
              this.uploadForm.controls.comment.setValue(this._visitConfig.noUploadsComment);
            }
            this.state.patientCode = this._patient['patientCode'];


            this.patient = JSON.parse(localStorage.getItem('tmp.upload.patient.visit'));
            this._edtfVisits = this.patient['visits'];
            if (this.patient['visitsUnscheduled'] != null) {
              const v = this.patient['visitsUnscheduled'].find(vu => vu.config.visitConfigsId == this._visitConfig.id);
              if (v != null) {
                this._edtfVisits.push(v);
              }
            }
            this.addEarlyTerminationVisitToEDTFVisitsIfItExists();
            this.state.visit = this.patient['visits'].filter(item => (item.config != null && item.config.visitId === this.state.visitDataId))[0];

            this.qualityControlService.getQCVisitByVisitWithoutRelations(this.state.imageProject, this.state.visitConfId).subscribe(qcVisitResp => {
              const qcVisit = qcVisitResp['data'];
              if (qcVisit != null) {
                // the patient discontinued flag was removed
                if (qcVisit['patientDiscontinuedFlag'] == true && this._patient['discontinued'] == false) {
                  this.state.qcVisitLock = false;
                } else {
                  if (qcVisit['lockFlag'] == true) {
                    this.state.qcVisitLock = true;
                  }
                }
              }
            });

            this.imagingProjectSerivce.getStudy(this.state.imageProject).subscribe(result => {
              if (result.hasOwnProperty('data')) {
                const data = result['data'];
                this.state.bucketLocation = data['bucketLocation'];
                if (data.eDTF != null) {
                  const edtfObj = data.eDTF;
                  this.setEdtfData(edtfObj);
                  this.updateValidationRules();
                }
              }
            });
          });

          this.studySequenceLabelService.getStudyModalitiesByStudyId(this.state.imageProject).subscribe(result => {
            const modalities = result['data'];
            modalities.forEach(item => {
              const m: ModalityElement = {
                name: item.name,
                id: item.id,
              };
              this.allModalities.push(m);
            });

            this.qualityControlService.getModalitiesByVisitConfigId(this.state.imageProject, this.state.visitConfId).subscribe(qcModalitiesResp => {
              let qcModalitiesData = qcModalitiesResp['data'];
              qcModalitiesData = qcModalitiesData.filter(m => m.lockFlag == true);

              this.allModalities.forEach(m => {
                const qcModals = qcModalitiesData.filter(qcm => m.id == qcm.modalityId);
                if (qcModals.length == 0) {
                  this.qcFilteredModalities.push(m);
                }
              });
              if (this.qcFilteredModalities.length == 0) {
                this.qcDisableUI = true;
                this.uploadForm.controls['comment'].disable();
              }
              if (this.profile.rights.iagProjectManager == true) {
                this.uploadForm.controls['comment'].disable();
              }
              if (this._patient != null) {
                if (this._patient['discontinued'] == true) {
                  this.uploadForm.controls['comment'].disable();
                }
              }

            });
          });

          const aStr = localStorage.getItem('activities');
          this.activities = JSON.parse(aStr);
          this.setActivityRights(this.state.imageProject);

          if (this.state.mode === 'edit') {
            this.initData();
          } else {
            this.visitDetails = [];
          }
        });
      });
    });

    // To clear specific cache if unschduled upload is opened.
    if (this.route.snapshot.params.unscheduled === 'true') {
      const uploadListURL = 'https://'
        + environment.MSUploadVersion
        + `msimagingproject-dot-${environment.apiUrl}/site-config/get-site-configs-by-study-id/${this.state.imageProject}`;
      this._cache.delete(uploadListURL)
    }

  }

  getFlexibleConfig() {
    this.readingConfigFlexibleService.getActiveConfigsByStudyId(this.state.imageProject).pipe(tap(flexibleConfig => {
      this.isEnableToldMRI = flexibleConfig.data.some(({config}) => config.enableToldMRI);
      this.flexibleConfig = flexibleConfig;
      this.updateDTFValidation();
    })).subscribe(() => {});
  }

  updateDTFValidation() {
    if (!this.flexibleConfig || this.flexibleConfig?.data.length <= 0 || this.flexibleConfig.data[0].config.readingVersion !== 'RAPNO_BASIC') {
      return;
    }

    const baselineVisit = this._patient?.visitConfigs?.find(vc => vc.visitName.toLowerCase() === 'baseline');
    if (!baselineVisit) {
      return;
    }

    if (this.state.visitConfId !== baselineVisit.id) {
      this.serviceDataUpload.getUploadGeneralInfoByVisitConfigIdWithDownloadLinks(this.state.imageProject, baselineVisit.id).subscribe( genInfoResp => {
        this.updateValidation(genInfoResp['data']);
      });
    }
  }
  private addEarlyTerminationVisitToEDTFVisitsIfItExists() {
    const earlyTerminationVisitConfig = this.patient['visitConfigs']
      .find(visitConfig => visitConfig.visitType === EARLY_TERMINATION_VISIT_TYPE);
    if (!!earlyTerminationVisitConfig) {
      this._edtfVisits.push({
        config: {
          name: earlyTerminationVisitConfig.visit?.name ?? earlyTerminationVisitConfig.visitName,
          visitId: earlyTerminationVisitConfig.visit?.id ?? earlyTerminationVisitConfig.visitId,
          visitConfigsId: earlyTerminationVisitConfig.id,
          durationTimeValue: earlyTerminationVisitConfig.visit?.durationTimeValue ?? earlyTerminationVisitConfig.durationTimeValue,
          durationTimeUnit: earlyTerminationVisitConfig.visit?.durationTimeUnit ?? earlyTerminationVisitConfig.durationTimeUnit,
        }
      });
    }
  }

  ngOnDestroy() {
  }

  ngAfterViewInit() {
    if (this.edtfData != null ) {
      this.edtfData.patientCode = this._patient['patientCode'];
      this.edtfData.patientDaeOfBirth = this._patient['patientBirthDate'];
    }
    if (this.selectedFiles != null) {
      this.selectedFiles._zipOnly = true;
    }
    if (this.selectedEdtfFile != null) {
      this.selectedEdtfFile._zipOnly = false;
    }
    this.updateValidationRules();
    this.updateValidationRules();
  }

  setEdtfData(edtf) {
    this.edtfTemplate = (JSON.parse(JSON.stringify(edtf)));
    if (this.edtfData != null) {
      if (typeof edtf === 'object')
        this.edtfData.setEdtf(JSON.parse(JSON.stringify(edtf)));
      else
      this.edtfData.setEdtf(JSON.parse(edtf));
    }
  }


  updateValidationRules() {
    if (this.edtfData != null) {
      if (this._patient != null && this._visitConfig != null) {
        this.edtfData.initValidation(this._patient['patientCode'], this._visitConfig.id, this._patient['patientBirthDate']);
      }
    }
  }

  setActivityRights(id) {
    const str  = localStorage.getItem('activities');
    if (str != null) {
      this.activities = JSON.parse(str);
      if (this.activities.indexOf('upload.create.or.upload.eDTF') > -1) {
        this.profile.rights.canCreateOrUploadEDTF = true;
      }
      if (this.activities.indexOf('upload.view.eDTF') > -1) {
        this.profile.rights.canViewEDTF = true;
      }
    }
    this.profile.rights.iagProjectManager = this.jwtUtilService.isUserHasActivity('configuration.upload.view.all.site.data', id);
  }

  initData() {
    this.serviceDataUpload.getUploadGeneralInfoByVisitConfigIdWithDownloadLinks(this.state.imageProject, this.state.visitConfId).subscribe( genInfoResp => {
      this.addUserInformation(genInfoResp['data']);
    });
  }

  addUserInformation(data) {
    const users = [];
    if (data != null) {
      data.forEach(upload => {
        upload.created = moment(upload.created).format('DD/MM/YYYY');
        users.push(upload.uploadUserId);
      });
    }

    this.userService.getByListIds(users).subscribe(usersInfo => {
      const userInfoData = usersInfo['data'];
      if (userInfoData != null) {
        data.forEach(upload => {
          const user = userInfoData.filter(user => user.id == upload.uploadUserId)[0];
          if (user != null) {
            upload.uploadUserFirstName = user.firstName;
            upload.uploadUserLastName = user.lastName;
          }
        });
      }
      this.setVisitInformation(data);
    });
  }

  setVisitInformation(visitInfo) {
    this.visitDetails = visitInfo;
    this.injectModalitiesToEDTF();
    const lastUpload = visitInfo[0]; // back end return general info  sorted by DESC
    const lastUploadStatus = this.getLastUploadStatus(lastUpload);
    if (this.state.visitConfId === visitInfo.visitConfigId && lastUploadStatus === 'success' &&  this.edtfData != null) {
      if (lastUpload.edtf != null) {
        this.edtfData.setEdtf(lastUpload.edtf);
      } else {
        this.edtfData.setEdtf(JSON.parse(JSON.stringify(this.edtfTemplate)));
      }
    }
    if (this.edtfData != null) {
      if (this.edtfData.edtf == null) {
        this.state.isUseEDTFUpload = false;
      }
    }
    this.uploadForm.patchValue({comment: lastUpload.comment});
    for (let i = 0 ; i < lastUpload.modalities.modality.length ; i++) {
      if (lastUpload.modalities.modality[i] != null) {
        this.uploadForm.patchValue({modality: lastUpload.modalities.modality[i]});
      }
    }
    if (this.edtfData != null) {
      this.edtfData.rights.canCreateOrUploadEDTF = this.profile.rights.canCreateOrUploadEDTF;
      this.edtfData.rights.canViewEDTF = this.profile.rights.canViewEDTF;
    }
  }

  updateValidation(data) {
    if (!data || !data[0]?.edtf) {
      return;
    }

    const edtf = data[0].edtf;
    const conditionQuestion = edtf.blocks.find(b => b.question?.toLowerCase() === 'please select below condition, only one option should be allowed and mandatory to select from these conditions.');
    if (conditionQuestion) {
      this.baselineConditioninValue = conditionQuestion.radioButtonValue;
    }
  }
  injectModalitiesToEDTF() {
    const modalitySection = {
      question: 'Modalities',
      modalitySelector: {
      selectedModalities: null,
      availableMOdalities: this.filteredModality,
      },
      actionId: 'modalitySelector',
    };

  }

  getLastUploadStatus(lastUpload) {
    if (lastUpload['files'] != null) {
      const files = lastUpload.files.filter(file => file.status.name != 'success');
      if (files.length > 0) {
        return 'failed';
      }
    }
    return 'success';
  }

  createForm() {
    this.uploadForm = this.fb.group({
      modality: ['', Validators.required],
      comment: ['', Validators.maxLength(200)],
      files: ['', Validators.required],
      edtf: {},
    });
  }

  clickCancel() {
    this.router.navigate(['/imagingproject/upload']);
  }

  onChangeNoUpload(event: MatCheckboxChange): void {
    if (event.checked) {
      this.uploadForm.controls.comment.setValidators([Validators.required, Validators.maxLength(200)]);
    } else {
      this.uploadForm.controls.comment.clearValidators();
      this.uploadForm.controls.comment.setValidators([Validators.maxLength(200)]);
    }
    this.uploadForm.controls.comment.markAsTouched();
    this.uploadForm.controls.comment.updateValueAndValidity();
    if (this.state.isUseEDTFUpload === true) {
      this.toggleEdtfLogic();
    }
    this.edtfExpantionPanel.close();
  }

  async clickUpload() {
    this.navigateAfterDialogClosed = true;
    if (((this.selectedFiles?.files?.length === 0 || this.uploadForm.controls.modality.invalid || this.qcDisableUI === true) &&
      !this.noUpload) || this._patient.discontinued === true || (this.noUpload && this.uploadForm.controls.comment.invalid) ||
      this.isETLocked || this.hasNoUpload || this.isNoUploadSaveDisabled || this.uploadForm.controls.comment.invalid) {
      this.isNoUploadSaveDisabled = false;
      if (this.noUpload) {
        this.showNoUploadError();
      } else {
        this.showUploadError();
      }
      return;
    }
    if (this.noUpload) {
      this.isNoUploadSaveDisabled = true;
      this.noUploadVisitUpdate();
      return;
    }
    const dataUploadForm = this.uploadForm.value;
    const dataFiles = this.selectedFiles['files'];
    let edtfFiles;
    if (this.selectedEdtfFile != null) {
      edtfFiles = this.selectedEdtfFile['files'];
    }
    this.uploadFilesInfo = new Array();
    const registerFilesInfo = new Array();
    const selModalities = this.uploadForm.get('modality').value;

    const formData: FormData = new FormData();

    formData.append('imageProjectId', JSON.stringify(this.state.imageProject));
    formData.append('siteConfigId', JSON.stringify(this.state.siteConfigId));
    formData.append('modalities', JSON.stringify({modality: Array.isArray(selModalities) ? selModalities : [{...selModalities}]}));
    formData.append('patientId', JSON.stringify(this.state.patientId));
    formData.append('visitId', JSON.stringify(this.state.visitDataId));
    formData.append('visitConfigId', JSON.stringify(this.state.visitConfId));
    formData.append('comment', dataUploadForm.comment);
    formData.append('bucketLocation', this.state.bucketLocation);
    formData.append('unscheduled', JSON.stringify(this.state.unscheduled));

    formData.append('edtf', JSON.stringify(this.edtfData.edtf));
    if (this.state.isUseEDTFUpload === true) {
      if (edtfFiles.length > 0) {
        const file = {
          name: edtfFiles[0].name,
          size: edtfFiles[0].size,
        };
        const edtfInfo = new UploadFileInfo();
        edtfInfo.name = edtfFiles[0].name;
        edtfInfo.size = edtfFiles[0].size;
        edtfInfo.status = 'pending';
        edtfInfo.resourceType = 'idtf';
        this.uploadFilesInfo.push(edtfInfo);
        formData.append('edtfFile', JSON.stringify(file));
      }
    } else { formData.append('edtfFile', null); }


    for (let i = 0; i < dataFiles.length; i++) {
      const extention = await this.extractFileTypes(dataFiles[i]);
      const resourceType =Array.isArray(extention) ? extention.filter(ex => dxaFileTypes.includes(ex.substring(0, 2))).length > 0 ? 'dxa' :
        ((extention.filter(ex => documentFileTypes.includes(ex)).length > 0 && this.checkEDTFForUploadDocument()) ? 'document' : 'image') : "image";
      const file = {
        name: dataFiles[i].name,
        size: dataFiles[i].size,
        resourceType: resourceType
      };
      const fInfo = new UploadFileInfo();
      fInfo.name = dataFiles[i].name;
      fInfo.size = dataFiles[i].size;
      fInfo.status = 'pending';
      fInfo.resourceType = resourceType;
      this.uploadFilesInfo.push(fInfo);
      registerFilesInfo.push(file);
    }
    formData.append('files', JSON.stringify(registerFilesInfo));
    this.openDialog();
    this.serviceDataUpload.getUploaddFileInformation(formData)
      .pipe(first())
      .subscribe(
        data => {
          const nonImage = (data as any[]).find(d => d.status === 'success'
          && (d.name === 'dxa-dummy.zip' || d.name === 'document-dummy.zip'));
          if (nonImage !== undefined) {
            const fInfo = new UploadFileInfo();
            fInfo.name = nonImage.name;
            fInfo.size = nonImage.size;
            fInfo.status = nonImage.status;
            fInfo.resourceType = 'image';
            this.uploadFilesInfo.push(fInfo);
          }
          for (let i = 0; i < this.uploadFilesInfo.length; i++) {
            const fi = this.getElementWithFileNameInfo(data, this.uploadFilesInfo[i]);
            if (fi != null) {
              this.uploadFilesInfo[i].id = fi.id;
              this.uploadFilesInfo[i].status = fi.status;
            }
          }
          this.uploadFiles(formData, data, dataFiles);
          if (edtfFiles != null) {
            this.uploadFiles(formData, data, edtfFiles);
          }
        }, (error) => {
          this.navigateAfterDialogClosed = false;
          this.dialog.closeAll();
          if (error.status === 400) {
            this.toastOptions.title = "ERROR: Acquisition Date";
            this.toastOptions.msg = "Acquisition Date must be valid";
            this.toastyService.error(this.toastOptions);
          }
        });
  }

  extractFileTypes(file: any): Promise<any[]> {
    return new Promise(resolve => {
      jszip.loadAsync(file)
        .then((zip) => {
          const regex = /(?:\.([^.]+))?$/;
          const extentions = [];
          zip.forEach(function (relativePath, zipEntry) {
            if (zipEntry.dir === false) {
              const entryName : any[] = regex.exec(zipEntry.name);
              if (entryName[1])
                extentions.push((regex.exec(zipEntry.name)[1]));
            }
          });
          resolve(extentions)
        }, (e) => {
          resolve(e.message)
        });
    });
  }

  noUploadVisitUpdate(): void {
    this.imagingProjectSerivce
      .updateNoUploads(this.state.imageProject, this._visitConfig.id,
        true, this.uploadForm.controls.comment.value, Number(JSON.parse(localStorage.getItem('userId'))))
      .subscribe(resp => {
        if (resp.responseCode === ResponseCode.OK) {
          this.router.navigate(['/imagingproject/upload']);
          this.toastOptions.title = 'No upload flag is submitted successfully';
          this.toastOptions.msg = 'The timepoint now is no longer available for the uploads. QC specialist must review the timepoint and confirm the event.';
          this.toastyService.success(this.toastOptions);
        } else {
          this.isNoUploadSaveDisabled = false;
          this.showNoUploadError();
        }
      }, error => {
        this.showNoUploadError();
      });
  }

  showUploadError(): void {
    this.toastOptions.title = 'The upload process failure';
    this.toastOptions.msg = 'Process has failed due to some reason. Resolve issues on the form or contact support team';
    this.toastyService.error(this.toastOptions);
  }

  showNoUploadError(): void {
    this.toastOptions.title = 'No upload flag failure';
    this.toastOptions.msg = 'No upload flag is not submitted due to some reason. Resolve issues on the form or contact support team';
    this.toastyService.error(this.toastOptions);
  }

  updateEDTF() {
    const uploadDataInfo = {
      bucketLocation: this.state.bucketLocation,
      imageProjectId: this.state.imageProject,
      visitConfigId: this.state.visitConfId,
      edtf: null,
      edtfFile: [],
    };

    let edtfFiles;
    if (this.selectedEdtfFile != null) {
      edtfFiles = this.selectedEdtfFile['files'];
    }

    if (this.state.isUseEDTFUpload == false) {
      uploadDataInfo.edtf = this.edtfData.edtf;
      uploadDataInfo.edtfFile = [];
    } else {
      if (edtfFiles.length > 0) {
        this.uploadFilesInfo = [];
        edtfFiles.forEach(edtfFile => {
          const file = {
            name: edtfFile.name,
            size: edtfFile.size,
          };
          const edtfInfo = new UploadFileInfo();
          edtfInfo.name = edtfFile.name;
          edtfInfo.size = edtfFile.size;
          edtfInfo.status = 'pending';
          edtfInfo.resourceType = 'idtf';
          this.uploadFilesInfo.push(edtfInfo);

          uploadDataInfo.edtfFile.push(file);
          uploadDataInfo.edtf = null;
        });
      }
    }

    this.serviceDataUpload.getUploadEdtfFileInformation(uploadDataInfo)
      .pipe(first())
      .subscribe(
        edtfResp => {
          if (Array.isArray(edtfResp['data']) == true && edtfResp['data']['length'] > 0) {
            // upload eDTF files
            // response contained URLs for google cloud
            this.uploadFilesInfo.forEach(file => {
              const fi = edtfResp['data'].find(item => item.name === file.name && item.size === file.size);
              if (fi != null) {
                file.id = fi.id;
                file.status = fi.status;
              }
            });
            this.uploadEdtfFiles(edtfResp['data'], edtfFiles);
          } else {
            // response when update inly eDTF json.
            // The response contained general_upload_info
            this.router.navigate(['/imagingproject/upload']);
          }
        }
      );
  }

  uploadFiles(formData: FormData, dbFilesInfo, files) {
    for (let i = 0;  i < files.length; i++) {
      const fileInfo = this.getElementWithFileNameInfo(dbFilesInfo, files[i]);
      if (fileInfo != null) {
        this.serviceDataUpload.uplodFileToCloud(fileInfo.url, files[i])
          .pipe(first())
          .subscribe( data => {
            this.setUploadFileInfosStatus(fileInfo.id, 'success');
          },
            error => {
            this.setUploadFileInfosStatus(fileInfo.id, 'failed');
          });
      }
    }
  }

  uploadEdtfFiles(dbFilesInfo, files) {
    this.openDialog();
    files.forEach(file => {
      const fileInfo = dbFilesInfo.find(item => item.name === file.name && item.size === file.size);
      if (fileInfo != null) {
        this.serviceDataUpload.uplodFileToCloud(fileInfo.url, file)
          .pipe(first())
          .subscribe( data => {
              this.setUploadEDTFFileInfosStatus(fileInfo.id, 'success');
            },
            error => {
              this.setUploadEDTFFileInfosStatus(fileInfo.id, 'failed');
            });
      }
    });
  }

  setUploadFileInfosStatus(fileId, status) {
    let all = true;
    for (let j = 0; j < this.uploadFilesInfo.length; j++) {
      if (fileId == this.uploadFilesInfo[j].id) {
        this.uploadFilesInfo[j].status = status;
      }
      if (this.uploadFilesInfo[j].status == 'pending') {
        all = false;
      }
    }
    if (all === true) {
      this.serviceDataUpload.setUploadFilesStatusInformation(this._patient['id'], this._patient['discontinued'], this.uploadFilesInfo)
        .pipe(first())
        .subscribe( data => {
          this.dialog.closeAll();
        },
          error => {
            this.dialog.closeAll();
        });
    }
  }

  setUploadEDTFFileInfosStatus(fileId, status) {
    let all = true;
    this.uploadFilesInfo.forEach(ufi => {
      if (fileId == ufi.id) {
        ufi.status = status;
      }
      if (ufi.status == 'pending') {
        all = false;
      }
    });
    if (all === true) {
      this.serviceDataUpload.setUploadEDTFFilesStatusInformation(
        this.state.imageProject, this._patient['id'], this._patient['discontinued'], this.uploadFilesInfo)

        .pipe(first())
        .subscribe( data => {
            this.dialog.closeAll();
          },
          error => {
            this.dialog.closeAll();
          });
    }
  }

  getElementWithFileNameInfo(array, element) {
    const ans = array.find(item => item.name === element.name && item.size === element.size);
    return ans;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(UploadDialogComponent, {
      height: '400px',
      width: '600px',
      disableClose: true,
      data: {files: this.uploadFilesInfo}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (this.navigateAfterDialogClosed)
        this.router.navigate(['/imagingproject/upload']);
      this.navigateAfterDialogClosed = true;
    });
  }

  toggleEdtfLogic() {
    this.state.isUseEDTFUpload = !this.state.isUseEDTFUpload;
  }

  downloadFile(file) {
    window.location.assign(file.url);
  }

  hasUploads(): boolean {
    return this?.visitDetails?.some(vd => vd.files.length > 0);
  }

  checkEDTFMandatoryFieldsAreValid(): boolean {
    let invalid = false;
    if (this.edtfData) {
      const edtf = this.edtfData.edtf;
      if (edtf) {
        edtf.blocks.forEach(block => {
          if (block.hasOwnProperty('radioButton')) {
            if (block['radioButton'] === true && (block['radioButtonValue'] === '' || !block['radioButtonValue'])&&
              block['question'].toString().trimEnd().trimStart().toLowerCase() === 'gbm eligibility version')
              invalid = true
            if (block['radioButton'] === true && (block['radioButtonValue'] === '' || !block['radioButtonValue']) &&
              block['question'].toString().trimEnd().trimStart().toLowerCase() === 'was the subject at first progression treated by re-resection or biopsy to confirm progression?') {
              invalid = this.getGBMVersionAnswer()?.toLocaleLowerCase() !== 'protocol amendment v4.0';
            }
            if (block['radioButton'] === true && block.hasOwnProperty('validation')) {
              if (block.validation[0].type === 'warningIsEmpty' && !block['radioButtonValue'])
                invalid = true;
            }
          }
          if (block.hasOwnProperty('group')) {
            if (block.group.length > 0) {
              if (block.group[0].hasOwnProperty('inputText')) {
                if (block.group[0].inputText.hasOwnProperty('validation')) {
                  if (block.group[0].inputText.validation[0].type === 'warningIsEmpty' && block.group[0].inputText.value === '') {
                    invalid = true
                  }
                }
              }
            }
          }
          if (block['question']) {
            if (block['question'].toLowerCase().trim() === 'select laterality of the hand') {
              if (block.group?.length) {
                invalid = block.group.filter(g => g.checkbox?.value === true).length === 0;
              }
            }
          }
          if (block['question']) {
            if (block['question'].toLowerCase().trim() === 'select laterality of the hand') {
              if (block.group?.length) {
                invalid = block.group.filter(g => g.checkbox?.value === true).length === 0;
              }
            }
          }
        });
      }
    }
    return invalid;
  }

  getGBMVersionAnswer() {
    let answer = '';
    if (this.edtfData) {
      const edtf = this.edtfData.edtf;
      if (edtf) {
        edtf.blocks.forEach(block => {
          if (block.hasOwnProperty('radioButton')) {
            if (block['radioButton'] === true &&
              block['question'].toString().trimEnd().trimStart().toLowerCase() === 'gbm eligibility version')
              answer = block['radioButtonValue'];
          }
        });
      }
    }
    return answer;
  }

  checkEDTFForUploadDocument() {
    let document = false;
    if (this.edtfData) {
      const edtf = this.edtfData.edtf;
      if (edtf) {
        edtf.blocks.forEach(block => {
          if (block.hasOwnProperty('group')) {
            const groups = block.group;
            groups.forEach(group => {
              if (group.hasOwnProperty('question')) {
                if (group['question'].toString().trimEnd().trimStart().toLowerCase() === 'the upload include only a pdf document file and no dicom sequence') {
                  if (group.hasOwnProperty('checkbox')) {
                    if(group.checkbox.value === true)
                      document = true;
                  }
                }
              }
            })
          }
        });
      }
    }
    return document;
  }
}
