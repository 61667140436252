import {Router} from '@angular/router';
import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {
  ReadingConfigFlexibleService,
  ReadingDemriqService,
  ReadingIncidentalFindingsService,
  ReadingKandlService,
  ReadingPsamrisService,
  ReadingPsmaPetEligibilityService,
  ReadingWbmriService,
  StudyService,
  StudyUserService,
  UserRoleMetaDataService
} from '../../../_services';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Store} from '@ngxs/store';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';
import {ReadingVersion} from '../../../core/constants/reading-version';
import {ReadingMoaksService} from '../../../_services/reading-moaks.service';
import {ReadingCandenSparccService} from '../../../_services/reading-canden-sparcc.service';
import {ReadingNovadip5Service} from '../../../_services/reading-novadip5.service';
import {IconService} from '../../../_services/icons/icon.service';
import {ReadingLuganoService} from '../../../_services/reading-lugano.service';
import {ReadingRecistService} from '../../../_services/reading-recist.service';
import {ReadingMranoService} from 'src/app/_services/reading-mrano.service';
import {ReadingHeelService} from 'src/app/_services/reading-heel.service';
import {ReadingJSWService} from '../../../_services/reading-jsw.service';
import {
  AdjudicationExternalServiceName,
  UploadAdjFileDialogComponent,
  UploadAdjFileDialogData
} from '../../OperationsDashboard/upload-adj-file-dialog/upload-adj-file-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import { ReadingOarsiService } from 'src/app/_services/reading-oarsi.service';
import { ReadingDXAService } from 'src/app/_services/reading-dxa.service';
import { ReadingGenantService } from 'src/app/_services/reading-genant.service';
import {CompactJwt} from '../../../_helpers/CompactJwt';
import { ReadingAdvancedAnalysisService } from 'src/app/_services/reading-advanced-analysis.service';
import { ReadingGuerbetService } from 'src/app/_services/reading-guerbet.service';
import { ReadingL3smiService } from 'src/app/_services/reading-l3smi.service';
import {ReadingAtyrService} from "../../../_services/reading-atyr.service";
import {forkJoin} from 'rxjs';
import { BasicResponse } from 'src/app/core/interfaces/basic-response';
import { ReadingMEPOService } from 'src/app/_services/reading-mepo.service';
import { ReadingRAPNOService } from 'src/app/_services/reading-rapno.service';

export interface ProjectData {
  id: number;
  sponsor: string;
  name: string;
  dashboardUrl: string;
  status: boolean;
  numberOfAvailableReadingsForIF: number;
  numberOfAvailableReadingsForKandL: number;
  numberOfAvailableReadingsForKandLAdjudication: number;
  numberOfAvailableReadingsForGenant: number;
  numberOfAvailableReadingsForGenantAdjudication: number;
  numberOfAvailableReadingsForPSAMRIS: number;
  numberOfAvailableReadingsForPSAMRISAdjudication: number;
  numberOfAvailableReadingsForWBMRI: number;
  numberOfAvailableReadingsForMOAKS: number;
  numberOfAvailableReadingsForCandenSparcc: number;
  numberOfAvailableReadingsForCandenSparccModeration: number;
  numberOfAvailableReadingsForJSW: number;
  numberOfAvailableReadingsForOARSI: number;
  numberOfAvailableReadingsForL3SMI: number;
  numberOfAvailableReadingsForJSWModeration: number;
  numberOfAvailableReadingsForWBMRIAdjudication: number;
  numberOfAvailableReadingsForNovadip5: number;
  numberOfAvailableReadingsForLugano: number;
  numberOfAvailableReadingsForRecist: number;
  numberOfAvailableReadingsForMRANO: number;
  numberOfAvailableReadingsForRANOAdjudication: number;
  numberOfAvailableReadingsForAdvancedAnalysis: number;
  numberOfAvailableReadingsForHeel: number;
  numberOfAvailableReadingsForDEMRIQ: number;
  numberOfAvailableReadingsForPSMAPET: number;
  numberOfAvailableReadingsForDXA: number;
  numberOfAvailableReadingsForGuerbet: number;
  numberOfAvailableReadingsForGuerbetAdjudication: number;
  numberOfAvailableReadingsForGuerbetConsensus: number;
  numberOfAvailableReadingsForAtyr: number;
  numberOfAvailableReadingsForMepo: number;
  numberOfAvailableReadingsForRAPNO: number;
  hasReadingAccessForIF: boolean;
  hasReadingAccessForKandL: boolean;
  hasReadingAccessForKandLAdjudication: boolean;
  hasReadingAccessForGenant: boolean;
  hasReadingAccessForGenantAdjudication: boolean;
  hasReadingAccessForPsAMRIS: boolean;
  hasReadingAccessForPsAMRISAdjudication: boolean;
  hasReadingAccessForWBMRI: boolean;
  hasReadingAccessForWBMRIAdjudication: boolean;
  hasReadingAccessForMOAKS: boolean;
  hasReadingAccessForCandenSparcc: boolean;
  hasReadingAccessForCandenSparccModeration: boolean;
  hasReadingAccessForJSW: boolean;
  hasReadingAccessForOARSI: boolean;
  hasReadingAccessForL3SMI: boolean;
  hasReadingAccessForJSWModeration: boolean;
  hasReadingAccessForNovadip5: boolean;
  hasReadingAccessForLugano: boolean;
  hasReadingAccessForRecist: boolean;
  hasReadingAccessForMRANO: boolean;
  hasReadingAccessForRANOAdjudication: boolean;
  isRANOAdjudication: boolean;
  hasReadingAccessForAdvancedAnalysis: boolean;
  hasReadingAccessForHeel: boolean;
  hasReadingAccessForDEMRIQ: boolean;
  hasReadingAccessForPSMAPET: boolean;
  hasReadingAccessForDXA: boolean;
  hasReadingAccessForAtyr: boolean;
  hasReadingAccessForMepo: boolean;
  hasReadingAccessForRAPNO: boolean;
  hasImportAdjudicationForWBMRI: boolean;
  hasImportAdjudicationForPSAMRIS: boolean;
  hasReadingAccessForGuerbet: boolean;
  hasReadingAccessForGuerbetAdjudication: boolean;
  hasReadingAccessForGuerbetConsensus: boolean;
}

const helper = new JwtHelperService();

const VERSION_KL_BASIC = 'ReadingVersion-BasicKL';
const VERSION_KL_ADJUDICATION = 'ReadingVersion-AdjudicationKL';
const VERSION_PSAMRIS_BASIC = 'ReadingVersion-BasicPsAMRIS';
const VERSION_PSAMRIS_ADJUDICATION = 'ReadingVersion-AdjudicationPsAMRIS';

export const EXTERNAL_ADJ_RULE = 'Adjudication-ExternalRule';

@Component({
  selector: 'app-study-list',
  templateUrl: './imaging-project-list.component.html',
  styleUrls: ['./imaging-project-list.component.css']
})
export class ImagingProjectListComponent implements OnInit {
  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };
  displayedColumns: string[] = ['row-num', 'sponsor', 'name', 'status', 'operations'];
  dataSource = new MatTableDataSource();
  activities: any = [];
  globals: any[] = [];
  projectList: ProjectData[] = [];

  metaDatas: any = [];
  jsontoken: any = {};
  userId: number;
  readingConfigs: any[] = [];
  showModalSpinner = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router,
              private studyService: StudyService,
              private studyUserService: StudyUserService,
              private readingIncidentialFindingsService: ReadingIncidentalFindingsService,
              private readingKandlService: ReadingKandlService,
              private readingGenantService: ReadingGenantService,
              private readingPsamrisService: ReadingPsamrisService,
              private readingWbmriService: ReadingWbmriService,
              private readingMoaksService: ReadingMoaksService,
              private readingCandenSparccService: ReadingCandenSparccService,
              private readingJSWService: ReadingJSWService,
              private readingOARSIService: ReadingOarsiService,
              private ReadingL3smiService: ReadingL3smiService,
              private readingNovadip5Service: ReadingNovadip5Service,
              private readingLuganoService: ReadingLuganoService,
              protected readingRecistService: ReadingRecistService,
              private readingMRANOService: ReadingMranoService,
              private readingAdvancedAnalysisService: ReadingAdvancedAnalysisService,
              private readingHeelService: ReadingHeelService,
              private readingDEMRIQService: ReadingDemriqService,
              private userRoleMetaDataService: UserRoleMetaDataService,
              private readingConfigFlexibleService: ReadingConfigFlexibleService,
              private readingPsmaPetEligibilityService: ReadingPsmaPetEligibilityService,
              private readingDXAService: ReadingDXAService,
              private readingGuerbetService: ReadingGuerbetService,
              private readingAtyrService: ReadingAtyrService,
              private readingMepoService: ReadingMEPOService,
              private readingRAPNOService: ReadingRAPNOService,
              private toastyService: ToastyService,
              private store: Store,
              private iconService: IconService,
              private dialog: MatDialog
  ) {
    this.jsontoken = CompactJwt.decodeActivities(helper.decodeToken(localStorage.getItem('currentUser')));
    this.globals = JSON.parse(localStorage.getItem('globals'));
  }

  ngOnInit() {
    this.store.dispatch(new SetPageHeaderTitle('Select Imaging Project'));
    this.iconService.registerIcons();
    this.userId = parseInt(JSON.parse(localStorage.getItem('userId')), 10);
    this.activities = JSON.parse(localStorage.getItem('activities'));
    localStorage.removeItem('project');
    this.showModalSpinner = true;
    if (this.isUserHasActivity('configuration.study.create', null)) {

      this.loadProjects(null);
    } else {

      // Changed from unsecure by email to more secure by Id
      this.studyUserService.getStudies(this.userId).subscribe(
        (sweResponse: any) => {
          if (sweResponse.responseCode === 200) {
            const projectIds = sweResponse.data;

            this.loadProjects(projectIds);
          } else {
            this.showModalSpinner = false;
            this.toastOptions.title = 'ERROR ' + sweResponse.responseCode;
            this.toastOptions.msg = sweResponse.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }, (error?: any) => {
          console.error(error),
          this.showModalSpinner = false;
          this.toastOptions.title = 'ERROR ' + 'Internal Error';
          this.toastOptions.msg = 'Failed to load studies for ' + localStorage.getItem('subject');
          this.toastyService.error(this.toastOptions);
       }
      );
    }
  }

  loadProjects(projectIds: number[]) {
    forkJoin([
      this.studyService.getAllForImagingProjectList(),
      this.readingConfigFlexibleService.getAllForImagingProjectList()
    ]).subscribe(
      ([studiesResponse, configurationsResponse]: [ BasicResponse<any>, BasicResponse<any>]) => {
        if (studiesResponse.responseCode === 200 && configurationsResponse.responseCode === 200) {
          // if no projectIds to filter - all studies are shown
          const studies = studiesResponse.data.filter(study => !projectIds || projectIds.length == 0 || projectIds.includes(study.id));
          const readingConfigs = configurationsResponse.data;

          this.projectList = this.initializeProjects(studies, readingConfigs);
          this.dataSource = new MatTableDataSource(this.projectList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.showModalSpinner = false;
        } else {
          this.showModalSpinner = false;
          this.toastOptions.title = 'ERROR ' + (studiesResponse.responseCode === 200 ? configurationsResponse.responseCode : studiesResponse.responseCode);
          this.toastOptions.msg = (studiesResponse.responseCode === 200 ? configurationsResponse.responseMessage : studiesResponse.responseMessage);
          this.toastyService.error(this.toastOptions);
        }
      },
      error => {
        console.error(error),
        this.showModalSpinner = false;
        this.toastOptions.title = 'ERROR ' + 'Internal Error';
        this.toastOptions.msg = 'Failed to load study';
        this.toastyService.error(this.toastOptions);
      }
    );
  }

  initializeProjects(studies: any[], readingConfigs: any[]): ProjectData[] {

    const projectList = studies.map(study => {
      const x = {
        id: study.id,
        sponsor: study.sponsor?.name,
        name: study.name,
        dashboardUrl: study.dashboardUrl,
        status: study.status,
        numberOfAvailableReadingsForIF: 0,
        numberOfAvailableReadingsForKandL: 0,
        numberOfAvailableReadingsForKandLAdjudication: 0,
        numberOfAvailableReadingsForGenant: 0,
        numberOfAvailableReadingsForGenantAdjudication: 0,
        numberOfAvailableReadingsForPSAMRIS: 0,
        numberOfAvailableReadingsForPSAMRISAdjudication: 0,
        numberOfAvailableReadingsForWBMRI: 0,
        numberOfAvailableReadingsForMOAKS: 0,
        numberOfAvailableReadingsForCandenSparcc: 0,
        numberOfAvailableReadingsForCandenSparccModeration: 0,
        numberOfAvailableReadingsForJSW: 0,
        numberOfAvailableReadingsForOARSI: 0,
        numberOfAvailableReadingsForL3SMI: 0,
        numberOfAvailableReadingsForJSWModeration: 0,
        numberOfAvailableReadingsForWBMRIAdjudication: 0,
        numberOfAvailableReadingsForNovadip5: 0,
        numberOfAvailableReadingsForLugano: 0,
        numberOfAvailableReadingsForRecist: 0,
        numberOfAvailableReadingsForMRANO: 0,
        numberOfAvailableReadingsForRANOAdjudication: 0,
        numberOfAvailableReadingsForAdvancedAnalysis: 0,
        numberOfAvailableReadingsForHeel: 0,
        numberOfAvailableReadingsForDEMRIQ: 0,
        numberOfAvailableReadingsForPSMAPET: 0,
        numberOfAvailableReadingsForDXA: 0,
        numberOfAvailableReadingsForGuerbet: 0,
        numberOfAvailableReadingsForGuerbetAdjudication: 0,
        numberOfAvailableReadingsForGuerbetConsensus: 0,
        numberOfAvailableReadingsForAtyr: 0,
        numberOfAvailableReadingsForMepo: 0,
        numberOfAvailableReadingsForRAPNO: 0,
        hasReadingAccessForIF: false,
        hasReadingAccessForKandL: false,
        hasReadingAccessForKandLAdjudication: false,
        hasReadingAccessForGenant: false,
        hasReadingAccessForGenantAdjudication: false,
        hasReadingAccessForPsAMRIS: false,
        hasReadingAccessForPsAMRISAdjudication: false,
        hasReadingAccessForWBMRI: false,
        hasReadingAccessForMOAKS: false,
        hasReadingAccessForCandenSparcc: false,
        hasReadingAccessForCandenSparccModeration: false,
        hasReadingAccessForJSW: false,
        hasReadingAccessForOARSI: false,
        hasReadingAccessForL3SMI: false,
        hasReadingAccessForJSWModeration: false,
        hasReadingAccessForWBMRIAdjudication: false,
        hasReadingAccessForNovadip5: false,
        hasReadingAccessForLugano: false,
        hasReadingAccessForRecist: false,
        hasReadingAccessForMRANO: false,
        hasReadingAccessForRANOAdjudication: false,
        isRANOAdjudication: false,
        hasReadingAccessForAdvancedAnalysis: false,
        hasReadingAccessForHeel: false,
        hasReadingAccessForDEMRIQ: false,
        hasReadingAccessForPSMAPET: false,
        hasReadingAccessForDXA: false,
        hasReadingAccessForAtyr: false,
        hasReadingAccessForRAPNO: false,
        hasImportAdjudicationForWBMRI: false,
        hasImportAdjudicationForPSAMRIS: false,
        hasReadingAccessForGuerbet: false,
        hasReadingAccessForGuerbetAdjudication: false,
        hasReadingAccessForGuerbetConsensus: false,
        hasReadingAccessForMepo: false,
      };
      return x;
    });

    projectList.forEach(project => {
        const studyReadingConfigs = readingConfigs.filter(readingConfig => readingConfig.studyId === project.id);
        if (this.isUserHasActivity('reader.central', project.id)) {
          // project info
          studyReadingConfigs.forEach(studyReadingConfig => {
            const configActive = studyReadingConfig.active;
            const config = studyReadingConfig;
            const isUserIdentified = studyReadingConfig.readers.some(readerId => readerId === this.userId);
            const isUserAdjudicationIdentified = config.adjudicationReaderId === this.userId;
            const isUserModerationIdentified = config.moderationReaderId === this.userId;
            const isUserConsensusIdentified = config.consensusReaderId === this.userId;

            if (isUserIdentified || isUserAdjudicationIdentified || isUserModerationIdentified || isUserConsensusIdentified) {
              switch (config.endpoint) {
                case 'Incidental Findings': {
                  this.updateIFProjectNumberOfReadings(project, config, configActive);
                  break;
                }
                case 'K&L': {
                  this.updateKLProjectNumberOfReadings(project, config, configActive);
                  break;
                }
                case 'GENANT': {
                  this.updateGenantProjectNumberOfReadings(project, config, configActive);
                  break;
                }
                case 'PSAMRIS': {
                  this.updatePasmrisProjectNumberOfReadings(project, config, configActive);
                  break;
                }
                case 'WBMRI': {
                  this.updateWbmriProjectNumberOfReadings(project, config, configActive);
                  break;
                }
                case 'MOAKS': {
                  this.updateMoaksProjectNumberOfReadings(project, config, configActive);
                  break;
                }
                case 'CANDEN&SPARCC': {
                  this.updateCASProjectNumberOfReadings(project, config, configActive);
                  break;
                }
                case 'JSW': {
                  this.updateJSWProjectNumberOfReadings(project, config, configActive);
                  break;
                }
                case 'OARSI': {
                  this.updateOARSIProjectNumberOfReadings(project, config, configActive);
                  break;
                }
                case 'L3SMI': {
                  this.updateL3SMIProjectNumberOfReadings(project, config, configActive);
                  break;
                }
                case 'NOVADIP5': {
                  this.updateNovadipProjectNumberOfReadings(project, config, configActive);
                  break;
                }
                case 'LUGANO': {
                  this.updateLuganoProjectNumberOfReadings(project, config, configActive);
                  break;
                }
                case 'RECIST': {
                  this.updateRecistProjectNumberOfReadings(project, config, configActive);
                  break;
                }
                case 'GUERBET': {
                  this.updateGuerbetProjectNumberOfReadings(project, config, configActive);
                  break;
                }
                case 'RANO': {
                  this.updateMRanoProjectNumberOfReadings(project, config, configActive);
                  break;
                }
                case 'Advanced Analysis': {
                  this.updateAdvancedAnalysisProjectNumberOfReadings(project, config, configActive);
                  break;
                }
                case 'HEEL': {
                  this.updateHeelProjectNumberOfReadings(project, config, configActive);
                  break;
                }
                case 'DEMRIQ': {
                  this.updateDEMRIQProjectNumberOfReadings(project, config, configActive);
                  break;
                }
                case 'PSMA PET Eligibility': {
                  this.updatePSMAPETProjectNumberOfReadings(project, config, configActive);
                  break;
                }
                case 'DXA': {
                  this.updateDXAProjectNumberOfReadings(project, config, configActive);
                  break;
                }
                case 'aTyr': {
                  this.updateAtyrProjectNumberOfReadings(project, config, configActive);
                  break;
                }
                case 'MEPO': {
                  this.updateMepoProjectNumberOfReadings(project, config, configActive);
                  break;
                }
                case 'RAPNO': {
                  this.updateRAPNOProjectNumberOfReadings(project, config, configActive);
                  break;
                }
              }
            }
          });
        }

        project.hasImportAdjudicationForWBMRI = studyReadingConfigs.some(
            studyReadingConfig =>
                studyReadingConfig.endpoint === 'WBMRI'
                && studyReadingConfig.active
                && studyReadingConfig.config?.adjudicationRule === EXTERNAL_ADJ_RULE
        );
        project.hasImportAdjudicationForPSAMRIS = studyReadingConfigs.some(
            studyReadingConfig =>
                studyReadingConfig.endpoint === 'PSAMRIS'
                && studyReadingConfig.active
                && studyReadingConfig.config?.adjudicationRule === EXTERNAL_ADJ_RULE
        );
      });

    return projectList;
  }

  addProject() {
    this.router.navigate(['/imagingproject/project/create/-1']);
  }

  recalculateActivities(element) {
    localStorage.setItem('project', JSON.stringify(element));
    this.activities = this.jsontoken.permissions.activities;
    this.activities.forEach(act => {
      if (act.projectId === element.id) {
        localStorage.setItem('activities', JSON.stringify(act.activities));
      }
    });
    this.activities = localStorage.getItem('activities');
    // we need to erase that data before navigation, since it can be used wrong value
    localStorage.removeItem('siteId');
    this.userRoleMetaDataService.getMetaDataWithEmail(JSON.parse(localStorage.getItem('project')).id, JSON.parse(localStorage.getItem('subject'))).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.metaDatas = JSON.parse(response.data);
          this.metaDatas.forEach(metaData => {
            if (metaData.site_id) {
              localStorage.setItem('siteId', metaData.site_id);
            }
          });
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
  }

  applyFilter(filterValue: string) {
    if (this.dataSource !== undefined) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  isUserHasActivity(activityName: string, projectId: number): boolean {
    let result = false;
    this.activities = this.jsontoken.permissions.activities;
    if (this.activities) {
      this.activities.forEach(act => {
        if (act.projectId === projectId) {
          localStorage.setItem('activities', JSON.stringify(act.activities));
          this.activities = JSON.parse(JSON.stringify(act.activities));
          if (this.activities.length > 0) {
            result = this.activities.indexOf(activityName) >= 0;
          }
        }
      });
    }
    if (!result) {
      if (this.globals) {
        result = this.globals.indexOf(activityName) >= 0;
      }
    }
    return result;
  }

  navigateTo(url: string, element, param?: { name: string, value: any }) {
    this.recalculateActivities(element);
    setTimeout(() => {
      if (!!param) {
        const extra = {};
        extra[param.name] = param.value;
        this.router.navigate([url, extra]);
      } else {
        this.router.navigate([url]);
      }
   }, 50);
  }

  updateIFProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    if (!(project.numberOfAvailableReadingsForIF > 0)) {
      this.readingIncidentialFindingsService.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            const readingsCount = response.data;
            project.numberOfAvailableReadingsForIF = readingsCount;
            project.hasReadingAccessForIF =
              project.hasReadingAccessForIF || (
                project.numberOfAvailableReadingsForIF > 0 || isConfigActive);
          } else {
            this.processError(response);
          }
        }
      );
    }
  }

  updateKLProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    // KANDL_BASIC
    if (!(project.numberOfAvailableReadingsForKandL > 0) && !!config.readers.find(r => r === this.userId)) {
      this.readingKandlService.getNumberOfAvailableBasicReadings(project.id, this.userId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            const readingsCount = response.data;
            project.numberOfAvailableReadingsForKandL = readingsCount;
            project.hasReadingAccessForKandL =
              project.hasReadingAccessForKandL ||
              (project.numberOfAvailableReadingsForKandL > 0 || isConfigActive);
          } else {
            this.processError(response);
          }
        }
      );
    }
    // KANDL_ADJUDICATION
    if (!(project.numberOfAvailableReadingsForKandLAdjudication > 0)) {
      if (ReadingVersion.KANDL_ADJUDICATION === config.readingVersion && config.adjudicationReaderId === this.userId) {
        this.readingKandlService.getNumberOfAvailableAdjudicationReadings(project.id, this.userId).subscribe(
          (response: any) => {
            if (response.responseCode === 200) {
              const readingsCount = response.data;
              project.numberOfAvailableReadingsForKandLAdjudication = readingsCount;
              project.hasReadingAccessForKandLAdjudication =
                project.hasReadingAccessForKandLAdjudication ||
                (project.numberOfAvailableReadingsForKandLAdjudication > 0 || isConfigActive);
            } else {
              this.processError(response);
            }
          }
        );
      }
    }
  }

  updateGenantProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    // GENANT_Basic
    if (!(project.numberOfAvailableReadingsForGenant > 0) && !!config.readers.find(r => r === this.userId)) {
      this.readingGenantService.getNumberOfAvailableLevelOneReadings(project.id, this.userId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            const readingsCount = response.data;
            project.numberOfAvailableReadingsForGenant = readingsCount;
            project.hasReadingAccessForGenant =
              project.hasReadingAccessForGenant ||
              (project.numberOfAvailableReadingsForGenant > 0 || isConfigActive);
          } else {
            this.processError(response);
          }
        }
      );
    }
    // GENANT_ADJUDICATION
    if (!(project.numberOfAvailableReadingsForGenantAdjudication > 0)) {
      if (ReadingVersion.GENANT_ELIGIBILITY_ADJUDICATION === config.readingVersion && config.adjudicationReaderId === this.userId) {
        this.readingGenantService.getNumberOfAvailableAdjudicationReadings(project.id, this.userId).subscribe(
          (response: any) => {
            if (response.responseCode === 200) {
              const readingsCount = response.data;
              project.numberOfAvailableReadingsForGenantAdjudication = readingsCount;
              project.hasReadingAccessForGenantAdjudication =
                project.hasReadingAccessForGenantAdjudication ||
                (project.numberOfAvailableReadingsForGenantAdjudication > 0 || isConfigActive);
            } else {
              this.processError(response);
            }
          }
        );
      }
    }
  }

  updatePasmrisProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    // PSAMRIS_BASIC
    if (!(project.numberOfAvailableReadingsForPSAMRIS > 0)) {
      this.readingPsamrisService.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            project.numberOfAvailableReadingsForPSAMRIS = response.data;
            project.hasReadingAccessForPsAMRIS =
              (project.hasReadingAccessForPsAMRIS || (project.numberOfAvailableReadingsForPSAMRIS > 0 || isConfigActive)) &&
              config.readers.some(r => r === this.userId);
          } else {
            this.processError(response);
          }
        }
      );
    }
    // PSAMRIS_ADJUDICATION
    if (!(project.numberOfAvailableReadingsForPSAMRISAdjudication > 0)) {
      if (ReadingVersion.PSAMRIS_ADJUDICATION === config.readingVersion && config.adjudicationReaderId === this.userId) {
        this.readingPsamrisService.getNumberOfAvailableAdjudicationReadings(project.id, this.userId).subscribe(
          (response: any) => {
            if (response.responseCode === 200) {
              project.numberOfAvailableReadingsForPSAMRISAdjudication = response.data;
              project.hasReadingAccessForPsAMRISAdjudication =
                project.hasReadingAccessForPsAMRISAdjudication ||
                (project.numberOfAvailableReadingsForPSAMRISAdjudication > 0 || isConfigActive);
            } else {
              this.processError(response);
            }
          }
        );
      }
    }
  }

  // TODO development & testing
  updateWbmriProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    // WBMRI BASIC
    if (!(project.numberOfAvailableReadingsForWBMRI > 0)) {
        if (config.readers.some(r => r === this.userId)) {
            this.readingWbmriService.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
                (response: any) => {
                    if (response.responseCode === 200) {
                        project.numberOfAvailableReadingsForWBMRI = response.data;
                        project.hasReadingAccessForWBMRI =
                            project.hasReadingAccessForWBMRI ||
                            project.numberOfAvailableReadingsForWBMRI > 0 ||
                            isConfigActive;
                    } else {
                        this.processError(response);
                    }
                }
            );
        }
    }
    // WBMRI ADJUDICATION
    if (!(project.numberOfAvailableReadingsForWBMRIAdjudication > 0)) {
      if (ReadingVersion.WBMRI_ADJUDICATION === config.readingVersion && config.adjudicationReaderId === this.userId) {
        this.readingWbmriService.getNumberOfAvailableAdjudicationReadings(project.id, this.userId).subscribe(
          (response: any) => {
            if (response.responseCode === 200) {
              project.numberOfAvailableReadingsForWBMRIAdjudication = response.data;
              project.hasReadingAccessForWBMRIAdjudication =
                    project.hasReadingAccessForWBMRIAdjudication ||
                    project.numberOfAvailableReadingsForWBMRIAdjudication > 0 ||
                    isConfigActive;
            } else {
              this.processError(response);
            }
          }
        );
      }
    }
  }

  updateMoaksProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    if (!(project.numberOfAvailableReadingsForMOAKS > 0)) {
      this.readingMoaksService.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            const readingsCount = response.data;
            project.numberOfAvailableReadingsForMOAKS = readingsCount;
            project.hasReadingAccessForMOAKS =
              project.hasReadingAccessForMOAKS ||
              (project.numberOfAvailableReadingsForMOAKS > 0 || isConfigActive);
          } else {
            this.processError(response);
          }
        }
      );
    }
  }

  updateCASProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    if (!(project.numberOfAvailableReadingsForCandenSparcc > 0)) {
        if (config.readers.some(r => r === this.userId)) {
            this.readingCandenSparccService.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
                (response: any) => {
                    if (response.responseCode === 200) {
                        project.numberOfAvailableReadingsForCandenSparcc = response.data;
                        project.hasReadingAccessForCandenSparcc =
                            project.hasReadingAccessForCandenSparcc ||
                            project.numberOfAvailableReadingsForCandenSparcc > 0 ||
                            isConfigActive;
                    } else {
                        this.processError(response);
                    }
                }
            );
        }
    }
    if (!(project.numberOfAvailableReadingsForCandenSparccModeration > 0)) {
        if (ReadingVersion.CANDEN_SPARCC_MODERATION === config.readingVersion && config.moderationReaderId === this.userId) {
            this.readingCandenSparccService.getNumberOfAvailableModerationReadings(project.id, this.userId).subscribe(
                (response: any) => {
                    if (response.responseCode === 200) {
                        project.numberOfAvailableReadingsForCandenSparccModeration = response.data;
                        project.hasReadingAccessForCandenSparccModeration =
                            project.hasReadingAccessForCandenSparccModeration ||
                            project.numberOfAvailableReadingsForCandenSparccModeration > 0 ||
                            isConfigActive;
                    } else {
                        this.processError(response);
                    }
                }
            );
        }
    }
  }

  updateJSWProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    if (!(project.numberOfAvailableReadingsForJSW > 0)) {
      if (config.readers.some(r => r === this.userId)) {
        this.readingJSWService.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
          (response: any) => {
            if (response.responseCode === 200) {
              project.numberOfAvailableReadingsForJSW = response.data;
              project.hasReadingAccessForJSW =
                project.hasReadingAccessForJSW ||
                project.numberOfAvailableReadingsForJSW > 0 ||
                isConfigActive;
            } else {
              this.processError(response);
            }
          }
        );
      }
    }
    // deprecated
    // if (!(project.numberOfAvailableReadingsForJSWModeration > 0)) {
    //   if (ReadingVersion.JSW_MODERATION === config.readingVersion && config.moderationReaderId === this.userId) {
    //     this.readingJSWService.getNumberOfAvailableModerationReadings(project.id, this.userId).subscribe(
    //       (response: any) => {
    //         if (response.responseCode === 200) {
    //           project.numberOfAvailableReadingsForJSWModeration = response.data;
    //           project.hasReadingAccessForJSWModeration =
    //             project.hasReadingAccessForJSWModeration ||
    //             project.numberOfAvailableReadingsForJSWModeration > 0 ||
    //             isConfigActive;
    //         } else {
    //           this.processError(response);
    //         }
    //       }
    //     );
    //   }
    // }
  }

  updateOARSIProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    if (!(project.numberOfAvailableReadingsForOARSI > 0)) {
      if (config.readers.some(r => r === this.userId)) {
        this.readingOARSIService.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
          (response: any) => {
            if (response.responseCode === 200) {
              project.numberOfAvailableReadingsForOARSI = response.data;
              project.hasReadingAccessForOARSI =
                project.hasReadingAccessForOARSI ||
                project.numberOfAvailableReadingsForOARSI > 0 ||
                isConfigActive;
            } else {
              this.processError(response);
            }
          }
        );
      }
    }
  }

  updateL3SMIProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    if (!(project.numberOfAvailableReadingsForL3SMI > 0)) {
      if (config.readers.some(r => r === this.userId)) {
        this.ReadingL3smiService.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
          (response: any) => {
            if (response.responseCode === 200) {
              project.numberOfAvailableReadingsForL3SMI = response.data;
              project.hasReadingAccessForL3SMI =
                project.hasReadingAccessForL3SMI ||
                project.numberOfAvailableReadingsForL3SMI > 0 ||
                isConfigActive;
            } else {
              this.processError(response);
            }
          }
        );
      }
    }
  }

  updateNovadipProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    if (!(project.numberOfAvailableReadingsForNovadip5 > 0)) {
      this.readingNovadip5Service.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            const readingsCount = response.data;
            project.numberOfAvailableReadingsForNovadip5 = readingsCount;
            project.hasReadingAccessForNovadip5 =
              project.hasReadingAccessForNovadip5 ||
              (project.numberOfAvailableReadingsForNovadip5 > 0 || isConfigActive);
          } else {
            this.processError(response);
          }
        }
      );
    }
  }

  updateLuganoProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    if (!(project.numberOfAvailableReadingsForLugano > 0)) {
      this.readingLuganoService.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            const readingsCount = response.data;
            project.numberOfAvailableReadingsForLugano = readingsCount;
            project.hasReadingAccessForLugano =
              project.hasReadingAccessForLugano ||
              (project.numberOfAvailableReadingsForLugano > 0 || isConfigActive);
          } else {
            this.processError(response);
          }
        }
      );
    }
  }

  updateRecistProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    if (!(project.numberOfAvailableReadingsForRecist > 0)) {
      this.readingRecistService.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            const readingsCount = response.data;
            project.numberOfAvailableReadingsForRecist = readingsCount;
            project.hasReadingAccessForRecist =
              project.hasReadingAccessForRecist ||
              (project.numberOfAvailableReadingsForRecist > 0 || isConfigActive);
          } else {
            this.processError(response);
          }
        }
      );
    }
  }

  updateGuerbetProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {

    // Primary
    if (!(project.numberOfAvailableReadingsForGuerbet > 0)) {
      if (config.readers.some(r => r === this.userId)) {
        this.readingGuerbetService.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
          (response: any) => {
            if (response.responseCode === 200) {
              const readingsCount = response.data;
              project.numberOfAvailableReadingsForGuerbet = readingsCount;
              project.hasReadingAccessForGuerbet =
                project.hasReadingAccessForGuerbet ||
                (project.numberOfAvailableReadingsForGuerbet > 0 || isConfigActive);
            } else {
              this.processError(response);
            }
          }
        );
      }
    }

    // Adjudication
    if (!(project.numberOfAvailableReadingsForGuerbetAdjudication > 0)) {
      if (config.adjudicationReaderId === this.userId) {
        this.readingGuerbetService.getNumberOfAvailableAdjudicationReadings(project.id, this.userId).subscribe(
          (response: any) => {
            if (response.responseCode === 200) {
              const readingsCount = response.data;
              project.numberOfAvailableReadingsForGuerbetAdjudication = readingsCount;
              project.hasReadingAccessForGuerbetAdjudication =
                project.hasReadingAccessForGuerbetAdjudication ||
                (project.numberOfAvailableReadingsForGuerbetAdjudication > 0 || isConfigActive);
            } else {
              this.processError(response);
            }
          }
        );
      }
    }

    if (!(project.numberOfAvailableReadingsForGuerbetConsensus > 0)) {
      if (config.consensusReaderId === this.userId) {
        this.readingGuerbetService.getNumberOfAvailableConsensusReadings(project.id, this.userId).subscribe(
          (response: any) => {
            if (response.responseCode === 200) {
              const readingsCount = response.data;
              project.numberOfAvailableReadingsForGuerbetConsensus = readingsCount;
              project.hasReadingAccessForGuerbetConsensus =
                project.hasReadingAccessForGuerbetConsensus ||
                (project.numberOfAvailableReadingsForGuerbetConsensus > 0 || isConfigActive);
            } else {
              this.processError(response);
            }
          }
        );
      }
    }

  }

  updateMRanoProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    if (!(project.numberOfAvailableReadingsForMRANO > 0)) {
      this.readingMRANOService.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            const readingsCount = response.data;
            project.numberOfAvailableReadingsForMRANO = readingsCount;
            project.hasReadingAccessForMRANO =
              project.hasReadingAccessForMRANO ||
              (project.numberOfAvailableReadingsForMRANO > 0 || isConfigActive);
          } else {
            this.processError(response);
          }
        }
      );

      project.isRANOAdjudication = ReadingVersion.RANO_ADJUDICATION === config.readingVersion;

      if (!(project.numberOfAvailableReadingsForRANOAdjudication > 0)) {
        if (ReadingVersion.RANO_ADJUDICATION === config.readingVersion && config.adjudicationReaderId === this.userId) {
          this.readingMRANOService.getNumberOfAvailableAdjudicationReadings(project.id, this.userId).subscribe(
            (response: any) => {
              if (response.responseCode === 200) {
                const readingsCount = response.data;
                project.numberOfAvailableReadingsForRANOAdjudication = readingsCount;
                project.hasReadingAccessForRANOAdjudication =
                  project.hasReadingAccessForRANOAdjudication ||
                  (project.numberOfAvailableReadingsForRANOAdjudication > 0 || isConfigActive);
              } else {
                this.processError(response);
              }
            }
          );
        }
      }
    }
  }

  updateAdvancedAnalysisProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    if (!(project.numberOfAvailableReadingsForAdvancedAnalysis > 0)) {
      this.readingAdvancedAnalysisService.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            const readingsCount = response.data;
            project.numberOfAvailableReadingsForAdvancedAnalysis = readingsCount;
            project.hasReadingAccessForAdvancedAnalysis =
              project.hasReadingAccessForAdvancedAnalysis ||
              (project.numberOfAvailableReadingsForAdvancedAnalysis > 0 || isConfigActive);
          } else {
           this.processError(response);
         }
        }
      );
    }
  }

  updateHeelProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    if (!(project.numberOfAvailableReadingsForHeel > 0)) {
      this.readingHeelService.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            const readingsCount = response.data;
            project.numberOfAvailableReadingsForHeel = readingsCount;
            project.hasReadingAccessForHeel =
              project.hasReadingAccessForHeel ||
              (project.numberOfAvailableReadingsForHeel > 0 || isConfigActive);
          } else {
            this.processError(response);
          }
        }
      );
    }
  }

  updateDEMRIQProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    if (!(project.numberOfAvailableReadingsForDEMRIQ > 0)) {
      this.readingDEMRIQService.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            const readingsCount = response.data;
            project.numberOfAvailableReadingsForDEMRIQ = readingsCount;
            project.hasReadingAccessForDEMRIQ =
              project.hasReadingAccessForDEMRIQ ||
              (project.numberOfAvailableReadingsForDEMRIQ > 0 || isConfigActive);
          } else {
            this.processError(response);
          }
        }
      );
    }
  }

  updatePSMAPETProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    if (!(project.numberOfAvailableReadingsForPSMAPET > 0)) {
      this.readingPsmaPetEligibilityService.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            const readingsCount = response.data;
            project.numberOfAvailableReadingsForPSMAPET = readingsCount;
            project.hasReadingAccessForPSMAPET =
              project.hasReadingAccessForPSMAPET ||
              (project.numberOfAvailableReadingsForPSMAPET > 0 || isConfigActive);
          } else {
            this.processError(response);
          }
        }
      );
    }
  }

  updateDXAProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    if (!(project.numberOfAvailableReadingsForDXA > 0)) {
      this.readingDXAService.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            const readingsCount = response.data;
            project.numberOfAvailableReadingsForDXA = readingsCount;
            project.hasReadingAccessForDXA =
              project.hasReadingAccessForDXA ||
              (project.numberOfAvailableReadingsForDXA > 0 || isConfigActive);
          } else {
            this.processError(response);
          }
        }
      );
    }
  }

  updateMepoProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    if (!(project.numberOfAvailableReadingsForMepo > 0)) {
      this.readingMepoService.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            const readingsCount = response.data;
            project.numberOfAvailableReadingsForMepo = readingsCount;
            project.hasReadingAccessForMepo =
              project.hasReadingAccessForMepo ||
              (project.numberOfAvailableReadingsForMepo > 0 || isConfigActive);
          } else {
            this.processError(response);
          }
        }
      );
    }
  }

  updateAtyrProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    if (project.numberOfAvailableReadingsForAtyr > 0) {
      return;
    }

    this.readingAtyrService.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          const readingsCount = response.data;
          project.numberOfAvailableReadingsForAtyr = readingsCount;
          project.hasReadingAccessForAtyr =
            project.hasReadingAccessForAtyr ||
            (project.numberOfAvailableReadingsForAtyr > 0 || isConfigActive);
        } else {
          this.processError(response);
        }
      }
    );
  }

  updateRAPNOProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    if (project.numberOfAvailableReadingsForRAPNO > 0) {
      return;
    }

    this.readingRAPNOService.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          const readingsCount = response.data;
          project.numberOfAvailableReadingsForRAPNO = readingsCount;
          project.hasReadingAccessForRAPNO =
            project.hasReadingAccessForRAPNO ||
            (project.numberOfAvailableReadingsForRAPNO > 0 || isConfigActive);
        } else {
          this.processError(response);
        }
      }
    );
  }

  navigateToAdjImportWBMRI(id) {
    this.navigateToAdjImport(id, AdjudicationExternalServiceName.WBMRI);
  }

  navigateToAdjImportPSAMRIS(id) {
    this.navigateToAdjImport(id, AdjudicationExternalServiceName.PSAMRIS);
  }

  navigateToAdjImport(id, service: AdjudicationExternalServiceName) {
    const data: UploadAdjFileDialogData = {
      studyId: id,
      service: service
    };

    const dialogRef = this.dialog.open(UploadAdjFileDialogComponent, {
      width: '700px',
      data: data,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result === 200) {
      }
    });
  }

  processError(response: any) {
    this.toastOptions.title = 'ERROR ' + response.responseCode;
    this.toastOptions.msg = response.responseMessage;
    this.toastyService.error(this.toastOptions);
  }
}
